import { normalize } from 'normalizr';
import * as constants from './constants';

import request from '../../util/request';
import { addEntities } from '../../entities/redux/actions';
import { booking as bookingSchema } from '../../entities/api/schema';

/**
 * STATUS_SET
 */
export const setStatus = (payload) => ({
  type: constants.STATUS_SET,
  payload,
});

/**
 * BOOKINGS_GET
 */
export const getBookings = (status, nextCursor, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.BOOKINGS_GET.REQUEST });

    const payload = await request({
      url: '/bookings',
      method: 'GET',
      params: {
        status,
        next_cursor: nextCursor,
      },
    });

    const { entities, result } = normalize(payload.data, [bookingSchema]);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.BOOKINGS_GET.SUCCESS,
      payload: {
        status,
        data: result,
        nextCursor: payload.next_cursor,
      },
    });
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.BOOKINGS_GET.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.BOOKINGS_GET.COMPLETE });
  }
};

/**
 *.BOOKINGS_REFRESH
 */
export const refreshBookings = (status, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.BOOKINGS_REFRESH.REQUEST });

    const payload = await request({
      url: '/bookings',
      method: 'GET',
      params: {
        status,
      },
    });
    const { entities, result } = normalize(payload.data, [bookingSchema]);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.BOOKINGS_REFRESH.SUCCESS,
      payload: {
        status,
        data: result,
        nextCursor: payload.next_cursor,
      },
    });
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.BOOKINGS_REFRESH.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.BOOKINGS_REFRESH.COMPLETE });
  }
};

/**
 * BOOKINGS_BY_MONTH_GET
 */
export const getBookingsByMonth = (month, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.BOOKINGS_BY_MONTH_GET.REQUEST });

    const payload = await request({
      url: '/bookings',
      method: 'GET',
      params: {
        month,
      },
    });
    const { entities, result } = normalize(payload.data, [bookingSchema]);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.BOOKINGS_BY_MONTH_GET.SUCCESS,
      payload: {
        month,
        data: result,
      },
    });
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.BOOKINGS_BY_MONTH_GET.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.BOOKINGS_BY_MONTH_GET.COMPLETE });
  }
};

/**
 *.BOOKING_UPDATE
 */
export const updateBooking = ({ id, action }, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.BOOKING_UPDATE.REQUEST });

    const payload = await request({
      url: `/bookings/${id}`,
      method: 'PUT',
      data: { action },
    });
    const { entities } = normalize(payload, bookingSchema);

    dispatch(addEntities(entities));
    dispatch({ type: constants.BOOKING_UPDATE.SUCCESS });
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.BOOKING_UPDATE.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.BOOKING_UPDATE.COMPLETE });
  }
};
