/**
 * Get error
 */
export const getError = (state) => state.Inventories.error;

/**
  * Get loading
  */
export const getLoading = (state) => state.Inventories.loading;

/**
  * Get inventories
  */
export const getInventories = (state) => state.Inventories.inventories;

/**
  * Get next cursor
  */
export const getNextCursor = (state) => state.Inventories.nextCursor;
