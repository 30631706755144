import React from 'react';
import { List } from 'react-feather';
import { Link, Text } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';

/* =============================================================================
<ExtraChoices />
============================================================================= */
const ExtraChoices = () => (
  <Link
    flex={1}
    as={NavLink}
    to="/menu/extra_choices"
    display="flex"
  >
    <List />
    <Text pl={3} fontWeight="semibold">
      Extra Choices
    </Text>
  </Link>
);

/* Export
============================================================================= */
export default ExtraChoices;
