import React from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
} from '@chakra-ui/react';

import EmployeesTableItem from './EmployeesTableItem';
import EmployeesTableHeadItem from './EmployeesTableHeadItem';

/* =============================================================================
<EmployeesTable />
============================================================================= */
const EmployeesTable = ({ data }) => (
  <Box flex={1}>
    <Table variant="simple" colorScheme="gray">
      <Thead>
        <Tr>
          {columns.map(renderColumn)}
        </Tr>
      </Thead>
      <Tbody>
        {data.map(renderItem)}
      </Tbody>
    </Table>
  </Box>
);

const renderColumn = (item, i) => <EmployeesTableHeadItem key={`${i}`} title={item} />;
const renderItem = (item) => <EmployeesTableItem key={item} id={item} />;

const columns = [
  'NAME',
  'EMAIL',
  'PHONE',
  'ROLE NAME',
  'SALARY',
  'STATUS',
  'ACTION',
];

/* Export
============================================================================= */
export default EmployeesTable;
