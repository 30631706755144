import { actionGenerator } from '../../util/reduxHelpers';

export const TAB_SET = 'TAB_SET';
export const AREAS_GET = actionGenerator('MANAGEMENT/AREAS_GET');
export const AREA_CREATE = actionGenerator('MANAGEMENT/AREA_CREATE');
export const AREA_UPDATE = actionGenerator('MANAGEMENT/AREA_UPDATE');
export const AREA_DELETE = actionGenerator('MANAGEMENT/AREA_DELETE');

export const TABLES_GET = actionGenerator('MANAGEMENT/TABLES_GET');
export const TABLE_CREATE = actionGenerator('MANAGEMENT/TABLE_CREATE');
export const TABLE_UPDATE = actionGenerator('MANAGEMENT/TABLE_UPDATE');
export const TABLE_DELETE = actionGenerator('MANAGEMENT/TABLE_DELETE');
