import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Switch, BrowserRouter as Router, Redirect } from 'react-router-dom';

import { Helmet } from 'react-helmet';
import Layout from '../common/Layout';
import LiveOrderListener from '../orders/components/LiveOrderListener';
import AuthenticatedRoute from './AuthenticatedRoute';
import UnauthenticatedRoute from './UnauthenticatedRoute';
import NotificationsListener from '../notifications/components/NotificationsListener';

import AuthScreen from '../auth/screens/AuthScreen';
// import SetupScreens from '../setup/screens/SetupScreens';
import InventoryScreen from '../inventory/screens/InventoryScreen';
import MenuScreen from '../menu/screens/MenuScreen';
import RestaurantScreen from '../restaurant/screens/RestaurantScreen';
import EmployeesScreen from '../employees/screens/EmployeesScreen';
import OrdersScreen from '../orders/screens/OrdersScreen';
import OrderHistoryScreen from '../orderHistory/screens/OrderHistoryScreen';
import BookingScreens from '../booking/screens/BookingScreens';
import ManagementScreen from '../management/screens/ManagementScreen';
import PosScreens from '../pos/screens/PosScreens';
import NeighborhoodsScreen from '../neighborhoods/screens/NeighborhoodsScreen';
import DashboardScreen from '../dashboard/screens/DashboardScreen';

import {
  getUser as getUserAction,
  loginWithCode as loginWithCodeAction,
} from '../auth/redux/actions';
import { getUser as getUserSelector } from '../auth/redux/selectors';
import useQueryParams from '../util/useQuery';
import { fromAdminPortal } from '../util/functions';

const LoginWithCode = connect(null, { loginWithCode: loginWithCodeAction })(
  ({ loginWithCode }) => {
    const params = useQueryParams();
    const code = params.get('code');

    useEffect(() => {
      if (!code) return;

      loginWithCode(code);
    }, [code, loginWithCode]);

    return null;
  }
);

/* =============================================================================
<AppNavigation />
============================================================================= */
const AppNavigation = ({ user, getUser }) => {
  const restaurantName =
    user?.restaurant?.name || user?.restaurant?.chain?.name;

  // Get user
  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Router>
      <Layout>
        <Helmet>
          <title>
            {restaurantName
              ? `${restaurantName} - Food Street Merchant`
              : 'Food Street Merchant'}
          </title>
        </Helmet>
        <LoginWithCode />
        <Switch>
          <Redirect from="/" to="/auth/login" exact />
          <UnauthenticatedRoute path="/auth">
            <AuthScreen />
          </UnauthenticatedRoute>
          {/* <AuthenticatedRoute path="/setup">
            <SetupScreens />
          </AuthenticatedRoute> */}
          <AuthenticatedRoute path="/dashboard">
            <DashboardScreen />
          </AuthenticatedRoute>
          <AuthenticatedRoute path="/inventory">
            <InventoryScreen />
          </AuthenticatedRoute>
          <AuthenticatedRoute path="/menu">
            <MenuScreen />
          </AuthenticatedRoute>
          <AuthenticatedRoute path="/restaurant">
            <RestaurantScreen />
          </AuthenticatedRoute>
          <AuthenticatedRoute path="/employees">
            <EmployeesScreen />
          </AuthenticatedRoute>
          <AuthenticatedRoute path="/delivery_areas">
            <NeighborhoodsScreen />
          </AuthenticatedRoute>
          <AuthenticatedRoute path="/live_order">
            <OrdersScreen />
          </AuthenticatedRoute>
          <AuthenticatedRoute path="/order_history">
            <OrderHistoryScreen />
          </AuthenticatedRoute>
          <AuthenticatedRoute path="/booking">
            <BookingScreens />
          </AuthenticatedRoute>
          <AuthenticatedRoute path="/table_management">
            <ManagementScreen />
          </AuthenticatedRoute>
          <AuthenticatedRoute path="/pos">
            <PosScreens />
          </AuthenticatedRoute>
        </Switch>
      </Layout>
      {!fromAdminPortal(user) && (
        <>
          <LiveOrderListener />
          <NotificationsListener />
        </>
      )}
    </Router>
  );
};

const mapStateToProps = state => ({
  user: getUserSelector(state),
});

const mapDispatchToProps = {
  getUser: getUserAction,
};

/* Export
============================================================================= */
export default connect(mapStateToProps, mapDispatchToProps)(AppNavigation);
