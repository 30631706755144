import React from 'react';
import { connect } from 'react-redux';
import { Search } from 'react-feather';
import {
  Box,
  Text,
  Input,
  Button,
  HStack,
  Divider,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';

import { getQuery, getLoading } from '../redux/selectors';
import { setQuery as setQueryAction } from '../redux/actions';

/* =============================================================================
<NeighborhoodsHeader />
============================================================================= */
const NeighborhoodsHeader = ({
  query,
  loading,
  setQuery,
  onSaveClick,
  onClickViewMyAreas
}) => {
  const _handleSearchChange = (event) => {
    setQuery(event.target.value);
  };

  return (
    <Box mb={6}>
      <HStack justify="space-between">
        <Box>
          <Text fontSize="lg" fontWeight="medium">Delivery Areas Managements</Text>
          <Text>Areas that you deliver</Text>
        </Box>
        <Button px={6} isLoading={loading} onClick={onSaveClick}>Save</Button>
      </HStack>
      <Divider mt={6} mb={10} />
      <HStack justify="space-between" pl={6}>
        <Text fontSize="lg" fontWeight="medium">Delivery Area List</Text>
        <HStack flex={1} justifyContent="flex-end">
          <Button variant="link" onClick={onClickViewMyAreas}>
            View My Areas
          </Button>
          <InputGroup maxW="320px">
            <InputLeftElement><Search size="15px" /></InputLeftElement>
            <Input placeholder="Search" value={query} onChange={_handleSearchChange} />
          </InputGroup>
        </HStack>
      </HStack>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  query: getQuery(state),
  loading: getLoading(state),
});

const mapDispatchToProps = {
  setQuery: setQueryAction,
};

const propsAreEqual = (prevProps, nextProps) => (
  prevProps.query === nextProps.query
  && prevProps.loading === nextProps.loading
  && prevProps.onSaveClick === nextProps.onSaveClick
);

/* Export
============================================================================= */
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(NeighborhoodsHeader, propsAreEqual));
