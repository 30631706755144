import React from 'react';
import { Tab } from '@chakra-ui/react';

/* =============================================================================
<MenusItemTabItemSpecial />
============================================================================= */
const MenusItemTabItemSpecial = ({ id, name, onClick }) => (
  <Tab fontWeight="medium" onClick={(event) => onClick(id, event)}>{name}</Tab>
);

// eslint-disable-next-line max-len
const propsAreEqual = (prevProps, nextProps) => prevProps.id === nextProps.id
 && prevProps.name === nextProps.name;

/* Export
============================================================================= */
export default React.memo(MenusItemTabItemSpecial, propsAreEqual);
