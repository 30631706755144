import React from 'react';
import {
  Text,
  Input,
  Radio,
  Stack,
  HStack,
  Divider,
  FormLabel,
  InputGroup,
  RadioGroup,
  FormControl,
  InputLeftAddon,
} from '@chakra-ui/react';

/* =============================================================================
<OrderSetupCustomerInfo />
============================================================================= */
const OrderSetupCustomerInfo = ({
  type,
  name,
  phone,
  address,
  onNameChange,
  onPhoneChange,
  onAddressChange,
}) => {
  if (type === 'dine_in') {
    return null;
  }

  return (
    <>
      <Text fontSize="lg" fontWeight="medium">Customer Information</Text>
      <Text fontSize="sm">Update customer details here.</Text>
      <Divider my={5} />
      <HStack spacing={4}>
        <FormControl>
          <FormLabel fontSize="sm" fontWeight="normal">Customer Name</FormLabel>
          <Input
            flex={1}
            value={name}
            placeholder="Name"
            onChange={(event) => onNameChange(event.target.value)}
          />
        </FormControl>
        {type !== 'dine_in' && (
        <FormControl>
          <FormLabel fontSize="sm" fontWeight="normal">Phone Number</FormLabel>
          <InputGroup flex={1}>
            <InputLeftAddon>+92</InputLeftAddon>
            <Input
              value={phone}
              onChange={(event) => onPhoneChange(event.target.value)}
            />
          </InputGroup>
        </FormControl>
        )}
      </HStack>
      {type === 'delivery' && (
      <FormControl mt={4}>
        <FormLabel fontSize="sm" fontWeight="normal">Address</FormLabel>
        <Input
          value={address}
          onChange={(event) => onAddressChange(event.target.value)}
        />
      </FormControl>
      )}
    </>
  );
};

/* Export
============================================================================= */
export default OrderSetupCustomerInfo;
