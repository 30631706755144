import { normalize } from 'normalizr';

import * as constants from './constants';
import * as posConstants from '../../pos/redux/constants';
import {
  order as orderSchema,
  booking as bookingSchema,
} from '../api/schema';

export const INITIAL_STATE = {};

export default function reducer(state = INITIAL_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    // ENTITIES_ADD
    case constants.ENTITIES_ADD: {
      const update = {};
      Object.keys(payload).forEach((key) => {
        update[key] = Object.entries(payload[key]).reduce(
          (mergedEntities, [id, entity]) => ({
            ...mergedEntities,
            [id]: {
              ...(mergedEntities[id] || {}),
              ...entity,
            },
          }),
          state[key] || {},
        );
      });

      return {
        ...state,
        ...update,
      };
    }

    /* POS OFFLINE ACTIONS */

    // ORDER_PLACE, ORDER_UPDATE, ORDER_READY, ORDER_DISPATCH, ORDER_COMPLETE
    case posConstants.ORDER_PLACE.REQUEST:
    case posConstants.ORDER_PLACE.COMMIT:
    case posConstants.ORDER_UPDATE.REQUEST:
    case posConstants.ORDER_UPDATE.COMMIT:
    case posConstants.ORDER_ITEMS_UPDATE.REQUEST:
    case posConstants.ORDER_ITEMS_UPDATE.COMMIT:
    case posConstants.ORDER_READY.REQUEST:
    case posConstants.ORDER_READY.COMMIT:
    case posConstants.ORDER_DISPATCH.REQUEST:
    case posConstants.ORDER_DISPATCH.COMMIT:
    case posConstants.ORDER_COMPLETE.REQUEST:
    case posConstants.ORDER_COMPLETE.COMMIT: {
      const { entities } = normalize(payload, orderSchema);
      const update = {};
      Object.keys(entities).forEach((key) => {
        update[key] = Object.entries(entities[key]).reduce(
          (mergedEntities, [id, entity]) => ({
            ...mergedEntities,
            [id]: {
              ...(mergedEntities[id] || {}),
              ...entity,
            },
          }),
          state[key] || {},
        );
      });

      return {
        ...state,
        ...update,
      };
    }

    // BOOKING_CREATE, BOOKING_UPDATE, BOOKING_CANCEL, BOOKING_COMPLETE
    case posConstants.BOOKING_CREATE.REQUEST:
    case posConstants.BOOKING_CREATE.COMMIT:
    case posConstants.BOOKING_UPDATE.REQUEST:
    case posConstants.BOOKING_UPDATE.COMMIT:
    case posConstants.BOOKING_CANCEL.REQUEST:
    case posConstants.BOOKING_CANCEL.COMMIT:
    case posConstants.BOOKING_COMPLETE.REQUEST:
    case posConstants.BOOKING_COMPLETE.COMMIT: {
      const { entities } = normalize(payload, bookingSchema);
      const update = {};
      Object.keys(entities).forEach((key) => {
        update[key] = Object.entries(entities[key]).reduce(
          (mergedEntities, [id, entity]) => ({
            ...mergedEntities,
            [id]: {
              ...(mergedEntities[id] || {}),
              ...entity,
            },
          }),
          state[key] || {},
        );
      });

      return {
        ...state,
        ...update,
      };
    }

    default:
      return state;
  }
}
