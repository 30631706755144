import React from 'react';
import { connect } from 'react-redux';
import { Clock } from 'react-feather';
import {
  Text,
  Box,
  HStack,
} from '@chakra-ui/react';

import { calculateTimeDiff } from '../../../util/functions';

import { getOrder } from '../../../entities/redux/selectors';

/* =============================================================================
<OrdersCardCompletedInfo />
============================================================================= */
const OrdersCardCompletedInfo = ({ order }) => {
  if (order?.status !== 'completed') {
    return null;
  }

  const completedIn = calculateTimeDiff(order?.createdAt, order?.completedAt);

  return (
    <Box my={3}>
      <Text fontSize="sm">Order Completed in:</Text>
      <HStack>
        <Clock size="15px" color="green" />
        <Text fontSize="sm" color="green">{completedIn}</Text>
      </HStack>
    </Box>
  );
};

const mapStateToProps = (state, { id }) => ({
  order: getOrder(state, { id }),
});

const propsAreEqual = (prevProps, nextProps) => (
  prevProps.order?.createdAt === nextProps.order?.createdAt
  && prevProps.order?.completedAt === nextProps.order?.completedAt
);

/* Export
============================================================================= */
export default connect(mapStateToProps)(React.memo(OrdersCardCompletedInfo, propsAreEqual));
