import * as constants from './constants';

export const INITIAL_STATE = {
  tab: 0,
  areas: [],
  tables: [],
  error: null,
  loading: false,
};

export default function reducer(state = INITIAL_STATE, action) {
  const { type, payload, error } = action;

  switch (type) {
    // TAB_SET
    case constants.TAB_SET:
      return {
        ...state,
        tab: payload,
      };

    // AREAS_GET
    case constants.AREAS_GET.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.AREAS_GET.FAIL:
      return {
        ...state,
        error,
      };
    case constants.AREAS_GET.SUCCESS:
      return {
        ...state,
        error: null,
        areas: payload,
      };
    case constants.AREAS_GET.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // AREA_CREATE
    case constants.AREA_CREATE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.AREA_CREATE.FAIL:
      return {
        ...state,
        error,
      };
    case constants.AREA_CREATE.SUCCESS:
      return {
        ...state,
        error: null,
        areas: [...state.areas, payload],
      };
    case constants.AREA_CREATE.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // AREA_UPDATE
    case constants.AREA_UPDATE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.AREA_UPDATE.FAIL:
      return {
        ...state,
        error,
      };
    case constants.AREA_UPDATE.SUCCESS:
      return {
        ...state,
        error: null,
      };
    case constants.AREA_UPDATE.COMPLETE:
      return {
        ...state,
        loading: false,
      };

      // .AREA_DELETE
    case constants.AREA_DELETE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.AREA_DELETE.FAIL:
      return {
        ...state,
        error,
      };
    case constants.AREA_DELETE.SUCCESS:
      return {
        ...state,
        error: null,
        areas: state.areas.filter((item) => item !== payload),
      };
    case constants.AREA_DELETE.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // TABLES_GET
    case constants.TABLES_GET.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.TABLES_GET.FAIL:
      return {
        ...state,
        error,
      };
    case constants.TABLES_GET.SUCCESS:
      return {
        ...state,
        error: null,
        tables: payload,
      };
    case constants.TABLES_GET.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // TABLE_CREATE
    case constants.TABLE_CREATE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.TABLE_CREATE.FAIL:
      return {
        ...state,
        error,
      };
    case constants.TABLE_CREATE.SUCCESS:
      return {
        ...state,
        error: null,
        tables: [...state.tables, payload],
      };
    case constants.TABLE_CREATE.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // TABLE_UPDATE
    case constants.TABLE_UPDATE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.TABLE_UPDATE.FAIL:
      return {
        ...state,
        error,
      };
    case constants.TABLE_UPDATE.SUCCESS:
      return {
        ...state,
        error: null,
      };
    case constants.TABLE_UPDATE.COMPLETE:
      return {
        ...state,
        loading: false,
      };

      // .TABLE_DELETE
    case constants.TABLE_DELETE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.TABLE_DELETE.FAIL:
      return {
        ...state,
        error,
      };
    case constants.TABLE_DELETE.SUCCESS:
      return {
        ...state,
        error: null,
        tables: state.tables.filter((item) => item !== payload),
      };
    case constants.TABLE_DELETE.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
