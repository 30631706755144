import React from 'react';
import * as Yup from 'yup';
import { Form, Formik, Field } from 'formik';
import { connect } from 'react-redux';
import { Link as ReactLink, useHistory } from 'react-router-dom';
import { ArrowLeft } from 'react-feather';
import {
  Box,
  Text,
  Link,
  Input,
  Center,
  Button,
  Heading,
  FormLabel,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/react';

import { ReactComponent as KeyIcon } from '../../assets/icons/edit-forgot-password-icon.svg';

import { sendPasswordResetCode as sendPasswordResetCodeAction } from '../redux/actions';

/* =============================================================================
<ForgotPasswordScreen />
============================================================================= */
const ForgotPasswordScreen = ({ sendPasswordResetCode }) => {
  const history = useHistory();

  const _handleSubmit = async (values) => {
    await sendPasswordResetCode(values.email, (err) => {
      if (!err) {
        history.push(`/auth/code_verification?email=${values.email}`);
      }
    });
  };

  return (
    <Formik
      initialValues={{ email: '' }}
      validationSchema={ForgotPasswordSchema}
      onSubmit={_handleSubmit}
    >
      {() => (
        <Form>
          <Box align="center">
            <KeyIcon />
            <Heading mt={6} mb={3} fontSize="3xl">
              Forgot password?
            </Heading>
            <Text mb={8}>
              No worries, email us at
              {' '}
              <Text as="span" fontWeight="bold">
                info@foodstreetpk.com
              </Text>
              {' '}
              or call us at
              {' '}
              <Text as="span" fontWeight="bold">
                +92 318 1054123
              </Text>
              {' '}
              for quick assistance
            </Text>
            <Center>
              <Link
                as={ReactLink}
                to="/auth/login"
                fontSize="sm"
                display="flex"
                alignItems="center"
              >
                <ArrowLeft size={14} style={{ marginRight: 8 }} />
                Back to login
              </Link>
            </Center>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email!')
    .required('Must not be empty!'),
});

const mapDispatchToProps = {
  sendPasswordResetCode: sendPasswordResetCodeAction,
};

/* Export
============================================================================= */
export default connect(null, mapDispatchToProps)(ForgotPasswordScreen);
