import { schema } from 'normalizr';

export const role = new schema.Entity('roles');

export const restaurant = new schema.Entity('restaurants');
restaurant.define({
  chain: restaurant,
});

export const reports = new schema.Object({
  byStatus: new schema.Array(),
  byPartnerAndMethod: new schema.Array(),
});

export const user = new schema.Entity('users', {
  restaurant,
  role,
});
restaurant.define({
  owner: user,
});

export const category = new schema.Entity('categories', {
  featured: [restaurant],
  newest: [restaurant],
  trending: [restaurant],
});
restaurant.define({
  categories: [category],
});

export const choice = new schema.Entity('choices');

export const choiceGroup = new schema.Entity('choiceGroups', {
  choices: [choice],
});

export const dish = new schema.Entity('dishes', {
  choiceGroups: [choiceGroup],
  category,
});

export const deal = new schema.Entity('deals', {
  dishes: [dish],
});

export const menuItem = new schema.Entity('menuItems', {
  dishes: [dish],
  category,
});

export const menu = new schema.Entity('menus', {
  featured: [dish],
  items: [menuItem],
});

export const area = new schema.Entity('areas', {
  restaurant,
});

export const table = new schema.Entity('tables', {
  area,
  restaurant,
});

export const order = new schema.Entity('orders', {
  customer: user,
  rider: user,
  waiter: user,
  restaurant,
  table,
  items: [{ dish }],
}, {
  idAttribute: 'uid',
});

export const inventory = new schema.Entity('inventories', {
  restaurant,
});

export const booking = new schema.Entity('bookings', {
  restaurant,
  customer: user,
}, {
  idAttribute: 'uid',
});

export const discount = new schema.Entity('discounts', {
  dishes: [dish],
});
dish.define({
  discount,
});

export const neighborhood = new schema.Entity('neighborhoods');
