import React, { useEffect, useState } from 'react';
import { Box, Text, Divider } from '@chakra-ui/react';
import { useFormikContext } from 'formik';

import SingleVariant from './SingleVariant';
import MultipleVariants from './MultipleVariants';

/* =============================================================================
<DishPricesAndVariants />
============================================================================= */
const DishPricesAndVariants = () => {
  const { values, setFieldValue } = useFormikContext();
  const { variants } = values;

  const _handleAddVariant = () => {
    setFieldValue('variants', [{
      name: '',
      price: '',
    }]);
  };

  return (
    <Box>
      <Divider my={5} />
      <Text fontSize="sm">Price and Variations</Text>
      <Text fontSize="sm">
        Add a variation if this item comes in different sizes (e.g. Small, Medium, Large) or
        options (e.g. Soup, Dry). Include GST/VAT in prices if applicable.
      </Text>
      {variants?.length > 0 ? (
        <MultipleVariants variants={values.variants} />
      ) : (
        <SingleVariant onAddVariant={_handleAddVariant} />
      )}
      <Divider my={5} />
    </Box>
  );
};

/* Export
============================================================================= */
export default DishPricesAndVariants;
