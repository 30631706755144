import React from 'react';
import { FormControl, FormLabel, Input } from '@chakra-ui/react';

const OrderSetupPosDiscount = ({ posDiscount, onChange }) => (
  <FormControl my={4}>
    <FormLabel fontSize="sm" fontWeight="normal">
      Pos Discount (%)
    </FormLabel>
    <Input
      defaultValue={0}
      type="number"
      min={0}
      max={100}
      value={posDiscount}
      onChange={e =>
        onChange(
          Number(e.target.value) >= 0 && Number(e.target.value) <= 100
            ? e.target.value
            : 0
        )
      }
    />
  </FormControl>
);

export default OrderSetupPosDiscount;
