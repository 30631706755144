import React from 'react';
import { Switch, Route } from 'react-router-dom';

import ReservationsScreen from './ReservationsScreen';
import ReservationSettingScreen from './ReservationSettingScreen';
import ReservationCalendarScreen from './ReservationCalendarScreen';

/* =============================================================================
<BookingScreens />
============================================================================= */
const BookingScreens = () => (
  <Switch>
    <Route path="/booking" exact>
      <ReservationCalendarScreen />
    </Route>
    <Route path="/booking/reservations" exact>
      <ReservationsScreen />
    </Route>
    <Route path="/booking/settings" exact>
      <ReservationSettingScreen />
    </Route>
  </Switch>
);

/* Export
============================================================================= */
export default BookingScreens;
