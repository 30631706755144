import React from 'react';
import { connect } from 'react-redux';
import {
  Avatar,
  Box,
  HStack,
  Td,
  Text,
} from '@chakra-ui/react';
import { Phone } from 'react-feather';

import { getBooking } from '../../../../../entities/redux/selectors';

/* =============================================================================
<ReservationsTableItemCustomer />
============================================================================= */
const ReservationsTableItemCustomer = ({ booking }) => {
  const isCustomer = Boolean(booking?.customer);
  const phone = isCustomer ? booking?.customer?.phone : booking?.meta?.phone;
  const avatar = booking?.customer?.profile?.avatar;
  const name = isCustomer
    ? `${booking?.customer?.profile.firstName} ${booking?.customer?.profile.lastName}` : booking?.meta?.name;
  return (
    <Td>
      <HStack>
        <Avatar w="40px" h="40px" src={avatar} />
        <Box>
          <Text>{name}</Text>
          <HStack>
            <Phone size="12px" />
            <Text noOfLines={1}>{phone}</Text>
          </HStack>
        </Box>
      </HStack>
    </Td>
  );
};

const mapStateToProps = (state, { id }) => ({
  booking: getBooking(state, { id }),
});

// eslint-disable-next-line max-len
const propsAreEqual = (prevProps, nextProps) => prevProps.booking?.customer === nextProps.booking?.customer
  && prevProps.booking?.customer?.profile === nextProps.booking?.customer?.profile
  && prevProps.booking?.customer?.phone === nextProps.booking?.customer?.phone;

/* Export
============================================================================= */
export default connect(mapStateToProps)(React.memo(ReservationsTableItemCustomer, propsAreEqual));
