import React, { useState } from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { Trash } from 'react-feather';
import {
  Text,
  Modal,
  VStack,
  HStack,
  Button,
  Divider,
  ModalBody,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  useToast,
} from '@chakra-ui/react';

import ChoiceName from '../ChoiceName';
import ChoiceRange from '../ChoiceRange';
import ChoiceAddChoices from '../ChoiceAddChoices';
import ChoiceDescription from '../ChoiceDescription';
import EditChoiceModalInitialize from './EditChoiceModalInitialize';

import {
  updateChoiceGroup as updateChoiceGroupAction,
  deleteChoiceGroup as deleteChoiceGroupAction,
} from '../../../../redux/actions';

/* =============================================================================
<EditChoiceModal />
============================================================================= */
const EditChoiceModal = ({
  id,
  isOpen,
  onClose,
  updateChoiceGroup,
  deleteChoiceGroup,
}) => {
  const toast = useToast();
  const [deleting, setDeleting] = useState(false);

  const _handleDeleteClick = async () => {
    setDeleting(true);
    await deleteChoiceGroup(id, (err) => {
      if (!err) {
        toast({
          title: 'Extra choice deleted',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        onClose();
      } else {
        toast({
          title: err.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    });
    setDeleting(false);
  };

  const _handleSubmit = async (values) => {
    await updateChoiceGroup(values, (err) => {
      if (!err) {
        toast({
          title: 'Changes Saved',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        onClose();
      } else {
        toast({
          title: err.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    });
  };

  return (
    <Modal size="3xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="lg">Edit Extra Choice</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Divider mb={5} />
          <Formik
            initialValues={INITIAL_VALUES}
            validationSchema={EditChoiceSchema}
            onSubmit={_handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <Text mb={5} fontWeight="medium">Extra Choice Details</Text>
                <ChoiceName />
                <ChoiceRange />
                <ChoiceDescription />
                <ChoiceAddChoices />
                <VStack>
                  <Button
                    colorScheme="gray"
                    rightIcon={<Trash size="20px" />}
                    isLoading={deleting}
                    onClick={_handleDeleteClick}
                  >
                    Delete
                  </Button>
                </VStack>
                <Divider my={5} />
                <HStack mb={2} spacing={4}>
                  <Button
                    flex={1}
                    colorScheme="gray"
                    variant="outline"
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" flex={1} isLoading={isSubmitting}>
                    Update item
                  </Button>
                </HStack>
                <EditChoiceModalInitialize id={id} />
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const INITIAL_VALUES = {
  name: '',
  min: 1,
  max: 1,
  about: '',
  choices: [],
};

const EditChoiceSchema = Yup.object().shape({
  name: Yup.string()
    .required('Must not be empty!'),
  min: Yup.number()
    .max(Yup.ref('max'))
    .required('Must not be empty!'),
  max: Yup.number()
    .min(Yup.ref('min'))
    .required('Must not be empty!'),
  about: Yup.string()
    .optional(),
  choices: Yup.array().of(Yup.object().shape({
    name: Yup.string().required('Must not be empty!'),
    price: Yup.number().required('Must not be empty!'),
    available: Yup.boolean().required('Must not be empty!'),
  })).min(Yup.ref('min'), 'Choices must match the minimum range'),
});

const mapDispatchToProps = {
  updateChoiceGroup: updateChoiceGroupAction,
  deleteChoiceGroup: deleteChoiceGroupAction,
};

/* Export
============================================================================= */
export default connect(null, mapDispatchToProps)(EditChoiceModal);
