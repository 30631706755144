import React from 'react';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import {
  Modal,
  Input,
  HStack,
  Button,
  Select,
  Divider,
  InputGroup,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  FormLabel,
  FormControl,
  InputLeftAddon,
  ModalCloseButton,
  FormErrorMessage,
  useToast,
} from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';

import InventoryEditModalInitialize from './InventoryEditModalInitialize';

import { updateInventory as updateInventoryAction } from '../../../redux/actions';

/* =============================================================================
<InventoryEditModal />
============================================================================= */
const InventoryEditModal = ({
  id,
  isOpen,
  onClose,
  updateInventory,
}) => {
  const toast = useToast();

  const _handleSubmit = (values) => {
    updateInventory(values, (err) => {
      if (!err) {
        toast({
          title: 'Changes Saved',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: err.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    });
    onClose();
  };

  return (
    <Modal size="2xl" isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="lg">Edit Inventory</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Divider mb={5} />
          <Formik
            initialValues={initialValues}
            validationSchema={editInventorySchema}
            onSubmit={_handleSubmit}
          >
            {() => (
              <Form>
                <HStack alignItems="flex-start">
                  <Field name="name">
                    {({ field, form }) => (
                      <FormControl flex={1} isInvalid={form.errors.name && form.touched.name}>
                        <FormLabel>Name</FormLabel>
                        <Input id="name" placeholder="Ex :Onion" {...field} />
                        <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="unit">
                    {({ field, form }) => (
                      <FormControl flex={1} isInvalid={form.errors.unit && form.touched.unit}>
                        <FormLabel>Unit</FormLabel>
                        <Select placeholder="Select Unit" {...field}>
                          {unitOptions.map((unit) => <option>{unit}</option>)}
                        </Select>
                      </FormControl>
                    )}
                  </Field>
                </HStack>
                <HStack alignItems="flex-start" mt={5}>
                  <Field name="amount">
                    {({ field, form }) => (
                      <FormControl flex={1} isInvalid={form.errors.amount && form.touched.amount}>
                        <FormLabel>Amount</FormLabel>
                        <Input id="Amount" placeholder="Ex :2" {...field} />
                        <FormErrorMessage>{form.errors.amount}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="price">
                    {({ field, form }) => (
                      <FormControl flex={1} isInvalid={form.errors.price && form.touched.price}>
                        <FormLabel>Price</FormLabel>
                        <InputGroup>
                          <InputLeftAddon>Rs</InputLeftAddon>
                          <Input id="price" placeholder="16" {...field} />
                        </InputGroup>
                        <FormErrorMessage>{form.errors.price}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </HStack>
                <Divider my={5} />
                <HStack mb={3}>
                  <Button
                    mr={4}
                    flex={1}
                    color="gray"
                    variant="outline"
                    colorScheme="gray"
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" flex={1}>Save</Button>
                </HStack>
                <InventoryEditModalInitialize id={id} />
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const unitOptions = [
  'Gram',
  'Kilogram',
  'Tonne',
  'Mega Tonne',
];

const initialValues = {
  name: '',
  unit: '',
  price: '',
  amount: '',
};

const editInventorySchema = Yup.object().shape({
  name: Yup.string()
    .required(),
  unit: Yup.string()
    .required(),
  amount: Yup.number()
    .required(),
  price: Yup.number()
    .required(),
});

const mapDispatchToProps = {
  updateInventory: updateInventoryAction,
};

/* Export
============================================================================= */
export default connect(null, mapDispatchToProps)(InventoryEditModal);
