import React from 'react';
import { FastField } from 'formik';
import {
  Text,
  VStack,
  Box,
  FormErrorIcon,
  FormLabel,
  FormControl,
  Textarea,
  FormErrorMessage,
} from '@chakra-ui/react';

/* =============================================================================
<DishDescription />
============================================================================= */
const DishDescription = () => (
  <FastField name="about">
    {({ field, form }) => (
      <FormControl
        mb={8}
        display="flex"
        isInvalid={form.errors.about && form.touched.about}
      >
        <Box flex={2.2}>
          <FormLabel mb={0}>Item Description</FormLabel>
          <Text fontSize="sm">Write a short description</Text>
        </Box>
        <VStack alignItems="flex-start" flex={3}>
          <Textarea id="about" {...field} />
          <FormErrorMessage>
            <FormErrorIcon />
            {form.errors.about}
          </FormErrorMessage>
        </VStack>
      </FormControl>
    )}
  </FastField>

);

/* Export
============================================================================= */
export default DishDescription;
