import React from 'react';
import { HStack, Text, Badge } from '@chakra-ui/react';

/* =============================================================================
<OrdersTabHeader />
============================================================================= */
const OrdersTabHeader = ({ title, ...props }) => (
  <HStack
    w="100%"
    h="56px"
    mb={2}
    justify="center"
    borderTopEndRadius="8px"
    borderTopStartRadius="8px"
    {...props}
  >
    <Text color="white" fontWeight="semibold">{title}</Text>
  </HStack>
);

/* Export
============================================================================= */
export default OrdersTabHeader;
