import React from 'react';
import { connect } from 'react-redux';
import {
  Tabs,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
} from '@chakra-ui/react';

import OrdersNew from './OrdersNew';
import OrdersAccepted from './OrdersAccepted';
import OrdersOnTheWay from './OrdersOnTheWay';
import OrdersCompleted from './OrdersCompleted';

import { getOrdersByStatusAndType } from '../redux/selectors';

/* =============================================================================
<OrdersMobileView />
============================================================================= */
const OrdersMobileView = ({
  pendingOrders,
  acceptedOrders,
  completedOrders,
  dispatchedOrders,
}) => {
  const pendingOrdersTotal = pendingOrders.length;
  const acceptedOrdersTotal = acceptedOrders.length;
  const completedOrdersTotal = completedOrders.length;
  const dispatchedOrdersTotal = dispatchedOrders.length;

  return (
    <Tabs colorScheme="red" isLazy>
      <TabList justifyContent="space-between" border="none">
        <Tab p={2} noOfLines={1}>
          {`New ${pendingOrdersTotal}`}
        </Tab>
        <Tab p={2} noOfLines={1}>
          {`Accepted ${acceptedOrdersTotal}`}
        </Tab>
        <Tab p={2} noOfLines={1}>
          On
          {' '}
          {`Way ${dispatchedOrdersTotal}`}
        </Tab>
        <Tab p={2} noOfLines={1}>
          {`Completed ${completedOrdersTotal}`}
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel mt={5} p={0}>
          <OrdersNew />
        </TabPanel>
        <TabPanel mt={5} p={0}>
          <OrdersAccepted />
        </TabPanel>
        <TabPanel mt={5} p={0}>
          <OrdersOnTheWay />
        </TabPanel>
        <TabPanel mt={5} p={0}>
          <OrdersCompleted />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

const mapStateToProps = (state) => ({
  pendingOrders: getOrdersByStatusAndType(state, {
    status: 'pending',
  }),
  acceptedOrders: getOrdersByStatusAndType(state, {
    status: 'accepted',
  }),
  completedOrders: getOrdersByStatusAndType(state, {
    status: 'completed',
  }),
  dispatchedOrders: getOrdersByStatusAndType(state, {
    status: 'dispatched',
  }),
});

const propsAreEqual = (prevProps, nextProps) => (
  prevProps.pendingOrders.length === nextProps.pendingOrders.length
  && prevProps.acceptedOrders.length === nextProps.acceptedOrders.length
  && prevProps.completedOrders.length === nextProps.completedOrders.length
  && prevProps.dispatchedOrders.length === nextProps.dispatchedOrders.length
);

/* Export
============================================================================= */
export default connect(mapStateToProps)(React.memo(OrdersMobileView, propsAreEqual));
