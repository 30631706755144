import React from 'react';
import { connect } from 'react-redux';
import {
  Tab,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
} from '@chakra-ui/react';

import Card from '../../common/Card';
import ManagementHeader from '../components/ManagementHeader';
import AreaManagementTable from '../components/AreaManagementTable/index';
import TableManagementTable from '../components/TableManagementTable/index';

import { setTab as setTabAction } from '../redux/actions';

/* =============================================================================
<ManagementScreen />
============================================================================= */
const ManagementScreen = ({ setTab }) => {
  const _handleTabChange = (value) => {
    switch (value) {
      case 0:
        setTab(0);
        break;
      case 1:
        setTab(1);
        break;

      default:
        break;
    }
  };
  return (
    <Card m={5} flex={1} direction="column">
      <ManagementHeader />
      <Tabs colorScheme="brand" onChange={_handleTabChange}>
        <TabList border="none">
          <Tab>Area Management</Tab>
          <Tab>Table Management</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <AreaManagementTable />
          </TabPanel>
          <TabPanel>
            <TableManagementTable />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Card>
  );
};

const mapDispatchToProps = {
  setTab: setTabAction,
};

/* Export
============================================================================= */
export default connect(null, mapDispatchToProps)(ManagementScreen);
