import { createSelector } from 'reselect';

/**
 * Get error
 */
export const getError = (state) => state.Employees.error;

/**
 * Get loading
 */
export const getLoading = (state) => state.Employees.loading;

/**
 * Get employees
 */
export const getEmployees = (state) => state.Employees.employees;

/**
 * Get next cursor
 */
export const getNextCursor = (state) => state.Employees.nextCursor;

/**
 * Get roles entity
 */
export const getRolesEntity = (state) => state.Entities.roles;

/**
 * Get roles
 */
export const getRoles = (state) => state.Employees.roles;

/**
 * Get roles by Id
 */
export const getRolesById = createSelector(
  [getRoles, getRolesEntity],
  (allIds, byId) => allIds.map((id) => byId[id]),
);
