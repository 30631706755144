import { normalize } from 'normalizr';
import { enc } from 'crypto-js';
import { decrypt } from 'crypto-js/aes';

import request from '../../util/request';
import * as constants from './constants';
import { addEntities } from '../../entities/redux/actions';
import {
  restaurant as restaurantSchema,
  neighborhood as neighborhoodSchema,
} from '../../entities/api/schema';

/**
 * NEIGHBORHOODS_GET
 */
export const getNeighborhoods = (nextCursor, cb) => async dispatch => {
  try {
    dispatch({ type: constants.NEIGHBORHOODS_GET.REQUEST });

    const payload = await request({
      url: '/neighborhoods',
      method: 'GET',
      params: {
        next_cursor: nextCursor,
      },
    });
    const dePayload = JSON.parse(
      Buffer.from(
        decrypt(payload, process.env.REACT_APP_API_URL).toString(enc.Base64),
        'base64'
      ).toString()
    );
    const { entities, result } = normalize(dePayload.data, [
      neighborhoodSchema,
    ]);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.NEIGHBORHOODS_GET.SUCCESS,
      payload: result,
    });
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.NEIGHBORHOODS_GET.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.NEIGHBORHOODS_GET.COMPLETE });
  }
};

/**
 * NEIGHBORHOODS_UPDATE
 */
export const updateNeighborhoods = (neighborhoods, cb) => async dispatch => {
  try {
    dispatch({ type: constants.NEIGHBORHOODS_UPDATE.REQUEST });

    const payload = await request({
      url: '/neighborhoods',
      method: 'PUT',
      data: {
        neighborhoods,
      },
    });
    const { entities } = normalize(payload, restaurantSchema);

    dispatch(addEntities(entities));
    dispatch({ type: constants.NEIGHBORHOODS_UPDATE.SUCCESS });
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.NEIGHBORHOODS_UPDATE.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.NEIGHBORHOODS_UPDATE.COMPLETE });
  }
};

/**
 * NEIGHBORHOODS_UPDATE
 */
export const setQuery = payload => ({
  type: constants.QUERY_SET,
  payload,
});
