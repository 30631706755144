import React from 'react';
import { connect } from 'react-redux';
import { MapPin } from 'react-feather';
import {
  Text,
  Box,
  HStack,
} from '@chakra-ui/react';

import { getOrder } from '../../../entities/redux/selectors';

/* =============================================================================
<OrderCardAddress />
============================================================================= */
const OrderCardAddress = ({ order }) => {
  if (order?.type !== 'delivery') {
    return null;
  }

  return (
    <Box>
      <Text fontSize="sm">Address</Text>
      <HStack mb={2} alignItems="flex-start">
        <MapPin size="25px" />
        <Text fontSize="sm">
          {order?.address?.text}
        </Text>
      </HStack>
    </Box>
  );
};

const mapStateToProps = (state, { id }) => ({
  order: getOrder(state, { id }),
});

const propsAreEqual = (prevProps, nextProps) => (
  prevProps.order?.address?.text === nextProps.order?.address?.text
);

/* Export
============================================================================= */
export default connect(mapStateToProps)(React.memo(OrderCardAddress, propsAreEqual));
