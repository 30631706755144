import React from 'react';
import { Box, Divider } from '@chakra-ui/react';

import Card from '../../../../common/Card';
import OngoingOrderCardId from './OngoingOrderCardId';
import OngoingOrderCardUser from './OngoingOrderCardUser';
import OngoingOrderCardDineInInfo from './OngoingOrderCardDineInInfo';
import OngoingOrderCardDishes from './OngoingOrderCardDishes';
import OngoingOrderCardRider from './OngoingOrderCardRider';
import OngoingOrderCardFooter from './OngoingOrderCardFooter';

/* =============================================================================
<OngoingOrderCard />
============================================================================= */
const OngoingOrderCard = ({ id }) => (
  <Card
    mb={5}
    px={5}
    pb={0}
    minW="380px"
    minH="380px"
    pos="relative"
    display="flex"
    flexDir="column"
    mr="10px !important"
    borderWidth="0.5px"
  >
    <Box flex={1}>
      <OngoingOrderCardId id={id} />
      <OngoingOrderCardUser id={id} />
      <Divider my={3} />
      <OngoingOrderCardDineInInfo id={id} />
      <OngoingOrderCardDishes id={id} />
      <Divider my={3} />
      <OngoingOrderCardRider id={id} />
    </Box>
    <OngoingOrderCardFooter id={id} />
  </Card>
);

/* Export
============================================================================= */
export default OngoingOrderCard;
