import React, { useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import {
  Tr,
  Td,
  Tag,
  Box,
  Text,
  Button,
  HStack,
  TagLeftIcon,
  useDisclosure,
} from '@chakra-ui/react';
import { Printer } from 'react-feather';
import { useReactToPrint } from 'react-to-print';

import OrderDetailsModal from '../../../../common/OrderDetailsModal';
import { ReactComponent as TakeAwayIcon } from '../../../../assets/icons/edit-takeaway-icon.svg';
import { ReactComponent as DeliveryIcon } from '../../../../assets/icons/edit-bike-icon.svg';
import { ReactComponent as DineInIcon } from '../../../../assets/icons/nav-restaurant-icon.svg';
import { calculateTimeDiff } from '../../../../util/functions';
import OrderReceipt from '../../../../common/OrderReceipt';

import { getOrder } from '../../../../entities/redux/selectors';

/* =============================================================================
<TodayOrderTableItem />
============================================================================= */
const TodayOrderTableItem = ({ id, order }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const orderReceiptRef = useRef(null);

  const type = order?.type;
  const name = order?.meta?.name ? order?.meta?.name : '--';
  const phone = order?.meta?.phone;
  const completedIn = calculateTimeDiff(order?.createdAt, order?.completedAt);
  const price = order?.payment?.amount;

  // eslint-disable-next-line max-len
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const reactToPrintContent = useCallback(() => orderReceiptRef.current, [orderReceiptRef.current]);

  const _handlePrintClick = useReactToPrint({
    content: reactToPrintContent,
  });

  return (
    <Tr>
      <Td>
        <Text>{name}</Text>
        <Text>{phone}</Text>
      </Td>
      <Td>
        {type === 'take_away' && (
          <Tag colorScheme="purple">
            <TagLeftIcon><TakeAwayIcon /></TagLeftIcon>
            Take Away
          </Tag>
        )}
        {type === 'delivery' && (
          <Tag colorScheme="gray">
            <TagLeftIcon><DeliveryIcon fill="gray" /></TagLeftIcon>
            Delivery
          </Tag>
        )}
        {type === 'dine_in' && (
          <Tag colorScheme="red">
            <TagLeftIcon><DineInIcon fill="red" /></TagLeftIcon>
            Dine In
          </Tag>
        )}
      </Td>
      <Td>{completedIn}</Td>
      <Td>{`Rs.${price}`}</Td>
      <Td>
        <HStack>
          <Button variant="outline" colorScheme="gray" onClick={onOpen}>Detail</Button>
          <Button variant="outline" colorScheme="gray" onClick={_handlePrintClick}><Printer /></Button>
        </HStack>
      </Td>
      <OrderDetailsModal id={order?.uid} isOpen={isOpen} onClose={onClose} />
      <Box display="none">
        <Box ref={orderReceiptRef}>
          <OrderReceipt id={id} />
        </Box>
      </Box>
    </Tr>
  );
};

const mapStateToProps = (state, { id }) => ({
  order: getOrder(state, { id }),
});

// eslint-disable-next-line max-len
const propsAreEqual = (prevProps, nextProps) => prevProps.order?.id === nextProps.order?.id
  && prevProps.order?.createdAt === nextProps.order?.createdAt
  && prevProps.order?.completedAt === nextProps.order?.completedAt
  && prevProps.order?.status === nextProps.order?.status
  && prevProps.order?.items?.toString() === nextProps.order?.items?.toString();

/* Export
============================================================================= */
export default connect(mapStateToProps)(React.memo(TodayOrderTableItem, propsAreEqual));
