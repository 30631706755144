import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Card from '../../common/Card';
import ReservationFooter from '../components/Reservation/ReservationFooter';
import ReservationTable from '../components/Reservation/ReservationTable/index';
import usePagination from '../../hooks/use-pagination';

import { getBookings as selectBookings } from '../redux/selectors';
import { getBookings as getBookingsAction } from '../redux/actions';

/* =============================================================================
<ReservationScreen />
============================================================================= */
const ReservationScreen = ({ bookings, getBookings }) => {
  const {
    next,
    prev,
    page,
    maxPage,
    items,
    canNext,
    canPrev,
  } = usePagination(bookings);

  // Get bookings
  useEffect(() => {
    getBookings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _handleNext = () => {
    if (canNext) {
      next();
    }
  };

  const _handlePrev = () => {
    if (canPrev) {
      prev();
    }
  };

  return (
    <Card>
      <ReservationTable data={items} />
      <ReservationFooter
        currentPage={page}
        maxPage={maxPage}
        canNext={canNext}
        canPrev={canPrev}
        onNext={_handleNext}
        onPrev={_handlePrev}
      />
    </Card>
  );
};

const mapStateToProps = (state) => ({
  bookings: selectBookings(state),
});

const mapDispatchToProps = {
  getBookings: getBookingsAction,
};

// eslint-disable-next-line max-len
const propsAreEqual = (prevProps, nextProps) => prevProps.bookings.toString() === nextProps.bookings.toString();

/* Export
============================================================================= */
export default connect(
  mapStateToProps, mapDispatchToProps,
)(React.memo(ReservationScreen, propsAreEqual));
