import React, { useCallback, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Check, Printer } from 'react-feather';
import {
  Box, Button, useDisclosure, useMediaQuery, useToast,
} from '@chakra-ui/react';

import OrderReceipt from '../../../common/OrderReceipt';
import OrderCancelModal from '../../../common/OrderCancelModal';
import usePrint from '../../../util/usePrint';

import { getOrder } from '../../../entities/redux/selectors';
import { updateOrder as updateOrderAction } from '../../redux/actions';

/* =============================================================================
<OrdersOnTheWayActions />
============================================================================= */
const OrdersOnTheWayActions = ({ id, order, updateOrder }) => {
  const toast = useToast();
  const orderReceiptRef = useRef(null);
  const reactToPrintContent = useCallback(() => orderReceiptRef.current, []);
  const [isLargerThen766] = useMediaQuery('(min-width: 766px)');
  const [loading, setLoading] = useState(false);
  const {
    isOpen: cancelModalIsOpen,
    onOpen: onCancelModalOpen,
    onClose: onCancelModalClose,
  } = useDisclosure();

  const orderPartner = order?.partner;

  const _handlePrintClick = usePrint({contentEl: reactToPrintContent, order});

  const _handleCancelConfirm = async (declineReason) => {
    await updateOrder({
      id: order?.id,
      action: 'decline',
      declineReason,
    }, (err) => {
      if (err) {
        toast({
          title: err.message,
        });
      }
    });
    onCancelModalClose();
  };

  const _handleCompleteClick = async () => {
    setLoading(true);
    await updateOrder({
      id: order.id,
      action: 'complete',
    }, (err) => {
      if (err) {
        toast({
          title: err.message,
        });
      }
    });
    setLoading(false);
  };

  if (orderPartner === 'bykea') {
    return (
      <>
        <Box display="none">
          <Box ref={orderReceiptRef}>
            <OrderReceipt id={id} />
          </Box>
        </Box>
        <Button
          variant="outline"
          colorScheme="gray"
          w={['100%', '100%', 'auto']}
          onClick={_handlePrintClick}
        >
          <Printer size="20px" />
        </Button>
      </>
    )
  }

  return (
    <>
      <Box display="none">
        <Box ref={orderReceiptRef}>
          <OrderReceipt id={id} />
        </Box>
      </Box>
      <Button
        w={['100%', '100%', 'auto']}
        variant="outline"
        colorScheme="gray"
        onClick={onCancelModalOpen}
      >
        Cancel
      </Button>
      <Button
        mx={1}
        variant="outline"
        colorScheme="gray"
        w={['100%', '100%', 'auto']}
        onClick={_handlePrintClick}
      >
        <Printer size="20px" />
      </Button>
      <Button
        w={['100%', '100%', 'auto']}
        isLoading={loading}
        leftIcon={isLargerThen766 && <Check size="20px" />}
        onClick={_handleCompleteClick}
      >
        Complete
      </Button>
      <OrderCancelModal
        id={order?.id}
        isOpen={cancelModalIsOpen}
        onClose={onCancelModalClose}
        onSubmit={_handleCancelConfirm}
      />
    </>
  );
};

const mapStateToProps = (state, { id }) => ({
  order: getOrder(state, { id }),
});

const mapDispatchToProps = {
  updateOrder: updateOrderAction,
};

const propsAreEqual = (prevProps, nextProps) => prevProps.order?.id === nextProps.order?.id;

/* Export
============================================================================= */
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)((React.memo(OrdersOnTheWayActions, propsAreEqual)));
