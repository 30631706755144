import React from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
} from '@chakra-ui/react';

import ReservationsTableItem from './ReservationsTableItem/index';
import ReservationsTableHeadItem from './ReservationsTableHeadItem';

/* =============================================================================
<ReservationsTable />
============================================================================= */
const ReservationsTable = ({ data }) => (
  <Box flex={1}>
    <Table variant="simple" colorScheme="gray">
      <Thead>
        <Tr>
          {columns.map(renderColumn)}
        </Tr>
      </Thead>
      <Tbody>
        {data.map(renderItem)}
      </Tbody>
    </Table>
  </Box>
);

const renderColumn = (item, i) => <ReservationsTableHeadItem key={`${i}`} title={item} />;
const renderItem = (item, i) => <ReservationsTableItem key={i} id={item} />;

const columns = [
  'CUSTOMER NAME',
  'PEOPLE',
  'DATE',
  'RESERVATION TIME',
  'STATUS',
  'ACTION',
];

/* Export
============================================================================= */
export default ReservationsTable;
