import { combineReducers } from 'redux';

import PosReducer from '../pos/redux/reducer';
import AuthReducer from '../auth/redux/reducer';
import MenuReducer from '../menu/redux/reducer';
import SetupReducer from '../setup/redux/reducer';
import OrdersReducer from '../orders/redux/reducer';
import BookingReducer from '../booking/redux/reducer';
import EntitiesReducer from '../entities/redux/reducer';
import SettingsReducer from '../settings/redux/reducer';
import EmployeesReducer from '../employees/redux/reducer';
import RestaurantReducer from '../restaurant/redux/reducer';
import ManagementReducer from '../management/redux/reducer';
import InventoriesReducer from '../inventory/redux/reducer';
import OrderHistoryReducer from '../orderHistory/redux/reducer';
import NeighborhoodsReducer from '../neighborhoods/redux/reducer';
import ReportsReducer from '../dashboard/redux/reducer';

/* ============================================================================
  Combine ALl Reducers
============================================================================= */
const rootReducer = combineReducers({
  Pos: PosReducer,
  Auth: AuthReducer,
  Menu: MenuReducer,
  Setup: SetupReducer,
  Orders: OrdersReducer,
  Reports: ReportsReducer,
  Booking: BookingReducer,
  Entities: EntitiesReducer,
  Settings: SettingsReducer,
  Employees: EmployeesReducer,
  Restaurant: RestaurantReducer,
  Management: ManagementReducer,
  Inventories: InventoriesReducer,
  OrderHistory: OrderHistoryReducer,
  Neighborhoods: NeighborhoodsReducer,
});

export default rootReducer;
