import * as constants from './constants';

export const INITIAL_STATE = {
  riders: [],
  waiters: [],
  orders: [],
  bookings: [],
  error: null,
  loading: false,
};

export default function reducer(state = INITIAL_STATE, action) {
  const {
    type, payload, error, meta,
  } = action;

  switch (type) {
    // DISH_GET
    case constants.DISH_GET.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.DISH_GET.FAIL:
      return {
        ...state,
        error,
      };
    case constants.DISH_GET.SUCCESS:
      return {
        ...state,
        error: null,
      };
    case constants.DISH_GET.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // RIDERS_GET
    case constants.RIDERS_GET.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.RIDERS_GET.FAIL:
      return {
        ...state,
        error,
      };
    case constants.RIDERS_GET.SUCCESS:
      return {
        ...state,
        error: null,
        riders: payload,
      };
    case constants.RIDERS_GET.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // WAITERS_GET
    case constants.WAITERS_GET.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.WAITERS_GET.FAIL:
      return {
        ...state,
        error,
      };
    case constants.WAITERS_GET.SUCCESS:
      return {
        ...state,
        error: null,
        waiters: payload,
      };
    case constants.WAITERS_GET.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // ORDER_ADD
    case constants.ORDER_ADD:
      return {
        ...state,
        orders: [...new Set([payload, ...state.orders])],
      };

    // ORDER_REMOVE
    case constants.ORDER_REMOVE:
      return {
        ...state,
        orders: state.orders.filter(order => order !== payload),
      };

    // ORDERS_GET
    case constants.ORDERS_GET.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.ORDERS_GET.FAIL:
      return {
        ...state,
        error,
      };
    case constants.ORDERS_GET.SUCCESS:
      return {
        ...state,
        error: null,
        orders: payload,
      };
    case constants.ORDERS_GET.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // ORDER_PLACE
    case constants.ORDER_PLACE.REQUEST:
      return {
        ...state,
        error: null,
        orders: [payload.uid, ...state.orders],
      };
    case constants.ORDER_PLACE.ROLLBACK:
      return {
        ...state,
        error: payload,
        orders: state.orders.filter((order) => order !== meta.uid),
      };

    // ORDER_UPDATE_REQUEST, ORDER_READY_REQUEST, ORDER_DISPATCH_REQUEST, ORDER_COMPLETE_REQUEST
    case constants.ORDER_UPDATE.REQUEST:
    case constants.ORDER_READY.REQUEST:
    case constants.ORDER_DISPATCH.REQUEST:
    case constants.ORDER_COMPLETE.REQUEST:
    case constants.ORDER_ITEMS_UPDATE.REQUEST:
      return {
        ...state,
        error: null,
      };

    // ORDER_UPDATE_ROLLBACK, ORDER_READY_ROLLBACK, ORDER_DISPATCH_ROLLBACK, ORDER_COMPLETE_ROLLBACK
    case constants.ORDER_UPDATE.ROLLBACK:
    case constants.ORDER_READY.ROLLBACK:
    case constants.ORDER_DISPATCH.ROLLBACK:
    case constants.ORDER_COMPLETE.ROLLBACK:
    case constants.ORDER_ITEMS_UPDATE.ROLLBACK:
      return {
        ...state,
        error: payload,
      };

    // ORDER_DELETE
    case constants.ORDER_DELETE.REQUEST:
      return {
        ...state,
        error: null,
        orders: state.orders.filter((order) => order !== payload.uid),
      };
    case constants.ORDER_DELETE.ROLLBACK:
      return {
        ...state,
        error: payload,
        orders: [meta.uid, ...state.orders],
      };

    // BOOKINGS_GET
    case constants.BOOKINGS_GET.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.BOOKINGS_GET.FAIL:
      return {
        ...state,
        error,
      };
    case constants.BOOKINGS_GET.SUCCESS:
      return {
        ...state,
        error: null,
        bookings: payload,
      };
    case constants.BOOKINGS_GET.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // BOOKING_CREATE
    case constants.BOOKING_CREATE.REQUEST:
      return {
        ...state,
        error: null,
        bookings: new Date(payload.date).toDateString() === (new Date().toDateString())
          ? [payload.uid, ...state.bookings]
          : state.bookings,
      };
    case constants.BOOKING_CREATE.ROLLBACK:
      return {
        ...state,
        error: payload,
        bookings: state.bookings.filter((booking) => booking !== meta.uid),
      };

    // BOOKING_UPDATE
    case constants.BOOKING_UPDATE.REQUEST:
      return {
        ...state,
        error: null,
        bookings: new Date(payload.date).toDateString() !== (new Date().toDateString())
          ? state.bookings.filter((booking) => booking !== payload.uid)
          : state.bookings,
      };
    case constants.BOOKING_UPDATE.ROLLBACK:
      return {
        ...state,
        error: payload,
        bookings: new Date(payload.date).toDateString() !== (new Date().toDateString())
          ? [payload.uid, ...state.bookings]
          : state.bookings,
      };

    // BOOKING_CANCEL_REQUEST, BOOKING_COMPLETE_REQUEST
    case constants.BOOKING_CANCEL.REQUEST:
    case constants.BOOKING_COMPLETE.REQUEST:
      return {
        ...state,
        error: null,
        bookings: state.bookings.filter((id) => id !== payload.uid),
      };

    // BOOKING_UPDATE_ROLLBACK, BOOKING_CANCEL_ROLLBACK, BOOKING_COMPLETE_ROLLBACK
    case constants.BOOKING_CANCEL.ROLLBACK:
    case constants.BOOKING_COMPLETE.ROLLBACK:
      return {
        ...state,
        error: payload,
        bookings: [meta.uid, ...state.bookings],
      };

    default:
      return state;
  }
}
