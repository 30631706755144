import React from 'react';
import { connect } from 'react-redux';
import { Phone } from 'react-feather';
import {
  Box,
  HStack,
  Text,
  Avatar,
} from '@chakra-ui/react';

import { getOrder } from '../../../../entities/redux/selectors';

/* =============================================================================
<OngoingOrderCardUser />
============================================================================= */
const OngoingOrderCardUser = ({ order }) => {
  const isCustomer = Boolean(order?.customer);
  const username = isCustomer
    ? `${order.customer.profile.firstName} ${order.customer.profile.lastName}` : order?.meta?.name;
  const phone = isCustomer ? order?.customer?.phone : order?.meta?.phone;

  return (
    <HStack mb={2} justify="space-between">
      {username ? (
        <HStack>
          <Avatar w="40px" h="40px" />
          <Box>
            <Text fontSize="sm">{username}</Text>
            {phone && (
              <HStack>
                <Phone size="15px" />
                <Text fontSize="sm">
                  {phone}
                </Text>
              </HStack>
            )}
          </Box>
        </HStack>
      ) : <Box />}
    </HStack>
  );
};

const mapStateToProps = (state, { id }) => ({
  order: getOrder(state, { id }),
});

const propsAreEqual = (prevProps, nextProps) => (
  prevProps.order?.meta?.name === nextProps.order?.meta?.name
  && prevProps.order?.meta?.phone === nextProps.order?.meta?.phone
  && prevProps.order?.customer?.name === nextProps.order?.customer?.name
  && prevProps.order?.customer?.phone === nextProps.order?.customer?.phone
);

/* Export
============================================================================= */
export default connect(mapStateToProps)(React.memo(OngoingOrderCardUser, propsAreEqual));
