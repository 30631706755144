import React from 'react';
import { Field } from 'formik';
import { Trash } from 'react-feather';
import {
  Text,
  Input,
  Button,
  HStack,
  FormControl,
} from '@chakra-ui/react';

/* =============================================================================
<OpeningScheduleDay />
============================================================================= */
const OpeningScheduleDay = ({
  index,
  dayIndex,
  onAddHourClick,
  onDeleteHourClick,
}) => (
  <HStack w="100%">
    <Field name={`hours.${dayIndex}.hours.${index}.open`}>
      {({ field }) => (
        <FormControl>
          <Input type="time" {...field} />
        </FormControl>
      )}
    </Field>
    <Text>To</Text>
    <Field name={`hours.${dayIndex}.hours.${index}.close`}>
      {({ field }) => (
        <FormControl>
          <Input type="time" {...field} />
        </FormControl>
      )}
    </Field>
    {index > 0 ? (
      <Button
        w="50%"
        variant="unstlyed"
        leftIcon={<Trash size="14px" />}
        onClick={onDeleteHourClick}
      >
        Delete
      </Button>
    ) : (
      <Button
        w="50%"
        color="red.700"
        variant="unstlyed"
        onClick={onAddHourClick}
      >
        + Add Hour
      </Button>
    )}
  </HStack>
);

/* Export
============================================================================= */
export default OpeningScheduleDay;
