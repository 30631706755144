import React from 'react';
import { HStack, Button, Text } from '@chakra-ui/react';

/* =============================================================================
<ReservationsFooter />
============================================================================= */
const ReservationsFooter = ({
  currentPage,
  maxPage,
  canNext,
  canPrev,
  onNext,
  onPrev,
}) => (
  <HStack p={6} justify="space-between">
    <Button
      color="gray.600"
      variant="outline"
      colorScheme="gray"
      disabled={!canPrev}
      onClick={onPrev}
    >
      Pervious
    </Button>
    <Text>{`Page ${currentPage} to ${maxPage}`}</Text>
    <Button
      color="gray.600"
      variant="outline"
      colorScheme="gray"
      disabled={!canNext}
      onClick={onNext}
    >
      Next
    </Button>
  </HStack>
);

/* Export
============================================================================= */
export default ReservationsFooter;
