import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  FormControl,
  FormLabel,
  Select,
  Input,
  ModalCloseButton,
  VStack,
  Button,
  Box,
  useToast,
  Checkbox,
  CheckboxGroup,
} from '@chakra-ui/react';
import { captureException } from 'logrocket';
import request from '../../util/request';

const TODAY_ISO_DATE = new Date().toISOString().split('T')[0]; // yyyy-mm-dd
const d = new Date();
d.setDate(1);
d.setMonth(d.getMonth() - 1);
const LAST_MONTH_ISO_DATE_FIRST_DAY = d.toISOString().split('T')[0]; // yyyy-mm-dd
const s = new Date();
s.setDate(0);
const LAST_MONTH_ISO_DATE_LAST_DAY = s.toISOString().split('T')[0]; // yyyy-mm-dd

/* =============================================================================
<OrderHistoryDownloadModal />
============================================================================= */
const OrderHistoryDownloadModal = ({ isOpen, onClose }) => {
  const toast = useToast();
  const [orderStatus, setOrderStatus] = useState('completed');
  const [fromFilter, setFromFilter] = useState(LAST_MONTH_ISO_DATE_FIRST_DAY);
  const [toFilter, setToFilter] = useState(LAST_MONTH_ISO_DATE_LAST_DAY);
  const [includeCustomer, setIncludeCustomer] = useState(false);
  const [loading, setLoading] = useState(false);


  const download = async () => {
    try {
      setLoading(true);
      const result = await request({
        url: '/orders/download',
        params: {
          from: fromFilter,
          to: toFilter,
          status: orderStatus,
          includeCustomer: includeCustomer ? 1 : 0,
        },
      });
      const _url = window.URL.createObjectURL(
        new Blob([result], { type: 'text/csv' })
      );
      window.open(_url);
    } catch (error) {
      toast({
        title: 'Something went wrong',
        status: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      size="xl"
      isOpen={isOpen}
      scrollBehavior="inside"
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Download History</ModalHeader>
        <ModalCloseButton />
        <ModalBody as={VStack}>
          <FormControl>
            <FormLabel>Status</FormLabel>
            <Select
              width="auto"
              value={orderStatus}
              onChange={e => setOrderStatus(e.target.value)}
            >
              <option value="completed">completed</option>
              <option value="declined">declined</option>
              <option value="pending">pending</option>
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="fromFilter">From</FormLabel>
            <Input
              id="fromFilter"
              type="date"
              min="2022-03-01"
              max={TODAY_ISO_DATE}
              value={fromFilter}
              onChange={e => setFromFilter(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="toFilter">To</FormLabel>
            <Input
              id="toFilter"
              type="date"
              min="2022-03-01"
              max={TODAY_ISO_DATE}
              value={toFilter}
              onChange={e => setToFilter(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <Checkbox isChecked={includeCustomer} onChange={(e) => setIncludeCustomer(e.target.checked)}>Include Customer</Checkbox>
          </FormControl>
          <Box py={4} w="100%" textAlign="right">
            <Button isDisabled={loading} isLoading={loading} onClick={download}>
              Download
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

/* Export
============================================================================= */
export default OrderHistoryDownloadModal;
