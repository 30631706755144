import React from 'react';
import { Text } from '@chakra-ui/react';

import OrderSetupDetailsTable from './OrderSetupDetailsTable';

/* =============================================================================
<OrderSetupDetails />
============================================================================= */
const OrderSetupDetails = ({ items, setItems }) => (
  <>
    <Text fontSize="lg" fontWeight="medium">Order Detail</Text>
    <Text fontSize="sm">Update customer orders here.</Text>
    <OrderSetupDetailsTable items={items} setItems={setItems} />
  </>
);

/* Export
============================================================================= */
export default OrderSetupDetails;
