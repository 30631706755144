import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Td,
  Text,
  HStack,
} from '@chakra-ui/react';
import { Clock } from 'react-feather';

import { calculateTimeDiff } from '../../../../util/functions';

import { getOrder } from '../../../../entities/redux/selectors';

/* =============================================================================
<OrderHistoryTableItemCompletedTime />
============================================================================= */
const OrderHistoryTableItemCompletedTime = ({ order }) => {
  const status = order?.status;
  const declinedIn = calculateTimeDiff(order?.createdAt, order?.declinedAt);
  const canceledIn = calculateTimeDiff(order?.createdAt, order?.canceledAt);
  const completedIn = calculateTimeDiff(order?.createdAt, order?.completedAt);

  return (
    <Td>
      <HStack>
        <Clock
          size="16px"
          color={status === 'completed'
            ? 'green' : 'red'}
        />
        <Text
          fontSize="sm"
          color={status === 'completed'
            ? 'green' : 'red'}
        >
          {status === 'declined' && declinedIn}
          {status === 'canceled' && canceledIn}
          {status === 'completed' && completedIn}
        </Text>
      </HStack>
    </Td>
  );
};

const mapStateToProps = (state, { id }) => ({
  order: getOrder(state, { id }),
});

// eslint-disable-next-line max-len
const propsAreEqual = (prevProps, nextProps) => prevProps.order?.completedIn === nextProps.order?.completedIn;

/* Export
============================================================================= */
export default connect(
  mapStateToProps,
)(React.memo(OrderHistoryTableItemCompletedTime, propsAreEqual));
