import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { BookOpen } from 'react-feather';
import {
  Text,
  List,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  useToast,
} from '@chakra-ui/react';

import MenuListItem from './MenuListItem';
import MenuListItemFeatured from './MenuListItemFeatured';
import { reorder } from '../../../../util/functions';

import { getMenu } from '../../../../entities/redux/selectors';
import { updateMenu as updateMenuAction } from '../../../redux/actions';

/* =============================================================================
<Menu />
============================================================================= */
const Menu = ({
  menu,
  updateMenu,
}) => {
  const toast = useToast();
  const [items, setItems] = useState([]);
  const name = menu?.name;
  const menuItemsStr = menu?.items?.toString();

  // Set menu
  useEffect(() => {
    if (menu?.items) {
      setItems(menu?.items);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuItemsStr]);

  const _handleDragEnd = (result) => {
    // Dropped at the same index
    if (result.source.index === result.destination.index) {
      return;
    }

    const reordered = reorder(
      items,
      result.source.index,
      result.destination.index,
    );

    const prevOrder = [...items];
    setItems(reordered);

    updateMenu({ id: menu?.id, items: reordered }, (err) => {
      if (!err) {
        toast({
          title: 'Changes Saved',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } else {
        setItems(prevOrder);
        toast({
          title: err.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    });
  };

  return (
    <Accordion mb={2} defaultIndex={[0]} allowMultiple>
      <AccordionItem border="none">
        <AccordionButton px={0}>
          <BookOpen />
          <Text
            flex={1}
            ml="14px"
            fontWeight="semibold"
            textAlign="left"
          >
            {name}
          </Text>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel px={0}>
          <MenuListItemFeatured menuId={menu?.id} />
          <DragDropContext onDragEnd={_handleDragEnd}>
            <Droppable droppableId="menuItem">
              {(provided, snapshot) => (
                <List
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  bg={snapshot.isDraggingOver ? 'gray.50' : 'inherit'}
                >
                  {items.map((item, i) => (
                    <MenuListItem
                      id={item}
                      key={item}
                      index={i}
                      menuId={menu?.id}
                    />
                  ))}
                  {provided.placeholder}
                </List>
              )}
            </Droppable>
          </DragDropContext>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

const mapStateToProps = (state, { id }) => ({
  menu: getMenu(state, { id, normalize: true }),
});

const mapDispatchToProps = {
  updateMenu: updateMenuAction,
};

const propsAreEqual = (prevProps, nextProps) => prevProps.id === nextProps.id
  && prevProps.menu?.name === nextProps.menu?.name
  && prevProps.menu?.items?.toString() === nextProps.menu?.items?.toString();

/* Export
============================================================================= */
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(Menu, propsAreEqual));
