import React from 'react';
import { connect } from 'react-redux';
import {
  Tr,
  Td,
  HStack,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import moment from 'moment';
import { Info } from 'react-feather';

import ReservationModal from '../../ReservationModal/index';
import ReservationsTableItemCustomer from './ReservationsTableItemCustomer';
import ReservationsTableItemStatus from './ReservationsTableItemStatus';

import { getBooking } from '../../../../../entities/redux/selectors';

/* =============================================================================
<ReservationsTableItem />
============================================================================= */
const ReservationsTableItem = ({ id, booking }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const seats = booking?.seats;
  const date = moment(booking?.date).format('MMM D, YY');
  const time = moment().startOf('day').minutes(booking?.time).format('HH:mm A');

  return (
    <Tr>
      <ReservationModal id={id} isOpen={isOpen} onClose={onClose} />
      <ReservationsTableItemCustomer id={id} />
      <Td>{seats}</Td>
      <Td textAlign="start">{date}</Td>
      <Td>{time}</Td>
      <ReservationsTableItemStatus id={id} />
      <Td>
        <Button
          variant="outline"
          colorScheme="gray"
          borderRadius="8px"
          leftIcon={<Info size="16px" />}
          onClick={onOpen}
        >
          Detail
        </Button>
      </Td>
    </Tr>
  );
};

const mapStateToProps = (state, { id }) => ({
  booking: getBooking(state, { id }),
});

// eslint-disable-next-line max-len
const propsAreEqual = (prevProps, nextProps) => prevProps.booking?.id === nextProps.booking?.id
  && prevProps.booking?.status === nextProps.booking?.status
  && prevProps.booking?.time === nextProps.booking?.time
  && prevProps.booking?.date === nextProps.booking?.date;

/* Export
============================================================================= */
export default connect(mapStateToProps)(React.memo(ReservationsTableItem, propsAreEqual));
