import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Tr,
  Box,
  Table,
  Thead,
  Tbody,
} from '@chakra-ui/react';

import AreaManagementTableItem from './AreaManagementTableItem';
import AreaManagementTableHeadItem from './AreaManagementTableHeadItem';
import AreaManagementTableFooter from './AreaManagementTableFooter';
import usePagination from '../../../hooks/use-pagination';

import { getAreas as getAreasAction } from '../../redux/actions';
import { getAreas as selectAreas } from '../../redux/selectors';

/* =============================================================================
<AreaManagementTable />
============================================================================= */
const AreaManagementTable = ({ areas, getAreas }) => {
  const {
    next,
    prev,
    page,
    maxPage,
    items,
    canNext,
    canPrev,
  } = usePagination(areas);

  // Get areas
  useEffect(() => {
    getAreas();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _handleNext = () => {
    if (canNext) {
      next();
    }
  };

  const _handlePrev = () => {
    if (canPrev) {
      prev();
    }
  };

  return (
    <Box flex={1}>
      <Table variant="simple" colorScheme="gray">
        <Thead>
          <Tr>
            {COLUMNS.map(renderColumn)}
          </Tr>
        </Thead>
        <Tbody>
          {items.map(renderItem)}
        </Tbody>
      </Table>
      <AreaManagementTableFooter
        currentPage={page}
        maxPage={maxPage}
        canNext={canNext}
        canPrev={canPrev}
        onNext={_handleNext}
        onPrev={_handlePrev}
      />
    </Box>
  );
};

const renderColumn = (item, i) => <AreaManagementTableHeadItem key={`${i}`} title={item} />;
const renderItem = (item) => <AreaManagementTableItem key={item} id={item} />;

const COLUMNS = [
  'Area Name',
  'Capacity',
  'STATUS',
  'ACTION',
];

const mapStateToProps = (state) => ({
  areas: selectAreas(state),
});

const mapDispatchToProps = {
  getAreas: getAreasAction,
};

/* Export
============================================================================= */
export default connect(mapStateToProps, mapDispatchToProps)(AreaManagementTable);
