import React, { useEffect, useState } from 'react';
import {
  Tab,
  Tabs,
  TabList,
  Divider,
  TabPanel,
  TabPanels,
} from '@chakra-ui/react';
import { connect } from 'react-redux';

import Card from '../../common/Card';
import RestaurantInfo from '../components/RestaurantInfo';
import RestaurantHeader from '../components/RestaurantHeader';
import RestaurantSettings from '../components/RestaurantSettings';
import useQuery from '../../hooks/useQuery';

import {
  getRestaurant as getRestaurantAction,
  getRestaurantBranches as getRestaurantBranchesAction,
} from '../redux/actions';

/* =============================================================================
<RestaurantScreen />
============================================================================= */
const RestaurantScreen = ({ getRestaurant, getRestaurantBranches }) => {
  const query = useQuery();
  const index = query.get('index');
  const [tabIndex, setTabIndex] = useState(0);

  // Get restaurant and branches
  useEffect(() => {
    getRestaurant();
    getRestaurantBranches();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Sync index from query with tab index
  useEffect(() => {
    if (!Number.isNaN(index)) {
      setTabIndex(+index);
    }
  }, [index]);

  const _handleTabsChange = (value) => {
    setTabIndex(value);
  };

  return (
    <Card m={5} flex={1} direction="column">
      <RestaurantHeader />
      <Divider mt={8} mb={5} />
      <Tabs isLazy index={tabIndex} colorScheme="brand" onChange={_handleTabsChange}>
        <TabList border="none">
          <Tab fontWeight="medium">Restaurant</Tab>
          <Tab fontWeight="medium">Settings</Tab>
          <Tab fontWeight="medium">Reviews</Tab>
        </TabList>
        <TabPanels>
          <TabPanel p={0}>
            <RestaurantInfo />
          </TabPanel>
          <TabPanel p={0}>
            <RestaurantSettings />
          </TabPanel>
          <TabPanel p={0} />
        </TabPanels>
      </Tabs>
    </Card>
  );
};

const mapDispatchToProps = {
  getRestaurant: getRestaurantAction,
  getRestaurantBranches: getRestaurantBranchesAction,
};

/* Export
============================================================================= */
export default connect(null, mapDispatchToProps)(RestaurantScreen);
