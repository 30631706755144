import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Tr,
  Td,
  Button,
  HStack,
  Switch,
  useToast,
  useDisclosure,
  Text,
} from '@chakra-ui/react';
import { Edit } from 'react-feather';

import EditAreaModal from '../ManagementModals/EditAreaModal';

import {
  deleteArea as deleteAreaAction,
  updateArea as updateAreaAction,
} from '../../redux/actions';
import { getArea } from '../../../entities/redux/selectors';

/* =============================================================================
<AreaManagementTableItem />
============================================================================= */
const AreaManagementTableItem = ({
  id,
  area,
  updateArea,
  deleteArea,
}) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  const name = area?.name;
  const capacity = area?.capacity;

  // Set disabled
  useEffect(() => {
    setDisabled(!!area?.disabled);
  }, [area?.disabled]);

  const _handleActiveChange = async () => {
    setDisabled((prevState) => !prevState);
    await updateArea({
      id,
      disabled: !disabled,
    }, (err) => {
      if (err) {
        setDisabled((prevState) => !prevState);
        toast({
          title: 'Error occured.',
          description: err?.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    });
  };

  const _handleDeleteClick = async () => {
    setLoading(true);
    await deleteArea(id, (err) => {
      if (err) {
        toast({
          title: 'Error occured.',
          description: err?.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    });
    setLoading(false);
  };

  return (
    <Tr>
      <Td>{name}</Td>
      <Td textAlign="start">{capacity}</Td>
      <Td>
        <HStack>
          <Switch isChecked={!disabled} colorScheme="brand" onChange={_handleActiveChange} />
          <Text>{disabled ? 'Inactive' : 'Active'}</Text>
        </HStack>
      </Td>
      <Td>
        <HStack>
          <Button leftIcon={<Edit size="18px" />} onClick={onOpen}>
            Edit
          </Button>
          <Button
            variant="outline"
            colorScheme="gray"
            isLoading={loading}
            onClick={_handleDeleteClick}
          >
            Delete
          </Button>
        </HStack>
      </Td>
      <EditAreaModal id={id} isOpen={isOpen} onClose={onClose} />
    </Tr>
  );
};

const mapStateToProps = (state, { id }) => ({
  area: getArea(state, { id }),
});

const mapDispatchToProps = {
  updateArea: updateAreaAction,
  deleteArea: deleteAreaAction,
};

// eslint-disable-next-line max-len
const propsAreEqual = (prevProps, nextProps) => prevProps.area?.name === nextProps.area?.name
&& prevProps.area?.capacity === nextProps.area?.capacity
&& prevProps.area?.disabled === nextProps.area?.disabled
&& prevProps.area?.restaurant.toString() === nextProps.area?.restaurant.toString();

/* Export
============================================================================= */
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(AreaManagementTableItem, propsAreEqual));
