import React from 'react';
import {
  Tr,
  Box,
  Table,
  Thead,
  Tbody,
} from '@chakra-ui/react';

import ReservationTableItem from './ReservationTableItem/index';
import ReservationTableHeadItem from './ReservationTableHeadItem';

/* =============================================================================
<ReservationTable />
============================================================================= */
const ReservationTable = ({ data }) => (
  <Box flex={1}>
    <Table variant="simple" colorScheme="gray">
      <Thead>
        <Tr>
          {COLUMNS.map(renderColumn)}
        </Tr>
      </Thead>
      <Tbody>
        {data.map(renderItem)}
      </Tbody>
    </Table>
  </Box>
);

const renderColumn = (item, i) => <ReservationTableHeadItem key={`${i}`} title={item} />;
const renderItem = (item) => <ReservationTableItem key={item} id={item} />;

const COLUMNS = [
  'Name',
  'Seats',
  'Event',
  'Reservation Time',
  'Action',
];

/* Export
============================================================================= */
export default ReservationTable;
