import React from 'react';
import { connect } from 'react-redux';
import { Wrapper } from '@googlemaps/react-wrapper';
import {
  Box,
  Modal,
  ModalBody,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
} from '@chakra-ui/react';

import MapView from '../../common/MapView';
import { GOOGLE_API_KEY } from '../../config/secrets';

import { getNeighborhood } from '../../entities/redux/selectors';

/* =============================================================================
<MyNeighborhoodsViewModal />
============================================================================= */
const MyNeighborhoodsViewModal = ({ isOpen, neighborhoods, onClose }) => {
  const polygons = neighborhoods?.map(neighborhood => ({
    title: `${neighborhood?.name} (${neighborhood?.area})`,
    path: neighborhood?.geometry?.coordinates?.[0]?.map(coordinate => ({
      lat: coordinate[1],
      lng: coordinate[0],
    })),
  }));

  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>My Areas</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box width="530px" height="500px" mb={6}>
            <Wrapper apiKey={GOOGLE_API_KEY}>
              <MapView polygons={polygons} />
            </Wrapper>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const mapStateToProps = (state, { ids }) => ({
  neighborhoods: ids.map(id => getNeighborhood(state, { id })),
});

const propsAreEqual = (prevProps, nextProps) =>
  prevProps.isOpen === nextProps.isOpen &&
  JSON.stringify(prevProps.ids) === JSON.stringify(nextProps.ids) &&
  JSON.stringify(prevProps.neighborhoods) ===
    JSON.stringify(nextProps.neighborhoods);

/* Export
============================================================================= */
export default connect(mapStateToProps)(
  React.memo(MyNeighborhoodsViewModal, propsAreEqual)
);
