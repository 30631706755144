import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Box } from '@chakra-ui/react';

import Menu from './Menu';
import ExtraChoices from './ExtraChoices';
import Discounts from './Discounts';

import { getMenus as selectMenus } from '../../redux/selectors';
import { getMenus as getMenusAction } from '../../redux/actions';

/* =============================================================================
<MenuList />
============================================================================= */
const MenuList = ({ menus, getMenus }) => {
  // Get menus
  useEffect(() => {
    getMenus();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box flex={1}>
      {menus.map((menu) => (
        <Menu key={menu} id={menu} />
      ))}
      <ExtraChoices />
      <Discounts />
    </Box>
  );
};

const mapStateToProps = (state) => ({
  menus: selectMenus(state),
});

const mapDispatchToProps = {
  getMenus: getMenusAction,
};

// eslint-disable-next-line max-len
const propsAreEqual = (prevProps, nextProps) => prevProps.menus.toString() === nextProps.menus.toString();

/* Export
============================================================================= */
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(MenuList, propsAreEqual));
