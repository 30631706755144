import React from 'react';
import { connect } from 'react-redux';
import {
  Input,
  HStack,
  FormLabel,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
  Select,
} from '@chakra-ui/react';
import { FastField } from 'formik';

import { getRestaurant } from '../../../../restaurant/redux/selectors';

/* =============================================================================
<OrderSetupAddReservationDineInInfo />
============================================================================= */
const OrderSetupAddReservationDineInInfo = ({ restaurant }) => {
  const events = restaurant?.booking?.events;

  const eventsOptions = events?.map((ev) => ({
    label: ev?.name,
    value: ev?.name,
  }));

  return (
    <HStack mb={8} spacing={4} alignItems="flex-start">
      <FastField name="seats">
        {({ field, form }) => (
          <FormControl isInvalid={form.errors.seats && form.touched.seats}>
            <FormLabel>Number of People</FormLabel>
            <Input {...field} placeholder="Ex: 4" />
            <FormErrorMessage>
              <FormErrorIcon />
              {form.errors.seats}
            </FormErrorMessage>
          </FormControl>
        )}
      </FastField>
      <FastField name="event">
        {({ field, form }) => (
          <FormControl isInvalid={form.errors.event && form.touched.event}>
            <FormLabel>Events</FormLabel>
            <Select
              placeholder="Select"
              textTransform="capitalize"
              {...field}
            >
              {eventsOptions.map((option) => (
                <option key={option.value} value={option.value}>{option.label}</option>
              ))}
            </Select>
            <FormErrorMessage>
              <FormErrorIcon />
              {form.errors.event}
            </FormErrorMessage>
          </FormControl>
        )}
      </FastField>
    </HStack>
  );
};

const mapStateToProps = (state) => ({
  restaurant: getRestaurant(state),
});

/* Export
============================================================================= */
export default connect(mapStateToProps)(OrderSetupAddReservationDineInInfo);
