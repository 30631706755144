import React from 'react';
import {
  Box,
  HStack,
  Text,
  Button,
  Divider,
} from '@chakra-ui/react';

import { NavLink } from 'react-router-dom';
import ReservationsStatusSelect from './ReservationsStatusSelect';

/* =============================================================================
<ReservationsHeader />
============================================================================= */
const ReservationsHeader = () => (
  <Box mb={5}>
    <HStack justify="space-between">
      <Box mr={5}>
        <Text fontSize="lg" fontWeight="medium">Reservations</Text>
        <Text fontSize="sm">View all reservations</Text>
      </Box>
      <HStack>
        <Button as={NavLink} to="/booking" variant="outline" colorScheme="gray">
          View in Calendar
        </Button>
        <Button as={NavLink} to="/booking/settings">
          Reservation Settings
        </Button>
      </HStack>
    </HStack>
    <Divider my={5} />
    <ReservationsStatusSelect />
  </Box>
);

/* Export
============================================================================= */
export default ReservationsHeader;
