import React from 'react';
import { connect } from 'react-redux';
import {
  Box,
  Text,
  HStack,
  Divider,
} from '@chakra-ui/react';

import { getOrder } from '../../../../entities/redux/selectors';

/* =============================================================================
<OngoingOrderCardDineInInfo />
============================================================================= */
const OngoingOrderCardDineInInfo = ({ order }) => {
  if (order?.type !== 'dine_in') {
    return null;
  }

  const table = order?.table?.name;
  const waiter = `${order?.waiter?.profile?.firstName} ${order?.waiter?.profile?.lastName}`;

  return (
    <>
      <HStack justify="space-between">
        <Box>
          <Text fontSize="xs">Table Number</Text>
          <Text>{table}</Text>
        </Box>
        <Box>
          <Text fontSize="xs">Waiter</Text>
          <Text>{waiter}</Text>
        </Box>
      </HStack>
      <Divider my={3} />
    </>
  );
};

const mapStateToProps = (state, { id }) => ({
  order: getOrder(state, { id }),
});

const propsAreEqual = (prevProps, nextProps) => (
  prevProps.order?.table?.name === nextProps.order?.table?.name
  && prevProps.order?.waiter?.profile?.fistName === nextProps.order?.waiter?.profile?.fistName
  && prevProps.order?.waiter?.profile?.lastName === nextProps.order?.waiter?.profile?.lastName
);

/* Export
============================================================================= */
export default connect(mapStateToProps)(React.memo(OngoingOrderCardDineInInfo, propsAreEqual));
