import React from 'react';
import { Mail } from 'react-feather';
import { FastField } from 'formik';
import {
  Input,
  VStack,
  InputGroup,
  FormLabel,
  FormControl,
  FormErrorIcon,
  InputLeftElement,
  FormErrorMessage,
} from '@chakra-ui/react';

/* =============================================================================
<RestaurantFormEmail />
============================================================================= */
const RestaurantFormEmail = ({ disabled }) => (
  <FastField name="email">
    {({ field, form }) => (
      <FormControl
        mb={8}
        display="flex"
        justifyContent="space-between"
        isInvalid={form.errors.email && form.touched.email}
      >
        <FormLabel flex={1.5}>Restaurant Email</FormLabel>
        <VStack alignItems="flex-start" flex={2}>
          <InputGroup>
            <InputLeftElement>
              <Mail size="18" color="#667085" />
            </InputLeftElement>
            <Input {...field} disabled={disabled} />
          </InputGroup>
          <FormErrorMessage>
            <FormErrorIcon />
            {form.errors.email}
          </FormErrorMessage>
        </VStack>
      </FormControl>
    )}
  </FastField>
);

/* Export
============================================================================= */
export default RestaurantFormEmail;
