import React from 'react';
import {
  Input,
  HStack,
  InputGroup,
  FormLabel,
  FormControl,
  InputLeftAddon,
  FormErrorMessage,
  Divider,
} from '@chakra-ui/react';
import { FastField } from 'formik';

/* =============================================================================
<EmployeeEmailAndSalary />
============================================================================= */
const EmployeeEmailAndSalary = () => (
  <>
    <Divider my={5} />
    <HStack spacing={5} align="flex-start">
      <FastField name="email">
        {({ field, form }) => (
          <FormControl
            flex={1}
            isInvalid={form.errors.email && form.touched.email}
          >
            <FormLabel fontWeight="normal">Email</FormLabel>
            <Input id="email" placeholder="Ex :ex@gmail.com" {...field} />
            <FormErrorMessage>{form.errors.email}</FormErrorMessage>
          </FormControl>
        )}
      </FastField>
      <FastField name="password">
        {({ field, form }) => (
          <FormControl
            flex={1}
            isInvalid={form.errors.password && form.touched.password}
          >
            <FormLabel fontWeight="normal">Password</FormLabel>
            <Input id="password" placeholder="Enter Password" {...field} />
            <FormErrorMessage>{form.errors.password}</FormErrorMessage>
          </FormControl>
        )}
      </FastField>
    </HStack>
  </>
);

/* Export
============================================================================= */
export default EmployeeEmailAndSalary;
