import React, { useCallback, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { ArrowRight, Printer } from 'react-feather';
import {
  Box,
  Button,
  useToast,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react';

import OrderReceipt from '../../../../common/OrderReceipt';
import OrderCancelModal from '../../../../common/OrderCancelModal';
import usePrint from '../../../../util/usePrint';

import { getOrder } from '../../../../entities/redux/selectors';
import { updateBykeaOrder as updateBykeaOrderAction } from '../../../redux/actions';

/* =============================================================================
<OrdersAcceptedActionsBykea />
============================================================================= */
const OrdersAcceptedActionsBykea = ({ id, order, updateBykeaOrder }) => {
  const toast = useToast();
  const orderReceiptRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [isLargerThen766] = useMediaQuery('(min-width: 766px)');
  const {
    isOpen: cancelModalIsOpen,
    onOpen: onCancelModalOpen,
    onClose: onCancelModalClose,
  } = useDisclosure();

  const bykeaStatus = order?.bykea?.status;
  const canTryAgain = bykeaStatus === 'expired' || bykeaStatus === 'cancelled';
  const canBookBykea = !bykeaStatus;

  // eslint-disable-next-line max-len
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const reactToPrintContent = useCallback(() => orderReceiptRef.current, [orderReceiptRef.current]);

  const _handlePrintClick = usePrint({contentEl: reactToPrintContent, order});

  const _handleCancelConfirm = async (declineReason) => {
    await updateBykeaOrder({
      id: order.id,
      action: 'decline',
      declineReason,
    }, (err) => {
      if (err) {
        toast({
          title: err.message,
        });
      }
    });
    onCancelModalClose();
  };

  const _handleBookBykeaClick = async () => {
    setLoading(true);
    await updateBykeaOrder({
      id: order.id,
      action: 'dispatch',
    }, (err) => {
      if (err) {
        toast({
          title: err.message,
        });
      }
    });
    setLoading(false);
  };

  const _handleTryAgainClick = async () => {
    setLoading(true);
    await updateBykeaOrder({
      id: order.id,
      action: 'retry',
    }, (err) => {
      if (err) {
        toast({
          title: err.message,
        });
      }
    });
    setLoading(false);
  };

  return (
    <>
      <Box display="none">
        <Box ref={orderReceiptRef}>
          <OrderReceipt id={id} />
        </Box>
      </Box>
      <Button
        px={4}
        variant="outline"
        colorScheme="gray"
        isDisabled={loading}
        onClick={onCancelModalOpen}
      >
        Cancel
      </Button>
      <Button
        variant="outline"
        colorScheme="gray"
        onClick={_handlePrintClick}
      >
        <Printer size="20px" />
      </Button>
      {canBookBykea && (
        <Button
          ml={2}
          w={['100%', '100%', 'auto']}
          isLoading={loading}
          rightIcon={isLargerThen766 && <ArrowRight size="20px" />}
          onClick={_handleBookBykeaClick}
        >
          Book Bykea
        </Button>
      )}
      {canTryAgain && (
        <Button
          ml={2}
          w={['100%', '100%', 'auto']}
          isLoading={loading}
          rightIcon={isLargerThen766 && <ArrowRight size="20px" />}
          onClick={_handleTryAgainClick}
        >
          Try Again
        </Button>
      )}
      <OrderCancelModal
        isOpen={cancelModalIsOpen}
        onClose={onCancelModalClose}
        onSubmit={_handleCancelConfirm}
      />
    </>
  );
};

const mapStateToProps = (state, { id }) => ({
  order: getOrder(state, { id }),
});

const mapDispatchToProps = {
  updateBykeaOrder: updateBykeaOrderAction,
};

const propsAreEqual = (prevProps, nextProps) => prevProps.order?.id === nextProps.order?.id
  && prevProps.order?.bykea?.status === nextProps.order?.bykea?.status;

/* Export
============================================================================= */
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(OrdersAcceptedActionsBykea, propsAreEqual));
