import React from 'react';
import { connect } from 'react-redux';
import {
  Box,
  Text,
  Avatar,
  HStack,
} from '@chakra-ui/react';
import { Mail, User, Phone } from 'react-feather';

import { getRestaurant } from '../../redux/selectors';

/* =============================================================================
<RestaurantInfoOwnerInformation />
============================================================================= */
const RestaurantInfoOwnerInformation = ({ restaurant }) => {
  const owner = restaurant?.owner;
  const name = owner?.profile ? `${owner.profile.firstName} ${owner.profile.lastName}` : '';
  const email = owner ? owner.email : '';
  const phone = owner ? owner.phone : '';
  const avatar = owner?.profile?.avatar;

  return (
    <Box flex={1} height="329px" p={4} borderRadius="8px" border="1px solid #D0D5DD">
      <Text mb={6} fontWeight="medium">Owner Info</Text>
      <Avatar src={avatar} />
      <HStack my={5}>
        <User size="16px" />
        <Text>
          Name :
          {' '}
          {name}
        </Text>
      </HStack>
      <HStack my={5}>
        <Mail size="16px" />
        <Text>
          Email :
          {' '}
          {email}
        </Text>
      </HStack>
      <HStack mt={5}>
        <Phone size="16px" />
        <Text>
          Phone :
          {' '}
          {phone}
        </Text>
      </HStack>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  restaurant: getRestaurant(state),
});

/* Export
============================================================================= */
export default connect(mapStateToProps)(RestaurantInfoOwnerInformation);
