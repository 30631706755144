import * as constants from './constants';

const INITIAL_STATE = {
  id: null,
  branches: [],
  error: null,
  loading: false,
};

export default function reducer(state = INITIAL_STATE, action) {
  const { type, payload, error } = action;

  switch (type) {
    // RESTAURANT_GET
    case constants.RESTAURANT_GET.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.RESTAURANT_GET.FAIL:
      return {
        ...state,
        error,
      };
    case constants.RESTAURANT_GET.SUCCESS:
      return {
        ...state,
        error: null,
        id: payload,
      };
    case constants.RESTAURANT_GET.COMPLETE:
      return {
        ...state,
        loading: false,
      };

      // RESTAURANT_BRANCHES_GET
    case constants.RESTAURANT_BRANCHES_GET.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.RESTAURANT_BRANCHES_GET.FAIL:
      return {
        ...state,
        error,
      };
    case constants.RESTAURANT_BRANCHES_GET.SUCCESS:
      return {
        ...state,
        error: null,
        branches: payload,
      };
    case constants.RESTAURANT_BRANCHES_GET.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // RESTAURANT_INFO_UPDATE
    case constants.RESTAURANT_INFO_UPDATE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.RESTAURANT_INFO_UPDATE.FAIL:
      return {
        ...state,
        error,
      };
    case constants.RESTAURANT_INFO_UPDATE.SUCCESS:
      return {
        ...state,
        error: null,
      };
    case constants.RESTAURANT_INFO_UPDATE.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // RESTAURANT_HOURS_UPDATE
    case constants.RESTAURANT_HOURS_UPDATE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.RESTAURANT_HOURS_UPDATE.FAIL:
      return {
        ...state,
        error,
      };
    case constants.RESTAURANT_HOURS_UPDATE.SUCCESS:
      return {
        ...state,
        error: null,
      };
    case constants.RESTAURANT_HOURS_UPDATE.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // RESTAURANT_BOOKING_UPDATE
    case constants.RESTAURANT_BOOKING_UPDATE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.RESTAURANT_BOOKING_UPDATE.FAIL:
      return {
        ...state,
        error,
      };
    case constants.RESTAURANT_BOOKING_UPDATE.SUCCESS:
      return {
        ...state,
        error: null,
      };
    case constants.RESTAURANT_BOOKING_UPDATE.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
