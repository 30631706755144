import { useEffect } from 'react';
import io from 'socket.io-client';
import { connect } from 'react-redux';
import { normalize } from 'normalizr';
import { useRouteMatch } from 'react-router-dom';

import { API_URL } from '../../config/secrets';
import { order as orderSchema } from '../../entities/api/schema';

import { addEntities as addEntitiesAction } from '../../entities/redux/actions';
import {
  addOrder as addOrderAction,
  getOrders as getOrdersAction,
  removeOrder as removeOrderAction,
} from '../redux/actions';

const audio = new Audio('/audios/new_order.mp3');

/* =============================================================================
<PosLiveOrderListener />
============================================================================= */
const PosLiveOrderListener = ({addOrder, addEntities, getOrders, removeOrder}) => {
  // Get POS orders
  const isOngoingOrdersRoute = !!useRouteMatch({path: "/pos/ongoing_order", exact: false });

  useEffect(() => {
    getOrders();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Listen for new orders, updates and deletion
  useEffect(() => {
    const socket = io(`${API_URL}/pos/orders`, {
      auth: {
        token: localStorage.getItem('@foodstreet/token'),
      },
      transports: ['websocket']
    });

    // Order create listener
    socket.on('create', (payload) => {
      const { entities, result } = normalize(payload, orderSchema);
      addEntities(entities);
      addOrder(result);
      if (isOngoingOrdersRoute) {
        audio.play();
        setTimeout(() => {
          audio.pause();
          audio.currentTime = 0;
        }, 6500);
      }
    });

    // Order update listener
    socket.on('update', (payload) => {
      const { entities } = normalize(payload, orderSchema);
      addEntities(entities);
    });

    // Order update listener
    socket.on('delete', (payload) => {
      removeOrder(payload.uid);
    });

    return () => {
      socket.off('create');
      socket.off('update');
      socket.off('delete');
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

const mapDispatchToProps = {
  addOrder: addOrderAction,
  getOrders: getOrdersAction,
  addEntities: addEntitiesAction,
  removeOrder: removeOrderAction,
};

/* Export
============================================================================= */
export default connect(null, mapDispatchToProps)(PosLiveOrderListener);
