import React, { useState } from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import {
  Text,
  Modal,
  HStack,
  Button,
  Divider,
  useToast,
  ModalBody,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';

import EmployeeName from '../EmployeeName';
import EmployeePhoneAndRole from '../EmployeePhoneAndRole';
import EmployeeEmailAndPassword from '../EmployeeEmailAndPassword';
import EmployeeSalary from '../EmployeeSalary';
import EmployeePhotoUpload from '../EmployeePhotoUpload';
import { uploadUserPhoto } from '../../../../util/upload';

import { createEmployee as createEmployeeAction } from '../../../redux/actions';

/* =============================================================================
<AddEmployeeModal />
============================================================================= */
const AddEmployeeModal = ({ isOpen, onClose, createEmployee }) => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const _handleSubmit = async (values) => {
    setLoading(true);

    const payload = {
      ...values,
      phone: `+92${values.phone}`,
    };

    if (payload.avatar) {
      payload.avatar = await uploadUserPhoto(payload.avatar);
    }

    await createEmployee(payload, (err) => {
      if (!err) {
        toast({
          title: 'Employee created',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        onClose();
      } else {
        toast({
          title: err.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    });
    setLoading(false);
  };

  return (
    <Modal size="2xl" isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="lg">Add Employee</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Divider mb={5} />
          <Formik
            initialValues={initialValues}
            validationSchema={AddEmployeeSchema}
            onSubmit={_handleSubmit}
          >
            {() => (
              <Form>
                <Text mb={5} fontWeight="semibold">Employee Form</Text>
                <EmployeeName />
                <EmployeePhoneAndRole />
                <EmployeeEmailAndPassword />
                <EmployeeSalary />
                <EmployeePhotoUpload />
                <Divider my={5} />
                <HStack mb={3}>
                  <Button
                    mr={4}
                    flex={1}
                    color="gray"
                    variant="outline"
                    colorScheme="gray"
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    flex={1}
                    type="submit"
                    isLoading={loading}
                  >
                    Create Employee
                  </Button>
                </HStack>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const initialValues = {
  firstName: '',
  lastName: '',
  phone: '',
  role: '',
  email: '',
  password: '',
  avatar: '',
  salary: '',
};

const AddEmployeeSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('Must not be empty!'),
  lastName: Yup.string()
    .required('Must not be empty!'),
  phone: Yup.number()
    .required('Must not be empty!'),
  role: Yup.string()
    .required('Must not be empty!'),
  email: Yup.string()
    .email('Should be a valid email!')
    .required('Must not be empty!'),
  password: Yup.string()
    .min(8, 'Must be at least 8 characters and contain one digit!')
    .max(25, 'Must not exceed 25 characters!')
    .required('Must not be empty!'),
  avatar: Yup.mixed()
    .optional(),
  salary: Yup.number()
    .required('Must not be empty!'),
});

const mapDispatchToProps = {
  createEmployee: createEmployeeAction,
};

/* Export
============================================================================= */
export default connect(null, mapDispatchToProps)(AddEmployeeModal);
