import React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import {
  Text,
  Modal,
  HStack,
  Button,
  Divider,
  ModalBody,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  useToast,
} from '@chakra-ui/react';

import ChoiceName from '../ChoiceName';
import ChoiceRange from '../ChoiceRange';
import ChoiceDescription from '../ChoiceDescription';
import ChoiceAddChoices from '../ChoiceAddChoices';

import { createChoiceGroup as createChoiceGroupAction } from '../../../../redux/actions';

/* =============================================================================
<CreateChoiceModal />
============================================================================= */
const CreateChoiceModal = ({ isOpen, onClose, createChoiceGroup }) => {
  const toast = useToast();

  const _handleSubmit = async (values) => {
    await createChoiceGroup(values, (err) => {
      if (!err) {
        toast({
          title: 'Extra choice created',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        onClose();
      } else {
        toast({
          title: err.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    });
  };

  return (
    <Modal size="3xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="lg">Create Extra Choice</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Divider mb={5} />
          <Formik
            initialValues={INITIAL_VALUES}
            validationSchema={CreateChoiceSchema}
            onSubmit={_handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <Text mb={5} fontWeight="medium">Extra Choice Details</Text>
                <ChoiceName />
                <ChoiceRange />
                <ChoiceDescription />
                <ChoiceAddChoices />
                <HStack mb={2} spacing={4}>
                  <Button
                    flex={1}
                    colorScheme="gray"
                    variant="outline"
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" flex={1} isLoading={isSubmitting}>
                    Create item
                  </Button>
                </HStack>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const INITIAL_VALUES = {
  name: '',
  min: 1,
  max: 1,
  about: '',
  choices: [],
};

const CreateChoiceSchema = Yup.object().shape({
  name: Yup.string()
    .required('Must not be empty!'),
  min: Yup.number()
    .max(Yup.ref('max'))
    .required('Must not be empty!'),
  max: Yup.number()
    .min(Yup.ref('min'))
    .required('Must not be empty!'),
  about: Yup.string()
    .optional(),
  choices: Yup.array().of(Yup.object().shape({
    name: Yup.string().required('Must not be empty!'),
    price: Yup.number().required('Must not be empty!'),
    available: Yup.boolean().required('Must not be empty!'),
  })).min(Yup.ref('min'), 'Choices must match the minimum range'),
});

const mapDispatchToProps = {
  createChoiceGroup: createChoiceGroupAction,
};

/* Export
============================================================================= */
export default connect(null, mapDispatchToProps)(CreateChoiceModal);
