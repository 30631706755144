import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import '@fontsource/metropolis/all.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { logrocket } from './util/analytics';

logrocket.init();

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
