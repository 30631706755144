import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Search as SearchIcon } from 'react-feather';
import {
  Box,
  Tab,
  Text,
  Tabs,
  Input,
  Select,
  VStack,
  TabList,
  FormLabel,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';

import Card from '../../common/Card';

import { getType, getOrdersByType } from '../redux/selectors';
import {
  setType as setTypeAction,
  setQuery as setQueryAction,
  setDaysFilter as setDaysFilterAction,
} from '../redux/actions';

/* =============================================================================
<OrdersHeader />
============================================================================= */
const OrdersHeader = ({
  type,
  query,
  allOrders,
  dineInOrders,
  deliveryOrders,
  takeawayOrders,
  setType,
  setQuery,
  setDaysFilter,
}) => {
  const [time, setTime] = useState(moment().format('MMMM Do YYYY, h:mm:ss A'));
  const [tabIndex, setTabIndex] = useState(0);
  const allOrdersTotal = allOrders.length;
  const dineInOrdersTotal = dineInOrders.length;
  const deliveryOrdersTotal = deliveryOrders.length;
  const takeawayOrdersTotal = takeawayOrders.length;

  // Start the clock
  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(moment().format('MMMM Do YYYY, h:mm:ss A'));
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  // Set current active tab
  useEffect(() => {
    switch (type) {
      case 'all':
        setTabIndex(0);
        break;
      case 'delivery':
        setTabIndex(1);
        break;
      case 'take_away':
        setTabIndex(2);
        break;
      default:
        break;
    }
  }, [type]);

  const _handleSearchChange = (event) => {
    setQuery(event.target.value);
  };

  const _handleTabChange = (value) => {
    setTabIndex(value);
    switch (value) {
      case 0:
        setType('all');
        break;
      case 1:
        setType('delivery');
        break;
      case 2:
        setType('take_away');
        break;
      case 3:
        setType('dine_in');
        break;
      default:
        break;
    }
  };

  return (
    <Card
      p={[0, 2, 5]}
      display={['block', 'block', 'flex']}
      alignItems="center"
    >
      {window.showDaysFilter && (
        <VStack alignItems="start" flex={1} maxW="300px" mx={6}>
          <FormLabel htmlFor="daysFilter" mb={0}>Show orders from:</FormLabel>
          <Select id="daysFilter" onChange={(e) => setDaysFilter(Number(e.target.value))}>
            <option value="1">Today</option>
            <option value="7">Last 7 days</option>
            <option value="30">Last 1 month</option>
            <option value="90">Last 3 months</option>
            <option value="180">Last 6 months</option>
          </Select>
        </VStack>
      )}
      <Box>
        {!window.ReactNativeWebView && <Box mb={2} mt={[3, 3, 0]}>
          <InputGroup>
            <InputLeftElement
              pointerEvents='none'
              children={<SearchIcon color='#999' />}
            />
            <Input
              type='text'
              value={query}
              placeholder="Name, Phone Number, Order No etc..."
              onChange={_handleSearchChange}
            />
          </InputGroup>
        </Box>}
        <Tabs mt={[1, 4, 0]} colorScheme="red" index={tabIndex} onChange={_handleTabChange}>
          <TabList justifyContent="space-between" border="none">
            <Tab>
              {`All (${allOrdersTotal})`}
            </Tab>
            <Tab>
              {`Delivery / Gift (${deliveryOrdersTotal})`}
            </Tab>
            <Tab>
              {`Takeaway (${takeawayOrdersTotal})`}
            </Tab>
            {!window.ReactNativeWebView && <Tab>
              {`Dine In (${dineInOrdersTotal})`}
            </Tab>}
          </TabList>
        </Tabs>
      </Box>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  type: getType(state),
  allOrders: getOrdersByType(state, {
    type: 'all',
  }),
  dineInOrders: getOrdersByType(state, {
    type: 'dine_in',
  }),
  deliveryOrders: getOrdersByType(state, {
    type: 'delivery',
  }),
  takeawayOrders: getOrdersByType(state, {
    type: 'take_away',
  }),
});

const mapDispatchToProps = {
  setType: setTypeAction,
  setQuery: setQueryAction,
  setDaysFilter: setDaysFilterAction,
};

const propsAreEqual = (prevProps, nextProps) => prevProps.type === nextProps.type
  && prevProps.query === nextProps.query
  && prevProps.allOrders.length === nextProps.allOrders.length
  && prevProps.dineInOrders.length === nextProps.dineInOrders.length
  && prevProps.deliveryOrders.length === nextProps.deliveryOrders.length
  && prevProps.takeawayOrders.length === nextProps.takeawayOrders.length;

/* Export
============================================================================= */
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(OrdersHeader, propsAreEqual));
