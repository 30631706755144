import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import {
  Text,
  Box,
  Input,
  Modal,
  Alert,
  HStack,
  ModalBody,
  AlertIcon,
  AlertTitle,
  ModalHeader,
  ModalOverlay,
  ModalFooter,
  ModalContent,
  ModalCloseButton,
  AlertDescription,
} from '@chakra-ui/react';

import ReservationPendingAction from './ReservationPendingAction';
import ReservationAcceptedAction from './ReservationAcceptedAction';
import request from '../../../../util/request';

import { getBooking } from '../../../../entities/redux/selectors';

/* =============================================================================
<ReservationModal />
============================================================================= */
const ReservationModal = ({
  id,
  isOpen,
  booking,
  onClose,
}) => {
  const [capacityFull, setCapacityFull] = useState(false);

  const seats = booking?.seats;
  const status = booking?.status;
  const isCustomer = Boolean(booking?.customer);
  const date = moment(booking?.date).format('MMM D, YY');
  const time = moment().startOf('day').minutes(booking?.time).format('HH:mm A');
  const name = isCustomer
    ? `${booking?.customer?.profile?.firstName} ${booking?.customer?.profile?.lastName}` : booking?.meta?.name;

  // Get booking capacity
  useEffect(() => {
    if (isOpen) {
      (async () => {
        const payload = await request({
          url: '/bookings/capacity',
          params: {
            d: moment(booking?.date).format('YYYY-MM-DD'),
            t: booking?.time,
          },
        }).catch(() => null);
        setCapacityFull(payload?.capacity < seats);
      })();
    } else {
      setCapacityFull(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, seats]);

  return (
    <Modal size="2xl" isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="lg">Reservation Details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <HStack spacing={5} mb={7} justify="space-between">
            <Box flex={1}>
              <Text>Customer Name</Text>
              <Input readOnly value={name} focusBorderColor="none" />
            </Box>
            <Box flex={1}>
              <Text>People</Text>
              <Input readOnly value={seats} focusBorderColor="none" />
            </Box>
          </HStack>
          <HStack spacing={5} mb={7} justify="space-between">
            <Box flex={1}>
              <Text>Date</Text>
              <Input readOnly value={date} focusBorderColor="none" />
            </Box>
            <Box flex={1}>
              <Text>Time</Text>
              <Input readOnly value={time} focusBorderColor="none" />
            </Box>
          </HStack>
          <Box flex={1}>
            <Text>Status</Text>
            <Input readOnly value={status} focusBorderColor="none" />
          </Box>
          {capacityFull && (
            <Alert status="warning" mt={4}>
              <AlertIcon />
              <AlertTitle mr={2}>Capacity Full!</AlertTitle>
              <AlertDescription>
                Booking capacity for this date and time is already full.
              </AlertDescription>
            </Alert>
          )}
        </ModalBody>
        <ModalFooter>
          {status === 'pending' && <ReservationPendingAction id={id} />}
          {status === 'accepted' && <ReservationAcceptedAction id={id} />}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const mapStateToProps = (state, { id }) => ({
  booking: getBooking(state, { id }),
});

// eslint-disable-next-line max-len
const propsAreEqual = (prevProps, nextProps) => prevProps.isOpen === nextProps.isOpen
  && prevProps.booking?.id === nextProps.booking?.id
  && prevProps.booking?.status === nextProps.booking?.status
  && prevProps.booking?.time === nextProps.booking?.time
  && prevProps.booking?.date === nextProps.booking?.date;

/* Export
============================================================================= */
export default connect(mapStateToProps)(React.memo(ReservationModal, propsAreEqual));
