import React from 'react';
import { FieldArray, useFormikContext } from 'formik';
import {
  Text,
  HStack,
  VStack,
  Button,
} from '@chakra-ui/react';

import ReservationFormHoursSelectTime from './ReservationFormHoursSelectTime';

/* =============================================================================
<ReservationFormHoursAvailable />
============================================================================= */
const ReservationFormHoursAvailable = () => {
  const { values } = useFormikContext();

  return (
    <HStack alignItems="flex-start" minH="44px" mb={5}>
      <Text flex={1.4} fontSize="sm" fontWeight="semibold">Booking Slots</Text>
      <FieldArray name="hours">
        {(arrayHelper) => (
          <VStack alignItems="flex-start" flex={3}>
            {values.hours
              .map((hour, index) => (
                <React.Fragment key={index}>
                  {!hour.discount && (
                  <ReservationFormHoursSelectTime
                    key={index}
                    hour={hour}
                    index={index}
                    onDeleteHourClick={() => arrayHelper.remove(index)}
                  />
                  )}
                </React.Fragment>
              ))}
            <Button
              color="red.700"
              variant="unstlyed"
              onClick={() => arrayHelper.push({
                active: true,
                start: '',
              })}
            >
              + Add Hour
            </Button>
          </VStack>
        )}
      </FieldArray>
    </HStack>
  );
};

/* Export
============================================================================= */
export default ReservationFormHoursAvailable;
