import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { FastField } from 'formik';
import {
  Input,
  Select,
  HStack,
  FormLabel,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
} from '@chakra-ui/react';

import { getRestaurant } from '../../../../restaurant/redux/selectors';

/* =============================================================================
<OrderSetupAddReservationDateAndTime />
============================================================================= */
const OrderSetupAddReservationDateAndTime = ({ restaurant }) => {
  const hours = restaurant?.booking?.hours?.filter((h) => h.active);
  const duration = restaurant?.booking?.duration;

  return (
    <HStack mb={8} spacing={4} alignItems="flex-start">
      <FastField name="date">
        {({ field, form }) => (
          <FormControl isInvalid={form.errors.date && form.touched.date}>
            <FormLabel>Date of Reservation</FormLabel>
            <Input {...field} type="date" />
            <FormErrorMessage>
              <FormErrorIcon />
              {form.errors.date}
            </FormErrorMessage>
          </FormControl>
        )}
      </FastField>
      <FastField name="time">
        {({ field, form }) => (
          <FormControl isInvalid={form.errors.time && form.touched.time}>
            <FormLabel>Time of Reservation</FormLabel>
            <Select
              placeholder="Select"
              textTransform="capitalize"
              {...field}
            >
              {hours?.map((hour, i) => {
                // eslint-disable-next-line prefer-template
                const label = moment().startOf('day').minutes(hour.start).format('hh:mm A') + ' - ' + moment().startOf('day').minutes(hour.start + duration).format('hh:mm A');
                return (
                  <option key={i} value={hour.start}>{label}</option>
                );
              })}
            </Select>
            <FormErrorMessage>
              <FormErrorIcon />
              {form.errors.time}
            </FormErrorMessage>
          </FormControl>
        )}
      </FastField>
    </HStack>
  );
};

const mapStateToProps = (state) => ({
  restaurant: getRestaurant(state),
});

const propsAreEqual = (prevProps, nextProps) => (
  prevProps.restaurant?.booking?.duration === nextProps.restaurant?.booking?.duration
  && JSON.stringify(prevProps.restaurant?.booking?.hours)
  === JSON.stringify(nextProps.restaurant?.booking?.hours)
);

/* Export
============================================================================= */
export default connect(mapStateToProps)(React.memo(
  OrderSetupAddReservationDateAndTime,
  propsAreEqual,
));
