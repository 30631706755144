import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  HStack,
  InputGroup,
  Select,
  FormLabel,
  FormControl,
  InputLeftAddon,
  FormErrorMessage,
} from '@chakra-ui/react';
import { FastField } from 'formik';

import { getRolesById } from '../../redux/selectors';
import {
  getRoles as getRolesAction,
} from '../../redux/actions';

/* =============================================================================
<EmployeePhoneAndRole />
============================================================================= */
const EmployeePhoneAndRole = ({ roles, getRoles }) => {
  const rolesOptions = useMemo(() => roles
    .map((role) => ({
      label: role.title,
      value: role.id,
    })), [roles]);

  // Get roles
  useEffect(() => {
    getRoles();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <HStack spacing={5} alignItems="flex-start" mt={5}>
      <FastField name="phone">
        {({ field, form }) => (
          <FormControl flex={1} isInvalid={form.errors.phone && form.touched.phone}>
            <FormLabel fontWeight="normal">Phone</FormLabel>
            <InputGroup>
              <InputLeftAddon>+92</InputLeftAddon>
              <Input id="phone" placeholder="Ex: (487) 739-5517" {...field} />
            </InputGroup>
            <FormErrorMessage>{form.errors.phone}</FormErrorMessage>
          </FormControl>
        )}
      </FastField>
      <FastField name="role">
        {({ field, form }) => (
          <FormControl flex={1} isInvalid={form.errors.role && form.touched.role}>
            <FormLabel fontWeight="normal">Role</FormLabel>
            <Select placeholder="Select Role" {...field}>
              {rolesOptions.map(({ label, value }) => (
                <option key={value} value={value}>{label}</option>
              ))}
            </Select>
          </FormControl>
        )}
      </FastField>
    </HStack>
  );
};

const mapStateToProps = (state) => ({
  roles: getRolesById(state),
});
const mapDispatchToProps = {
  getRoles: getRolesAction,
};

/* Export
============================================================================= */
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmployeePhoneAndRole);
