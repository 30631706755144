import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, List, useToast } from '@chakra-ui/react';

import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import MenuItemDetailsHeader from '../MenuItemDetailsHeader';
import MenuFeaturedItemsItem from './MenuFeaturedItemsItem';
import { reorder } from '../../../util/functions';

import { getMenu as selectMenu } from '../../../entities/redux/selectors';
import {
  getMenu as getMenuAction,
  updateMenu as updateMenuAction,
} from '../../redux/actions';

/* =============================================================================
<MenuFeaturedItems />
============================================================================= */
const MenuFeaturedItems = () => {
  const toast = useToast();
  const params = useParams();
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);

  const menuId = params?.menu;

  const menu = useSelector((state) => selectMenu(state, {
    id: menuId,
    normalize: true,
  }));
  const name = 'Featured Items';
  const dishesStr = menu?.featured?.toString();

  // Get menu
  useEffect(() => {
    if (menuId) {
      dispatch(getMenuAction(menuId));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuId]);

  // Set items
  useEffect(() => {
    if (menu?.featured) {
      setItems(menu.featured);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dishesStr]);

  const _handleDragEnd = (result) => {
    // Dropped outside the menuItem list
    if (result?.destination?.droppableId !== 'menuFeatured') {
      return;
    }

    // Dropped at the same index
    if (result.source.index === result.destination.index) {
      return;
    }

    const reordered = reorder(
      items,
      result.source.index,
      result.destination.index,
    );

    const prevOrder = [...items];
    setItems(reordered);

    dispatch(updateMenuAction({ id: menuId, featured: reordered }, (err) => {
      if (!err) {
        toast({
          title: 'Changes Saved',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } else {
        setItems(prevOrder);
        toast({
          title: err.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    }));
  };

  const _handleItemDelete = async (e, value) => {
    const payload = {
      id: menuId,
      featured: items.filter((item) => item !== value),
    };

    await dispatch(updateMenuAction(payload, (err) => {
      if (!err) {
        toast({
          title: 'Changes Saved',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        setItems(payload.featured);
      } else {
        toast({
          title: err.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    }));
  };

  return (
    <Box flex={4} px={2}>
      <MenuItemDetailsHeader menuId={menuId} title={name} />
      <DragDropContext onDragEnd={_handleDragEnd}>
        <Droppable droppableId="menuFeatured">
          {(provided, snapshot) => (
            <List
              ref={provided.innerRef}
              {...provided.droppableProps}
              bg={snapshot.isDraggingOver ? 'blue.50' : 'inherit'}
            >
              {items.map((item, index) => (
                <MenuFeaturedItemsItem
                  id={item}
                  key={item}
                  index={index}
                  menuId={menuId}
                  onDelete={_handleItemDelete}
                />
              ))}
              {provided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  );
};

/* Export
============================================================================= */
export default MenuFeaturedItems;
