import React from 'react';
import { connect } from 'react-redux';
import { useFormikContext } from 'formik';
import {
  Text,
  Image,
  HStack,
  VStack,
  FormLabel,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
} from '@chakra-ui/react';

import FileSelect from '../../FileSelect';

import { getRestaurant } from '../../../restaurant/redux/selectors';

/* =============================================================================
<RestaurantFormPhoto />
============================================================================= */
const RestaurantFormPhoto = ({ restaurant, disabled }) => {
  const {
    values,
    errors,
    touched,
    setFieldValue,
  } = useFormikContext();
  const { photo } = values;
  const photos = restaurant?.type === 'branch' ? restaurant?.chain?.photos : restaurant?.photos;

  const _handlePhotoChange = (value) => {
    setFieldValue('photo', value);
  };

  return (
    <FormControl
      mb={8}
      display="flex"
      justifyContent="space-between"
      isInvalid={errors.photo && touched.photo}
    >
      <FormLabel flex={1.5}>
        <Text fontWeight="semibold" fontSize="sm">Restaurant Logo</Text>
        <Text fontSize="sm">This will be logo of your Restaurant.</Text>
      </FormLabel>
      <VStack flex={2} alignItems="flex-start">
        <HStack alignItems="flex-start">
          <Image src={photos && photos[0]} w="150px" h="150px" borderRadius="8px" mr={4} />
          {!disabled && <FileSelect size="150px" value={photo} onChange={_handlePhotoChange} />}
        </HStack>
        <FormErrorMessage>
          <FormErrorIcon />
          {errors.photo}
        </FormErrorMessage>
      </VStack>
    </FormControl>
  );
};

const mapStateToProps = (state) => ({
  restaurant: getRestaurant(state),
});

// eslint-disable-next-line max-len
const propsAreEqual = (prevProps, nextProps) => prevProps.restaurant?.photos?.toString() === nextProps.restaurant?.photos?.toString();

/* Export
============================================================================= */
export default connect(mapStateToProps)(React.memo(RestaurantFormPhoto, propsAreEqual));
