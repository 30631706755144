import React from 'react';
import {
  Box,
  Text,
  HStack,
  Divider,
} from '@chakra-ui/react';

/* =============================================================================
<DiscountsHeader />
============================================================================= */
const DiscountsHeader = () => (
  <HStack flex={1} justify="space-between">
    <Box>
      <Text fontSize="20px" fontWeight="semibold">Discount</Text>
      <Text fontSize="sm">Make  a Discount for your menu or food</Text>
    </Box>
  </HStack>
);

/* Export
============================================================================= */
export default DiscountsHeader;
