import React from 'react';
import { FastField, getIn } from 'formik';
import {
  Input,
  VStack,
  FormLabel,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
} from '@chakra-ui/react';

/* =============================================================================
<RestaurantFormPhone />
============================================================================= */
const RestaurantFormPhone = () => (
  <>
    <FastField name="phone.branch">
      {({ field, form }) => (
        <FormControl
          mb={8}
          display="flex"
          justifyContent="space-between"
          isInvalid={getIn(form.errors, 'phone.branch') && getIn(form.touched, 'phone.branch')}
        >
          <FormLabel flex={1.5}>Branch Phone Number</FormLabel>
          <VStack alignItems="flex-start" flex={2}>
            <Input {...field} id="phone.branch" />
            <FormErrorMessage>
              <FormErrorIcon />
              {getIn(form.errors, 'phone.branch')}
            </FormErrorMessage>
          </VStack>
        </FormControl>
      )}
    </FastField>
    <FastField name="phone.delivery1">
      {({ field, form }) => (
        <FormControl
          mb={8}
          display="flex"
          justifyContent="space-between"
          isInvalid={getIn(form.errors, 'phone.delivery1') && getIn(form.touched, 'phone.delivery1')}
        >
          <FormLabel flex={1.5}>Restaurant Phone Number 1</FormLabel>
          <VStack alignItems="flex-start" flex={2}>
            <Input {...field} id="phone.delivery1" />
            <FormErrorMessage>
              <FormErrorIcon />
              {getIn(form.errors, 'phone.delivery1')}
            </FormErrorMessage>
          </VStack>
        </FormControl>
      )}
    </FastField>
    <FastField name="phone.delivery2">
      {({ field, form }) => (
        <FormControl
          mb={8}
          display="flex"
          justifyContent="space-between"
          isInvalid={getIn(form.errors, 'phone.delivery2') && getIn(form.touched, 'phone.delivery2')}
        >
          <FormLabel flex={1.5}>Restaurant Phone Number 2</FormLabel>
          <VStack alignItems="flex-start" flex={2}>
            <Input {...field} id="phone.delivery2" />
            <FormErrorMessage>
              <FormErrorIcon />
              {getIn(form.errors, 'phone.delivery2')}
            </FormErrorMessage>
          </VStack>
        </FormControl>
      )}
    </FastField>
  </>
);

/* Export
============================================================================= */
export default RestaurantFormPhone;
