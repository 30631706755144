import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Divider, Text, useDisclosure } from '@chakra-ui/react';

import { useLocation } from 'react-router-dom';
import Card from '../../../common/Card';
import MenusTab from './MenusTab';
// import MenusSearch from './MenusSearch';
import MenusDishes from './MenusDishes';
import MenusItemTab from './MenusItemTab';
import MenusFeatured from './MenusFeatured';
import MenusAddDishModal from './MenusAddDishModal/index';

import { getPosMenus as getPosMenusAction } from '../../../menu/redux/actions';
import { getUser } from '../../../auth/redux/selectors';
import { fromAdminPortal } from '../../../util/functions';

/* =============================================================================
<Menus />
============================================================================= */
const Menus = ({ user, isFullScreen, getPosMenus, onItemSelect }) => {
  const { pathname } = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedDish, setSelectedDish] = useState(null);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);

  // Get menus
  useEffect(() => {
    getPosMenus();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _handleMenuChange = (value) => {
    setSelectedMenu(value);
    setSelectedMenuItem(null);
  };

  const _handleMenuItemChange = (value) => {
    setSelectedMenuItem(value);
  };

  const _handleDishClick = (dish) => {
    onOpen();
    setSelectedDish(dish);
  };

  const _handleAddToCart = (item) => {
    onItemSelect(item);
    setSelectedDish(null);
  };

  const _renderDishes = useCallback(() => {
    switch (selectedMenuItem) {
      case 'featured':
        return (
          <MenusFeatured
            menu={selectedMenu}
            onItemClick={_handleDishClick}
          />
        );

      default:
        return (
          <MenusDishes
            menuItem={selectedMenuItem}
            onItemClick={_handleDishClick}
          />
        );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMenu, selectedMenuItem]);

  return (
    <Card
      flex={3}
      p={0}
      pt={6}
      display="flex"
      flexDirection="column"
      maxHeight={fromAdminPortal(user) ? "100%" : isFullScreen ? '85vh' : '70vh'}
      overflow="hidden"
    >
      {pathname.includes('/pos/order') && (
        <>
          <Text fontSize="lg" fontWeight="medium">Edit Order</Text>
          <Divider my={3} />
        </>
      )}
      {/* <MenusSearch /> */}
      <MenusTab value={selectedMenu} onChange={_handleMenuChange} />
      <MenusItemTab
        menu={selectedMenu}
        value={selectedMenuItem}
        onChange={_handleMenuItemChange}
      />
      {_renderDishes()}
      <MenusAddDishModal
        id={selectedDish}
        isOpen={isOpen}
        onClose={onClose}
        onAddToCart={_handleAddToCart}
      />
    </Card>
  );
};

const mapStateToProps = (state) => ({
  user: getUser(state)
})

const mapDispatchToProps = {
  getPosMenus: getPosMenusAction,
};

/* Export
============================================================================= */
export default connect(mapStateToProps, mapDispatchToProps)(Menus);
