import * as constants from './constants';

export const INITIAL_STATE = {
  params: { from: constants.TODAY_ISO_DATE, to: constants.TODAY_ISO_DATE },
  reports: [],
  error: null,
  loading: false,
};

export default function reducer(state = INITIAL_STATE, action) {
  const { type, payload, error } = action;

  switch (type) {
    // REPORTS_GET
    case constants.REPORTS_GET.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.REPORTS_GET.FAIL:
      return {
        ...state,
        error,
      };
    case constants.REPORTS_GET.SUCCESS:
      return {
        ...state,
        params: payload.params,
        error: null,
        reports: payload.data,
      };
    case constants.REPORTS_GET.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
