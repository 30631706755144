import React from 'react';
import { connect } from 'react-redux';
import {
  Text,
  Flex,
} from '@chakra-ui/react';

import { calcDishTotal } from '../../../../util/functions';

import { getDish } from '../../../../entities/redux/selectors';

/* =============================================================================
<MenusFeaturedItem />
============================================================================= */
const MenusFeaturedItem = ({ dish, onClick }) => {
  const name = dish?.name;
  const photo = dish?.photos?.length && dish.photos[0];
  const { price, discount, total } = calcDishTotal(dish);

  return (
      <Flex
        w="100%"
        mt={2}
        mb={4}
        py={6}
        px={4}
        justify="space-between"
        cursor="pointer"
        overflow="hidden"
        border="1px solid #E4E7EC"
        borderRadius="sm"
        onClick={(event) => onClick(dish?.id, event)}
      >
        {/* <Image width="120px" height="120px" src={photo} alt={name} /> */}
        <Text mr={4} fontWeight="semibold">{name}</Text>
        <Flex>
          <Text
            fontSize="sm"
            fontWeight={!discount && "semibold"}
            textDecorationLine={Boolean(discount) && 'line-through'}
          >
            {`${price.toFixed(2)} Rs`}
          </Text>
          {Boolean(discount) && (
            <Text ml={2} fontSize="sm" fontWeight="semibold">
              {`${total.toFixed(2)} Rs`}
            </Text>
          )}
        </Flex>
      </Flex>
  );
};

const mapStateToProps = (state, { id }) => ({
  dish: getDish(state, { id }),
});

const propsAreEqual = (prevProps, nextProps) => prevProps.dish?.name === nextProps.dish?.name
  && prevProps.dish?.price === nextProps.dish?.price
  && prevProps.dish?.discount?.type === nextProps.dish?.discount?.type
  && prevProps.dish?.discount?.value === nextProps.dish?.discount?.value
  && prevProps.dish?.photos?.toString() === nextProps.dish?.photos?.toString()
  && JSON.stringify(prevProps.dish?.variants) === JSON.stringify(nextProps.dish?.variants);

/* Export
============================================================================= */
export default connect(mapStateToProps)(React.memo(MenusFeaturedItem, propsAreEqual));
