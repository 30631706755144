import React from 'react';
import { Th } from '@chakra-ui/react';

/* =============================================================================
<TableManagementTableHeadItem />
============================================================================= */
const TableManagementTableHeadItem = ({ title }) => (
  <Th
    bg="gray.50"
    color="#667085"
    fontSize="13px"
    borderTop="1px solid #E4E7EC"
  >
    {title}
  </Th>
);

/* Export
============================================================================= */
export default TableManagementTableHeadItem;
