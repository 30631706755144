import moment from 'moment';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useFormikContext } from 'formik';

import { getMenu } from '../../../../../entities/redux/selectors';

/* =============================================================================
<EditMenuModalInitialize />
============================================================================= */
const EditMenuModalInitialize = ({ menu }) => {
  const { resetForm } = useFormikContext();

  const id = menu?.id;
  const name = menu?.name;
  const about = menu?.about;
  const hours = menu?.hours;
  const hoursStr = JSON.stringify(hours || {});

  // Set default values
  useEffect(() => {
    resetForm({
      values: {
        id,
        name,
        about,
        hours: hours.map((hour) => ({
          active: hour.active,
          hours: hour.hours.map((h) => ({
            start: moment().startOf('day').minutes(h.start).format('HH:mm'),
            end: moment().startOf('day').minutes(h.end).format('HH:mm'),
          })),
        })),
      },
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    id,
    name,
    about,
    hoursStr,
  ]);

  return null;
};

const mapStateToProps = (state, { id }) => ({
  menu: getMenu(state, { id, normalize: true }),
});

/* Export
============================================================================= */
export default connect(mapStateToProps)(EditMenuModalInitialize);
