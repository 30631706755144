import { normalize } from 'normalizr';

import request from '../../util/request';
import * as constants from './constants';
import { addEntities } from '../../entities/redux/actions';
import { reports as reportsSchema } from '../../entities/api/schema';

/**
 * REPORTS_GET
 */
export const getReports = (params, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.REPORTS_GET.REQUEST });

    const payload = await request({
      url: '/reports',
      method: 'GET',
      params,
    });
    const { entities, result } = normalize(payload.data, reportsSchema);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.REPORTS_GET.SUCCESS,
      payload: {
        data: result,
        params,
      },
    });
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.REPORTS_GET.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.REPORTS_GET.COMPLETE });
  }
};

export const refreshReports = {};
