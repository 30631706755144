import { getRestaurant as getRestaurantDetails } from '../../entities/redux/selectors';

/**
 * Get id
 */
export const getId = (state) => state.Restaurant.id;

/**
 * Get error
 */
export const getError = (state) => state.Restaurant.error;

/**
 * Get loading
 */
export const getLoading = (state) => state.Restaurant.loading;

/**
 * Get branches
 */
export const getBranches = (state) => state.Restaurant.branches;

/**
 * Get restaurant
 */
export const getRestaurant = (state) => getRestaurantDetails(state, { id: getId(state) });

/**
 * Get branches with details
 */
export const getBranchesWithDetails = (state) => getBranches(state)
  ?.map((id) => getRestaurantDetails(state, { id }));
