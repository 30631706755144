import React from 'react';
import { connect } from 'react-redux';
import { Box, HStack, Text } from '@chakra-ui/react';

import { getOrder } from '../../entities/redux/selectors';

/* =============================================================================
<OrderReceiptPaymentDetails />
============================================================================= */
const OrderReceiptPaymentDetails = ({ order }) => {
  const paymentMethod = order?.payment?.method;
  const total = order?.payment?.amount || 0;
  const discount = Math.round(order?.payment?.discount || 0);
  const deliveryFee = order?.payment?.deliveryFee || 0;
  const vatExclusive = order?.restaurant?.vatExclusive || order?.restaurant?.chain?.vatExclusive;
  const vatPercentage = order?.restaurant?.vat || order?.restaurant?.chain?.vat || 0;
  const couponDiscount = Math.round(order?.payment?.couponDiscount || 0);
  const posDiscount = Math.round(order?.payment?.posDiscount || 0);
  const posDiscountPerc = order?.payment?.posDiscountPerc || 0;

  const subtotalWithoutVat = Math.round(total + discount + posDiscount + couponDiscount - deliveryFee);
  const vat = vatExclusive
    ? Math.round(order?.payment?.vat || 0)
    : Math.round(subtotalWithoutVat - subtotalWithoutVat / (1 + vatPercentage / 100));
  const subtotal = subtotalWithoutVat - vat;
  const paidOnline = paymentMethod === 'card' ? total : (order?.payment?.wallet || 0);
  const totalAfterWallet = total - paidOnline;

  return (
    <Box my={4}>
      <HStack justify="space-between" mt={2}>
        <Text fontSize="lg">Sub-Total :</Text>
        <Text>{`Rs.${subtotal}`}</Text>
      </HStack>
      {Boolean(discount) && (
        <HStack justify="space-between" mt={2}>
          <Text fontSize="lg">Discount :</Text>
          <Text fontSize="lg">{`Rs.${discount}`}</Text>
        </HStack>
      )}
      {Boolean(posDiscount) && (
        <HStack justify="space-between" mt={2}>
          <Text fontSize="lg">Pos Discount ({posDiscountPerc}%):</Text>
          <Text fontSize="lg">{`Rs.${posDiscount}`}</Text>
        </HStack>
      )}
      <HStack justify="space-between" mt={2}>
        <Text fontSize="lg">
          {`GST (${vatPercentage})% :`}
        </Text>
        <Text fontSize="lg">{`Rs.${vat}`}</Text>
      </HStack>
      {Boolean(couponDiscount) && (
        <HStack justify="space-between" mt={2}>
          <Text fontSize="lg">Promo Discount :</Text>
          <Text fontSize="lg">{`Rs.${couponDiscount}`}</Text>
        </HStack>
      )}
      {Boolean(deliveryFee) && (
        <HStack justify="space-between" mt={2}>
          <Text fontSize="lg">Delivery Fee :</Text>
          <Text fontSize="lg">{`Rs.${deliveryFee}`}</Text>
        </HStack>
      )}
      {!order?.pos && Boolean(paidOnline) && (
        <HStack justify="space-between" mt={2}>
          <Text fontSize="lg">Paid Online :</Text>
          <Text fontSize="lg">{`Rs.${paidOnline}`}</Text>
        </HStack>
      )}
      <HStack justify="space-between" mt={2}>
        <Text fontSize="xl" fontWeight="bold">Total :</Text>
        <Text fontSize="xl" fontWeight="bold">{`Rs.${order?.pos ? total : totalAfterWallet}`}</Text>
      </HStack>
    </Box>
  );
};

const mapStateToProps = (state, { id }) => ({
  order: getOrder(state, { id }),
});

const propsAreEqual = (prevProps, nextProps) => (
  prevProps.order?.payment?.amount === nextProps.order?.payment?.amount
  && prevProps.order?.payment?.wallet === nextProps.order?.payment?.wallet
  && prevProps.order?.restaurant?.vat === nextProps.order?.restaurant?.vat
  && prevProps.order?.payment?.discount === nextProps.order?.payment?.discount
  && prevProps.order?.payment?.deliveryFee === nextProps.order?.payment?.deliveryFee
  && prevProps.order?.payment?.couponDiscount === nextProps.order?.payment?.couponDiscount
  && prevProps.order?.restaurant?.vatExclusive === nextProps.order?.restaurant?.vatExclusive
);
/* Export
============================================================================= */
export default connect(mapStateToProps)(
  React.memo(OrderReceiptPaymentDetails, propsAreEqual),
);
