import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Draggable } from 'react-beautiful-dnd';
import { MoreVertical, Edit2 } from 'react-feather';
import {
  Box,
  Text,
  Image,
  Select,
  HStack,
  ListItem,
  useDisclosure,
} from '@chakra-ui/react';

import EditDishModal from '../DishModals/EditDishModal';

import { getDish } from '../../../entities/redux/selectors';
import { getRestaurant } from '../../../restaurant/redux/selectors';
import { updateDishAvailability as updateDishAvailabilityAction } from '../../redux/actions';

/* =============================================================================
<MenuItemDetailsItem />
============================================================================= */
const MenuItemDetailsItem = ({
  id,
  index,
  dish,
  restaurant,
  updateDishAvailability,
}) => {
  const [available, setAvailable] = useState(true);
  const {
    isOpen,
    onOpen,
    onClose,
  } = useDisclosure();

  const name = dish?.name || '';
  const about = dish?.about || '';
  const photo = dish?.photos && dish.photos[0];
  const price = dish?.variants?.length ? dish.variants[0]?.price : dish?.price;
  const restaurantId = restaurant?.id;
  const restaurantType = restaurant?.type;
  const dishAvailability = dish?.available;
  const dishAvailabilityByBranch = dish?.branchAvailability
    && dish?.branchAvailability[restaurantId];

  // Set dish availability
  useEffect(() => {
    if (restaurantType === 'branch') {
      setAvailable(Boolean(dishAvailabilityByBranch));
    } else {
      setAvailable(Boolean(dishAvailability));
    }
  }, [
    restaurantType,
    dishAvailability,
    dishAvailabilityByBranch,
  ]);

  const _handleAvailableChange = (event) => {
    const value = event.target.value === 'true';
    setAvailable(value);
    updateDishAvailability({
      id,
      available: value,
    });
  };

  const _handleEditClick = (e) => {
    e.stopPropagation();
    onOpen();
  };

  return (
    <Draggable draggableId={id} index={index}>
      {(provided) => (
        <ListItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          style={provided.draggableProps.style}
          mb={4}
          bg="white"
          display="flex"
          height={height}
          overflow="hidden"
          borderRadius="lg"
          alignItems="center"
          border="1px solid #E4E7EC"
          justifyContent="space-between"
        >
          <HStack flex={1} px={6}>
            <Box {...provided.dragHandleProps}>
              <MoreVertical size={20} />
            </Box>
            <Box flex={1} pl={5}>
              <Text fontWeight="semibold">{name}</Text>
              <Text my="4px" fontSize="sm">{about}</Text>
              <Text fontWeight="semibold">
                {price}
                {' '}
                Rs
              </Text>
            </Box>
            <Select
              size="xs"
              width="100px"
              value={available}
              color={available ? 'green' : 'red'}
              variant="unstyled"
              onChange={_handleAvailableChange}
            >
              <option value="true">Available</option>
              <option value="false">Unavailable</option>
            </Select>
            <Edit2 size={14} onClick={_handleEditClick} />
          </HStack>
          <Image w="auto" h="100%" src={photo} />
          <EditDishModal
            id={id}
            isOpen={isOpen}
            onClose={onClose}
          />
        </ListItem>
      )}
    </Draggable>
  );
};

const height = '146px';

const mapStateToProps = (state, { id }) => ({
  dish: getDish(state, { id, normalize: true }),
  restaurant: getRestaurant(state),
});

const mapDispatchToProps = {
  updateDishAvailability: updateDishAvailabilityAction,
};

const propsAreEqual = (prevProps, nextProps) => prevProps.id === nextProps.id
  && prevProps.index === nextProps.index
  && prevProps.menuId === nextProps.menuId
  && prevProps.dish?.name === nextProps.dish?.name
  && prevProps.dish?.about === nextProps.dish?.about
  && prevProps.dish?.price === nextProps.dish?.price
  && prevProps.dish?.available === nextProps.dish?.available
  && prevProps.restaurant?.id === nextProps.restaurant?.id
  && prevProps.restaurant?.type === nextProps.restaurant?.type
  && prevProps.dish?.photos?.toString() === nextProps.dish?.photos?.toString()
  // eslint-disable-next-line max-len
  && JSON.stringify(prevProps.dish?.branchAvailability) === JSON.stringify(nextProps.dish?.branchAvailability)
  // eslint-disable-next-line max-len
  && (prevProps.dish?.variants && prevProps.dish?.variants[0]?.price) === (nextProps.dish?.variants && nextProps.dish?.variants[0]?.price);

/* Export
============================================================================= */
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(MenuItemDetailsItem, propsAreEqual));
