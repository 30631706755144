import { createSelector } from 'reselect';

/**
 * Get error
 */
export const getError = (state) => state.OrderHistory.error;

/**
  * Get loading
  */
export const getLoading = (state) => state.OrderHistory.loading;

/**
  * Get order type
  */
export const getType = (state) => state.OrderHistory.type;

/**
  * Get order status
  */
export const getStatus = (state) => state.OrderHistory.status;

/**
 * Get by type and status
 */
export const getByTypeAndStatus = (state) => state.OrderHistory.byTypeAndStatus;

/**
  * Get orders by type and status
  */
export const getOrdersByTypeAndStatus = createSelector(
  [getByTypeAndStatus, getType, getStatus],
  (byTypeAndStatus, type, status) => (
    (byTypeAndStatus[`${type}/${status}`] && byTypeAndStatus[`${type}/${status}`].data) || []
  ),
);

/**
  * Get next cursor by type and status
  */
export const getNextCursorByTypeAndStatus = createSelector(
  [getByTypeAndStatus, getType, getStatus],
  (byTypeAndStatus, type, status) => (
    (byTypeAndStatus[`${type}/${status}`] && byTypeAndStatus[`${type}/${status}`].nextCursor) || ''
  ),
);
