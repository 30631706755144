import React from 'react';

import {
  Star,
  Calendar,
  ArrowDown,
  PlusSquare,
} from 'react-feather';

import { ReactComponent as ReservationIcon } from '../assets/icons/pos-reservation-icon.svg';

export default [
  {
    to: '/pos/new_order',
    title: 'New Order',
    UnActiveIcon: <PlusSquare color="#475467" size="20px" />,
    ActiveIcon: <PlusSquare color="#A32821" size="20px" />,
  },
  {
    to: '/pos/ongoing_order',
    title: 'Ongoing Order',
    UnActiveIcon: <Star color="#475467" size="20px" />,
    ActiveIcon: <Star color="#A32821" size="20px" />,
  },
  {
    to: '/pos/live_order',
    title: 'Live Order',
    UnActiveIcon: <ArrowDown color="#475467" size="20px" />,
    ActiveIcon: <ArrowDown color="#A32821" size="20px" />,
  },
  {
    to: '/pos/reservation',
    title: 'Reservation',
    UnActiveIcon: <ReservationIcon fill="#475467" />,
    ActiveIcon: <ReservationIcon fill="#A32821" />,
  },
  {
    to: '/pos/today_order',
    title: 'Today Order',
    UnActiveIcon: <Calendar color="#667085" size="20px" />,
    ActiveIcon: <Calendar color="#A32821" size="20px" />,
  },
];
