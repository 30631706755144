import React, { useState } from 'react';
import {
  Modal,
  Button,
  HStack,
  Divider,
  Textarea,
  ModalBody,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
  RadioGroup,
  Radio,
  VStack,
  Text,
} from '@chakra-ui/react';

/* =============================================================================
<OrderCancelModal />
============================================================================= */
const OrderCancelModal = ({ isOpen, onClose, onSubmit }) => {
  const [loading, setLoading] = useState(false);
  const [preFilledReason, setPreFilledReason] = useState('custom');
  const [declineReason, setDeclineReason] = useState('');

  const _handleConfirmClick = async () => {
    if (preFilledReason === 'custom' && !declineReason) {
      return;
    }

    setLoading(true);
    await onSubmit(
      preFilledReason === 'custom' ? declineReason : preFilledReason,
    );
    setLoading(false);
  };

  return (
    <Modal size="xl" isOpen={isOpen} scrollBehavior="inside" onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent mx={2}>
        <ModalHeader fontSize="lg">Cancel Order</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <RadioGroup
            defaultValue={preFilledReason}
            onChange={(value) => setPreFilledReason(value)}
          >
            <VStack mb={4} alignItems="flex-start">
              <Radio value="We're really sorry :( No rider was available">
                No riders available
              </Radio>
              <Radio value="We canceled your duplicate order">
                Duplicate order
              </Radio>
              <Radio value="We're really sorry :( One of the items you ordered is out of stock">
                One of the dishes is out of stock
              </Radio>
              <Radio value="We're really sorry :( We're no longer accepting orders">
                No longer accepting orders
              </Radio>
              <Radio value="We tried to reach you but you did not pick up the call">
                Customer not picking call
              </Radio>
              <Radio value="We prepared your order but you did not take it">
                Customer did not take order
              </Radio>
              <Radio value="custom">Custom</Radio>
            </VStack>
          </RadioGroup>
          {preFilledReason === 'custom' && (
            <>
              <Textarea
                autoFocus
                value={declineReason}
                placeholder="Please describe the reason for cancellation..."
                onChange={(e) => setDeclineReason(e.target.value)}
              />
              <Text fontSize="sm">Minimum length: 20</Text>
            </>
          )}

          <Divider my={5} />
          <HStack alignItems={['flex-end', 'flex-end', 'flex-start']}>
            <Button
              mr={4}
              flex={1}
              color="gray"
              variant="outline"
              colorScheme="gray"
              onClick={onClose}
            >
              Close
            </Button>
            <Button
              disabled={
                preFilledReason === 'custom'
                && (!declineReason || declineReason.length < 20)
              }
              flex={1}
              isLoading={loading}
              onClick={_handleConfirmClick}
            >
              Confirm
            </Button>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

/* Export
============================================================================= */
export default OrderCancelModal;
