import React, { useRef, useEffect, useState } from 'react';

import useDeepCompareEffectForMaps from '../hooks/use-deep-compare-effect-for-maps';

/* =============================================================================
<MapView />
============================================================================= */
const MapView = ({
  bounds,
  polygons,
  onClick,
  onIdle,
  children,
  ...options
}) => {
  const ref = useRef(null);
  const infoWinRef = useRef(null);
  const [map, setMap] = useState();

  // Set map
  useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}));
    }
  }, [ref, map]);

  // because React does not do deep comparisons, a custom hook is used
  // see discussion in https://github.com/googlemaps/js-samples/issues/946
  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [map, options]);

  // Attach listeners
  useEffect(() => {
    if (map) {
      ['click', 'idle'].forEach(eventName =>
        window.google.maps.event.clearListeners(map, eventName)
      );

      map.addListener('click', (e) => {
        onClick?.(e);
        if (infoWinRef.current) {
          infoWinRef.current.close();
        }
      });

      if (onIdle) {
        map.addListener('idle', () => onIdle(map));
      }
    }
  }, [map, infoWinRef, onClick, onIdle]);

  // Set bounds
  useDeepCompareEffectForMaps(() => {
    if (map && bounds) {
      map.fitBounds(getMarkerBounds(bounds));
    }
  }, [map, bounds]);

  // Create polygons
  useEffect(() => {
    if (map && polygons?.length) {
      const _polygons = [];
      polygons.forEach(polygon => {
        const _polygon = new window.google.maps.Polygon({
          paths: polygon.path,
          strokeColor: '#9B2C2C',
          strokeOpacity: 0.8,
          strokeWeight: 1,
          fillColor: '#9B2C2C',
          fillOpacity: 0.35,
        });
        _polygon.setMap(map);

        if (polygon.title) {
          _polygon.addListener(
            'click',
            (p => event => {
              if (infoWinRef.current) {
                infoWinRef.current.close();
              }
              infoWinRef.current = new window.google.maps.InfoWindow();
              infoWinRef.current.setContent(p.title);
              infoWinRef.current.setPosition(event.latLng);
              infoWinRef.current.open(map);
            })(polygon)
          );
        }
        _polygons.push(_polygon);
      });
      map.fitBounds(getArrayBounds(_polygons));
    }
  }, [map, polygons]);

  return (
    <>
      <div ref={ref} style={style} />
      {React.Children.map(children, child => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          return React.cloneElement(child, { map });
        }
        return null;
      })}
    </>
  );
};

const style = {
  width: '100%',
  height: '100%',
};

const getMarkerBounds = bounds => {
  const markersBounds = new window.google.maps.LatLngBounds();

  bounds.forEach(bound => {
    if (bound?.length > 0) {
      markersBounds.extend(new window.google.maps.LatLng(bound[1], bound[0]));
    }
  });

  return markersBounds;
};

const getArrayBounds = polyArray => {
  const bounds = new window.google.maps.LatLngBounds();
  let path;
  let paths;
  for (let polys = 0; polys < polyArray.length; polys += 1) {
    paths = polyArray[polys].getPaths();
    for (let i = 0; i < paths.getLength(); i += 1) {
      path = paths.getAt(i);
      for (let ii = 0; ii < path.getLength(); ii += 1) {
        bounds.extend(path.getAt(ii));
      }
    }
  }
  return bounds;
};

/* Export
============================================================================= */
export default MapView;
