import React from 'react';
import { connect } from 'react-redux';
import {
  Td,
  Button,
  HStack,
  useDisclosure,
} from '@chakra-ui/react';
import {
  Edit, Trash, Check,
} from 'react-feather';

import ReservationEditModal from '../../ReservationModals/ReservationEditModal';

import { getBooking } from '../../../../../entities/redux/selectors';
import {
  cancelBooking as cancelBookingAction,
  completeBooking as completedBookingAction,
} from '../../../../redux/actions';

/* =============================================================================
<ReservationTableItemActions />
============================================================================= */
const ReservationTableItemActions = ({
  id,
  booking,
  cancelBooking,
  completedBooking,
}) => {
  const {
    isOpen: editModalIsOpen,
    onClose: editModalOnClose,
    onOpen: editModalOnOpen,
  } = useDisclosure();

  const _handleCancelBooking = () => {
    cancelBooking(booking?.uid);
  };

  const _handleCompletedBooking = () => {
    completedBooking(booking?.uid);
  };

  return (
    <Td>
      <HStack>
        <Button
          variant="outline"
          colorScheme="gray"
          onClick={editModalOnOpen}
        >
          Edit
        </Button>
        <Button
          colorScheme="red"
          onClick={_handleCancelBooking}
        >
          Cancel
        </Button>
        <Button
          colorScheme="green"
          onClick={_handleCompletedBooking}
        >
          Complete
        </Button>
      </HStack>
      <ReservationEditModal id={id} isOpen={editModalIsOpen} onClose={editModalOnClose} />
    </Td>
  );
};

const mapStateToProps = (state, { id }) => ({
  booking: getBooking(state, { id }),
});

const mapDispatchToProps = {
  cancelBooking: cancelBookingAction,
  completedBooking: completedBookingAction,
};

// eslint-disable-next-line max-len
const propsAreEqual = (prevProps, nextProps) => prevProps.booking?.id === nextProps.booking?.id
  && prevProps.booking?.status === nextProps.booking?.status;

/* Export
============================================================================= */
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(ReservationTableItemActions, propsAreEqual));
