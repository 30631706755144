import React, { useState } from 'react';
import { HStack } from '@chakra-ui/react';

import OrderSetup from '../components/OrderSetup/index';
import Menus from '../components/Menus/index';

/* =============================================================================
<NewOrderScreen />
============================================================================= */
const NewOrderScreen = ({ isFullScreen }) => {
  const [items, setItems] = useState([]);

  const _handleItemSelect = (item) => {
    setItems((prevState) => [...prevState, item]);
  };

  return (
    <HStack flex={1} align="start" spacing={4} mt={isFullScreen ? 6 : 0}>
      <Menus isFullScreen={isFullScreen} onItemSelect={_handleItemSelect} />
      <OrderSetup isFullScreen={isFullScreen} items={items} setItems={setItems} />
    </HStack>
  );
};

/* Export
============================================================================= */
export default NewOrderScreen;
