import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Card from '../../common/Card';
import InventoryHeader from '../components/InventoryHeader';
import InventoryTable from '../components/InventoryTable/index';
import InventoryFooter from '../components/InventoryFooter';
import usePagination from '../../hooks/use-pagination';

import { getNextCursor, getInventories as selectInventories } from '../redux/selectors';
import {
  getInventories as getInventoriesAction,
  refreshInventories as refreshInventoriesAction,
} from '../redux/actions';

/* =============================================================================
<InventoryScreen />
============================================================================= */
const InventoryScreen = ({
  nextCursor,
  inventories,
  getInventories,
  refreshInventories,
}) => {
  const {
    next,
    prev,
    page,
    maxPage,
    items,
    canNext,
    canPrev,
  } = usePagination(inventories);

  // Get delivery fees
  useEffect(() => {
    refreshInventories();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _handleNext = () => {
    if (canNext) {
      next();
      return;
    }

    if (nextCursor) {
      getInventories(nextCursor, (err) => {
        if (!err) {
          next(inventories);
        }
      });
    }
  };

  const _handlePrev = () => {
    if (canPrev) {
      prev();
    }
  };

  return (
    <Card m={5} flex={1} direction="column">
      <InventoryHeader />
      <InventoryTable data={items} />
      <InventoryFooter
        currentPage={page}
        maxPage={maxPage}
        canNext={canNext}
        canPrev={canPrev}
        onNext={_handleNext}
        onPrev={_handlePrev}
      />
    </Card>
  );
};

const mapStateToProps = (state) => ({
  nextCursor: getNextCursor(state),
  inventories: selectInventories(state),
});

const mapDispatchToProps = {
  getInventories: getInventoriesAction,
  refreshInventories: refreshInventoriesAction,
};

// eslint-disable-next-line max-len
const propsAreEqual = (prevProps, nextProps) => prevProps.inventories.toString() === nextProps.inventories.toString();

/* Export
============================================================================= */
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(InventoryScreen, propsAreEqual));
