import React from 'react';
import {
  Button, HStack, Text, useDisclosure, useMediaQuery,
} from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { BarChart2, Bell, PlusCircle } from 'react-feather';
import { useLocation } from 'react-router';

import { ReactComponent as CalculatorIcon } from '../../assets/icons/edit-calculator-icon.svg';
import OrderSetupAddReservationModal from '../../pos/components/OrderSetup/OrderSetupAddReservationModal';

/* =============================================================================
<HeaderLinkButton />
============================================================================= */
const HeaderLinkButton = () => {
  const location = useLocation();
  const [isSmallerThan766] = useMediaQuery('(max-width: 766px)');
  const { isOpen, onClose, onOpen } = useDisclosure();
  const paths = location.pathname.split('/').slice(1)[0];

  return (
    <>
      <OrderSetupAddReservationModal isOpen={isOpen} onClose={onClose} />
      <HStack>
        {paths === 'pos' && (
        <Button
          to="/dashboard"
          as={NavLink}
          variant="light"
        >
          <BarChart2 />
          <Text ml={2} display={['none', 'none', 'block']}>Back to Dashboard </Text>
        </Button>
        )}
        {paths !== 'pos' && !window.ReactNativeWebView && (
        <>
          <Button
            variant="outline"
            fontWeight="medium"
            leftIcon={<PlusCircle size={16} />}
            colorScheme="gray"
            onClick={onOpen}
          >
            {isSmallerThan766 ? 'Reserve' : 'Add Reservation'}
          </Button>
          <Button
            to="/pos"
            as={NavLink}
            variant="light"
          >
            <CalculatorIcon />
            <Text ml={2} display={['none', 'none', 'block']}>Go to POS </Text>
          </Button>
        </>
        )}

      </HStack>
    </>
  );
};

/* Export
============================================================================= */
export default HeaderLinkButton;
