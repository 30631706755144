import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Card from '../../common/Card';
import OrderHistoryHeader from '../components/OrderHistoryHeader/index';
import OrderHistoryTable from '../components/OrderHistoryTable/index';
import OrderHistoryFooter from '../components/OrderHistoryFooter';
import usePagination from '../../hooks/use-pagination';

import {
  getType,
  getStatus,
  getOrdersByTypeAndStatus,
  getNextCursorByTypeAndStatus,
} from '../redux/selectors';
import {
  getOrders as getOrdersAction,
  refreshOrders as refreshOrdersAction,
} from '../redux/actions';

/* =============================================================================
<OrderHistoryScreen />
============================================================================= */
const OrderHistoryScreen = ({
  type,
  status,
  orders,
  nextCursor,
  getOrders,
  refreshOrders,
}) => {
  const {
    next,
    prev,
    page,
    maxPage,
    items,
    canNext,
    canPrev,
  } = usePagination(orders);

  // Get orders
  useEffect(() => {
    refreshOrders(type, status);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, status]);

  const _handleNext = () => {
    if (canNext) {
      next();
      return;
    }

    if (nextCursor) {
      getOrders(type, status, nextCursor, (err) => {
        if (!err) {
          next(orders);
        }
      });
    }
  };

  const _handlePrev = () => {
    if (canPrev) {
      prev();
    }
  };

  return (
    <Card m={5} flex={1} direction="column">
      <OrderHistoryHeader />
      <OrderHistoryTable data={items} />
      <OrderHistoryFooter
        currentPage={page}
        maxPage={maxPage}
        canNext={canNext || nextCursor}
        canPrev={canPrev}
        onNext={_handleNext}
        onPrev={_handlePrev}
      />
    </Card>
  );
};

const mapStateToProps = (state) => ({
  type: getType(state),
  status: getStatus(state),
  orders: getOrdersByTypeAndStatus(state),
  nextCursor: getNextCursorByTypeAndStatus(state),
});

const mapDispatchToProps = {
  getOrders: getOrdersAction,
  refreshOrders: refreshOrdersAction,
};

const propsAreEqual = (prevProps, nextProps) => prevProps.type === nextProps.type
  && prevProps.status === nextProps.status
  && prevProps.nextCursor === nextProps.nextCursor
  && prevProps.orders?.toString() === nextProps.orders?.toString();

/* Export
============================================================================= */
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(OrderHistoryScreen, propsAreEqual));
