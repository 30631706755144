import React from 'react';
import { connect } from 'react-redux';
import {
  Box,
  Text,
  Image,
  HStack,
  Stack,
  VStack,
} from '@chakra-ui/react';

import { getRestaurant } from '../../redux/selectors';

/* =============================================================================
<RestaurantInfoRatings />
============================================================================= */
const RestaurantInfoRatings = ({ restaurant }) => {
  const isBranch = restaurant?.type === 'branch';
  const photos = isBranch ? restaurant?.chain?.photos : restaurant?.photos;
  const ratings = isBranch ? restaurant?.chain?.ratings : restaurant?.ratings;
  const totalRatings = ratings ? Object.values(ratings).reduce((a, b) => a + b, 0) : 0;

  return (
    <HStack flex={1} p={4} borderRadius="8px" border="1px solid #D0D5DD">
      <Box w="160px" h="160px" borderRadius="50%" overflow="hidden">
        <Image h="100%" src={photos && photos[0]} />
      </Box>
      <VStack flex={1}>
        {RATING_STARS.map((star) => (
          <HStack w="100%" key={star}>
            <Text flex={0.8}>{`${star} stars`}</Text>
            <Stack
              h="8px"
              flex={2}
              bg="red.100"
              borderRadius="20px"
              overflow="hidden"
            >
              <Box
                w={`${(((ratings && ratings[star]) || 0) / (totalRatings || 1)) * 100}%`}
                h="100%"
                bg="red.700"
                borderRadius="20px"
              />
            </Stack>
            <Text align="right" flex={0.4}>{(ratings && ratings[star]) || 0}</Text>
          </HStack>
        ))}
      </VStack>
    </HStack>
  );
};

const RATING_STARS = [1, 2, 3, 4, 5];

const mapStateToProps = (state) => ({
  restaurant: getRestaurant(state),
});

/* Export
============================================================================= */
export default connect(mapStateToProps)(RestaurantInfoRatings);
