import { denormalize } from 'normalizr';

import * as schema from '../api/schema';

/**
 * Get user by id
 */
export const getUser = (state, { id, normalize }) => (normalize
  ? state.Entities.users && state.Entities.users[id]
  : denormalize(id, schema.user, state.Entities));

/**
 * Get deal by id
 */
export const getDeal = (state, { id, normalize }) => (normalize
  ? state.Entities.deals && state.Entities.deals[id]
  : denormalize(id, schema.deal, state.Entities));

/**
 * Get menu by id
 */
export const getMenu = (state, { id, normalize }) => (normalize
  ? state.Entities.menus && state.Entities.menus[id]
  : denormalize(id, schema.menu, state.Entities));

/**
 * Get menu item by id
 */
export const getMenuItem = (state, { id, normalize }) => (normalize
  ? state.Entities.menuItems && state.Entities.menuItems[id]
  : denormalize(id, schema.menuItem, state.Entities));

export const getMenuItems = (state) => state.Entities.menuItems;

/**
 * Get dish by id
 */
export const getDish = (state, { id, normalize }) => (normalize
  ? state.Entities.dishes && state.Entities.dishes[id]
  : denormalize(id, schema.dish, state.Entities));

/**
 * Get choice group by id
 */
// eslint-disable-next-line max-len
export const getChoiceGroup = (state, { id, normalize }) => (normalize
  ? state.Entities.choiceGroups && state.Entities.choiceGroups[id]
  : denormalize(id, schema.choiceGroup, state.Entities));

/**
 * Get choice by id
 */
export const getChoice = (state, { id, normalize }) => (normalize
  ? state.Entities.choices && state.Entities.choices[id]
  : denormalize(id, schema.choice, state.Entities));

/**
 * Get order
 */
export const getOrder = (state, { id, normalize }) => (normalize
  ? state.Entities.orders && state.Entities.orders[id]
  : denormalize(id, schema.order, state.Entities));

/**
 * Get category by id
 */
export const getCategory = (state, { id, normalize }) => (normalize
  ? state.Entities.categories && state.Entities.categories[id]
  : denormalize(id, schema.category, state.Entities));

/**
 * Get restaurant by id
 */
export const getRestaurant = (state, { id, normalize }) => (normalize
  ? state.Entities.restaurants && state.Entities.restaurants[id]
  : denormalize(id, schema.restaurant, state.Entities));

/**
 * Get inventoryItem by id
 */
export const getInventory = (state, { id, normalize }) => (normalize
  ? state.Entities.inventories && state.Entities.inventories[id]
  : denormalize(id, schema.inventory, state.Entities));

/**
 * Get employee by id
 */
export const getEmployee = (state, { id, normalize }) => (normalize
  ? state.Entities.user && state.Entities.user[id]
  : denormalize(id, schema.user, state.Entities));

/**
 * Get booking by id
 */
export const getBooking = (state, { id, normalize }) => (normalize
  ? state.Entities.booking && state.Entities.booking[id]
  : denormalize(id, schema.booking, state.Entities));

/**
 * Get area by id
 */
export const getArea = (state, { id, normalize }) => (normalize
  ? state.Entities.area && state.Entities.area[id]
  : denormalize(id, schema.area, state.Entities));

/**
 * Get table by id
 */
export const getTable = (state, { id, normalize }) => (normalize
  ? state.Entities.tables && state.Entities.tables[id]
  : denormalize(id, schema.table, state.Entities));

/**
 * Get discount by id
 */
export const getDiscount = (state, { id, normalize }) => (normalize
  ? state.Entities.discounts && state.Entities.discounts[id]
  : denormalize(id, schema.discount, state.Entities));

/**
 * Get neighborhood by id
 */
export const getNeighborhood = (state, { id, normalize }) => (normalize
  ? state.Entities.neighborhoods && state.Entities.neighborhoods[id]
  : denormalize(id, schema.neighborhood, state.Entities));

/*
 * Get report by id (not present in db but randomized)
 */
export const getReport = (state, { id, normalize }) => (normalize
  ? state.Entities.reports && state.Entities.reports[id]
  : denormalize(id, schema.reports, state.Entities));
