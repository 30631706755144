import { normalize } from 'normalizr';

import request from '../../util/request';
import * as constants from './constants';
import { addEntities } from '../../entities/redux/actions';
import { restaurant as restaurantSchema } from '../../entities/api/schema';

/**
 * RESTAURANT_GET
 */
export const getRestaurant = (cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.RESTAURANT_GET.REQUEST });

    const payload = await request({
      url: '/restaurant',
      method: 'GET',
    });
    const { entities, result } = normalize(payload, restaurantSchema);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.RESTAURANT_GET.SUCCESS,
      payload: result,
    });

    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({ type: constants.RESTAURANT_GET.FAIL, error });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.RESTAURANT_GET.COMPLETE });
  }
};

/**
* RESTAURANT_BRANCHES_GET
*/
export const getRestaurantBranches = (cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.RESTAURANT_BRANCHES_GET.REQUEST });

    const payload = await request({
      url: '/restaurant/branches',
      method: 'GET',
    });
    const { entities, result } = normalize(payload.data, [restaurantSchema]);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.RESTAURANT_BRANCHES_GET.SUCCESS,
      payload: result,
    });

    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({ type: constants.RESTAURANT_BRANCHES_GET.FAIL, error });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.RESTAURANT_BRANCHES_GET.COMPLETE });
  }
};

/**
 * RESTAURANT_INFO_UPDATE
 */
export const updateRestaurantInfo = (restaurant, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.RESTAURANT_INFO_UPDATE.REQUEST });

    const payload = await request({
      url: '/restaurant/info',
      method: 'PUT',
      data: restaurant,
    });
    const { entities } = normalize(payload, restaurantSchema);

    dispatch(addEntities(entities));
    dispatch({ type: constants.RESTAURANT_INFO_UPDATE.SUCCESS });

    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({ type: constants.RESTAURANT_INFO_UPDATE.FAIL, error });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.RESTAURANT_INFO_UPDATE.COMPLETE });
  }
};

/**
 * RESTAURANT_HOURS_UPDATE
 */
export const updateRestaurantHours = (hours, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.RESTAURANT_HOURS_UPDATE.REQUEST });

    const payload = await request({
      url: '/restaurant/hours',
      method: 'PUT',
      data: {
        hours,
      },
    });
    const { entities } = normalize(payload, restaurantSchema);

    dispatch(addEntities(entities));
    dispatch({ type: constants.RESTAURANT_HOURS_UPDATE.SUCCESS });

    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({ type: constants.RESTAURANT_HOURS_UPDATE.FAIL, error });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.RESTAURANT_HOURS_UPDATE.COMPLETE });
  }
};

/**
 * RESTAURANT_OPERATIONS_UPDATE
 */
export const updateRestaurantOperations = (operations, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.RESTAURANT_OPERATIONS_UPDATE.REQUEST });

    const payload = await request({
      url: '/restaurant/operations',
      method: 'PUT',
      data: operations,
    });

    const { entities } = normalize(payload, restaurantSchema);

    dispatch(addEntities(entities));
    dispatch({ type: constants.RESTAURANT_OPERATIONS_UPDATE.SUCCESS });

    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({ type: constants.RESTAURANT_OPERATIONS_UPDATE.FAIL, error });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.RESTAURANT_OPERATIONS_UPDATE.COMPLETE });
  }
};

/**
 * RESTAURANT_BOOKING_UPDATE
 */
export const updateRestaurantBooking = (booking, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.RESTAURANT_BOOKING_UPDATE.REQUEST });
    const payload = await request({
      url: '/restaurant/booking',
      method: 'PUT',
      data: booking,
    });

    const { entities } = normalize(payload, restaurantSchema);

    dispatch(addEntities(entities));
    dispatch({ type: constants.RESTAURANT_BOOKING_UPDATE.SUCCESS });

    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({ type: constants.RESTAURANT_BOOKING_UPDATE.FAIL, error });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.RESTAURANT_BOOKING_UPDATE.COMPLETE });
  }
};
