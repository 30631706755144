import React from 'react';
import { Link } from 'react-router-dom';
import {
  ChevronLeft,
  ChevronRight,
} from 'react-feather';
import {
  Tab,
  Tabs,
  Text,
  Button,
  HStack,
  TabList,
  IconButton,
} from '@chakra-ui/react';

/* =============================================================================
<ReservationCalendarToolbar />
============================================================================= */
const ReservationCalendarToolbar = ({
  view,
  label,
  onView,
  onNavigate,
}) => {
  const _handlePrevClick = () => {
    onNavigate('PREV');
  };

  const _handleNextClick = () => {
    onNavigate('NEXT');
  };

  const _handleTabsChange = (index) => {
    switch (index) {
      case 0:
        onView('month');
        break;
      case 1:
        onView('week');
        break;
      case 2:
        onView('day');
        break;
      default:
        break;
    }
  };

  return (
    <HStack mb={6} justify="space-between">
      <HStack spacing={5}>
        <Text fontSize="2xl" fontWeight="semibold">{label}</Text>
        <HStack>
          <IconButton
            size="sm"
            variant="outline"
            colorScheme="blackAlpha"
            isRound
            icon={<ChevronLeft />}
            onClick={_handlePrevClick}
          />
          <IconButton
            size="sm"
            variant="outline"
            colorScheme="blackAlpha"
            isRound
            icon={<ChevronRight />}
            onClick={_handleNextClick}
          />
        </HStack>
        <Button as={Link} to="/booking/reservations">
          View All Reservation
        </Button>
      </HStack>
      <Tabs
        index={getTabIndex(view)}
        size="md"
        variant="soft-rounded"
        colorScheme="red"
        onChange={_handleTabsChange}
      >
        <TabList>
          <Tab>Month</Tab>
          <Tab>Week</Tab>
          <Tab>Day</Tab>
        </TabList>
      </Tabs>
    </HStack>
  );
};

const getTabIndex = (view) => {
  switch (view) {
    case 'month':
      return 0;
    case 'week':
      return 1;
    case 'day':
      return 2;

    default:
      return 0;
  }
};

/* Export
============================================================================= */
export default ReservationCalendarToolbar;
