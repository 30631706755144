import React from 'react';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import {
  Link,
  List,
  Text,
  ListItem,
} from '@chakra-ui/react';

import NAV_LINKS from '../../static/nav_links';
import SideNavOrderCountBadge from './SideNavOrderCountBadge';

const SideNavList = ({ isOpen, orderCount }) => {
  const location = useLocation();

  return (
    <List mx={!isOpen && '0.5rem'} mt="1rem">
      {NAV_LINKS.map((link) => (
        <ListItem key={link.to}>
          {isActive(link.to, location) ? (
            <Link
              p="1rem"
              as={NavLink}
              to={link.to}
              position="relative"
              display="flex"
              bgColor="red.50"
              borderRadius={!isOpen && '6px'}
              alignItems="center"
              textDecor="none !important"
            >
              {link.ActiveIcon}
              {isOpen && (
                <Text ml="18px" color="brand.700">{link.title}</Text>
              )}
              {link.to === '/live_order' && orderCount > 0 && <SideNavOrderCountBadge orderCount={orderCount} />}
            </Link>
          ) : (
            <Link
              p="1rem"
              as={NavLink}
              to={link.to}
              position="relative"
              display="flex"
              alignItems="center"
              textDecor="none !important"
            >
              {link.UnActiveIcon}
              {isOpen && (
                <Text ml="18px">{link.title}</Text>
              )}
              {link.to === '/live_order' && orderCount > 0 && <SideNavOrderCountBadge orderCount={orderCount} />}
            </Link>
          ) }
        </ListItem>
      ))}
    </List>
  );
};

const isActive = (link, location) => location.pathname.startsWith(link);

export default SideNavList;
