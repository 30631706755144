import React from 'react';
import { FastField } from 'formik';
import {
  Select,
  VStack,
  FormLabel,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
} from '@chakra-ui/react';

/* =============================================================================
<RestaurantFormCouponAcceptability />
============================================================================= */
const RestaurantFormCouponAcceptability = ({ disabled }) => (
  <FastField name="couponAcceptability">
    {({ field, form }) => (
      <FormControl
        mb={8}
        display="flex"
        isInvalid={form.errors.couponAcceptability && form.touched.couponAcceptability}
      >
        <FormLabel flex={1.5}>Coupons Acceptability</FormLabel>
        <VStack alignItems="flex-start" flex={2}>
          <Select id="couponAcceptability" {...field} disabled={disabled}>
            <option value="">Select</option>
            <option value="all">All</option>
            <option value="restaurant">Coupons for this Restaurant</option>
            <option value="none">None</option>
          </Select>
          <FormErrorMessage>
            <FormErrorIcon />
            {form.errors.couponAcceptability}
          </FormErrorMessage>
        </VStack>
      </FormControl>
    )}
  </FastField>
);

/* Export
============================================================================= */
export default RestaurantFormCouponAcceptability;
