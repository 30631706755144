import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Tabs, TabList } from '@chakra-ui/react';

import MenusItemTabItem from './MenusItemTabItem';
import MenusItemTabItemSpecial from './MenusItemTabItemSpecial';

import { getMenu } from '../../../../entities/redux/selectors';

/* =============================================================================
<MenusItemTab />
============================================================================= */
const MenusItemTab = ({ value, onChange, menuItems }) => {
  const [tabIndex, setTabIndex] = useState(0);

  // Select default menu at start
  useEffect(() => {
    if (!value && menuItems?.length) {
      onChange(menuItems[0]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, menuItems?.length]);

  // Select tab
  useEffect(() => {
    if (value === 'featured') {
      // Featured items
      setTabIndex(menuItems.length);
    } else if (value === 'deals') {
      // Deals
      setTabIndex(menuItems.length + 1);
    } else if (value) {
      // Menu item
      for (let i = 0; i < menuItems?.length; i += 1) {
        if (menuItems[i] === value) {
          setTabIndex(i);
          break;
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const _handleChange = (index) => {
    if (index < menuItems?.length) {
      onChange(menuItems[index]);
    }
  };

  const _handleSpecialItemClick = (id) => {
    onChange(id);
  };

  return (
    <Tabs
      isLazy 
      index={tabIndex}
      whiteSpace="nowrap"
      colorScheme="brand"
      onChange={_handleChange}>
      <TabList border="none" overflowX="scroll" px={6} py={3}>
        {menuItems?.map((menuItem) => (
          <MenusItemTabItem key={menuItem} id={menuItem} />
        ))}
        <MenusItemTabItemSpecial
          id="featured"
          name="Featured"
          onClick={_handleSpecialItemClick}
        />
      </TabList>
    </Tabs>
  );
};

const mapStateToProps = (state, { menu }) => ({
  menuItems: getMenu(state, { id: menu, normalize: true })?.items,
});

const propsAreEqual = (prevProps, nextProps) => prevProps.value === nextProps.value
  && prevProps.menuItems?.toString() === nextProps.menuItems?.toString();

/* Export
============================================================================= */
export default connect(mapStateToProps)(React.memo(MenusItemTab, propsAreEqual));
