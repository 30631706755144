import React from 'react';
import { Center } from '@chakra-ui/react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Card from '../../common/Card';
import AuthErrorMessage from '../components/AuthErrorMessage';
import LoginScreen from './LoginScreen';
import ForgotPasswordScreen from './ForgotPasswordScreen';
import CodeVerificationScreen from './CodeVerificationScreen';
import ResetPasswordScreen from './ResetPasswordScreen';

/* =============================================================================
<AuthScreen />
============================================================================= */
const AuthScreen = () => (
  <Center h="100%">
    <Card w={['100%', '500px']}>
      <Switch>
        <Redirect from="/auth" to="/auth/login" exact />
        <Route path="/auth/login" exact>
          <LoginScreen />
        </Route>
        <Route path="/auth/forgot_password" exact>
          <ForgotPasswordScreen />
        </Route>
        <Route path="/auth/code_verification" exact>
          <CodeVerificationScreen />
        </Route>
        <Route path="/auth/reset_password" exact>
          <ResetPasswordScreen />
        </Route>
      </Switch>
      <AuthErrorMessage mt={6} />
    </Card>
  </Center>
);

/* Export
============================================================================= */
export default AuthScreen;
