import React, { useState } from 'react';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { Eye } from 'react-feather';
import {
  Box,
  Text,
  Input,
  Button,
  Heading,
  FormLabel,
  InputGroup,
  FormControl,
  FormErrorMessage,
  InputRightElement,
} from '@chakra-ui/react';

import { ReactComponent as KeyIcon } from '../../assets/icons/edit-forgot-password-icon.svg';

import { resetPassword as resetPasswordAction } from '../redux/actions';

/* =============================================================================
<FindAccountScreen />
============================================================================= */
const FindAccountScreen = ({ resetPassword }) => {
  const [passwordToggle, setPasswordToggle] = useState(false);
  const [confirmPassToggle, setConfirmPassToggle] = useState(false);
  const query = new URLSearchParams(useLocation().search);
  const token = query.get('token');

  const _handleSubmit = async (values) => {
    await resetPassword(values.password, token);
  };

  return (
    <Formik
      initialValues={{ password: '', confirmPassword: '' }}
      validationSchema={ResetPasswordSchema}
      onSubmit={_handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <Box align="center">
            <KeyIcon />
            <Heading mt={6} mb={3} fontSize="3xl">
              Enter new password.
            </Heading>
            <Text mb={8}>
              Your password must at least 8 characters long,
              and contains at least one letter and one digit.
            </Text>
            <Field name="password">
              {({ field, form }) => (
                <FormControl my="1rem" isInvalid={form.errors.password && form.touched.password}>
                  <FormLabel htmlFor="password">New Password*</FormLabel>
                  <InputGroup>
                    <Input
                      id="password"
                      placeholder="New Password"
                      type={passwordToggle ? 'text' : 'password'}
                      {...field}
                    />
                    <InputRightElement>
                      <Button
                        variant="unstyled"
                        onClick={() => setPasswordToggle(!passwordToggle)}
                      >
                        <Eye size="0.9rem" />
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                  <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="confirmPassword">
              {({ field, form }) => (
                <FormControl
                  isInvalid={form.errors.confirmPassword && form.touched.confirmPassword}
                >
                  <FormLabel htmlFor="confirmPassword">Confirm Password*</FormLabel>
                  <InputGroup>
                    <Input
                      id="confirmPassword"
                      type={confirmPassToggle ? 'text' : 'password'}
                      placeholder="Confirm Password"
                      {...field}
                    />
                    <InputRightElement>
                      <Button
                        variant="unstyled"
                        onClick={() => setConfirmPassToggle(!confirmPassToggle)}
                      >
                        <Eye size="0.9rem" />
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                  <FormErrorMessage>{form.errors.confirmPassword}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Button
              size="xl"
              mt={8}
              type="submit"
              isLoading={isSubmitting}
            >
              Save
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Must be at least 8 characters and contain one digit!')
    .max(25, 'Must not exceed 25 characters!')
    .required('Must not be empty!'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Both passwords must match!')
    .required('Must not be empty!'),
});

const mapDispatchToProps = {
  resetPassword: resetPasswordAction,
};

/* Export
============================================================================= */
export default connect(null, mapDispatchToProps)(FindAccountScreen);
