import React from 'react';
import { FastField } from 'formik';
import {
  Input,
  VStack,
  FormLabel,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
  Box,
} from '@chakra-ui/react';

/* =============================================================================
<RestaurantFormCapacity />
============================================================================= */
const RestaurantFormCapacity = () => (
  <Box pr={[0, 0, 0, '40%']}>
    <FastField name="capacity">
      {({ field, form }) => (
        <FormControl
          mb={8}
          display="flex"
          isInvalid={form.errors.capacity && form.touched.capacity}
        >
          <FormLabel flex={1.5}>Total Capacity</FormLabel>
          <VStack alignItems="flex-start" flex={2}>
            <Input type="number" id="capacity" {...field} />
            <FormErrorMessage>
              <FormErrorIcon />
              {form.errors.capacity}
            </FormErrorMessage>
          </VStack>
        </FormControl>
      )}
    </FastField>
  </Box>
);

/* Export
============================================================================= */
export default RestaurantFormCapacity;
