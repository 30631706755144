import React from 'react';
import { connect } from 'react-redux';
import { Tab } from '@chakra-ui/react';

import { getMenu } from '../../../../entities/redux/selectors';

/* =============================================================================
<MenusTabItem />
============================================================================= */
const MenusTabItem = ({ menu }) => (
  <Tab fontWeight="medium">{menu?.name}</Tab>
);

const mapStateToProps = (state, { id }) => ({
  menu: getMenu(state, { id, normalize: true }),
});

// eslint-disable-next-line max-len
const propsAreEqual = (prevProps, nextProps) => prevProps.menu?.name === nextProps.menu?.name;

/* Export
============================================================================= */
export default connect(mapStateToProps)(React.memo(MenusTabItem, propsAreEqual));
