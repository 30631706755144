import React from 'react';
import {
  Tr,
  Th,
  Td,
  Thead,
  Tbody,
  Table,
  Button,
} from '@chakra-ui/react';

/* =============================================================================
<OrderSetupDetailsTable />
============================================================================= */
const OrderSetupDetailsTable = ({ items, setItems }) => {
  const _handleDeleteClick = (index) => () => {
    const _items = [...items];
    _items.splice(index, 1);
    setItems(_items);
  };

  return (
    <Table variant="simple" size="sm" mt={5}>
      <Thead bgColor="gray.100">
        <Tr>
          <Th fontWeight="medium">NAME</Th>
          <Th fontWeight="medium">PRICE</Th>
          <Th fontWeight="medium">QUANTITY</Th>
          <Th fontWeight="medium">SUB TOTAL</Th>
          <Th fontWeight="medium">ACTION</Th>
        </Tr>
      </Thead>
      <Tbody>
        {items?.map((item, index) => {
          const price = (item.price || 0);
          const optionsPrice = (
            item.options?.map((option) => (option.price || 0)).reduce((a, b) => a + b, 0) || 0
          );
          const subtotal = Number(price + optionsPrice).toFixed(2);
          const total = Number(subtotal * item?.qty).toFixed(2);

          return (
            <Tr key={index}>
              <Td>{item?.name}</Td>
              <Td>
                Rs
                {' '}
                {subtotal}
              </Td>
              <Td>{item?.qty}X</Td>
              <Td>
                Rs
                {' '}
                {total}
              </Td>
              <Td>
                <Button
                  colorScheme="gray"
                  variant="outline"
                  onClick={_handleDeleteClick(index)}
                >
                  Delete
                </Button>
              </Td>
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
};

/* Export
============================================================================= */
export default OrderSetupDetailsTable;
