import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Box, List } from '@chakra-ui/react';

import DiscountsHeader from './DiscountsHeader';
import Discount from './Discount/index';

import { getDiscounts as selectDiscounts } from '../../redux/selectors';
import { getDiscounts as getDiscountsAction } from '../../redux/actions';

/* =============================================================================
<ExtraChoices />
============================================================================= */
const ExtraChoices = ({ discounts, getDiscounts }) => {
  // Get choice groups
  useEffect(() => {
    getDiscounts();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box flex={4} px={4}>
      <DiscountsHeader />
      <List>
        {discounts?.map(renderDiscount)}
      </List>
    </Box>
  );
};

const renderDiscount = (item) => <Discount id={item} />;

const mapStateToProps = (state) => ({
  discounts: selectDiscounts(state),
});

const mapDispatchToProps = {
  getDiscounts: getDiscountsAction,
};

// eslint-disable-next-line max-len
const propsAreEqual = (prevProps, nextProps) => prevProps.discounts?.toString() === nextProps.discounts?.toString();

/* Export
============================================================================= */
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(ExtraChoices, propsAreEqual));
