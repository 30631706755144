import React from 'react';
import { FastField, getIn } from 'formik';

import {
  Box,
  Input,
  VStack,
  FormLabel,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
  HStack,
  Text,
} from '@chakra-ui/react';

/* =============================================================================
<ReservationFormSeats />
============================================================================= */
const ReservationFormSeats = () => (
  <Box mb={8} pr={[0, 0, 0, '40%']}>
    <HStack>
      <Text flex={1.5} fontSize="sm" fontWeight="semibold">Seats</Text>
      <HStack flex={2} alignItems="flex-start">
        <FastField name="seats.min">
          {({ field, form }) => (
            <FormControl isInvalid={getIn(form.errors, 'seats.min') && getIn(form.touched, 'seats.min')}>
              <FormLabel>Min:</FormLabel>
              <Input type="number" {...field} />
              <FormErrorMessage>
                <FormErrorIcon />
                {getIn(form.errors, 'seats.min')}
              </FormErrorMessage>
            </FormControl>
          )}
        </FastField>
        <FastField name="seats.max">
          {({ field, form }) => (
            <FormControl isInvalid={getIn(form.errors, 'seats.max') && getIn(form.touched, 'seats.max')}>
              <FormLabel>Max:</FormLabel>
              <Input type="number" {...field} />
              <FormErrorMessage>
                <FormErrorIcon />
                {getIn(form.errors, 'seats.max')}
              </FormErrorMessage>
            </FormControl>
          )}
        </FastField>
      </HStack>
    </HStack>
  </Box>
);

/* Export
============================================================================= */
export default ReservationFormSeats;
