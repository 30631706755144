import React from 'react';
import { connect } from 'react-redux';
import {
  Text, Box, HStack, useMediaQuery,
} from '@chakra-ui/react';

import { getUser } from '../../../auth/redux/selectors';

/* =============================================================================
<HeaderMenu />
============================================================================= */
const HeaderMenu = ({ user }) => {
  const [isSmallerThan766] = useMediaQuery('(max-width: 766px)');
  const username = user?.profile ? `${user.profile.firstName} ${user.profile.lastName}` : '';
  const role = user?.role ? user.role.title : '';

  return (
    <HStack>
      {!isSmallerThan766 && (
      <Box>
        <Text fontSize="sm" fontWeight="bold">{username}</Text>
        <Text fontSize="xs" textTransform="capitalize">{role}</Text>
      </Box>
      )}
    </HStack>
  );
};

const mapStateToProps = (state) => ({
  user: getUser(state),
});

/* Export
============================================================================= */
export default connect(mapStateToProps)(HeaderMenu);
