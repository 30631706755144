import * as constants from './constants';

const INITIAL_STATE = {
  user: null,
  error: null,
  loading: false,
};

export default function reducer(state = INITIAL_STATE, action) {
  const { type, payload, error } = action;

  switch (type) {
    // GET USER
    case constants.USER_GET.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.USER_GET.FAIL:
      return {
        ...state,
        error,
      };
    case constants.USER_GET.SUCCESS:
      return {
        ...state,
        error: null,
        user: payload,
      };
    case constants.USER_GET.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // REGISTER
    case constants.REGISTER.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.REGISTER.FAIL:
      return {
        ...state,
        error,
      };
    case constants.REGISTER.SUCCESS:
      return {
        ...state,
        error: null,
        user: payload,
      };
    case constants.REGISTER.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // LOGIN
    case constants.LOGIN.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.LOGIN.FAIL:
      return {
        ...state,
        error,
      };
    case constants.LOGIN.SUCCESS:
      return {
        ...state,
        error: null,
        user: payload,
      };
    case constants.LOGIN.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // PASSWORD_RESET_CODE_SEND
    case constants.PASSWORD_RESET_CODE_SEND.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.PASSWORD_RESET_CODE_SEND.FAIL:
      return {
        ...state,
        error,
      };
    case constants.PASSWORD_RESET_CODE_SEND.SUCCESS:
      return {
        ...state,
        error: null,
      };
    case constants.PASSWORD_RESET_CODE_SEND.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // PASSWORD_RESET_CODE_VERIFICATION
    case constants.PASSWORD_RESET_CODE_VERIFICATION.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.PASSWORD_RESET_CODE_VERIFICATION.FAIL:
      return {
        ...state,
        error,
      };
    case constants.PASSWORD_RESET_CODE_VERIFICATION.SUCCESS:
      return {
        ...state,
        error: null,
      };
    case constants.PASSWORD_RESET_CODE_VERIFICATION.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // RESET_PASSWORD
    case constants.PASSWORD_RESET.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.PASSWORD_RESET.FAIL:
      return {
        ...state,
        error,
      };
    case constants.PASSWORD_RESET.SUCCESS:
      return {
        ...state,
        error: null,
        user: payload,
      };
    case constants.PASSWORD_RESET.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // LOGOUT
    case constants.LOGOUT.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.LOGOUT.FAIL:
      return {
        ...state,
        error,
      };
    case constants.LOGOUT.SUCCESS:
      return {
        ...state,
        error: null,
        user: null,
      };
    case constants.LOGOUT.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // ERROR_SET
    case constants.ERROR_SET:
      return {
        ...state,
        error: payload,
      };

    default:
      return state;
  }
}
