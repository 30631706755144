import React from 'react';
import {
  Box,
  Text,
  Button,
  Divider,
  HStack,
} from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';

/* =============================================================================
<ReservationFormHeader />
============================================================================= */
const ReservationFormHeader = ({ isSubmitting }) => (
  <Box>
    <HStack flex={1} justify="space-between">
      <Box mr={5}>
        <Text fontSize="lg" fontWeight="medium">Reservation Setting</Text>
        <Text fontSize="sm">Update your restaurant reservation setting</Text>
      </Box>
      <HStack>
        <Button as={NavLink} to="/booking/reservations" variant="outline" colorScheme="gray">
          View Reservations
        </Button>
        <Button
          type="submit"
          isLoading={isSubmitting}
        >
          Save Changes
        </Button>
      </HStack>
    </HStack>
    <Divider my={5} />
  </Box>
);

/* Export
============================================================================= */
export default ReservationFormHeader;
