import React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalFooter,
} from '@chakra-ui/react';

import ReservationEditModalNameAndPhone from './ReservationEditModalNameAndPhone';
import ReservationEditModalDateAndTime from './ReservationEditModalDateAndTime';
import ReservationEditModalSeatsAndEvent from './ReservationEditModalSeatsAndEvent';
import ReservationEditModalNote from './ReservationEditModalNote';
import ReservationEditModalInitialize from './ReservationEditModalInitialize';
import ReservationEditModalFooter from './ReservationEditModalFooter';

import { updateBooking as updateBookingAction } from '../../../../redux/actions';

/* =============================================================================
<ReservationEditModal />
============================================================================= */
const ReservationEditModal = ({
  id,
  isOpen,
  onClose,
  updateBooking,
}) => {
  const _handleSubmit = (values) => {
    updateBooking({
      ...values,
      uid: id,
    });
    onClose();
  };

  return (
    <Modal size="2xl" isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="lg">Reservation Details</ModalHeader>
        <ModalCloseButton />
        <Formik
          initialValues={initialValues}
          validationSchema={ReservationEditSchema}
          onSubmit={_handleSubmit}
        >
          {() => (
            <Form>
              <ModalBody>
                <ReservationEditModalNameAndPhone />
                <ReservationEditModalDateAndTime />
                <ReservationEditModalSeatsAndEvent />
                <ReservationEditModalNote />
                <ReservationEditModalInitialize id={id} />
              </ModalBody>
              <ModalFooter>
                <ReservationEditModalFooter />
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

const initialValues = {
  name: '',
  phone: '',
  seats: '',
  date: '',
  time: '',
  status: '',
  event: '',
  note: '',
};

const ReservationEditSchema = Yup.object().shape({
  name: Yup.string()
    .required('Must not be empty'),
  phone: Yup.number()
    .required('Must not be empty'),
  seats: Yup.number()
    .required('Must not be empty'),
  date: Yup.string()
    .required('Must not be empty'),
  time: Yup.string()
    .required('Must not be empty'),
  event: Yup.string()
    .optional(),
  note: Yup.string()
    .optional(),
});

const mapDispatchToProps = {
  updateBooking: updateBookingAction,
};

// eslint-disable-next-line max-len
const propsAreEqual = (prevProps, nextProps) => prevProps.isOpen === nextProps.isOpen
  && prevProps?.id === nextProps?.id;

/* Export
============================================================================= */
export default connect(
  null,
  mapDispatchToProps,
)(React.memo(ReservationEditModal, propsAreEqual));
