import React from 'react';

import {
  BarChart2,
  ShoppingBag,
  List,
  BookOpen,
  Calendar,
  MapPin,
  Shield,
  Box,
  Users,
} from 'react-feather';

import { ReactComponent as RestaurantIcon } from '../assets/icons/nav-restaurant-icon.svg';
import { ReactComponent as TableIcon } from '../assets/icons/nav-table-icon.svg';

export default [
  {
    to: '/dashboard',
    title: 'Dashboard',
    UnActiveIcon: <BarChart2 color="#667085" size="20px" />,
    ActiveIcon: <BarChart2 color="#A32821" size="20px" />,
  },
  {
    to: '/live_order',
    title: 'Live Order',
    UnActiveIcon: <ShoppingBag color="#667085" size="20px" />,
    ActiveIcon: <ShoppingBag color="#A32821" size="20px" />,
  },
  {
    to: '/order_history',
    title: 'Order History',
    UnActiveIcon: <List color="#667085" size="20px" />,
    ActiveIcon: <List color="#A32821" size="20px" />,
  },
  {
    to: '/restaurant',
    title: 'Restaurant',
    UnActiveIcon: <RestaurantIcon fill="#667085" />,
    ActiveIcon: <RestaurantIcon fill="#A32821" />,
  },
  {
    to: '/menu',
    title: 'Menu',
    UnActiveIcon: <BookOpen color="#667085" size="20px" />,
    ActiveIcon: <BookOpen color="#A32821" size="20px" />,
  },
  {
    to: '/booking',
    title: 'Bookings',
    UnActiveIcon: <Calendar color="#667085" size="20px" />,
    ActiveIcon: <Calendar color="#A32821" size="20px" />,
  },
  {
    to: '/table_management',
    title: 'Table & Area Management',
    UnActiveIcon: <TableIcon fill="#667085" />,
    ActiveIcon: <TableIcon fill="#A32821" />,
  },
  {
    to: '/delivery_areas',
    title: 'Delivery Areas',
    UnActiveIcon: <MapPin color="#667085" size="20px" />,
    ActiveIcon: <MapPin color="#A32821" size="20px" />,
  },
  {
    to: '/finance',
    title: 'Finance',
    UnActiveIcon: <Shield color="#667085" size="20px" />,
    ActiveIcon: <Shield color="#A32821" size="20px" />,
  },
  {
    to: '/inventory',
    title: 'Inventory',
    UnActiveIcon: <Box color="#667085" size="20px" />,
    ActiveIcon: <Box color="#A32821" size="20px" />,
  },
  {
    to: '/employees',
    title: 'Staff',
    UnActiveIcon: <Users color="#667085" size="20px" />,
    ActiveIcon: <Users color="#A32821" size="20px" />,
  },
];
