import React from 'react';
import { connect } from 'react-redux';
import { useFormikContext } from 'formik';
import {
  Text,
  Image,
  HStack,
  VStack,
  FormLabel,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
} from '@chakra-ui/react';

import FileSelect from '../../../common/FileSelect';

/* =============================================================================
<DishPhoto />
============================================================================= */
const DishPhoto = () => {
  const {
    values,
    errors,
    touched,
    setFieldValue,
  } = useFormikContext();
  const { photo, photoUrl } = values;

  const _handlePhotoChange = (value) => {
    setFieldValue('photo', value);
  };

  return (
    <FormControl
      mb={8}
      display="flex"
      justifyContent="space-between"
      isInvalid={errors.photo && touched.photo}
    >
      <FormLabel flex={2.1}>
        <Text fontWeight="semibold" fontSize="sm">Image</Text>
        <Text fontSize="sm">This will be displayed in your Restaurant.</Text>
      </FormLabel>
      <VStack flex={3} alignItems="flex-start">
        <HStack alignItems="flex-start">
          {!!photoUrl && <Image src={photoUrl} w="150px" h="150px" borderRadius="8px" mr={4} />}
          <FileSelect size="150px" value={photo} onChange={_handlePhotoChange} />
        </HStack>
        <FormErrorMessage>
          <FormErrorIcon />
          {errors.photo}
        </FormErrorMessage>
      </VStack>
    </FormControl>
  );
};

/* Export
============================================================================= */
export default DishPhoto;
