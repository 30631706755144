import * as Yup from 'yup';

export const DishSchema = Yup.object().shape({
  name: Yup.string()
    .required('Must not be empty!'),
  about: Yup.string()
    .required('Must not be empty!'),
  photo: Yup.mixed().optional(),
  price: Yup.mixed().when('variants', {
    is: (v) => !v?.length,
    then: Yup.number().required('Must not be empty!'),
  }),
  variants: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required('Must not be empty!'),
        price: Yup.number().required('Must not be empty!'),
      }),
    ).optional(),
  choiceGroups: Yup.array().of(Yup.string().required('Must not be empty!')).optional(),
  stock: Yup.array()
    .of(
      Yup.object().shape({
        qty: Yup.number().required('Must not be empty!'),
        inventory: Yup.string().required('Must not be empty!'),
      }),
    ).optional(),
});

export default DishSchema;
