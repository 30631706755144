import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useFormikContext } from 'formik';

import { getRestaurant } from '../../restaurant/redux/selectors';

/* =============================================================================
<RestaurantInformationInitialize />
============================================================================= */
const RestaurantInformationInitialize = ({ restaurant }) => {
  const { setFieldValue } = useFormikContext();

  const isBranch = restaurant?.type === 'branch';
  const name = isBranch ? restaurant?.chain?.name : restaurant?.name;
  const email = isBranch ? restaurant?.chain?.email : restaurant?.email;
  const about = isBranch ? restaurant?.chain?.about : restaurant?.about;
  const website = isBranch ? restaurant?.chain?.website : restaurant?.website;
  const phone1 = restaurant?.phone?.delivery1;
  const phone2 = restaurant?.phone?.delivery2;
  const phone3 = restaurant?.phone?.branch;
  const address = restaurant?.address?.text;
  const minOrder = isBranch ? restaurant?.chain?.minOrder: restaurant?.minOrder;
  const deliveryTime = isBranch ? restaurant?.chain?.deliveryTime: restaurant?.deliveryTime;
  const couponAcceptability = isBranch ? restaurant?.chain?.couponAcceptability: restaurant?.couponAcceptability;
  const ownerFirstName = restaurant?.owner?.profile?.firstName;
  const ownerLastName = restaurant?.owner?.profile?.lastName;
  const ownerEmail = restaurant?.owner?.email;
  const ownerPhone = restaurant?.owner?.phone;

  // Initialize form
  useEffect(() => {
    if (name) {
      setFieldValue('name', name);
    }
    if (email) {
      setFieldValue('email', email);
    }
    if (about) {
      setFieldValue('about', about);
    }
    if (website) {
      setFieldValue('website', website);
    }
    if (phone1) {
      setFieldValue('phone.delivery1', phone1);
    }
    if (phone2) {
      setFieldValue('phone.delivery2', phone2);
    }
    if (phone3) {
      setFieldValue('phone.branch', phone3);
    }
    if (address) {
      setFieldValue('address', address);
    }
    if (minOrder) {
      setFieldValue('minOrder', minOrder);
    }
    if (deliveryTime) {
      setFieldValue('deliveryTime', deliveryTime);
    }
    if (couponAcceptability) {
      setFieldValue('couponAcceptability', couponAcceptability);
    }
    if (ownerFirstName) {
      setFieldValue('owner.firstName', ownerFirstName);
    }
    if (ownerLastName) {
      setFieldValue('owner.lastName', ownerLastName);
    }
    if (ownerEmail) {
      setFieldValue('owner.email', ownerEmail);
    }
    if (ownerPhone) {
      setFieldValue('owner.phone', ownerPhone);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    name,
    email,
    about,
    phone1,
    phone2,
    phone3,
    address,
    minOrder,
    deliveryTime,
    couponAcceptability,
    ownerFirstName,
    ownerLastName,
    ownerEmail,
    ownerPhone,
  ]);

  return null;
};

const mapStateToProps = (state) => ({
  restaurant: getRestaurant(state),
});

/* Export
============================================================================= */
export default connect(mapStateToProps)(RestaurantInformationInitialize);
