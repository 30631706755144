import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useDisclosure } from '@chakra-ui/react';

import Card from '../../common/Card';
import EmployeesHeader from '../components/EmployeesHeader';
import EmployeesTable from '../components/EmployeesTable/index';
import EmployeesFooter from '../components/EmployeesFooter';
import AddEmployeesModal from '../components/EmployeeModals/AddEmployeeModal.jsx/index';
import usePagination from '../../hooks/use-pagination';

import { getNextCursor, getEmployees as selectEmployees } from '../redux/selectors';
import {
  getEmployees as getEmployeesAction,
  refreshEmployees as refreshEmployeesAction,
} from '../redux/actions';

/* =============================================================================
<EmployeesScreen />
============================================================================= */
const EmployeesScreen = ({
  nextCursor,
  employees,
  getEmployees,
  refreshEmployees,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    next,
    prev,
    page,
    maxPage,
    items,
    canNext,
    canPrev,
  } = usePagination(employees);

  // Get delivery fees
  useEffect(() => {
    refreshEmployees();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _handleNext = () => {
    if (canNext) {
      next();
      return;
    }

    if (nextCursor) {
      getEmployees(nextCursor, (err) => {
        if (!err) {
          next(employees);
        }
      });
    }
  };

  const _handlePrev = () => {
    if (canPrev) {
      prev();
    }
  };

  return (
    <Card m={5} flex={1} direction="column">
      <AddEmployeesModal isOpen={isOpen} onClose={onClose} />
      <EmployeesHeader onOpen={onOpen} />
      <EmployeesTable data={items} />
      <EmployeesFooter
        currentPage={page}
        maxPage={maxPage}
        canNext={canNext}
        canPrev={canPrev}
        onNext={_handleNext}
        onPrev={_handlePrev}
      />
    </Card>
  );
};

const mapStateToProps = (state) => ({
  nextCursor: getNextCursor(state),
  employees: selectEmployees(state),
});

const mapDispatchToProps = {
  getEmployees: getEmployeesAction,
  refreshEmployees: refreshEmployeesAction,
};

// eslint-disable-next-line max-len
const propsAreEqual = (prevProps, nextProps) => prevProps.employees.toString() === nextProps.employees.toString();

/* Export
============================================================================= */
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(EmployeesScreen, propsAreEqual));
