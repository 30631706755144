import axios from 'axios';
import LogRocket from 'logrocket';
import { RUDDER_DATA_URL, RUDDER_WRITE_KEY } from '../config/secrets';

export const useAnalytics = () => ({
  trackForUser,
});

export const trackForUser = (userId, event, properties) => axios.post(`${RUDDER_DATA_URL}/v1/track`, {
  userId,
  event,
  properties,
  integrations: {
    All: true,
  },
  context: {
    traits: {
      userId,
    },
  },
}, {
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Basic ${btoa(`${RUDDER_WRITE_KEY}:`)}`,
  },
});

const isProd = process.env.REACT_APP_ENVIRONMENT === 'production';

export const logrocket = {
  init: () => {
    if (isProd) {
      LogRocket.init('cq5s7i/foodstreet-app');
    }
  },
  identify: (userId, traits) => {
    if (isProd) {
      LogRocket.identify(userId, traits);
    }
  },
};
