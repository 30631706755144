/**
 * Get error
 */
export const getError = (state) => state.Menu.error;

/**
 * Get loading
 */
export const getLoading = (state) => state.Menu.loading;

/**
 * Get menus
 */
export const getMenus = (state) => state.Menu.menus;

/**
 * Get choice groups
 */
export const getChoiceGroups = (state) => state.Menu.choiceGroups;

/**
 * Get categories
 */
export const getCategories = (state) => state.Menu.categories;

/**
 * Get discounts
 */
export const getDiscounts = (state) => state.Menu.discounts;
