import React from 'react';
import { Tr, Td, Box, Table, Thead, Tbody, Checkbox } from '@chakra-ui/react';

import NeighborhoodsTableItem from './NeighborhoodsTableItem';
import NeighborhoodsTableHeadItem from './NeighborhoodsTableHeadItem';

/* =============================================================================
<NeighborhoodsTable />
============================================================================= */
const NeighborhoodsTable = ({
  data,
  onClickColumn,
  selectedData,
  onSelectAll,
  onUnselectAll,
  onItemSelect,
  onItemUnselect,
  onItemFeeChange,
  onItemViewClick,
}) => {
  const selectedIds = selectedData?.map(item => item.id);
  const unselectedIds = data?.filter(item => !selectedIds.includes(item));
  const areAllSelected = unselectedIds?.length === 0;

  const _handleCheckboxChange = () => {
    if (areAllSelected) {
      onUnselectAll();
    } else {
      onSelectAll();
    }
  };

  return (
    <Box flex={1}>
      <Table variant="simple" colorScheme="gray">
        <Thead>
          <Tr>
            <Td>
              <Checkbox
                isChecked={areAllSelected}
                onChange={_handleCheckboxChange}
              />
            </Td>
            {COLUMNS.map(column => (
              <NeighborhoodsTableHeadItem
                key={column}
                title={column}
                onClick={() => onClickColumn(column)}
              />
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {data.map(item => {
            const selectedItem = selectedData?.find(itm => itm.id === item);
            return (
              <NeighborhoodsTableItem
                id={item}
                key={item}
                fee={selectedItem?.fee}
                isSelected={Boolean(selectedItem)}
                onSelect={onItemSelect}
                onUnselect={onItemUnselect}
                onFeeChange={onItemFeeChange}
                onViewClick={onItemViewClick}
              />
            );
          })}
        </Tbody>
      </Table>
    </Box>
  );
};

const COLUMNS = ['NAME', 'TOWN', 'DELIVERY FEE', 'VIEW'];

/* Export
============================================================================= */
export default NeighborhoodsTable;
