import { actionGenerator } from '../../util/reduxHelpers';

export const MENUS_GET = actionGenerator('MENU/MENUS_GET');
export const MENU_GET = actionGenerator('MENU/MENU_GET');
export const MENU_CREATE = actionGenerator('MENU/MENU_CREATE');
export const MENU_UPDATE = actionGenerator('MENU/MENU_UPDATE');
export const MENU_DELETE = actionGenerator('MENU/MENU_DELETE');

export const MENU_ITEM_GET = actionGenerator('MENU/MENU_ITEM_GET');
export const MENU_ITEM_CREATE = actionGenerator('MENU/MENU_ITEM_CREATE');
export const MENU_ITEM_UPDATE = actionGenerator('MENU/MENU_ITEM_UPDATE');
export const MENU_ITEM_DELETE = actionGenerator('MENU/MENU_ITEM_DELETE');

export const DISH_CREATE = actionGenerator('MENU/DISH_CREATE');
export const DISH_UPDATE = actionGenerator('MENU/DISH_UPDATE');
export const DISH_DELETE = actionGenerator('MENU/DISH_DELETE');
export const DISH_AVAILABILITY_CHANGE = actionGenerator('MENU/DISH_AVAILABILITY_CHANGE');

export const CHOICE_GROUPS_GET = actionGenerator('MENU/CHOICE_GROUPS_GET');
export const CHOICE_GROUP_CREATE = actionGenerator('MENU/CHOICE_GROUP_CREATE');
export const CHOICE_GROUP_UPDATE = actionGenerator('MENU/CHOICE_GROUP_UPDATE');
export const CHOICE_GROUP_DELETE = actionGenerator('MENU/CHOICE_GROUP_DELETE');

export const DISCOUNTS_GET = actionGenerator('MENU/DISCOUNTS_GET');
export const DISCOUNT_DISHES_UPDATE = actionGenerator('MENU/DISCOUNT_DISHES_UPDATE');
export const DISCOUNT_DISH_DELETE = actionGenerator('MENU/DISCOUNT_DISH_DELETE');

export const CATEGORIES_GET = actionGenerator('MENU/CATEGORIES_GET');

export const POS_MENUS_GET = actionGenerator('MENU/POS_MENUS_GET');
