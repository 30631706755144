import React, { useEffect } from 'react';
import {
  Tr,
  Box,
  Table,
  Thead,
  Tbody,
  Th,
  TableCaption,
  Text,
  Td,
  Checkbox,
} from '@chakra-ui/react';

/* =============================================================================
<MenusAddExtraChoiceTable />
============================================================================= */
const MenusAddExtraChoiceTable = ({
  name,
  data,
  value,
  max,
  min,
  onSelect,
  onDelete,
}) => {
  const multiple = max > 1;
  const required = min > 0;

  // Select one on required items
  useEffect(() => {
    if (required) {
      onSelect([data[0]?.id]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _handleCheckboxClick = (input) => (e) => {
    const check = e.target.checked;
    if (check) {
      // Multiple
      if (multiple) {
        if (value?.includes(input)) {
          if (value?.length === 1 && required) {
            return;
          }
          onSelect(value.filter((item) => item !== input));
        } else {
          onSelect([...(value || []), input]);
        }
        return;
      }

      // Single
      if (value?.includes(input)) {
        if (!required) {
          onSelect([]);
        }
      } else {
        onSelect([input]);
      }
    } else if (!required || value?.length > min) {
      onDelete(input);
    }
  };

  return (
    <Box>
      <Table>
        <TableCaption textAlign="left" style={{ captionSide: 'top' }}>
          <Text fontSize="lg">{name}</Text>
          <Text>{`Min: ${min} Max: ${max}`}</Text>
        </TableCaption>
        <Thead>
          <Tr>
            {COLUMNS.map((title) => (
              <Th
                flex={1}
                key={title}
                bg="gray.50"
                color="#667085"
                fontSize="13px"
                borderTop="1px solid #E4E7EC"
              >
                {title}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {data?.map((choice, index) => {
            const _value = value?.find((item) => item === choice.id);
            const selected = value?.includes(choice.id);
            return (
              <Tr flex={1} key={index}>
                <Td w="200px">{choice.name}</Td>
                <Td>{choice.price}</Td>
                <Td>
                  <Checkbox
                    size="lg"
                    key={_value}
                    name={name}
                    label={name}
                    value={_value}
                    colorScheme="brand"
                    isChecked={selected}
                    onChange={_handleCheckboxClick(choice.id)}
                  />
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Box>
  );
};

const COLUMNS = [
  'Items',
  'Price',
  'Action ',
];

/* Export
============================================================================= */
export default MenusAddExtraChoiceTable;
