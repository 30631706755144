import React from 'react';
import { connect } from 'react-redux';
import {
  Box,
  Text,
  HStack,
} from '@chakra-ui/react';
import { MapPin } from 'react-feather';

import { getUser } from '../../auth/redux/selectors';

/* =============================================================================
<RestaurantHeader />
============================================================================= */
const RestaurantHeader = ({ user }) => {
  const restaurant = user?.restaurant;
  const isBranch = restaurant?.type === 'branch';
  const isChain = restaurant?.type === 'chain';
  const name = isBranch ? restaurant?.chain?.name : restaurant?.name;
  const address = restaurant?.address?.text;

  return (
    <Box>
      <Text fontSize="lg" fontWeight="semibold">{name}</Text>
      {!isChain && (
        <HStack>
          <MapPin size="18px" />
          <Text>{address}</Text>
        </HStack>
      )}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  user: getUser(state),
});

/* Export
============================================================================= */
export default connect(mapStateToProps)(RestaurantHeader);
