import moment from 'moment';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useFormikContext } from 'formik';

import { getRestaurant } from '../../../restaurant/redux/selectors';

/* =============================================================================
<ReservationFormInitialize />
============================================================================= */
const ReservationFormInitialize = ({ restaurant }) => {
  const { resetForm } = useFormikContext();
  const booking = restaurant?.booking;
  const capacity = booking?.capacity;
  const duration = booking?.duration;
  const interval = booking?.interval;
  const fee = booking?.fee;
  const seats = booking?.seats;
  const hours = booking?.hours;
  const hoursStr = JSON.stringify(hours);
  const events = booking?.events;
  const eventsStr = JSON.stringify(events);

  // Initialize form
  useEffect(() => {
    resetForm({
      values: {
        capacity,
        duration,
        fee,
        interval,
        seats,
        hours: hours.map((hour) => ({
          ...hour,
          start: moment().startOf('day').minutes(hour.start).format('HH:mm'),
        })),
        events,
      },
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    capacity,
    duration,
    interval?.min,
    interval?.max,
    fee,
    seats?.min,
    seats?.max,
    hoursStr,
    eventsStr,
  ]);

  return null;
};

const mapStateToProps = (state) => ({
  restaurant: getRestaurant(state),
});

/* Export
============================================================================= */
export default connect(mapStateToProps)(ReservationFormInitialize);
