import React from 'react';
import { Box, Divider } from '@chakra-ui/react';

import ReservationFormHoursAvailable from './ReservationFormHoursAvailable';
import ReservationFormHoursSpecial from './ReservationFormHoursSpecial';

/* =============================================================================
<ReservationFormHours />
============================================================================= */
const ReservationFormHours = () => (
  <Box pr={[0, 0, 0, '20%']}>
    <ReservationFormHoursAvailable />
    <Divider my={5} />
    <ReservationFormHoursSpecial />
  </Box>
);

/* Export
============================================================================= */
export default ReservationFormHours;
