import React from 'react';
import {
  Input,
  Divider,
  FormLabel,
  InputGroup,
  FormControl,
  InputLeftAddon,
  FormErrorMessage,
} from '@chakra-ui/react';
import { FastField } from 'formik';

/* =============================================================================
<EmployeeSalary />
============================================================================= */
const EmployeeSalary = () => (
  <>
    <Divider my={5} />
    <FastField name="salary">
      {({ field, form }) => (
        <FormControl flex={1} isInvalid={form.errors.salary && form.touched.salary}>
          <FormLabel fontWeight="normal">Salary</FormLabel>
          <InputGroup>
            <InputLeftAddon>Rs</InputLeftAddon>
            <Input id="salary" placeholder="Ex :20000" {...field} />
          </InputGroup>
          <FormErrorMessage>{form.errors.salary}</FormErrorMessage>
        </FormControl>
      )}
    </FastField>
  </>
);

/* Export
============================================================================= */
export default EmployeeSalary;
