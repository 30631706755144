import storage from 'redux-persist-indexeddb-storage';
import reduxThunk from 'redux-thunk';
import offlineConfig from '@redux-offline/redux-offline/lib/defaults/index';
import { createOffline } from '@redux-offline/redux-offline';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';

import rootReducer from './reducers';
import request from '../util/request';

const AppReducer = (state, action) => {
  if (action.type === 'AUTH/LOGOUT_SUCCESS') {
    return rootReducer(undefined, action);
  }
  return rootReducer(state, action);
};

const persistConfig = {
  key: 'root',
  storage: storage('@foodstreetDB'),
  blacklist: ['Orders'],
};

const effect = (_effect, _action) => request(_effect);
const discard = (error, _action, _retries) => {
  const { req, res } = error;
  if (!req) throw error; // There was an error creating the request
  if (!res) return false; // There was no response
  return res.status >= 400 && res.status < 500;
};

const {
  middleware: offlineMiddleware,
  enhanceReducer: offlineEnhanceReducer,
  enhanceStore: offlineEnhanceStore,
} = createOffline({
  ...offlineConfig,
  persist: false,
  effect,
  discard,
});

const persistedReducer = persistReducer(persistConfig, offlineEnhanceReducer(AppReducer));

export default () => {
  const store = createStore(
    persistedReducer,
    composeWithDevTools(
      offlineEnhanceStore,
      applyMiddleware(reduxThunk, offlineMiddleware),
    ),
  );
  const persistor = persistStore(store);
  return { store, persistor };
};
