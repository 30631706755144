import React from 'react';
import { connect } from 'react-redux';
import { Stack, Text } from '@chakra-ui/react';

import FoodStreetLogo from '../../assets/images/edit-foodStreet-logo.png';

import { getOrder } from '../../entities/redux/selectors';

/* =============================================================================
<OrderReceiptFooter />
============================================================================= */
const OrderReceiptFooter = ({ order }) => {
  const restaurant = order?.restaurant;
  const restaurantName = restaurant?.name || restaurant?.chain?.name;
  const restaurantPhone = restaurant?.phone?.delivery1;

  return (
    <Stack alignItems="center">
      <Text>
        <Text as="span" fontWeight="bold">{restaurantName}</Text>
      </Text>
      <Text>{restaurantPhone}</Text>
      <Text>{restaurant?.email}</Text>
      <Text>{FOODSTREET_EMAIL}</Text>
      <Text>{FOODSTREET_PHONE}</Text>
    </Stack>
  );
};

const FOODSTREET_EMAIL = 'www.foodstreetpk.com';
const FOODSTREET_PHONE = '021-35295229';

const mapStateToProps = (state, { id }) => ({
  order: getOrder(state, { id }),
});

const propsAreEqual = (prevProps, nextProps) => (
  prevProps.order?.restaurant?.name === nextProps.order?.restaurant?.name
  && prevProps.order?.restaurant?.chain?.name === nextProps.order?.restaurant?.chain?.name
  && prevProps.order?.restaurant?.address?.text === nextProps.order?.restaurant?.address?.text
  && prevProps.order?.restaurant?.phone?.delivery1 === nextProps.order?.restaurant?.phone?.delivery1
);

/* Export
============================================================================= */
export default connect(mapStateToProps)(React.memo(OrderReceiptFooter, propsAreEqual));
