import React from 'react';
import { connect } from 'react-redux';
import { Box, Flex } from '@chakra-ui/react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';

import PosNavHeader from '../components/PosNavHeader';
import NewOrderScreen from './NewOrderScreen';
import EditOrderScreen from './EditOrderScreen';
import TodayOrderScreen from './TodayOrderScreen';
import ReservationScreen from './ReservationScreen';
import OngoingOrderScreen from './OngoingOrderScreen';
import PosLiveOrderListener from '../components/PosLiveOrderListener';
import { getUser } from '../../auth/redux/selectors';
import { fromAdminPortal } from '../../util/functions';

/* =============================================================================
<PosScreens />
============================================================================= */
const PosScreens = ({user}) => {
  const fullScreen = useFullScreenHandle();

  return (
    <>
      <FullScreen handle={fullScreen}>
        <Box h="100%" bgColor="gray.100">
          <PosNavHeader fullScreen={fullScreen} />
          <Flex flexDir="column" mx={fromAdminPortal(user) ? 0 : 9}>
            <Switch>
              <Redirect from="/pos" to="/pos/new_order" exact />
              <Route path="/pos/new_order" exact>
                <NewOrderScreen isFullScreen={fullScreen.active} />
              </Route>
              <Route path="/pos/today_order" exact>
                <TodayOrderScreen />
              </Route>
              <Route path="/pos/ongoing_order" exact>
                <OngoingOrderScreen />
              </Route>
              <Route path="/pos/reservation" exact>
                <ReservationScreen />
              </Route>
              <Route path="/pos/order/:id" exact>
                <EditOrderScreen />
              </Route>
            </Switch>
          </Flex>
        </Box>
      </FullScreen>
      {!fromAdminPortal(user) && <PosLiveOrderListener />}
    </>
  );
};

const mapStateToProps = (state) => ({
  user: getUser(state)
})

/* Export
============================================================================= */
export default connect(mapStateToProps)(PosScreens);
