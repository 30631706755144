import React from 'react';
import { getIn, FastField } from 'formik';
import {
  Input,
  VStack,
  FormLabel,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
} from '@chakra-ui/react';

/* =============================================================================
<OwnerFormPhone />
============================================================================= */
const OwnerFormPhone = () => (
  <FastField name="owner.phone">
    {({ field, form }) => (
      <FormControl
        mb={8}
        display="flex"
        justifyContent="space-between"
        isInvalid={getIn(form.errors, 'owner.phone') && getIn(form.touched, 'owner.phone')}
      >
        <FormLabel flex={1.5}>Owner Phone</FormLabel>
        <VStack alignItems="flex-start" flex={2}>
          <Input
            {...field}
            id="owner.phone"
          />
          <FormErrorMessage>
            <FormErrorIcon />
            {getIn(form.errors, 'owner.phone')}
          </FormErrorMessage>
        </VStack>
      </FormControl>
    )}
  </FastField>
);

/* Export
============================================================================= */
export default OwnerFormPhone;
