import React from 'react';
import {
  Box,
  Text,
  HStack,
  Button,
  Divider,
} from '@chakra-ui/react';
import { useLocation } from 'react-router';

/* =============================================================================
<RestaurantInfoHeader />
============================================================================= */
const RestaurantInfoHeader = ({ isSubmitting }) => {
  const location = useLocation();
  const paths = location.pathname.split('/').slice(1);

  return (
    <>
      <HStack justifyContent="space-between">
        <Box>
          <Text fontSize="lg" fontWeight="semibold">Restaurant Information</Text>
          <Text fontSize="sm">Update your restaurant information details here.</Text>
        </Box>
        <Button type="submit" isLoading={isSubmitting}>
          {paths === 'setup' ? 'Continue' : 'Save Changes'}
        </Button>
      </HStack>
      <Divider my={5} />
    </>
  );
};

/* Export
============================================================================= */
export default RestaurantInfoHeader;
