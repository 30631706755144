import React from 'react';
import { connect } from 'react-redux';
import {
  Tr, Td, Checkbox, Input, Button,
} from '@chakra-ui/react';

import { getNeighborhood } from '../../../entities/redux/selectors';

/* =============================================================================
<NeighborhoodsTableItem  />
============================================================================= */
const NeighborhoodsTableItem = ({
  id,
  fee,
  isSelected,
  neighborhood,
  onSelect,
  onUnselect,
  onFeeChange,
  onViewClick,
}) => {
  const name = neighborhood ? neighborhood.name : '';
  const town = neighborhood ? neighborhood.area : '';
  const available = neighborhood?.available;

  const _handleCheckboxChange = () => {
    if (available) {
      if (isSelected) {
        onUnselect(id);
      } else {
        onSelect(id);
      }
    }
  };

  const _handleDeliveryFeeChange = (event) => {
    if (available) {
      onFeeChange(id, event.target.value);
    }
  };

  const _handleViewClick = () => {
    onViewClick(id);
  };

  return (
    <Tr color={available ? 'gray.900' : 'gray.500'} bgColor={available ? 'white' : 'red.50'}>
      <Td>
        <Checkbox
          disabled={!available}
          isChecked={isSelected}
          onChange={_handleCheckboxChange}
        />
      </Td>
      <Td>{name}</Td>
      <Td>{town}</Td>
      <Td>
        <Input
          type="number"
          value={fee}
          disabled={!isSelected}
          onChange={_handleDeliveryFeeChange}
        />
      </Td>
      <Td>
        <Button variant="link" onClick={_handleViewClick}>
          View
        </Button>
      </Td>
    </Tr>
  );
};

const mapStateToProps = (state, { id }) => ({
  neighborhood: getNeighborhood(state, { id }),
});

const propsAreEqual = (prevProps, nextProps) => (
  prevProps.id === nextProps.id
  && prevProps.fee === nextProps.fee
  && prevProps.isSelected === nextProps.isSelected
  && prevProps.neighborhood?.name === nextProps.neighborhood?.name
  && prevProps.neighborhood?.town === nextProps.neighborhood?.town
  && prevProps.neighborhood?.district === nextProps.neighborhood?.district
);

/* Export
============================================================================= */
export default connect(
  mapStateToProps,
)(React.memo(NeighborhoodsTableItem, propsAreEqual));
