import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useFormikContext } from 'formik';

import { getInventory } from '../../../../entities/redux/selectors';

/* =============================================================================
<InventoryEditModalInitialize />
============================================================================= */
const InventoryEditModalInitialize = ({ inventory }) => {
  const { resetForm } = useFormikContext();

  const name = inventory?.name;
  const unit = inventory?.unit;
  const amount = inventory?.amount;
  const price = inventory?.price;

  // Set default values
  useEffect(() => {
    resetForm({
      values: {
        name,
        unit,
        amount,
        price,
      },
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    name,
    unit,
    amount,
    price,
  ]);

  return null;
};

const mapStateToProps = (state, { id }) => ({
  inventory: getInventory(state, { id, normalize: true }),
});

/* Export
============================================================================= */
export default connect(mapStateToProps)(InventoryEditModalInitialize);
