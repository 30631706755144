import React from 'react';
import {
  Input,
  HStack,
  FormLabel,
  InputGroup,
  FormControl,
  FormErrorIcon,
  InputLeftAddon,
  FormErrorMessage,
} from '@chakra-ui/react';
import { FastField } from 'formik';

/* =============================================================================
<OrderSetupAddReservationNameAndPhone />
============================================================================= */
const OrderSetupAddReservationNameAndPhone = () => (
  <HStack mb={8} spacing={4} alignItems="flex-start">
    <FastField name="name">
      {({ field, form }) => (
        <FormControl isInvalid={form.errors.name && form.touched.name}>
          <FormLabel>Customer Name</FormLabel>
          <Input {...field} placeholder="Enter Name" />
          <FormErrorMessage>
            <FormErrorIcon />
            {form.errors.name}
          </FormErrorMessage>
        </FormControl>
      )}
    </FastField>
    <FastField name="phone">
      {({ field, form }) => (
        <FormControl isInvalid={form.errors.phone && form.touched.phone}>
          <FormLabel>Phone</FormLabel>
          <InputGroup>
            <InputLeftAddon>+92</InputLeftAddon>
            <Input id="phone" placeholder="Ex: (487) 739-5517" {...field} />
          </InputGroup>
          <FormErrorMessage>
            <FormErrorIcon />
            {form.errors.phone}
          </FormErrorMessage>
        </FormControl>
      )}
    </FastField>
  </HStack>
);

/* Export
============================================================================= */
export default OrderSetupAddReservationNameAndPhone;
