import React, { useEffect, useState } from 'react';
import {
  HStack,
  Heading,
  Divider,
} from '@chakra-ui/react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';

import HeaderMenu from './HeaderMenu/index';
import HeaderLinkButton from './HeaderLinkButton';
import HeaderSideNavButton from './HeaderSideNavButton';
import { ReactComponent as AppNameIcon } from '../../assets/icons/app-name-icon.svg';
import { ReactComponent as AppLogoIcon } from '../../assets/icons/app-logo-icon.svg';
import NAV_LINKS from '../../static/nav_links';

import { getOrdersByStatus } from '../../orders/redux/selectors';

/* =============================================================================
<Header />
============================================================================= */
const Header = ({ orderCount }) => {
  const location = useLocation();
  const [pathName, setPathName] = useState();
  const paths = location.pathname.split('/').slice(1)[0];

  useEffect(() => {
    NAV_LINKS.map((link) => {
      if (link.to === `/${paths}`) {
        return setPathName(link.title);
      }
      return null;
    });
    if (paths === 'notifications') {
      setPathName('Notifications');
    }
    if (paths === 'settings') {
      setPathName('Settings');
    }
  }, [paths]);

  return (
    <HStack
      p={[3, 3, 4]}
      w="100%"
      pos="fixed"
      zIndex={5}
      bgColor="white"
      alignItems="center"
      justifyContent="space-between"
      boxShadow="0 1px 2px 0 rgba(0,0,0,0.1)"
    >
      <HStack>
        {paths === 'pos' ? (
          <HStack>
            <AppLogoIcon />
            <AppNameIcon />
          </HStack>
        ) : (
          <>
            <HeaderSideNavButton orderCount={orderCount} />
            <Heading
              pt={[1, 1, 0]}
              pl={[0, 0, 20]}
              fontWeight="600"
              fontSize={['lg', 'xl', '2xl']}
            >
              {pathName}
            </Heading>
          </>
        )}
      </HStack>
      <HStack>
        <HeaderLinkButton />
        <HeaderMenu />
      </HStack>
    </HStack>
  );
};
const mapStateToProps = (state) => ({
  orderCount: getOrdersByStatus(state, { status: 'pending' })?.length,
});

const propsAreEqual = (prevProps, nextProps) => prevProps.orderCount === nextProps.orderCount;

/* Export
============================================================================= */
export default connect(mapStateToProps)(React.memo(Header, propsAreEqual));
