import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Wrapper } from '@googlemaps/react-wrapper';
import { Box, Text } from '@chakra-ui/react';

import MapView from '../../../common/MapView';
import MapMarker from '../../../common/MapMarker';
import { GOOGLE_API_KEY } from '../../../config/secrets';

import { getBranchesWithDetails, getRestaurant } from '../../redux/selectors';

/* =============================================================================
<RestaurantInfoBranches />
============================================================================= */
const RestaurantInfoBranches = ({ restaurant, branches }) => {
  const [bounds, setBounds] = useState(null);
  const locations = branches?.map((branch) => branch.location);
  const locationsStr = JSON.stringify(locations);

  // Center map around coordinates
  useEffect(() => {
    setBounds(locations);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationsStr]);

  if (restaurant?.type !== 'chain') {
    return null;
  }

  return (
    <Box flex={1.5} p={4} borderRadius="8px" border="1px solid #D0D5DD">
      <Text fontWeight="medium">Restaurant Branches</Text>
      <Box my={2} height="230px" borderRadius="md" overflow="hidden">
        <Wrapper apiKey={GOOGLE_API_KEY} render={render}>
          <MapView bounds={bounds}>
            {locations?.map((location, i) => (
              <MapMarker
                key={i}
                position={{
                  lat: location[1],
                  lng: location[0],
                }}
              />
            ))}
          </MapView>
        </Wrapper>
      </Box>
    </Box>
  );
};

const render = (status) => <div>{status}</div>;

const mapStateToProps = (state) => ({
  restaurant: getRestaurant(state),
  branches: getBranchesWithDetails(state),
});

// eslint-disable-next-line max-len
const propsAreEqual = (prevProps, nextProps) => prevProps.restaurant?.type === nextProps.restaurant?.type
 && JSON.stringify(prevProps.branches?.map((b) => b.location))
 === JSON.stringify(nextProps.branches?.map((b) => b.location));

/* Export
============================================================================= */
export default connect(mapStateToProps)(React.memo(RestaurantInfoBranches, propsAreEqual));
