/**
 * Get error
 */
export const getError = (state) => state.Reports.error;

/**
 * Get loading
 */
export const getLoading = (state) => state.Reports.loading;

/**
 * Get reports
 */
export const selectReports = (state) => state.Reports.reports;

/**
 * Get params
 */
export const selectParams = (state) => state.Reports.params;
