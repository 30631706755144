import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Draggable } from 'react-beautiful-dnd';
import { MoreVertical, Trash } from 'react-feather';
import {
  Box,
  Text,
  Image,
  HStack,
  Spinner,
  ListItem,
} from '@chakra-ui/react';

import { getDish } from '../../../entities/redux/selectors';

/* =============================================================================
<MenuFeaturedItemsItem />
============================================================================= */
const MenuFeaturedItemsItem = ({
  id,
  index,
  dish,
  onDelete,
}) => {
  const [deleting, setDeleting] = useState(false);

  const name = dish?.name || '';
  const about = dish?.about || '';
  const photo = dish?.photos && dish.photos[0];
  const price = dish?.variants?.length ? dish.variants[0]?.price : dish?.price;

  const _handleDeleteClick = async (e) => {
    e.stopPropagation();
    setDeleting(true);
    await onDelete(e, id);
    setDeleting(false);
  };

  return (
    <Draggable draggableId={id} index={index}>
      {(provided) => (
        <ListItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          style={provided.draggableProps.style}
          mb={4}
          bg="white"
          display="flex"
          height={height}
          overflow="hidden"
          borderRadius="lg"
          alignItems="center"
          border="1px solid #E4E7EC"
          justifyContent="space-between"
        >
          <HStack flex={1} px={6}>
            <Box {...provided.dragHandleProps}>
              <MoreVertical size={20} />
            </Box>
            <Box flex={1} pl={5}>
              <Text fontWeight="semibold">{name}</Text>
              <Text my="4px" fontSize="sm">{about}</Text>
              <Text fontWeight="semibold">
                {price}
                {' '}
                Rs
              </Text>
            </Box>
            {deleting ? (
              <Spinner size="sm" />
            ) : (
              <Trash size={14} onClick={_handleDeleteClick} />
            )}
          </HStack>
          <Image w="auto" h="100%" src={photo} />
        </ListItem>
      )}
    </Draggable>
  );
};

const height = '146px';

const mapStateToProps = (state, { id }) => ({
  dish: getDish(state, { id, normalize: true }),
});

const propsAreEqual = (prevProps, nextProps) => prevProps.id === nextProps.id
  && prevProps.index === nextProps.index
  && prevProps.menuId === nextProps.menuId
  && prevProps.dish?.name === nextProps.dish?.name
  && prevProps.dish?.about === nextProps.dish?.about
  && prevProps.dish?.price === nextProps.dish?.price
  && prevProps.dish?.photos?.toString() === nextProps.dish?.photos?.toString()
  // eslint-disable-next-line max-len
  && (prevProps.dish?.variants && prevProps.dish?.variants[0]?.price) === (nextProps.dish?.variants && nextProps.dish?.variants[0]?.price);

/* Export
============================================================================= */
export default connect(mapStateToProps)(React.memo(MenuFeaturedItemsItem, propsAreEqual));
