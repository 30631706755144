import React from 'react';
import { connect } from 'react-redux';
import { Form, Formik } from 'formik';
import {
  Modal,
  Button,
  HStack,
  Divider,
  ModalBody,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  useToast,
} from '@chakra-ui/react';

import DishName from '../DishName';
import DishPhoto from '../DishPhoto';
// import AddNewInventoryItems from '../AddNewInventoryItems';
import DishDescription from '../DishDescription';
import DishChoiceGroups from '../DishChoiceGroups';
import DishPricesAndVariants from '../DishPricesAndVariants/index';

import { createDish as createDishAction } from '../../../redux/actions';
import { uploadDishPhoto } from '../../../../util/upload';
import { DishSchema } from '../schema';

/* =============================================================================
<CreateDishModal />
============================================================================= */
const CreateDishModal = ({
  isOpen,
  menuItemId,
  onClose,
  createDish,
}) => {
  const toast = useToast();

  // Note: There's a different submit handler for edit
  const _handleSubmit = async (values) => {
    const firstVariantPrice = values.variants?.[0]?.price;
    const payload = {
      ...values,
      price: firstVariantPrice || values.price || null,
      menuItem: menuItemId,
    };

    if (values.photo) {
      const photoURL = await uploadDishPhoto(values.photo);
      payload.photo = photoURL;
    }

    await createDish(payload, (err) => {
      if (!err) {
        toast({
          title: 'Menu item created',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        onClose();
      } else {
        toast({
          title: err.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    });
  };

  return (
    <Modal size="3xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="lg">Add new Food</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Divider mb={5} />
          <Formik
            initialValues={INITIAL_VALUES}
            validationSchema={DishSchema}
            onSubmit={_handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <DishPhoto />
                <DishName />
                <DishDescription />
                <DishPricesAndVariants />
                <DishChoiceGroups />
                {/* <AddNewInventoryItems /> */}
                <HStack mb={2} spacing={4}>
                  <Button
                    flex={1}
                    colorScheme="gray"
                    variant="outline"
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" flex={1} isLoading={isSubmitting}>
                    Create item
                  </Button>
                </HStack>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const INITIAL_VALUES = {
  name: '',
  about: '',
  photo: undefined,
  category: '',
  price: '',
  variants: [],
  choiceGroups: [],
  stock: [],
};

const mapDispatchToProps = {
  createDish: createDishAction,
};

/* Export
============================================================================= */
export default connect(null, mapDispatchToProps)(CreateDishModal);
