import React from 'react';
import { Box } from '@chakra-ui/react';
import { connect } from 'react-redux';

// import RestaurantFormName from './RestaurantFormName';
import RestaurantFormPhoto from './RestaurantFormPhoto';
import RestaurantFormAbout from './RestaurantFormAbout';
import RestaurantFormAddress from './RestaurantFormAddress';
import RestaurantFormPhone from './RestaurantFormPhone';
import RestaurantFormEmail from './RestaurantFormEmail';
import RestaurantFormDeliveryTime from './RestaurantFormDeliveryTime';
import RestaurantFormMinOrder from './RestaurantFormMinOrder';
import RestaurantFormCouponAcceptability from './RestaurantFormCouponAcceptability';

import { getRestaurant } from '../../../restaurant/redux/selectors';

/* =============================================================================
<RestaurantForm />
============================================================================= */
const RestaurantForm = ({ isBranch }) => (
  <Box pr={[0, 0, 0, 36]}>
    {/* <RestaurantFormName disabled={isBranch} /> */}
    <RestaurantFormPhoto disabled={isBranch} />
    <RestaurantFormAbout disabled={isBranch} />
    <RestaurantFormAddress />
    <RestaurantFormPhone />
    <RestaurantFormEmail disabled={isBranch} />
    <RestaurantFormDeliveryTime disabled={isBranch} />
    <RestaurantFormMinOrder disabled={isBranch} />
    <RestaurantFormCouponAcceptability disabled={isBranch} />
  </Box>
);

const mapStateToProps = (state) => ({
  isBranch: getRestaurant(state)?.type === 'branch',
});

const propsAreEqual = (prevProps, nextProps) => prevProps.isBranch === nextProps.isBranch;

/* Export
============================================================================= */
export default connect(mapStateToProps)(React.memo(RestaurantForm, propsAreEqual));
