import { useReactToPrint } from 'react-to-print';
import getCircularReplacer from './json-circular';

export default function usePrint({contentEl, order}) {
  const webPrint = useReactToPrint({
    content: contentEl,
  });

  if (window.ReactNativeWebView) {
    return () => {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ order }, getCircularReplacer())
      );
    };
  }
  return webPrint;
}
