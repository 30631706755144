import React from 'react';
import { connect } from 'react-redux';
import { Box, HStack, Text } from '@chakra-ui/react';

import { getOrder } from '../../entities/redux/selectors';

/* =============================================================================
<OrderReceiptCustomerDetails />
============================================================================= */
const OrderReceiptCustomerDetails = ({ order }) => {
  if (!order?.customer && !order?.meta) {
    return null;
  }

  const hasCustomer = Boolean(order?.customer);
  const name = hasCustomer
    ? `${order?.customer?.profile.firstName} ${order?.customer?.profile.lastName}`
    : order?.meta?.name;
  const phone = hasCustomer ? order?.customer?.phone : order?.meta?.phone;

  return (
    <Box my={1}>
      <HStack my={2} justify="space-between">
        <Text>Name :</Text>
        <Text>{name}</Text>
      </HStack>
      {phone && (
        <HStack my={2} justify="space-between">
          <Text>Phone :</Text>
          <Text>{phone}</Text>
        </HStack>
      )}
    </Box>
  );
};

const mapStateToProps = (state, { id }) => ({
  order: getOrder(state, { id }),
});

const propsAreEqual = (prevProps, nextProps) => (
  prevProps.order?.meta?.name === nextProps.order?.meta?.name
  && prevProps.order?.meta?.phone === nextProps.order?.meta?.phone
  && prevProps.order?.customer?.phone === nextProps.order?.customer?.phone
  && prevProps.order?.customer?.profile?.lastName === nextProps.order?.customer?.profile?.lastName
  && prevProps.order?.customer?.profile?.firstName === nextProps.order?.customer?.profile?.firstName
);

/* Export
============================================================================= */
export default connect(mapStateToProps)(React.memo(OrderReceiptCustomerDetails, propsAreEqual));
