import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { normalize } from 'normalizr';
import debounce from 'lodash/debounce';
import {
  Modal,
  Input,
  Divider,
  Spinner,
  FormLabel,
  ModalBody,
  InputGroup,
  FormControl,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  InputRightElement,
  useToast,
} from '@chakra-ui/react';

import AddFeaturedItemModalItem from './AddFeaturedItemModalItem';
import AddFeaturedItemModalFooter from './AddFeaturedItemModalFooter';
import { dish as dishSchema } from '../../../entities/api/schema';
import request from '../../../util/request';

import { getMenu as selectedMenu } from '../../../entities/redux/selectors';
import { updateMenu as updateMenuAction } from '../../redux/actions';
import { addEntities as addEntitiesAction } from '../../../entities/redux/actions';

/* =============================================================================
<AddFeaturedItemModal />
============================================================================= */
const AddFeaturedItemModal = ({
  menuId,
  menu,
  isOpen,
  onClose,
  updateMenu,
  addEntities,
}) => {
  const toast = useToast();
  const [query, setQuery] = useState('');
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState();
  const [searching, setSearching] = useState(false);
  const [selected, setSelected] = useState([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const _searchDishes = useCallback(
    debounce((text) => {
      (async () => {
        setSearching(true);
        try {
          const payload = await request({
            url: '/dishes/find',
            method: 'GET',
            params: {
              q: text,
            },
          });
          const { entities, result } = normalize(payload.data, [dishSchema]);

          addEntities(entities);
          setItems(result);
        } catch (e) {
          // TODO
        }
        setSearching(false);
      })();
    }, 400),
    [],
  );

  const _handleChange = (event) => {
    setQuery(event.target.value);
    _searchDishes(event.target.value);
  };

  const _handleSelect = (e, id) => {
    setSelected((prevState) => [...prevState, id]);
  };

  const _handleUnselect = (e, id) => {
    setSelected((prevState) => prevState.filter((item) => item !== id));
  };

  const _handleSubmit = async () => {
    if (!selected?.length) {
      return;
    }

    setLoading(true);
    const payload = {
      id: menuId,
      featured: [
        ...new Set([
          ...(menu?.featured || []),
          ...selected,
        ]),
      ],
    };
    await updateMenu(payload, (err) => {
      if (!err) {
        toast({
          title: 'Featured item added',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        onClose();
      } else {
        toast({
          title: err.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    });
    setLoading(false);
  };

  return (
    <Modal size="3xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="lg">Add Featured Items</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Divider mb={5} />
          <FormControl mb={8}>
            <FormLabel flex={2.1}>Search Food</FormLabel>
            <InputGroup>
              <Input
                name="searchFood"
                value={query}
                onChange={_handleChange}
              />
              {searching && (
                <InputRightElement>
                  <Spinner size="sm" />
                </InputRightElement>
              )}
            </InputGroup>
          </FormControl>
          {items.map((item) => (
            <AddFeaturedItemModalItem
              id={item}
              key={item}
              menuId={menuId}
              selected={selected.includes(item)}
              onSelect={_handleSelect}
              onUnselect={_handleUnselect}
            />
          ))}
          <AddFeaturedItemModalFooter
            isSubmitting={loading}
            onCancel={onClose}
            onSubmit={_handleSubmit}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const mapStateToProps = (state, { menuId }) => ({
  menu: selectedMenu(state, { id: menuId, normalize: true }),
});

const mapDispatchToProps = {
  updateMenu: updateMenuAction,
  addEntities: addEntitiesAction,
};

const propsAreEqual = (prevProps, nextProps) => prevProps.isOpen === nextProps.isOpen
    && prevProps.menuId === nextProps.menuId
    && prevProps.menu?.featured?.toString() === nextProps.menu?.featured?.toString();

/* Export
============================================================================= */
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(AddFeaturedItemModal, propsAreEqual));
