import { normalize } from 'normalizr';
import * as constants from './constants';

import request from '../../util/request';
import { addEntities } from '../../entities/redux/actions';
import { order as orderSchema } from '../../entities/api/schema';
import { getStatus, getType } from './selectors';

/**
 * TYPE_SET
 */
export const setType = (payload) => ({
  type: constants.TYPE_SET,
  payload,
});

/**
 * TYPE_SET
 */
export const setStatus = (payload) => ({
  type: constants.STATUS_SET,
  payload,
});

/**
 * ORDERS_GET
 */
export const getOrders = (type, status, nextCursor, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.ORDERS_GET.REQUEST });

    const payload = await request({
      url: '/orders',
      method: 'GET',
      params: {
        type,
        status,
        next_cursor: nextCursor,
      },
    });

    const { entities, result } = normalize(payload.data, [orderSchema]);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.ORDERS_GET.SUCCESS,
      payload: {
        data: result,
        nextCursor: payload.next_cursor,
      },
    });
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.ORDERS_GET.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.ORDERS_GET.COMPLETE });
  }
};
/**
 * ORDERS_REFRESH
 */
export const refreshOrders = (type, status, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.ORDERS_REFRESH.REQUEST });

    const payload = await request({
      url: '/orders',
      method: 'GET',
      params: {
        type,
        status,
      },
    });

    const { entities, result } = normalize(payload.data, [orderSchema]);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.ORDERS_REFRESH.SUCCESS,
      payload: {
        data: result,
        nextCursor: payload.next_cursor,
      },
    });
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.ORDERS_REFRESH.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.ORDERS_REFRESH.COMPLETE });
  }
};
