import React from 'react';
import { connect } from 'react-redux';
import {
  Tr,
  Td,
  HStack,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import { Info } from 'react-feather';

import OrderDetailsModal from '../../../../common/OrderDetailsModal/index';
import OrdersCompletedActions from '../../../../orders/components/OrdersCompleted/OrdersCompletedActions';
import OrderHistoryTableItemCustomer from './OrderHistoryTableItemCustomer';
import OrderHistoryTableItemCompletedTime from './OrderHistoryTableItemCompletedTime';

import { getOrder } from '../../../../entities/redux/selectors';

/* =============================================================================
<OrderHistoryTableItem />
============================================================================= */
const OrderHistoryTableItem = ({ id, order }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const amount = order?.payment?.amount;
  // const itemsCount = order?.items.length;
  const walletAmount = order?.payment?.wallet;
  const paymentMethod = order?.payment?.method;
  const orderPaymentMethod = paymentMethod === 'card' || walletAmount === amount
    ? 'Online'
    : 'Cash';

  return (
    <Tr>
      <OrderDetailsModal
        id={id}
        footer={<OrdersCompletedActions id={id} />}
        isOpen={isOpen}
        onClose={onClose}
      />
      <OrderHistoryTableItemCustomer id={id} />
      <Td>{order?.tag}</Td>
      <Td textTransform="uppercase">{orderPaymentMethod}</Td>
      <Td>{`Rs. ${amount}`}</Td>
      <OrderHistoryTableItemCompletedTime id={id} />
      <Td>
        <HStack>
          <Button
            variant="outline"
            colorScheme="gray"
            borderRadius="8px"
            leftIcon={<Info size="16px" />}
            onClick={onOpen}
          >
            Detail
          </Button>
        </HStack>
      </Td>
    </Tr>
  );
};

const mapStateToProps = (state, { id }) => ({
  order: getOrder(state, { id }),
});

// eslint-disable-next-line max-len
const propsAreEqual = (prevProps, nextProps) => prevProps.order?.id === nextProps.order?.id
  && prevProps.order?.items === nextProps.order?.items
  && prevProps.order?.payment === nextProps.order?.payment;

/* Export
============================================================================= */
export default connect(mapStateToProps)(
  React.memo(OrderHistoryTableItem, propsAreEqual),
);
