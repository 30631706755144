import React from 'react';
import { Box, HStack, Text } from '@chakra-ui/react';

/* =============================================================================
<OrderReceiptItemsListItemExtraChoices />
============================================================================= */
const OrderReceiptItemsListItemExtraChoices = ({ option }) => (
  <HStack ml={9} justify="space-between">
    <Text flex={1}>
      {option?.name}
      :
      {' '}
      {option?.values
        ?.map((value) => value)
        ?.join(', ')}

    </Text>
    <Text flex={1} align="right">
      Rs.
      {option?.price}
    </Text>
  </HStack>
);

/* Export
============================================================================= */
export default OrderReceiptItemsListItemExtraChoices;
