import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Tr,
  Box,
  Table,
  Thead,
  Tbody,
} from '@chakra-ui/react';

import ExtraChoicesHeader from './ExtraChoicesHeader';
import ExtraChoicesTableItem from './ExtraChoicesTableItem';
import ExtraChoicesTableHeadItem from './ExtraChoicesTableHeadItem';

import { getChoiceGroups as selectChoiceGroups } from '../../redux/selectors';
import { getChoiceGroups as getChoiceGroupsActions } from '../../redux/actions';

/* =============================================================================
<ExtraChoices />
============================================================================= */
const ExtraChoices = ({ choiceGroups, getChoiceGroups }) => {
  // Get choice groups
  useEffect(() => {
    getChoiceGroups();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box flex={4} px={4}>
      <ExtraChoicesHeader />
      <Table variant="unstyled">
        <Thead>
          <Tr>
            {COLUMNS.map(renderColumn)}
          </Tr>
        </Thead>
        <Tbody>
          {choiceGroups.map(renderItem)}
        </Tbody>
      </Table>
    </Box>
  );
};

const renderColumn = (item, i) => <ExtraChoicesTableHeadItem key={item} title={item} />;
const renderItem = (item) => <ExtraChoicesTableItem key={item} id={item} />;

const COLUMNS = [
  'Choice Groups',
  'Available Choices',
  'Unavailable Choices',
  'Action',
];

const mapStateToProps = (state) => ({
  choiceGroups: selectChoiceGroups(state),
});

const mapDispatchToProps = {
  getChoiceGroups: getChoiceGroupsActions,
};

// eslint-disable-next-line max-len
const propsAreEqual = (prevProps, nextProps) => prevProps.choiceGroups.toString() === nextProps.choiceGroups.toString();

/* Export
============================================================================= */
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(ExtraChoices, propsAreEqual));
