import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { nanoid } from 'nanoid';
import { useHistory } from 'react-router-dom';
import { Button, Divider, useToast } from '@chakra-ui/react';

import Card from '../../../common/Card';
import OrderSetupTotal from './OrderSetupTotal';
import OrderSetupError from './OrderSetupError';
import OrderSetupHeader from './OrderSetupHeader';
import OrderSetupDetails from './OrderSetupDetails';
import OrderSetupTypeSelect from './OrderSetupTypeSelect';
import OrderSetupDineInInfo from './OrderSetupDineInInfo';
import OrderSetupCustomerInfo from './OrderSetupCustomerInfo';
import OrderSetupPaymentMethod from './OrderSetupPaymentMethod';
import OrderSetupDeliveryFeeSelect from './OrderSetupDeliveryFeeSelect';

import { getOrder } from '../../../entities/redux/selectors';
import { getRestaurant } from '../../../restaurant/redux/selectors';
import {
  getWaiters as getWaitersAction,
  placeOrder as placeOrderAction,
  updateOrder as updateOrderAction,
} from '../../redux/actions';
import {
  getAreas as getAreasAction,
  getTables as getTablesAction,
} from '../../../management/redux/actions';
import OrderSetupOnlinePaymentType from './OrderSetupOnlinePaymentType';
import OrderSetupComments from './OrderSetupComment';
import OrderSetupPosDiscount from './OrderSetupPosDiscount';
import { getUser } from '../../../auth/redux/selectors';
import { fromAdminPortal } from '../../../util/functions';

const ONLINE_PAYMENT_TYPES = ['card', 'room', 'btc', 'official', 'member'];

/* =============================================================================
<OrderSetup />
============================================================================= */
const OrderSetup = ({
  user,
  items,
  order,
  restaurant,
  isFullScreen,
  setItems,
  getAreas,
  getTables,
  getWaiters,
  placeOrder,
  updateOrder,
}) => {
  const history = useHistory();
  const toast = useToast();
  const [type, setType] = useState('take_away');
  const [deliveryFee, setDeliveryFee] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('cod');
  const [area, setArea] = useState('');
  const [table, setTable] = useState('');
  const [waiter, setWaiter] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [comment, setComment] = useState('');
  const [posDiscountPerc, setPosDiscountPerc] = useState(0);

  const vatExclusive =
    restaurant?.vatExclusive || restaurant?.chain?.vatExclusive;
  const vatPercentage = restaurant?.vat || restaurant?.chain?.vat || 0;
  const subtotal = +items
    ?.map(
      item =>
        (item.price +
          (item.options
            ?.map(option => option.price || 0)
            .reduce((a, b) => a + b, 0) || 0)) *
        item.qty
    )
    ?.reduce((a, b) => a + b, 0);
  const discount = +items
    ?.map(item => (+item.discount || 0) * item.qty)
    ?.reduce((a, b) => a + b, 0);

  const totalWithDiscount = Number(subtotal - discount);
  const vat = Number(
    vatExclusive
    ? (totalWithDiscount * vatPercentage) / 100
    : totalWithDiscount - totalWithDiscount / (1 + vatPercentage / 100)
    );
  const subtotalWithTax = vatExclusive ? subtotal : subtotal - vat - discount;
  const totalWithTax = vatExclusive ? totalWithDiscount + vat : totalWithDiscount;
  const posDiscount = totalWithTax * posDiscountPerc / 100;
  const totalWithPosDiscount = Number(totalWithTax - posDiscount);
  const totalWithTaxAndDelivery = totalWithPosDiscount - (Number(deliveryFee) || 0);

  // Initialize Form
  useEffect(() => {
    if (order) {
      setType(order?.type);
      setDeliveryFee(order?.payment?.deliveryFee);
      setPaymentMethod(order?.payment?.method);
      setComment(order?.payment?.comment);
      setPosDiscountPerc(order?.payment?.posDiscountPerc || 0);
      setArea(order?.table?.area?.id);
      setTable(order?.table?.id);
      setWaiter(order?.waiter?.id);
      setName(order?.meta?.name);
      setPhone(order?.meta?.phone?.slice(3));
      setAddress(order?.address?.text);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Boolean(order)]);

  // Get areas, tables and waiters
  useEffect(() => {
    getAreas();
    getTables();
    getWaiters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _handlePlaceOrderClick = async () => {
    if (!items?.length) {
      toast({
        title: 'Cart is empty!',
        duration: 3000,
        status: 'error',
        isClosable: true,
      });
      return;
    }
    if (type === 'delivery' && !name) {
      toast({
        title: 'Customer name is required!',
        duration: 3000,
        status: 'error',
        isClosable: true,
      });
      return;
    }
    if (type === 'delivery' && !phone) {
      toast({
        title: 'Phone number is required!',
        duration: 3000,
        status: 'error',
        isClosable: true,
      });
      return;
    }
    if (type === 'delivery' && !address) {
      toast({
        title: 'Address is required!',
        duration: 3000,
        status: 'error',
        isClosable: true,
      });
      return;
    }
    if (type === 'delivery' && !deliveryFee) {
      toast({
        title: 'Delivery area is required!',
        duration: 3000,
        status: 'error',
        isClosable: true,
      });
      return;
    }
    if (type === 'dine_in' && !table) {
      toast({
        title: 'Table is required!',
        duration: 3000,
        status: 'error',
        isClosable: true,
      });
      return;
    }
    if (type === 'dine_in' && !waiter) {
      toast({
        title: 'Waiter is required!',
        duration: 3000,
        status: 'error',
        isClosable: true,
      });
      return;
    }

    if (order) {
      // Update order
      await updateOrder({
        uid: order?.uid,
        tag: order?.tag,
        type,
        table,
        waiter,
        name,
        phone: phone && `+92${phone}`,
        address,
        comment,
        items: items.map(item => ({
          ...item,
          dish: item.dish?.id || item.dish,
        })),
        vat: vatExclusive ? vat : 0,
        restaurant: restaurant.id,
        deliveryFee,
        posDiscount,
        paymentMethod,
        posDiscountPerc,
        offline: {
          discount,
          posDiscount,
          posDiscountPerc,
          subtotal: subtotalWithTax,
          total: totalWithTaxAndDelivery,
        },
      });
      history.push('/pos/ongoing_order');
    } else {
      // Place order
      await placeOrder({
        uid: nanoid(),
        tag: nanoid(8),
        type,
        table,
        waiter,
        name,
        phone: phone && `+92${phone}`,
        address,
        comment,
        items,
        vat: vatExclusive ? vat : 0,
        restaurant: restaurant.id,
        deliveryFee,
        posDiscount,
        paymentMethod,
        posDiscountPerc,
        fromAdminPortal: !!fromAdminPortal(user),
        createdAt: new Date(),
        offline: {
          discount,
          posDiscount,
          posDiscountPerc,
          subtotal: subtotalWithTax,
          total: totalWithTaxAndDelivery,
        },
      });

      // Reset form
      setDeliveryFee('');
      setPaymentMethod('cod');
      setComment('');
      setArea('');
      setTable('');
      setWaiter('');
      setName('');
      setPhone('');
      setAddress('');
      setItems([]);
    }
  };

  return (
    <Card
      flex={2}
      p={6}
      maxHeight={fromAdminPortal(user) ? "100%" : isFullScreen ? '85vh' : '70vh'}
      overflowY="scroll"
    >
      <OrderSetupHeader />
      <Divider my={5} />
      <OrderSetupTypeSelect value={type} onChange={setType} />
      <Divider my={5} />
      {type === 'delivery' && (
        <>
          <OrderSetupDeliveryFeeSelect
            value={deliveryFee}
            onChange={setDeliveryFee}
          />
          <Divider my={5} />
        </>
      )}
      {type === 'dine_in' && (
        <>
          <OrderSetupDineInInfo
            area={area}
            table={table}
            waiter={waiter}
            onAreaChange={e => {
              setArea(e);
              setTable('');
            }}
            onTableChange={setTable}
            onWaiterChange={setWaiter}
          />
          <Divider my={5} />
        </>
      )}
      <OrderSetupCustomerInfo
        type={type}
        name={name}
        phone={phone}
        address={address}
        onNameChange={setName}
        onPhoneChange={setPhone}
        onAddressChange={setAddress}
      />
      <OrderSetupComments comment={comment} onChange={setComment} />
      <OrderSetupPaymentMethod
        value={
          ONLINE_PAYMENT_TYPES.includes(paymentMethod) ? 'card' : paymentMethod
        }
        onChange={setPaymentMethod}
      />
      {ONLINE_PAYMENT_TYPES.includes(paymentMethod) && (
        <OrderSetupOnlinePaymentType
          onlinePayType={paymentMethod}
          onSelect={setPaymentMethod}
        />
      )}
      <Divider my={5} />
      {items?.length > 0 && (
        <>
          <OrderSetupDetails items={items} setItems={setItems} />
          {!fromAdminPortal(user) && <OrderSetupPosDiscount posDiscount={posDiscountPerc} onChange={setPosDiscountPerc} />}
          <Divider my={5} />
          <OrderSetupTotal
            vat={vat}
            total={totalWithTaxAndDelivery}
            subtotal={subtotalWithTax}
            discount={discount}
            posDiscount={posDiscount}
            deliveryFee={deliveryFee}
          />
        </>
      )}
      <Button isFullWidth onClick={_handlePlaceOrderClick}>
        {order ? 'Save' : 'Place Order'}
      </Button>
      <OrderSetupError />
    </Card>
  );
};

const mapStateToProps = (state, { id }) => ({
  order: getOrder(state, { id }),
  restaurant: getRestaurant(state),
  user: getUser(state)
});

const mapDispatchToProps = {
  getAreas: getAreasAction,
  getTables: getTablesAction,
  getWaiters: getWaitersAction,
  placeOrder: placeOrderAction,
  updateOrder: updateOrderAction,
};

/* Export
============================================================================= */
export default connect(mapStateToProps, mapDispatchToProps)(OrderSetup);
