import React from 'react';
import { connect } from 'react-redux';
import { useDisclosure } from '@chakra-ui/react';

import SideNavOpen from './SideNavOpen';
import SideNavClosed from './SideNavClosed';

import { getOrdersByStatus } from '../../orders/redux/selectors';

/* =============================================================================
<SideNav />
============================================================================= */
const SideNav = ({ orderCount }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <SideNavClosed orderCount={orderCount} onOpen={onOpen} />
      <SideNavOpen orderCount={orderCount} onClose={onClose} isOpen={isOpen} />
    </>
  );
};

const mapStateToProps = (state) => ({
  orderCount: getOrdersByStatus(state, { status: 'pending' })?.length,
});

const propsAreEqual = (prevProps, nextProps) => prevProps.orderCount === nextProps.orderCount;

/* Export
============================================================================= */
export default connect(mapStateToProps)(React.memo(SideNav, propsAreEqual));
