import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Search } from 'react-feather';
import {
  HStack,
  Input,
  Tabs,
  Tab,
  TabList,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';

import { getStatus } from '../../../redux/selectors';
import { setStatus as setStatusAction } from '../../../redux/actions';

/* =============================================================================
<ReservationsStatusSelect />
============================================================================= */
const ReservationsStatusSelect = ({ status, setStatus }) => {
  const [tabIndex, setTabIndex] = useState(0);

  // Set current active tab
  useEffect(() => {
    switch (status) {
      case 'pending':
        setTabIndex(0);
        break;
      case 'accepted':
        setTabIndex(1);
        break;
      case 'completed':
        setTabIndex(2);
        break;
      case 'canceled':
        setTabIndex(3);
        break;

      default:
        break;
    }
  }, [status]);

  const _handleTabChange = (value) => {
    setTabIndex(value);
    switch (value) {
      case 0:
        setStatus('pending');
        break;
      case 1:
        setStatus('accepted');
        break;
      case 2:
        setStatus('completed');
        break;
      case 3:
        setStatus('canceled');
        break;

      default:
        break;
    }
  };
  return (
    <HStack flex={1} justify="space-between" px={6}>
      <Tabs ml="-30px" colorScheme="brand" index={tabIndex} onChange={_handleTabChange}>
        <TabList border="none">
          <Tab>Pending</Tab>
          <Tab>Accepted</Tab>
          <Tab>Completed</Tab>
          <Tab>Canceled</Tab>
        </TabList>
      </Tabs>
      <InputGroup maxW="320px">
        <InputLeftElement><Search size="15px" /></InputLeftElement>
        <Input placeholder="Search" />
      </InputGroup>
    </HStack>
  );
};

const mapStateToProps = (state) => ({
  status: getStatus(state),
});

const mapDispatchToProps = {
  setStatus: setStatusAction,
};

const propsAreEqual = (prevProps, nextProps) => prevProps.status === nextProps.status;

/* Export
============================================================================= */
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(ReservationsStatusSelect, propsAreEqual));
