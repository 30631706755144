import React from 'react';
import {
  Textarea,
  FormLabel,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
} from '@chakra-ui/react';
import { FastField } from 'formik';

/* =============================================================================
<ReservationEditModalNote />
============================================================================= */
const ReservationEditModalNote = () => (
  <FastField name="note">
    {({ field, form }) => (
      <FormControl mt={5} isInvalid={form.errors.note && form.touched.note}>
        <FormLabel>Note</FormLabel>
        <Textarea {...field} placeholder="Notes..." />
        <FormErrorMessage>
          <FormErrorIcon />
          {form.errors.note}
        </FormErrorMessage>
      </FormControl>
    )}
  </FastField>
);

/* Export
============================================================================= */
export default ReservationEditModalNote;
