import React from 'react';
import {
  Input,
  VStack,
  FormErrorIcon,
  FormLabel,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/react';
import { FastField } from 'formik';

/* =============================================================================
<DishName />
============================================================================= */
const DishName = () => (
  <FastField name="name">
    {({ field, form }) => (
      <FormControl
        mb={8}
        display="flex"
        isInvalid={form.errors.name && form.touched.name}
      >
        <FormLabel flex={2.1}>Item Name</FormLabel>
        <VStack alignItems="flex-start" flex={3}>
          <Input id="name" {...field} />
          <FormErrorMessage>
            <FormErrorIcon />
            {form.errors.name}
          </FormErrorMessage>
        </VStack>
      </FormControl>
    )}
  </FastField>
);

/* Export
============================================================================= */
export default DishName;
