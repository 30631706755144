import React, { useRef, useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import Card from '../../common/Card';
import ReservationCalendarToolbar from '../components/ReservationCalendar/ReservationCalendarToolbar';

import { getCalendarEvents } from '../redux/selectors';
import { getBookingsByMonth as getBookingsByMonthAction } from '../redux/actions';

/* =============================================================================
<ReservationCalendarScreen />
============================================================================= */
const ReservationCalendarScreen = () => {
  const calendar = useRef();
  const dispatch = useDispatch();
  const [month, setMonth] = useState(moment().format('YYYY-MM-DD'));
  const events = useSelector(
    (state) => getCalendarEvents(state, { month }),
    (left, right) => JSON.stringify(left) === JSON.stringify(right),
  );

  // Get bookings by month
  useEffect(() => {
    if (month) {
      dispatch(getBookingsByMonthAction(month));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month]);

  const _handleOnNavigate = (date) => {
    setMonth(moment(date).format('YYYY-MM-DD'));
  };

  return (
    <Card m={5} flex={1} direction="column">
      <Calendar
        ref={calendar}
        localizer={localizer}
        defaultView="month"
        events={events}
        dayPropGetter={dayPropGetter}
        eventPropGetter={eventPropGetter}
        components={{
          toolbar: ReservationCalendarToolbar,
        }}
        onNavigate={_handleOnNavigate}
      />
    </Card>
  );
};

const localizer = momentLocalizer(moment);
const eventPropGetter = (event, start) => ({
  style: {
    fontSize: '12px',
    fontWeight: '600',
    backgroundColor: start >= new Date() ? '#9B2C2C' : 'gray',
  },
});
const dayPropGetter = (date) => ({
  style: {
    backgroundColor: moment(date).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') ? '#F6EAE9' : 'transparent',
  },
});

/* Export
============================================================================= */
export default ReservationCalendarScreen;
