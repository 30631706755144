import React from 'react';
import {
  Input,
  HStack,
  FormLabel,
  FormControl,
  FormErrorMessage,
  Select,
  FormErrorIcon,
} from '@chakra-ui/react';
import { connect } from 'react-redux';
import { FastField } from 'formik';

import moment from 'moment';
import { getRestaurant } from '../../../../../restaurant/redux/selectors';

/* =============================================================================
<ReservationEditModalDateAndTime />
============================================================================= */
const ReservationEditModalDateAndTime = ({ restaurant }) => {
  const hours = restaurant?.booking?.hours?.filter((h) => h.active);
  const duration = restaurant?.booking?.duration;

  return (
    <HStack mt={5} spacing={5} alignItems="flex-start">
      <FastField name="date">
        {({ field, form }) => (
          <FormControl
            flex={1}
            isInvalid={form.errors.date && form.touched.date}
          >
            <FormLabel>Date</FormLabel>
            <Input type="date" id="date" {...field} />
            <FormErrorMessage>{form.errors.date}</FormErrorMessage>
          </FormControl>
        )}
      </FastField>
      <FastField name="time">
        {({ field, form }) => (
          <FormControl flex={1} isInvalid={form.errors.time && form.touched.time}>
            <FormLabel>Time of Reservation</FormLabel>
            <Select placeholder="Select" textTransform="capitalize" {...field}>
              {hours?.map((hour, i) => {
              // eslint-disable-next-line prefer-template
                const label = moment().startOf('day').minutes(hour.start).format('hh:mm a');
                return (
                  <option key={i} value={hour.start}>{label}</option>
                );
              })}
            </Select>
            <FormErrorMessage>
              <FormErrorIcon />
              {form.errors.time}
            </FormErrorMessage>
          </FormControl>
        )}
      </FastField>
    </HStack>
  );
};

const mapStateToProps = (state) => ({
  restaurant: getRestaurant(state),
});

const propsAreEqual = (prevProps, nextProps) => (
  prevProps.restaurant?.booking?.duration === nextProps.restaurant?.booking?.duration
  && JSON.stringify(prevProps.restaurant?.booking?.hours)
  === JSON.stringify(nextProps.restaurant?.booking?.hours)
);

/* Export
============================================================================= */
export default connect(mapStateToProps)(React.memo(
  ReservationEditModalDateAndTime,
  propsAreEqual,
));
