import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
} from '@chakra-ui/react';

import { connect } from 'react-redux';
import OrderHistoryTableItem from './OrderHistoryTableItem/index';
import OrderHistoryTableHeadItem from './OrderHistoryTableHeadItem';

import { getStatus } from '../../redux/selectors';

/* =============================================================================
<OrderHistoryTable />
============================================================================= */
const OrderHistoryTable = ({ status, data }) => {
  const currentStatus = status === 'completed' ? 'COMPLETED' : 'CANCELED';

  const columns = [
    'CUSTOMER',
    'ID',
    'PAYMENT METHOD',
    'AMOUNT',
    `${currentStatus} IN`,
    'ACTION',
  ];

  return (
    <Box flex={1}>
      <Table variant="simple" colorScheme="gray">
        <Thead>
          <Tr>
            {columns.map(renderColumn)}
          </Tr>
        </Thead>
        <Tbody>
          {data.map(renderItem)}
        </Tbody>
      </Table>
    </Box>
  );
};

const renderColumn = (item, i) => <OrderHistoryTableHeadItem key={`${i}`} title={item} />;
const renderItem = (item, i) => <OrderHistoryTableItem key={item} id={item} />;

const mapStateToProps = (state) => ({
  status: getStatus(state),
});

/* Export
============================================================================= */
export default connect(mapStateToProps)(OrderHistoryTable);
