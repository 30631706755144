import React from 'react';
import { connect } from 'react-redux';

import OrdersAcceptedActionsBykea from './OrdersAcceptedActionsBykea';
import OrdersAcceptedActionsDefault from './OrdersAcceptedActionsDefault';

import { getOrder } from '../../../../entities/redux/selectors';

/* =============================================================================
<OrdersAcceptedActions />
============================================================================= */
const OrdersAcceptedActions = ({ id, order }) => {
  const orderPartner = order?.partner;

  if (orderPartner === 'bykea') {
    return <OrdersAcceptedActionsBykea id={id} />
  }

  return <OrdersAcceptedActionsDefault id={id} />;
};

const mapStateToProps = (state, { id }) => ({
  order: getOrder(state, { id }),
});

const propsAreEqual = (prevProps, nextProps) =>
  prevProps.id === nextProps.id
  && prevProps.order?.partner === nextProps.order?.partner;

/* Export
============================================================================= */
export default connect(mapStateToProps)(React.memo(OrdersAcceptedActions, propsAreEqual));
