import React from 'react';
import { Tag } from 'react-feather';
import { Link, Text } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';

/* =============================================================================
<Discounts />
============================================================================= */
const Discounts = () => (
  <Link
    flex={1}
    as={NavLink}
    my={6}
    to="/menu/discounts"
    display="flex"
  >
    <Tag />
    <Text pl={3} fontWeight="semibold">
      Discounts
    </Text>
  </Link>
);

/* Export
============================================================================= */
export default Discounts;
