import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Flex,
  Tab,
  Tabs,
  TabList,
} from '@chakra-ui/react';

import { getOngoingOrdersCount } from '../../redux/selectors';

/* =============================================================================
<OngoingOrderHeader />
============================================================================= */
const OngoingOrderHeader = ({
  type,
  allCount,
  dineInCount,
  deliveryCount,
  takeAwayCount,
  setType,
}) => {
  const [tabIndex, setTabIndex] = useState(0);

  // Set current active tab
  useEffect(() => {
    switch (type) {
      case 'all':
        setTabIndex(0);
        break;
      case 'dine_in':
        setTabIndex(1);
        break;
      case 'delivery':
        setTabIndex(2);
        break;
      case 'take_away':
        setTabIndex(3);
        break;

      default:
        break;
    }
  }, [type]);

  const _handleTabChange = (value) => {
    setTabIndex(value);
    switch (value) {
      case 0:
        setType('all');
        break;
      case 1:
        setType('dine_in');
        break;
      case 2:
        setType('delivery');
        break;
      case 3:
        setType('take_away');
        break;

      default:
        break;
    }
  };

  const _renderOrderCountBadge = (count) => (
    <Flex
      width="20px"
      height="20px"
      align="center"
      justify="center"
      ml={2}
      mb={2}
      bgColor="red"
      color="white"
      fontSize="10px"
      fontWeight="bold"
      borderRadius="50%"
    >
      {count}
    </Flex>
  );

  return (
    <Tabs mb={5} colorScheme="brand" index={tabIndex} onChange={_handleTabChange}>
      <TabList border="none">
        <Tab>
          All
          {' '}
          {allCount > 0 && _renderOrderCountBadge(allCount)}
        </Tab>
        <Tab>
          Dine In
          {' '}
          {dineInCount > 0 && _renderOrderCountBadge(dineInCount)}
        </Tab>
        <Tab>
          Delivery
          {' '}
          {deliveryCount > 0 && _renderOrderCountBadge(deliveryCount)}
        </Tab>
        <Tab>
          Take Away
          {' '}
          {takeAwayCount > 0 && _renderOrderCountBadge(takeAwayCount)}
        </Tab>
      </TabList>
    </Tabs>
  );
};

const mapStateToProps = (state) => ({
  allCount: getOngoingOrdersCount(state, { type: 'all' }),
  dineInCount: getOngoingOrdersCount(state, { type: 'dine_in' }),
  deliveryCount: getOngoingOrdersCount(state, { type: 'delivery' }),
  takeAwayCount: getOngoingOrdersCount(state, { type: 'take_away' }),
});

const propsAreEqual = (prevProps, nextProps) => (
  prevProps.type === nextProps.type
  && prevProps.allCount === nextProps.allCount
  && prevProps.dineInCount === nextProps.dineInCount
  && prevProps.deliveryCount === nextProps.deliveryCount
  && prevProps.takeAwayCount === nextProps.takeAwayCount
);

/* Export
============================================================================= */
export default connect(mapStateToProps)(React.memo(OngoingOrderHeader, propsAreEqual));
