import React from 'react';
import { PlusCircle } from 'react-feather';
import {
  Text,
  Button,
  HStack,
  useDisclosure,
} from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

import OrderSetupAddReservationModal from './OrderSetupAddReservationModal/index';

/* =============================================================================
<OrderSetupHeader />
============================================================================= */
const OrderSetupHeader = () => {
  const { pathname } = useLocation();
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <HStack justify="space-between">
      <OrderSetupAddReservationModal isOpen={isOpen} onClose={onClose} />
      <Text fontSize="lg" fontWeight="medium">Order Setup</Text>
      {pathname.includes('/pos/new_order') && (
        <Button
          variant="outline"
          fontWeight="medium"
          leftIcon={<PlusCircle size={16} />}
          colorScheme="gray"
          onClick={onOpen}
        >
          Add Reservation
        </Button>
      )}
    </HStack>
  );
};

/* Export
============================================================================= */
export default OrderSetupHeader;
