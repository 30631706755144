import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Box,
  Text,
  HStack,
  Button,
  Select,
  Divider,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
} from '@chakra-ui/react';
import { Plus, Trash } from 'react-feather';
import {
  FastField, FieldArray, getIn, useFormikContext,
} from 'formik';

import { getChoiceGroup } from '../../../entities/redux/selectors';
import { getChoiceGroups as selectChoiceGroups } from '../../redux/selectors';
import { getChoiceGroups as getChoiceGroupsAction } from '../../redux/actions';

/* =============================================================================
<DishChoiceGroups />
============================================================================= */
const DishChoiceGroups = ({ options, getChoiceGroups }) => {
  const { values } = useFormikContext();
  const choiceGroups = values?.choiceGroups || [];

  // Get choice groups
  useEffect(() => {
    getChoiceGroups();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FieldArray name="choiceGroups">
      {(arrayHelper) => (
        <Box>
          <HStack justify="space-between">
            <Text fontWeight="semibold">Choice Groups</Text>
            <Button
              mx={10}
              size="sm"
              variant="link"
              onClick={() => arrayHelper.push('')}
              leftIcon={<Plus size="18px" />}
            >
              Add Choice
            </Button>
          </HStack>
          {!!choiceGroups
            && choiceGroups.map((item, index) => (
              <HStack key={index} mt={5} spacing={5} alignItems="flex-start">
                <FastField name={`choiceGroups.${index}`} key={index}>
                  {({ field, form }) => (
                    <FormControl isInvalid={getIn(form.errors, `choiceGroups.${index}`) && getIn(form.touched, `choiceGroups.${index}`)}>
                      <Select placeholder="Select" {...field}>
                        {options.map((option) => (
                          <option value={option.id} key={option.id}>{option.name}</option>
                        ))}
                      </Select>
                      <FormErrorMessage>
                        <FormErrorIcon />
                        {getIn(form.errors, `choiceGroups.${index}`)}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </FastField>
                <Button onClick={() => arrayHelper.remove(index)} colorScheme="gray">
                  <Trash size="20px" />
                </Button>
              </HStack>
            ))}
          <Divider my={5} />
        </Box>
      )}
    </FieldArray>
  );
};

const mapStateToProps = (state) => ({
  options: selectChoiceGroups(state)
    .map((id) => getChoiceGroup(state, { id, normalize: true })),
});

const mapDispatchToProps = {
  getChoiceGroups: getChoiceGroupsAction,
};

// eslint-disable-next-line max-len
const propsAreEqual = (prevProps, nextProps) => prevProps.options.length === nextProps.options.length;

/* Export
============================================================================= */
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(DishChoiceGroups, propsAreEqual));
