import React from 'react';
import {
  Text,
  Textarea,
  FormLabel,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
} from '@chakra-ui/react';
import { FastField } from 'formik';

/* =============================================================================
<ChoiceDescription />
============================================================================= */
const ChoiceDescription = () => (
  <FastField name="about">
    {({ field, form }) => (
      <FormControl isInvalid={form.errors.about && form.touched.about}>
        <FormLabel>Category Desc (Optional)</FormLabel>
        <Textarea
          id="about"
          {...field}
          placeholder="Enter Desc"
        />
        <FormErrorMessage>
          <FormErrorIcon />
          {form.errors.about}
        </FormErrorMessage>
      </FormControl>
    )}
  </FastField>
);

/* Export
============================================================================= */
export default ChoiceDescription;
