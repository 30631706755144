import React from 'react';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import {
  Modal,
  Input,
  HStack,
  Select,
  Button,
  Divider,
  ModalBody,
  FormLabel,
  useToast,
  FormControl,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  FormErrorMessage,
} from '@chakra-ui/react';
import { FastField, Form, Formik } from 'formik';

import { getTable } from '../../../entities/redux/selectors';
import { getAreasById } from '../../redux/selectors';
import { updateTable as updateTableAction } from '../../redux/actions';

/* =============================================================================
<EditTableModal />
============================================================================= */
const EditTableModal = ({
  id,
  areas,
  table,
  isOpen,
  onClose,
  updateTable,
}) => {
  const toast = useToast();
  const initialValues = {
    name: table ? table.name : '',
    area: table ? table.area.id : '',
    capacity: table ? table.capacity : '',
  };

  const _handleSubmit = async (values) => {
    await updateTable({ ...values, id }, (err) => {
      if (!err) {
        toast({
          title: 'Changes Saved',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: err.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    });
    onClose();
  };

  return (
    <Modal size="2xl" isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="lg">Edit Table</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Divider mb={5} />
          <Formik
            initialValues={initialValues}
            validationSchema={AddATableSchema}
            onSubmit={_handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <HStack alignItems="flex-start">
                  <FastField name="name">
                    {({ field, form }) => (
                      <FormControl flex={1} isInvalid={form.errors.name && form.touched.name}>
                        <FormLabel>Table Name</FormLabel>
                        <Input id="name" placeholder="Ex : Front Side, Second Floor" {...field} />
                        <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                      </FormControl>
                    )}
                  </FastField>
                  <FastField name="area">
                    {({ field, form }) => (
                      <FormControl
                        flex={1}
                        isInvalid={form.errors.area && form.touched.area}
                      >
                        <FormLabel>Area</FormLabel>
                        <Select placeholder="Select" {...field}>
                          {areas?.map((area) => (
                            <option
                              key={area.id}
                              value={area.id}
                            >
                              {area.name}
                            </option>
                          ))}
                        </Select>
                        <FormErrorMessage>{form.errors.area}</FormErrorMessage>
                      </FormControl>
                    )}
                  </FastField>
                  <FastField name="capacity">
                    {({ field, form }) => (
                      <FormControl
                        flex={1}
                        isInvalid={form.errors.capacity && form.touched.capacity}
                      >
                        <FormLabel>Capacity</FormLabel>
                        <Input id="capacity" placeholder="Ex : 4" {...field} />
                        <FormErrorMessage>{form.errors.capacity}</FormErrorMessage>
                      </FormControl>
                    )}
                  </FastField>
                </HStack>
                <Divider my={5} />
                <HStack mb={3}>
                  <Button
                    mr={4}
                    flex={1}
                    color="gray"
                    variant="outline"
                    colorScheme="gray"
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    flex={1}
                    type="submit"
                    isLoading={isSubmitting}
                  >
                    Save
                  </Button>
                </HStack>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const AddATableSchema = Yup.object().shape({
  name: Yup.string()
    .required('Must not be empty!'),
  area: Yup.string()
    .required('Must not be empty'),
  capacity: Yup.number()
    .required('Must not be empty!'),
});

const mapStateToProps = (state, { id }) => ({
  table: getTable(state, { id }),
  areas: getAreasById(state),
});

const mapDispatchToProps = {
  updateTable: updateTableAction,
};

/* Export
============================================================================= */
export default connect(mapStateToProps, mapDispatchToProps)(EditTableModal);
