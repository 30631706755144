import React from 'react';
import {
  Modal,
  Button,
  HStack,
  ModalBody,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
  Text,
} from '@chakra-ui/react';

/* =============================================================================
<OrderDeleteModal />
============================================================================= */
const OrderDeleteModal = ({ isOpen, onClose, onSubmit }) => (
    <Modal isOpen={isOpen} scrollBehavior="inside" onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent mx={2}>
        <ModalHeader fontSize="lg">Delete Order</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text mb={6}>Are you sure you want to delete this order?</Text>
          <HStack alignItems={['flex-end', 'flex-end', 'flex-start']}>
            <Button
              mr={4}
              flex={1}
              color="gray"
              variant="outline"
              colorScheme="gray"
              onClick={onClose}
            >
              Close
            </Button>
            <Button
              flex={1}
              onClick={onSubmit}
            >
              Delete
            </Button>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );

/* Export
============================================================================= */
export default OrderDeleteModal;
