import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Box,
  Text,
  HStack,
  Switch,
  useToast,
} from '@chakra-ui/react';

import { getRestaurant as selectRestaurant } from '../../../restaurant/redux/selectors';
import { updateRestaurantOperations as updateRestaurantOperationsAction } from '../../../restaurant/redux/actions';

/* =============================================================================
<HeaderMenuRestaurantOperations />
============================================================================= */
const HeaderMenuRestaurantOperations = ({
  restaurant,
  updateRestaurantOperations,
}) => {
  const toast = useToast();
  const [bykea, setBykea] = useState(false);
  const [dineIn, setDineIn] = useState(false);
  const [takeAway, setTakeAway] = useState(false);
  const [delivery, setDelivery] = useState(false);

  const operations = restaurant?.operations;

  // Sync operations
  useEffect(() => {
    if (typeof operations?.bykea === 'boolean') {
      setBykea(operations.bykea);
    }
    if (typeof operations?.dine_in === 'boolean') {
      setDineIn(operations.dine_in);
    }
    if (typeof operations?.delivery === 'boolean') {
      setDelivery(operations.delivery);
    }
    if (typeof operations?.take_away === 'boolean') {
      setTakeAway(operations.take_away);
    }
  }, [
    operations?.bykea,
    operations?.dine_in,
    operations?.delivery,
    operations?.take_away,
  ]);

  const _updateOperations = (payload) => {
    updateRestaurantOperations({
      bykea,
      dine_in: dineIn,
      delivery,
      take_away: takeAway,
      ...payload,
    }, (err) => {
      if (err) {
        toast({
          title: err.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: 'Changes Saved',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
    });
  };

  const _handleChange = (name) => () => {
    switch (name) {
      case 'bykea':
        setBykea((prevState) => {
          _updateOperations({
            bykea: !prevState,
          });
          return !prevState;
        });
        break;
      case 'dine_in':
        setDineIn((prevState) => {
          _updateOperations({
            dine_in: !prevState,
          });
          return !prevState;
        });
        break;
      case 'delivery':
        setDelivery((prevState) => {
          _updateOperations({
            delivery: !prevState,
          });
          return !prevState;
        });
        break;
      case 'take_away':
        setTakeAway((prevState) => {
          _updateOperations({
            take_away: !prevState,
          });
          return !prevState;
        });
        break;
      default:
        break;
    }
  };

  return (
    <Box>
      <HStack justifyContent="space-between" px={5} mb={3}>
        <Text fontWeight="semibold">Dine In</Text>
        <Switch colorScheme="brand" isChecked={dineIn} onChange={_handleChange('dine_in')} />
      </HStack>
      <HStack justifyContent="space-between" px={5} mb={3}>
        <Text fontWeight="semibold">Delivery</Text>
        <Switch colorScheme="brand" isChecked={delivery} onChange={_handleChange('delivery')} />
      </HStack>
      <HStack justifyContent="space-between" px={5} mb={3}>
        <Text fontWeight="semibold">Take Away</Text>
        <Switch colorScheme="brand" isChecked={takeAway} onChange={_handleChange('take_away')} />
      </HStack>
      <HStack justifyContent="space-between" px={5} mb={3}>
        <Text fontWeight="semibold">Bykea</Text>
        <Switch isDisabled={!delivery} colorScheme="brand" isChecked={bykea} onChange={_handleChange('bykea')} />
      </HStack>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  restaurant: selectRestaurant(state),
});

const mapDispatchToProps = {
  updateRestaurantOperations: updateRestaurantOperationsAction,
};

const propsAreEqual = (prevProps, nextProps) => (
  prevProps.restaurant?.operations?.bykea === nextProps.restaurant?.operations?.bykea
  && prevProps.restaurant?.operations?.dine_in === nextProps.restaurant?.operations?.dine_in
  && prevProps.restaurant?.operations?.delivery === nextProps.restaurant?.operations?.delivery
  && prevProps.restaurant?.operations?.take_away === nextProps.restaurant?.operations?.take_away
);

/* Export
============================================================================= */
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(HeaderMenuRestaurantOperations, propsAreEqual));
