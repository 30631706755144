import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import {
  Tr,
  Td,
  Box,
  Text,
  Avatar,
  HStack,
} from '@chakra-ui/react';

import ReservationTableItemActions from './ReservationTableItemActions';

import { getBooking } from '../../../../../entities/redux/selectors';

/* =============================================================================
<ReservationTableItem />
============================================================================= */
const ReservationTableItem = ({ id, booking }) => {
  const name = booking?.meta?.name || `${booking?.customer?.profile?.firstName} ${booking?.customer?.profile?.lastName}`;
  const phone = booking?.meta?.phone || booking?.customer?.phone;
  const avatar = booking?.customer?.profile?.avatar;
  const seats = booking?.seats;
  const event = booking?.event || '--';
  const time = booking && moment().startOf('day').minutes(booking?.time).format('HH:mm A');

  return (
    <Tr>
      <Td as={HStack}>
        <Avatar src={avatar} />
        <Box>
          <Text>{name}</Text>
          <Text>{phone}</Text>
        </Box>
      </Td>
      <Td>{seats}</Td>
      <Td>{event}</Td>
      <Td>{time}</Td>
      <ReservationTableItemActions id={id} />
    </Tr>
  );
};

const mapStateToProps = (state, { id }) => ({
  booking: getBooking(state, { id }),
});

// eslint-disable-next-line max-len
const propsAreEqual = (prevProps, nextProps) => prevProps.booking?.id === nextProps.booking?.id
  && prevProps.booking?.time === nextProps.booking?.time
  && prevProps.booking?.seats === nextProps.booking?.seats
  && prevProps.booking?.date === nextProps.booking?.date
  && prevProps.booking?.status === nextProps.booking?.status
  && prevProps.booking?.meta?.name === nextProps.booking?.meta?.name
  && prevProps.booking?.meta?.phone === nextProps.booking?.meta?.phone
  && prevProps.booking?.customer?.toString() === nextProps.booking?.customer?.toString();

/* Export
============================================================================= */
export default connect(
  mapStateToProps,
)(React.memo(ReservationTableItem, propsAreEqual));
