import React from 'react';
import { connect } from 'react-redux';
import { Text } from '@chakra-ui/react';

import OrderReceiptItemsListItem from './OrderReceiptItemsListItem';

import { getOrder } from '../../../entities/redux/selectors';

/* =============================================================================
<OrderReceiptItemsList />
============================================================================= */
const OrderReceiptItemsList = ({ order }) => {
  const items = order?.items;

  return (
    <>
      <Text fontWeight="bold" fontSize="xl">
        Item(s):
      </Text>
      {items?.map((item, i) => (
        <OrderReceiptItemsListItem key={i} item={item} />
      ))}
    </>
  );
};

const mapStateToProps = (state, { id }) => ({
  order: getOrder(state, { id, normalize: true }),
});

const propsAreEqual = (prevProps, nextProps) => (
  JSON.stringify(prevProps.order?.items) === JSON.stringify(nextProps.order?.items)
);

/* Export
============================================================================= */
export default connect(mapStateToProps)(
  React.memo(OrderReceiptItemsList, propsAreEqual),
);
