import React from 'react';
import { Flex } from '@chakra-ui/react';

/* =============================================================================
<SideNavOrderCountBadge />
============================================================================= */
const SideNavOrderCountBadge = ({ orderCount }) => (
  <Flex
    position="absolute"
    top={1}
    right={2}
    width="16px"
    height="16px"
    align="center"
    justify="center"
    bgColor="red"
    color="white"
    fontSize="10px"
    fontWeight="bold"
    borderRadius="50%"
  >
    {orderCount}
  </Flex>
);

/* Export
============================================================================= */
export default SideNavOrderCountBadge;
