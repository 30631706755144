import React from 'react';
import { Flex, HStack } from '@chakra-ui/react';

import OrdersNew from './OrdersNew';
import OrdersAccepted from './OrdersAccepted';
import OrdersOnTheWay from './OrdersOnTheWay';
import OrdersCompleted from './OrdersCompleted';

/* =============================================================================
<OrdersMobileView />
============================================================================= */
const OrdersMobileView = () => (
  <HStack flex={1} alignItems="flex-start" my={6} spacing={5}>
    <OrdersNew />
    <OrdersAccepted />
    <OrdersOnTheWay />
    <OrdersCompleted />
  </HStack>
);

/* Export
============================================================================= */
export default OrdersMobileView;
