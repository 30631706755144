import moment from 'moment';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useFormikContext } from 'formik';

import { getMenuItem } from '../../../../entities/redux/selectors';

/* =============================================================================
<EditCategoryModalInitialize />
============================================================================= */
const EditCategoryModalInitialize = ({ menuItem }) => {
  const { resetForm } = useFormikContext();

  const id = menuItem?.id;
  const name = menuItem?.name;
  const about = menuItem?.about;
  const category = menuItem?.category;

  // Set default values
  useEffect(() => {
    resetForm({
      values: {
        id,
        name,
        about,
        category,
      },
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    id,
    name,
    about,
    category,
  ]);

  return null;
};

const mapStateToProps = (state, { id }) => ({
  menuItem: getMenuItem(state, { id, normalize: true }),
});

/* Export
============================================================================= */
export default connect(mapStateToProps)(EditCategoryModalInitialize);
