import * as constants from './constants';

const INITIAL_STATE = {
  setUpCompleted: false,
  error: null,
  loading: false,
};

export default function reducer(state = INITIAL_STATE, action) {
  const { type, payload, error } = action;

  switch (type) {
    // RESTAURANT_INFO_UPDATE
    case constants.RESTAURANT_INFO_UPDATE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.RESTAURANT_INFO_UPDATE.FAIL:
      return {
        ...state,
        error,
      };
    case constants.RESTAURANT_INFO_UPDATE.SUCCESS:
      return {
        ...state,
        error: null,
        user: payload,
      };
    case constants.RESTAURANT_INFO_UPDATE.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // RESTAURANT_HOURS_UPDATE
    case constants.RESTAURANT_HOURS_UPDATE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.RESTAURANT_HOURS_UPDATE.FAIL:
      return {
        ...state,
        error,
      };
    case constants.RESTAURANT_HOURS_UPDATE.SUCCESS:
      return state;
    case constants.RESTAURANT_HOURS_UPDATE.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // SETUP_COMPLETED_SET
    case constants.SETUP_COMPLETED_SET:
      return {
        ...state,
        setUpCompleted: true,
      };

    default:
      return state;
  }
}
