import React from 'react';
import {
  Input,
  HStack,
  FormLabel,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/react';
import { FastField } from 'formik';

/* =============================================================================
<ReservationEditModalNameAndPhone />
============================================================================= */
const ReservationEditModalNameAndPhone = () => (
  <HStack spacing={5} alignItems="flex-start">
    <FastField name="name">
      {({ field, form }) => (
        <FormControl
          flex={1}
          isInvalid={form.errors.name && form.touched.name}
        >
          <FormLabel>Name</FormLabel>
          <Input id="name" {...field} />
          <FormErrorMessage>{form.errors.name}</FormErrorMessage>
        </FormControl>
      )}
    </FastField>
    <FastField name="phone">
      {({ field, form }) => (
        <FormControl
          flex={1}
          isInvalid={form.errors.phone && form.touched.phone}
        >
          <FormLabel>Phone</FormLabel>
          <Input id="phone" {...field} />
          <FormErrorMessage>{form.errors.phone}</FormErrorMessage>
        </FormControl>
      )}
    </FastField>
  </HStack>
);

/* Export
============================================================================= */
export default ReservationEditModalNameAndPhone;
