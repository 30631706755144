/* eslint-disable consistent-return */
import React, { memo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { nanoid } from 'nanoid/async';
import { useToast } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { getToken, onMessage, deleteToken } from 'firebase/messaging';

import NotificationToast from './NotificationToast';
import request from '../../util/request';
import { messaging } from '../../config/firebase';
import { FIREBASE_VAPID_KEY } from '../../config/secrets';
import * as NOTIFICATION_TYPES from '../../static/notification_types';

import { getUserId } from '../../auth/redux/selectors';

/* =============================================================================
<NotificationListener />
============================================================================= */
const NotificationListener = ({ authenticated }) => {
  const history = useHistory();
  const toast = useToast({
    position: 'bottom-right',
  });
  const [notificationsAllowed, setNotificationsAllowed] = useState(false);

  // Request user permission or unregister token
  useEffect(() => {
    if (authenticated) {
      _requestPermission();
    } else {
      _unregisterToken();
    }
  }, [authenticated]);

  // Register FCM token
  useEffect(() => {
    if (notificationsAllowed) {
      (async () => {
        try {
          const token = await getToken(messaging, {
            vapidKey: FIREBASE_VAPID_KEY,
          });
          _registerToken(token.toString());
        } catch (e) {
          // Couldn't register FCM token
        }
      })();
    }
  }, [notificationsAllowed]);

  // Listen for foreground notifications
  useEffect(() => {
    if (notificationsAllowed) {
      const unsubscribe = onMessage(messaging, _handleForegroundNotification);
      return unsubscribe;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationsAllowed]);

  // Request notifications permission
  const _requestPermission = async () => {
    try {
      const permission = await Notification.requestPermission();
      setNotificationsAllowed(permission === 'granted');
    } catch (e) {
      setNotificationsAllowed(false);
    }
  };

  const _unregisterToken = async () => {
    try {
      const token = await getToken(messaging);
      await deleteToken(messaging);
      setNotificationsAllowed(false);
    } catch (e) {
      // Couldn't unregister token
    }
  };

  // Register token to the api and device
  const _registerToken = async (token) => {
    try {
      let deviceId = localStorage.getItem('@foodstreet-merchant/deviceId');

      if (!deviceId) {
        deviceId = await nanoid();
        localStorage.setItem('@foodstreet-merchant/deviceId', deviceId);
      }

      await request({
        url: '/users/me/notification_tokens',
        method: 'POST',
        data: {
          token,
          deviceId,
        },
      });
    } catch (e) {
      // Couldn't register token
    }
  };

  // Handle foreground notification
  const _handleForegroundNotification = (message) => {
    const { data, notification } = message;

    if (notification?.title && notification?.body) {
      toast({
        render: ({ onClose }) => (
          <NotificationToast
            title={notification.title}
            body={notification.body}
            onClose={onClose}
            onClick={() => {
              const type = data?.type;
              if (NOTIFICATION_TYPES[type]) {
                const { screen } = NOTIFICATION_TYPES[type](data);
                history.push(screen);
              }
            }}
          />
        ),
      });
    }
  };

  return null;
};

const mapStateToProps = (state) => ({
  authenticated: Boolean(getUserId(state)),
});

const propsAreEqual = (prevProps, nextProps) => (
  prevProps.authenticated === nextProps.authenticated
);

/* Export
============================================================================= */
export default connect(mapStateToProps)(
  memo(NotificationListener, propsAreEqual),
);
