import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Box,
  Text,
  HStack,
  Button,
  Checkbox,
} from '@chakra-ui/react';

import { getOrder } from '../../../../entities/redux/selectors';
import { updateOrderItems as updateOrderItemsAction } from '../../../redux/actions';

/* =============================================================================
<OngoingOrderCardDishes />
============================================================================= */
const OngoingOrderCardDishes = ({ order, updateOrderItems }) => {
  const [showMore, setShowMore] = useState(false);
  const items = order?.items?.slice().reverse();
  const showToggleBtn = Boolean(items?.find((item) => item?.options?.length > 0));

  const _handleShowMore = () => {
    setShowMore((prevState) => !prevState);
  };

  const _handleCheckboxClick = (index) => (event) => {
    // Update order
    updateOrderItems({
      uid: order?.uid,
      items: order?.items?.map((item, i) => {
        if ((items?.length - 1) - index === i) {
          return {
            ...item,
            prepared: event.target.checked,
          };
        }
        return item;
      }),
    });
  };

  const renderShowMore = () => items?.map((item, i) => {
    const { name } = item;
    const { variant } = item;
    const { qty } = item;
    const { options } = item;
    const prepared = item?.prepared;
    const price = item.price * qty;

    return (
      <Box key={i} mb={3}>
        <HStack justify="space-between" textDecorationLine={prepared ? 'line-through': 'none'}>
        <Checkbox
          colorScheme="brand"
          isChecked={prepared}
          onChange={_handleCheckboxClick(i)}
        />
          <HStack>
            <Text fontSize="sm" fontWeight="semibold">
              {qty}
              X
            </Text>
            {variant ? (
              <Text fontSize="sm" fontWeight="semibold" color="brand.700">
                {name}
                {' '}
                (
                {variant}
                )
              </Text>
            ) : (
              <Text fontSize="sm" fontWeight="semibold" color="brand.700">
                {name}
              </Text>
            )}
          </HStack>
          <Text fontSize="sm" fontWeight="semibold">
            Rs
            {' '}
            {price}
          </Text>
        </HStack>
        {options?.map((option, index) => (
          <HStack key={index} ml={8} justify="space-between">
            <Text fontSize="xs" fontWeight="semibold">
              {option.name}
              :
              {' '}
              {option?.values?.map((value) => value)?.join(', ')}
            </Text>
            <Text fontSize="sm" fontWeight="semibold">
              Rs
              {' '}
              {(option.price || 0) * qty}
            </Text>
          </HStack>
        ))}
      </Box>
    );
  });

  const renderShowLess = () => items?.map((item, i) => {
    const name = item?.name;
    const variant = item?.variant;
    const qty = item?.qty;
    const prepared = item?.prepared;
    const optionsPrice = item.options
      ?.map((option) => (option.price || 0)).reduce((a, b) => a + b, 0) || 0;
    const price = (item.price + optionsPrice) * item.qty;

    return (
      <HStack key={i} mb={3} justify="space-between"  textDecorationLine={prepared ? 'line-through': 'none'}>
        <Checkbox
          colorScheme="brand"
          isChecked={prepared}
          onChange={_handleCheckboxClick(i)}
        />
        <Text fontSize="sm" fontWeight="semibold">
          {qty}
          X
        </Text>
        {variant ? (
          <Text flex={1} fontSize="sm" fontWeight="semibold" color="brand.700">
            {name}
            {' '}
            (
            {variant}
            )
          </Text>
        ) : (
          <Text flex={1} fontSize="sm" fontWeight="semibold" color="brand.700">
            {name}
          </Text>
        )}
        <Text fontSize="sm" fontWeight="semibold">
          Rs
          {' '}
          {price}
        </Text>
      </HStack>
    );
  });

  const renderToggleButton = () => (
    <HStack mb={3} justify="center">
      <Button
        variant="link"
        onClick={_handleShowMore}
      >
        {showMore ? 'Show less' : 'Show more'}
      </Button>
    </HStack>
  );

  return (
    <Box>
      <Text mb={1} fontSize="sm">Order info</Text>
      {showMore
        ? renderShowMore()
        : order?.items?.length > 0 && renderShowLess()}
      {showToggleBtn && renderToggleButton()}
    </Box>
  );
};

const mapStateToProps = (state, { id }) => ({
  order: getOrder(state, { id, normalize: true }),
});

const mapDispatchToProps = {
  updateOrderItems: updateOrderItemsAction,
};

const propsAreEqual = (prevProps, nextProps) => (
  JSON.stringify(prevProps.order?.items) === JSON.stringify(nextProps.order?.items)
);

/* Export
============================================================================= */
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(OngoingOrderCardDishes, propsAreEqual));
