import React, { useState } from 'react';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  HStack,
  Input,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react';
import { connect } from 'react-redux';
import Card from '../../common/Card';
import { getLoading, selectParams, selectReports } from '../redux/selectors';
import { getReports } from '../redux/actions';
import { TODAY_ISO_DATE } from '../redux/constants';

const DashboardScreen = ({ loading, reports, params, getReportsAction }) => {
  const onSubmit = async values => {
    const { from, to } = values;
    getReportsAction({ from, to });
  };

  const completedReport = reports?.byStatus?.find(r => r._id === 'completed');
  const declinedReport = reports?.byStatus?.find(r => r._id === 'declined');
  const deliveryByBykea =
    reports?.byPartnerAndMethod
      ?.filter(r => r._id.partner === 'bykea')
      ?.reduce((a, b) => a + b.totalDelivery, 0) || 0;

  return (
    <Box>
      <HStack p={5} alignItems="flex-end">
        <Formik
          initialValues={{ from: params.from, to: params.to }}
          onSubmit={onSubmit}
        >
          {({ handleChange, handleSubmit, values }) => (
            <>
              <FormControl width="300px">
                <FormLabel htmlFor="fromFilter">From</FormLabel>
                <Input
                  id="fromFilter"
                  type="date"
                  min="2022-03-01"
                  max={TODAY_ISO_DATE}
                  value={values.from}
                  onChange={handleChange('from')}
                />
              </FormControl>
              <FormControl width="300px">
                <FormLabel htmlFor="toFilter">To</FormLabel>
                <Input
                  id="toFilter"
                  type="date"
                  min="2022-03-01"
                  max={TODAY_ISO_DATE}
                  value={values.to}
                  onChange={handleChange('to')}
                />
              </FormControl>
              <Button onClick={handleSubmit}>Reload</Button>
            </>
          )}
        </Formik>
      </HStack>
      <Grid m={5} gridTemplateColumns="repeat(3, 350px)" gridGap={6}>
        <Card>
          <Text>Total Sales</Text>
          <Flex
            py={6}
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            {loading ? (
              <Spinner />
            ) : (
              <>
                <Text fontSize="4xl" fontWeight="bold">
                  {completedReport?.totalRevenue?.toLocaleString() || 0}
                </Text>
                <Text fontSize="sm" color="gray.600">
                  {completedReport?.count?.toLocaleString() || 0} completed
                  orders
                </Text>
              </>
            )}
          </Flex>
          {!loading && (
            <HStack justify="center" fontSize="sm" spacing={5}>
              {reports.byPartnerAndMethod
                ?.filter(r => !r._id.partner)
                .map(r => (
                  <VStack spacing={0}>
                    <Box
                      textTransform="uppercase"
                      fontWeight="bold"
                      fontSize="xs"
                    >
                      {r._id.method}
                    </Box>
                    <Box>
                      Rs.{' '}
                      {reports.byPartnerAndMethod
                        .filter(rep => rep._id.method === r._id.method)
                        .reduce((a, b) => a + b.totalRevenue, 0)
                        .toLocaleString() || 0}
                    </Box>
                    <Box>
                      {reports.byPartnerAndMethod
                        .filter(rep => rep._id.method === r._id.method)
                        .reduce((a, b) => a + b.count, 0)
                        .toLocaleString()}{' '}
                      orders
                    </Box>
                  </VStack>
                ))}
            </HStack>
          )}
        </Card>
        <Card>
          <Text>Revenue lost from canceled orders</Text>
          <Flex
            py={6}
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            {loading ? (
              <Spinner />
            ) : (
              <>
                <Text
                  fontSize="4xl"
                  fontWeight="bold"
                  color={declinedReport?.totalRevenue && 'red.600'}
                >
                  {declinedReport?.totalRevenue?.toLocaleString() || 0}
                </Text>
                <Text fontSize="sm" color="gray.600">
                  {declinedReport?.count?.toLocaleString() || 0} cancelled
                  orders
                </Text>
              </>
            )}
          </Flex>
        </Card>
        <Card>
          <Text>Your discounts</Text>
          <Flex
            py={6}
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            {loading ? (
              <Spinner />
            ) : (
              <>
                <Text fontSize="4xl" fontWeight="bold">
                  {completedReport?.totalDiscount?.toLocaleString() || 0}
                </Text>
              </>
            )}
          </Flex>
        </Card>
        <Card>
          <Text>Food Street Promo Discounts</Text>
          <Flex
            py={6}
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            {loading ? (
              <Spinner />
            ) : (
              <>
                <Text fontSize="4xl" fontWeight="bold">
                  {completedReport?.totalCouponDiscount?.toLocaleString() || 0}
                </Text>
              </>
            )}
          </Flex>
        </Card>
        <Card>
          <Text>Food Street Dine-In Discounts</Text>
          <Flex
            py={6}
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            {loading ? (
              <Spinner />
            ) : (
              <>
                <Text fontSize="4xl" fontWeight="bold">
                  {completedReport?.totalQrCodeDiscount?.toLocaleString() || 0}
                </Text>
              </>
            )}
          </Flex>
        </Card>
        <Card>
          <Text>Delivery fees</Text>
          <Flex
            py={6}
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            {loading ? (
              <Spinner />
            ) : (
              <>
                <Text fontSize="4xl" fontWeight="bold">
                  {completedReport?.totalDelivery?.toLocaleString() || 0}
                </Text>
              </>
            )}
          </Flex>
          {!loading && deliveryByBykea && (
            <HStack justify="center" fontSize="sm" spacing={5}>
              <VStack spacing={0}>
                <Box textTransform="uppercase" fontWeight="bold" fontSize="xs">
                  bykea
                </Box>
                <Box>Rs. {deliveryByBykea.toLocaleString() || 0}</Box>
              </VStack>
            </HStack>
          )}
        </Card>
        <Card>
          <Text>Taxes</Text>
          <Flex
            py={6}
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            {loading ? (
              <Spinner />
            ) : (
              <>
                <Text fontSize="4xl" fontWeight="bold">
                  {completedReport?.totalTax?.toLocaleString() || 0}
                </Text>
              </>
            )}
          </Flex>
        </Card>
      </Grid>
    </Box>
  );
};

const mapStateToProps = state => ({
  reportIds: selectReports(state),
  reports: selectReports(state),
  params: selectParams(state),
  loading: getLoading(state),
});

const mapDispatchToProps = {
  getReportsAction: getReports,
};

const propsAreEqual = (p, n) =>
  p.reportIds?.toString() === n.reportIds?.toString() &&
  p.loading === n.loading;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(DashboardScreen, propsAreEqual));
