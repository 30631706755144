import React from 'react';
import {
  Box,
  HStack,
  Text,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import { Plus } from 'react-feather';
import { connect } from 'react-redux';

import AddAreaModal from './ManagementModals/AddAreaModal';
import AddTableModal from './ManagementModals/AddTableModal';

import { getTab } from '../redux/selectors';

/* =============================================================================
<ManagementHeader />
============================================================================= */
const ManagementHeader = ({ tab }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <HStack mb={5} flex={1} justify="space-between">
      <Box mr={5}>
        <Text fontSize="lg" fontWeight="medium">Area & Table Management</Text>
        <Text fontSize="sm">Set Area & Table Management</Text>
      </Box>
      {tab === 0 && (
      <>
        <AddAreaModal isOpen={isOpen} onClose={onClose} />
        <Button leftIcon={<Plus />} onClick={onOpen}>
          Add New Area
        </Button>
      </>
      )}
      {tab === 1 && (
        <>
          <AddTableModal isOpen={isOpen} onClose={onClose} />
          <Button leftIcon={<Plus />} onClick={onOpen}>
            Add New Table
          </Button>
        </>
      )}
    </HStack>
  );
};

const mapStateToProps = (state) => ({
  tab: getTab(state),
});

/* Export
============================================================================= */
export default connect(mapStateToProps)(ManagementHeader);
