import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Check } from 'react-feather';
import {
  Button,
  HStack,
  Spinner,
  useToast,
  useDisclosure,
} from '@chakra-ui/react';

import OrderCancelModal from '../../../common/OrderCancelModal';
import getCircularReplacer from '../../../util/json-circular';

import { getOrder } from '../../../entities/redux/selectors';
import {
  updateOrder as updateOrderAction,
  updateBykeaOrder as updateBykeaOrderAction,
} from '../../redux/actions';

/* =============================================================================
<OrdersNewActions />
============================================================================= */
const OrdersNewActions = ({ order, updateOrder, updateBykeaOrder }) => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const {
    isOpen: cancelModalIsOpen,
    onOpen: onCancelModalOpen,
    onClose: onCancelModalClose,
  } = useDisclosure();

  const orderId = order?.id;
  const orderPartner = order?.partner;

  const _handleCancelConfirm = async declineReason => {
    if (orderPartner === 'bykea') {
      await updateBykeaOrder(
        {
          id: orderId,
          action: 'decline',
          declineReason,
        },
        err => {
          if (err) {
            toast({
              title: err.message,
            });
          }
        }
      );
    } else {
      await updateOrder(
        {
          id: orderId,
          action: 'decline',
          declineReason,
        },
        err => {
          if (err) {
            toast({
              title: err.message,
            });
          }
        }
      );
    }

    onCancelModalClose();
  };

  const _handleAcceptClick = async () => {
    setLoading(true);
    if (orderPartner === 'bykea') {
      await updateBykeaOrder(
        {
          id: orderId,
          action: 'accept',
        },
        err => {
          if (err) {
            toast({
              title: err.message,
            });
          } else if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({ order }, getCircularReplacer())
            );
          }
        }
      );
    } else {
      await updateOrder(
        {
          id: orderId,
          action: 'accept',
        },
        err => {
          if (err) {
            toast({
              title: err.message,
            });
          } else if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({ order }, getCircularReplacer())
            );
          }
        }
      );
    }
    setLoading(false);
  };

  return (
    <HStack flex={1} justify="flex-end">
      {loading && <Spinner size="sm" mr={1} />}
      <Button
        mr={1}
        flex={0.2}
        variant="outline"
        colorScheme="gray"
        onClick={onCancelModalOpen}
      >
        Cancel
      </Button>
      <Button
        flex={[1, 1, 0.4]}
        leftIcon={<Check size="20px" />}
        onClick={_handleAcceptClick}
      >
        Accept
      </Button>
      <OrderCancelModal
        isOpen={cancelModalIsOpen}
        onClose={onCancelModalClose}
        onSubmit={_handleCancelConfirm}
      />
    </HStack>
  );
};

const mapStateToProps = (state, { id }) => ({
  order: getOrder(state, { id }),
});

const mapDispatchToProps = {
  updateOrder: updateOrderAction,
  updateBykeaOrder: updateBykeaOrderAction,
};

const propsAreEqual = (prevProps, nextProps) =>
  prevProps.order?.id === nextProps.order?.id
  && prevProps.order?.partner === nextProps.order?.partner;

/* Export
============================================================================= */
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(OrdersNewActions, propsAreEqual));
