import React from 'react';
import { Search, Plus } from 'react-feather';
import {
  Box,
  HStack,
  Text,
  Input,
  Button,
  Divider,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';

/* =============================================================================
<EmployeesHeader />
============================================================================= */
const EmployeesHeader = ({ onOpen }) => (
  <Box mb={6}>
    <HStack justify="space-between">
      <Box>
        <Text fontSize="lg" fontWeight="medium">Employees Managements</Text>
        <Text>Set employees</Text>
      </Box>
      <Button leftIcon={<Plus size="16px" />} onClick={onOpen}>Add New Employee</Button>
    </HStack>
    <Divider mt={6} mb={10} />
    <HStack justify="space-between" px={6}>
      <Text fontSize="lg" fontWeight="medium">Employee Table</Text>
      <InputGroup maxW="320px">
        <InputLeftElement><Search size="15px" /></InputLeftElement>
        <Input placeholder="Search" />
      </InputGroup>
    </HStack>
  </Box>
);

/* Export
============================================================================= */
export default EmployeesHeader;
