import React from 'react';
import {
  Box,
  Text,
  HStack,
  Divider,
  useDisclosure,
} from '@chakra-ui/react';
import moment from 'moment';
import { connect } from 'react-redux';

import EditDiscountModal from '../EditDiscountModal/index';

import { getDiscount } from '../../../../entities/redux/selectors';

/* =============================================================================
<DiscountHeader />
============================================================================= */
const DiscountHeader = ({ id, discount }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const title = discount?.name;
  const type = discount?.type === 'percentage' ? '%' : 'Rs';
  const value = discount?.value;
  const startAt = moment(discount?.startAt).format('DD MM YYYY');
  const endAt = moment(discount?.endAt).format('DD MM YYYY');

  return (
    <>
      <Divider my={6} />
      <HStack flex={1} justify="space-between">
        <Box>
          <Text fontSize="lg" fontWeight="semibold">{title}</Text>
          <Text fontSize="sm">{`Discount ${value}${type} From ${startAt} to ${endAt}`}</Text>
        </Box>
        <Text fontSize="sm" color="blue.600" onClick={onOpen}>Edit Discount</Text>
        <EditDiscountModal discountId={id} isOpen={isOpen} onClose={onClose} />
      </HStack>
    </>
  );
};

const mapStateToProps = (state, { id }) => ({
  discount: getDiscount(state, { id }),
});

// eslint-disable-next-line max-len
const propsAreEqual = (prevProps, nextProps) => prevProps.discounts?.toString() === nextProps.discounts?.toString()
 && prevProps.discount?.name === nextProps.discount?.name
 && prevProps.discount?.type === nextProps.discount?.type
 && prevProps.discount?.value === nextProps.discount?.value
 && prevProps.discount?.startAt === nextProps.discount?.startAt
 && prevProps.discount?.endAt === nextProps.discount?.endAt;

/* Export
============================================================================= */
export default connect(mapStateToProps)(React.memo(DiscountHeader, propsAreEqual));
