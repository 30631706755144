import React from 'react';
import { connect } from 'react-redux';
import { Box, Tag, Flex, Text } from '@chakra-ui/react';

import { getOrder } from '../../../entities/redux/selectors';
import { getRestaurant } from '../../../restaurant/redux/selectors';

/* =============================================================================
<OrderCardPartner />
============================================================================= */
const OrderCardPartner = ({ order }) => {
  if (order?.partner === 'bykea') {
    const partnerId = order.bykea?.batchNo;
    const partnerStatus = order.bykea?.status;

    return (
      <Box mb={3}>
        <Flex align="center" justify="space-between">
          <Tag
            bg="green.500"
            variant="subtle"
            color="white"
            textTransform="capitalize"
          >
            {order.partner}
          </Tag>
          {partnerStatus && (
            <Tag
              mr={-5}
              variant="subtle"
              colorScheme="green"
              textTransform="capitalize"
              borderRightRadius={0}
            >
              {STATUSES.bykea[partnerStatus] || 'Unknown'}
            </Tag>
          )}
        </Flex>
        {partnerId && (
          <Text fontSize="xs" color="green.700" mt={1}>{`#${partnerId}`}</Text>
        )}
      </Box>
    );
  }

  return null;
};

const STATUSES = {
  bykea: {
    created: 'Requested',
    accepted: 'Accepted',
    arrived: 'Arrived',
    started: 'Dispatched',
    opened: 'Requested',
    expired: 'Expired',
    finished: 'Finished',
    cancelled: 'Cancelled',
  },
};

const mapStateToProps = (state, { id }) => ({
  order: getOrder(state, { id }),
  restaurant: getRestaurant(state),
});

const propsAreEqual = (prevProps, nextProps) => (
  prevProps.order?.partner === nextProps.order?.partner
  && prevProps.order?.bykea.status === nextProps.order?.bykea.status
  && prevProps.order?.bykea.batchNo === nextProps.order?.bykea.batchNo
);

/* Export
============================================================================= */
export default connect(mapStateToProps)(React.memo(OrderCardPartner, propsAreEqual));
