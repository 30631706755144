import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Box,
  Text,
  HStack,
  Button,
} from '@chakra-ui/react';

import { getOrder } from '../../../entities/redux/selectors';

/* =============================================================================
<OrderCardDishes />
============================================================================= */
const OrderCardDishes = ({ order }) => {
  const [showMore, setShowMore] = useState(false);
  const showToggleBtn = Boolean(order?.items?.find((item) => item?.options?.length > 0));

  const _handleShowMore = () => {
    setShowMore((prevState) => !prevState);
  };

  const renderShowMore = () => order?.items?.map((item, i) => {
    const { name } = item;
    const { variant } = item;
    const { qty } = item;
    const { options } = item;
    const discount = item.discount || 0;
    const price = Math.round((item.price - discount) * qty);

    return (
      <Box key={i} mb={3}>
        <HStack justify="space-between">
          <HStack>
            <Text fontSize="sm" fontWeight="semibold">
              {qty}
              X
            </Text>
            {variant ? (
              <Text fontSize="sm" fontWeight="semibold" color="brand.700">
                {name}
                {' '}
                (
                {variant}
                )
              </Text>
            ) : (
              <Text fontSize="sm" fontWeight="semibold" color="brand.700">
                {name}
              </Text>
            )}
          </HStack>
          <Text fontSize="sm" fontWeight="semibold">
            Rs
            {' '}
            {price}
          </Text>
        </HStack>
        {options?.map((option, index) => (
          <HStack key={index} ml={8} justify="space-between">
            <Text fontSize="xs" fontWeight="semibold">
              {option.name}
              :
              {' '}
              {option?.values?.map((value) => value)?.join(', ')}
            </Text>
            <Text fontSize="sm" fontWeight="semibold">
              Rs
              {' '}
              {(option.price || 0) * qty}
            </Text>
          </HStack>
        ))}
      </Box>
    );
  });

  const renderShowLess = () => order?.items?.map((item, i) => {
    const name = item?.name;
    const variant = item?.variant;
    const qty = item?.qty;
    const discount = item.discount || 0;
    const optionsPrice = item.options
      ?.map((option) => (option.price || 0)).reduce((a, b) => a + b, 0) || 0;
    const price = Math.round(((item.price - discount) + optionsPrice) * item.qty);

    return (
      <HStack key={i} mb={3} justify="space-between">
        <Text fontSize="sm" fontWeight="semibold">
          {qty}
          X
        </Text>
        {variant ? (
          <Text flex={1} fontSize="sm" fontWeight="semibold" color="brand.700">
            {name}
            {' '}
            (
            {variant}
            )
          </Text>
        ) : (
          <Text flex={1} fontSize="sm" fontWeight="semibold" color="brand.700">
            {name}
          </Text>
        )}
        <Text fontSize="sm" fontWeight="semibold">
          Rs
          {' '}
          {price}
        </Text>
      </HStack>
    );
  });

  const renderToggleButton = () => (
    <HStack mb={3} justify="center">
      <Button
        variant="link"
        onClick={_handleShowMore}
      >
        {showMore ? 'Show less' : 'Show more'}
      </Button>
    </HStack>
  );

  return (
    <Box>
      <Text mb={1} fontSize="sm">Order info</Text>
      {showMore
        ? renderShowMore()
        : order?.items?.length > 0 && renderShowLess()}
      {showToggleBtn && renderToggleButton()}
    </Box>
  );
};

const mapStateToProps = (state, { id }) => ({
  order: getOrder(state, { id, normalize: true }),
});

const propsAreEqual = (prevProps, nextProps) => (
  JSON.stringify(prevProps.order?.items) === JSON.stringify(nextProps.order?.items)
);

/* Export
============================================================================= */
export default connect(mapStateToProps)(React.memo(OrderCardDishes, propsAreEqual));
