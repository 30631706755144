import React from 'react';
import { connect } from 'react-redux';
import {
  Avatar,
  Box,
  HStack,
  Td,
  Text,
} from '@chakra-ui/react';
import { Phone } from 'react-feather';

import { getOrder } from '../../../../entities/redux/selectors';

/* =============================================================================
<OrderHistoryTableItemCustomer />
============================================================================= */
const OrderHistoryTableItemCustomer = ({ order }) => {
  const isCustomer = Boolean(order?.customer);
  const phone = isCustomer ? order?.customer?.phone : order?.meta?.phone || '--';
  const avatar = order?.customer?.profile?.avatar;
  const username = isCustomer
    ? `${order.customer.profile.firstName} ${order.customer.profile.lastName}` : order?.meta?.name || '--';

  return (
    <Td>
      <HStack>
        <Avatar w="40px" h="40px" src={avatar} />
        <Box>
          <Text>{username}</Text>
          <HStack>
            <Phone size="12px" />
            <Text noOfLines={1}>{phone}</Text>
          </HStack>
        </Box>
      </HStack>
    </Td>
  );
};

const mapStateToProps = (state, { id }) => ({
  order: getOrder(state, { id }),
});

// eslint-disable-next-line max-len
const propsAreEqual = (prevProps, nextProps) => prevProps.order?.customer === nextProps.order?.customer
  && prevProps.order?.customer?.profile === nextProps.order?.customer?.profile
  && prevProps.order?.customer?.phone === nextProps.order?.customer?.phone;

/* Export
============================================================================= */
export default connect(mapStateToProps)(React.memo(OrderHistoryTableItemCustomer, propsAreEqual));
