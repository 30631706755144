import React from 'react';
import { connect } from 'react-redux';
import { Box } from '@chakra-ui/react';

import MenusFeaturedItem from './MenusFeaturedItem';

import { getMenu } from '../../../../entities/redux/selectors';

/* =============================================================================
<MenusFeatured />
============================================================================= */
const MenusFeatured = ({ dishes, onItemClick }) => (
  <Box mt={4}>
    {dishes?.map((dish) => (
      <MenusFeaturedItem
        id={dish}
        key={dish}
        onClick={(event) => onItemClick(dish, event)}
      />
    ))}
  </Box>
);

const mapStateToProps = (state, { menu }) => ({
  dishes: getMenu(state, { id: menu, normalize: true })?.featured,
});

// eslint-disable-next-line max-len
const propsAreEqual = (prevProps, nextProps) => prevProps.dishes?.toString() === nextProps.dishes?.toString();

/* Export
============================================================================= */
export default connect(mapStateToProps)(React.memo(MenusFeatured, propsAreEqual));
