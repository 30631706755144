import React from 'react';
import { connect } from 'react-redux';
import { Plus } from 'react-feather';
import {
  Box,
  Text,
  Button,
  HStack,
  useDisclosure,
} from '@chakra-ui/react';

import Card from '../../common/Card';
import CreateMenuModal from './MenuModals/MenuModals/CreateMenuModal';

import { getRestaurant } from '../../restaurant/redux/selectors';

/* =============================================================================
<MenuHeader />
============================================================================= */
const MenuHeader = ({ restaurant }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isBranch = restaurant?.type === 'branch';

  return (
    <Card as={HStack} justify="space-between">
      <Box>
        <Text fontSize="2xl" fontWeight="bold">Menu Management</Text>
        <Text fontSize="sm">Make changes to your menu items, prices and photos easily</Text>
      </Box>
      {!isBranch && (
        <>
          <Button onClick={onOpen} leftIcon={<Plus />}>Create Menu</Button>
          <CreateMenuModal isOpen={isOpen} onClose={onClose} />
        </>
      )}
    </Card>
  );
};

const mapStateToProps = (state) => ({
  restaurant: getRestaurant(state),
});

// eslint-disable-next-line max-len
const propsAreEqual = (prevProps, nextProps) => prevProps.restaurant?.type === nextProps.restaurant?.type;

/* Export
============================================================================= */
export default connect(mapStateToProps)(React.memo(MenuHeader, propsAreEqual));
