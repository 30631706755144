import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  HStack,
  Button,
  useToast,
} from '@chakra-ui/react';
import { Check } from 'react-feather';

import { getBooking } from '../../../../entities/redux/selectors';
import { updateBooking as updateBookingAction } from '../../../redux/actions';

/* =============================================================================
<ReservationAcceptedAction />
============================================================================= */
const ReservationAcceptedAction = ({ booking, updateBooking }) => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const _handleMarkAsCompletedClick = async () => {
    setLoading(true);
    await updateBooking({
      id: booking?.id,
      action: 'complete',
    }, (err) => {
      if (err) {
        toast({
          title: err.message,
          duration: 3000,
          status: 'error',
          isClosable: true,
        });
      }
    });
    setLoading(false);
  };

  return (
    <HStack>
      <Button
        isLoading={loading}
        leftIcon={<Check size="20px" />}
        onClick={_handleMarkAsCompletedClick}
      >
        Mark as completed
      </Button>
    </HStack>
  );
};

const mapStateToProps = (state, { id }) => ({
  booking: getBooking(state, { id }),
});

const mapDispatchToProps = {
  updateBooking: updateBookingAction,
};

// eslint-disable-next-line max-len
const propsAreEqual = (prevProps, nextProps) => prevProps.booking?.id === nextProps.booking?.id;

/* Export
============================================================================= */
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(ReservationAcceptedAction, propsAreEqual));
