import React, { useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import { Printer } from 'react-feather';
import {
  Box, Button, useMediaQuery,
} from '@chakra-ui/react';

import OrderReceipt from '../../../common/OrderReceipt';
import usePrint from '../../../util/usePrint';

import { getOrder } from '../../../entities/redux/selectors';

/* =============================================================================
<OrdersAcceptedActions />
============================================================================= */
const OrdersCompletedActions = ({ id, order }) => {
  const orderReceiptRef = useRef(null);
  const [isLargerThen766] = useMediaQuery('(min-width: 766px)');

  // eslint-disable-next-line max-len
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const reactToPrintContent = useCallback(
    () => orderReceiptRef.current,
    [],
  );

  const _handlePrintClick = usePrint({contentEl: reactToPrintContent, order});

  return (
    <>
      <Box display="none">
        <Box ref={orderReceiptRef}>
          <OrderReceipt id={id} />
        </Box>
      </Box>
      {isLargerThen766 ? (
        <Button
          mx={1}
          variant="outline"
          colorScheme="gray"
          leftIcon={<Printer size="20px" />}
          onClick={_handlePrintClick}
        >
          Print
        </Button>
      ) : (
        <Button
          w="20%"
          variant="outline"
          colorScheme="gray"
          onClick={_handlePrintClick}
        >
          Print
        </Button>
      )}
    </>
  );
};

const mapStateToProps = (state, { id }) => ({
  order: getOrder(state, { id }),
});

const propsAreEqual = (prevProps, nextProps) => prevProps.order?.id === nextProps.order?.id;

/* Export
============================================================================= */
export default connect(
  mapStateToProps,
  null,
)(React.memo(OrdersCompletedActions, propsAreEqual));
