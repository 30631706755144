import { actionGenerator } from '../../util/reduxHelpers';

export const TYPE_SET = 'ORDER_HISTORY/TYPE_SET';
export const STATUS_SET = 'ORDER_HISTORY/STATUS_SET';
export const ORDERS_GET = actionGenerator(
  'ORDER_HISTORY/ORDERS_GET',
);
export const ORDERS_REFRESH = actionGenerator(
  'ORDER_HISTORY/ORDERS_REFRESH',
);
