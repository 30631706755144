import React from 'react';
import {
  Th,
  Box,
  Text,
  HStack,
} from '@chakra-ui/react';

/* =============================================================================
<ExtraChoicesTableHeadItem />
============================================================================= */
const ExtraChoicesTableHeadItem = ({ title }) => {
  if (title === 'Choice Groups') {
    return <Th textTransform="none" fontSize="sm" fontWeight="500">{title}</Th>;
  }
  if (title === 'Available Choices') {
    return (
      <Th textTransform="none" fontSize="sm" fontWeight="500">
        <HStack>
          <Box w="20px" h="20px" borderRadius="6px" bg="#22C55E" />
          <Text>{title}</Text>
        </HStack>
      </Th>
    );
  }
  if (title === 'Unavailable Choices') {
    return (
      <Th textTransform="none" fontSize="sm" fontWeight="500">
        <HStack>
          <Box w="20px" h="20px" borderRadius="6px" bg="#EF4444" />
          <Text>{title}</Text>
        </HStack>
      </Th>
    );
  }
  return <Th textTransform="none" fontSize="sm" fontWeight="500">{title}</Th>;
};

/* Export
============================================================================= */
export default ExtraChoicesTableHeadItem;
