import React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { useToast } from '@chakra-ui/react';

import OwnerForm from './OwnerForm';
import RestaurantForm from './RestaurantForm';
import RestaurantInformationHeader from './RestaurantInformationHeader';
import RestaurantInformationInitialize from './RestaurantInformationInitialize';
import { uploadRestaurantPhoto } from '../../util/upload';

import { updateRestaurantInfo as updateRestaurantInfoAction } from '../../restaurant/redux/actions';

/* =============================================================================
<RestaurantInformation />
============================================================================= */
const RestaurantInformation = ({ updateRestaurantInfo }) => {
  const toast = useToast();

  const _handleSubmit = async (values) => {
    const data = {
      name: values.name,
      email: values.email,
      about: values.about,
      phone: values.phone,
      address: values.address,
      website: values.website,
      minOrder: values.minOrder,
      deliveryTime: values.deliveryTime,
      couponAcceptability: values.couponAcceptability,
      owner: values.owner,
    };

    if (values.photo) {
      const photoURL = await uploadRestaurantPhoto(values.photo);
      data.photo = photoURL;
    }

    await updateRestaurantInfo(data, (err) => {
      if (!err) {
        toast({
          title: 'Changes Saved',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: err.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={RestaurantInformationSchema}
      onSubmit={_handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <RestaurantInformationHeader isSubmitting={isSubmitting} />
          <RestaurantForm />
          <OwnerForm />
          <RestaurantInformationInitialize />
        </Form>
      )}
    </Formik>
  );
};

const initialValues = {
  name: '',
  about: '',
  address: '',
  website: '',
  phone: {
    branch: '',
    delivery1: '',
    delivery2: '',
  },
  email: '',
  deliveryTime: '',
  minOrder: '',
  couponAcceptability: '',
  owner: {
    firstName: '',
    lastName: '',
    // email: '',
    phone: '',
  },
};

const RestaurantInformationSchema = Yup.object().shape({
  about: Yup.string()
    .required('Must not be empty!'),
  address: Yup.string()
    .required('Must not be empty!'),
  phone: Yup.object().shape({
    branch: Yup.string()
      .matches(/^\+[1-9]\d{10,14}$/, 'Must be a valid phone number in E164 format!')
      .required('Must not be empty!'),
    delivery1: Yup.string()
      .matches(/^\+[1-9]\d{10,14}$/, 'Must be a valid phone number in E164 format!')
      .required('Must not be empty!'),
    delivery2: Yup.string()
      .matches(/^\+[1-9]\d{10,14}$/, 'Must be a valid phone number in E164 format!')
      .optional(),
  }),
  email: Yup.string()
    .email('Must be a valid email')
    .required('Must not be empty!'),
  deliveryTime: Yup.number()
    .optional(),
  minOrder: Yup.number()
    .optional(),
  couponAcceptability: Yup.string()
    .optional(),
  owner: Yup.object().shape({
    firstName: Yup.string()
      .required('Must not be empty!'),
    lastName: Yup.string()
      .required('Must not be empty!'),
    // email: Yup.string()
    //   .email('Must be a valid email')
    //   .required('Must not be empty!'),
    phone: Yup.string()
      // .matches(/^\+[1-9]\d{10,14}$/, 'Must be a valid phone number in E164 format!')
      .required('Must not be empty!'),
  }),
});

const mapDispatchToProps = {
  updateRestaurantInfo: updateRestaurantInfoAction,
};

/* Export
============================================================================= */
export default connect(null, mapDispatchToProps)(RestaurantInformation);
