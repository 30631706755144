import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import {
  Modal,
  Divider,
  ModalBody,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  useToast,
} from '@chakra-ui/react';

import MenuFooter from '../MenuFooter';
import MenuSchedule from '../MenuSchedule';
import MenuInformation from '../MenuInformation';

import { createMenu as createMenuAction } from '../../../../redux/actions';

/* =============================================================================
<CreateMenuModal />
============================================================================= */
const CreateMenuModal = ({ isOpen, onClose, createMenu }) => {
  const toast = useToast();

  const _handleSubmit = async (values) => {
    const payload = {
      ...values,
      hours: values.hours.map((hour) => ({
        active: hour.active,
        hours: hour.hours.map((h) => ({
          start: (moment(h.start, 'hh:mm').hours() * 60) + moment(h.start, 'hh:mm').minutes(),
          end: (moment(h.end, 'hh:mm').hours() * 60) + moment(h.end, 'hh:mm').minutes(),
        })),
      })),
    };

    await createMenu(payload, (err) => {
      if (!err) {
        toast({
          title: 'Menu created',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        onClose();
      } else {
        toast({
          title: err.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    });
  };

  return (
    <Modal size="3xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="lg">Create Menu</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Divider mb={5} />
          <Formik
            initialValues={INITIAL_VALUES}
            validationSchema={CreateMenuSchema}
            onSubmit={_handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <MenuInformation />
                <MenuSchedule />
                <MenuFooter isSubmitting={isSubmitting} onCancel={onClose} />
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const INITIAL_VALUES = {
  name: '',
  about: '',
  hours: [
    {
      active: false,
      hours: [
        { start: '00:00', end: '23:59' },
      ],
    },
    {
      active: false,
      hours: [
        { start: '00:00', end: '23:59' },
      ],
    },
    {
      active: false,
      hours: [
        { start: '00:00', end: '23:59' },
      ],
    },
    {
      active: false,
      hours: [
        { start: '00:00', end: '23:59' },
      ],
    },
    {
      active: false,
      hours: [
        { start: '00:00', end: '23:59' },
      ],
    },
    {
      active: false,
      hours: [
        { start: '00:00', end: '23:59' },
      ],
    },
    {
      active: false,
      hours: [
        { start: '00:00', end: '23:59' },
      ],
    },
  ],
};

const CreateMenuSchema = Yup.object().shape({
  name: Yup.string()
    .required('Must not be empty!'),
  about: Yup.string()
    .optional('Must not be empty!'),
});

const mapDispatchToProps = {
  createMenu: createMenuAction,
};

/* Export
============================================================================= */
export default connect(null, mapDispatchToProps)(CreateMenuModal);
