import React from "react";
import { FormControl, Select } from '@chakra-ui/react';

const OrderSetupOnlinePaymentType = ({ onlinePayType, onSelect }) => (
  <FormControl mt={4}>
    <Select
      defaultValue="card"
      value={onlinePayType}
      placeholder="Select"
      textTransform="capitalize"
      onChange={event => onSelect(event.target.value)}
    >
      <option key="card" value="card">
        Card
      </option>
      <option key="room" value="room">
        Room
      </option>
      <option key="btc" value="btc">
        BTC (Bill To Company)
      </option>
      <option key="official" value="official">
        Official
      </option>
      <option key="member" value="member">
        Member
      </option>
    </Select>
  </FormControl>
);

export default OrderSetupOnlinePaymentType;
