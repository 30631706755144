import React from 'react';
import { connect } from 'react-redux';
import { Text, Tag, Flex } from '@chakra-ui/react';

import { getOrder } from '../../../entities/redux/selectors';
import { getRestaurant } from '../../../restaurant/redux/selectors';

/* =============================================================================
<OrderCardId />
============================================================================= */
const OrderCardId = ({ order, restaurant }) => {
  const orderTag = order?.tag;
  const orderType = ORDER_TYPES[order?.type];
  const branchName = restaurant?.type === 'chain' && order?.restaurant?.address?.branch;

  return (
    <>
      <Flex mb={2} align="center" justify="space-between">
        <Tag variant="subtle" colorScheme="red">{orderType}</Tag>
        {Boolean(branchName) && (
          <Tag
            mr={-5}
            bg="brand.800"
            variant="subtle"
            color="white"
            borderRightRadius={0}
          >
            {branchName}
          </Tag>
        )}
      </Flex>
      <Text fontSize="sm" color="brand.700" mb={2}>{`#${orderTag}`}</Text>
    </>
  );
};

const ORDER_TYPES = {
  gift: 'Gift',
  dine_in: 'Dine In',
  delivery: 'Delivery',
  take_away: 'Takeaway',
};

const mapStateToProps = (state, { id }) => ({
  order: getOrder(state, { id }),
  restaurant: getRestaurant(state),
});

const propsAreEqual = (prevProps, nextProps) => (
  prevProps.order?.tag === nextProps.order?.tag
  && prevProps.order?.type === nextProps.order?.type
  && prevProps.restaurant?.type === nextProps.restaurant?.type
  && prevProps.order?.restaurant?.address?.branch === nextProps.order?.restaurant?.address?.branch
);

/* Export
============================================================================= */
export default connect(mapStateToProps)(React.memo(OrderCardId, propsAreEqual));
