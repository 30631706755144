import React from 'react';
import { connect } from 'react-redux';
import { Draggable } from 'react-beautiful-dnd';
import { MoreVertical, Edit2 } from 'react-feather';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Flex,
  Text,
  ListItem,
  useDisclosure,
} from '@chakra-ui/react';

import EditCategoryModal from '../../MenuModals/EditCategoryModal';

import { getMenuItem } from '../../../../entities/redux/selectors';
import { getRestaurant } from '../../../../restaurant/redux/selectors';

/* =============================================================================
<MenuListItem />
============================================================================= */
const MenuListItem = ({
  id,
  index,
  menuId,
  menuItem,
  restaurant,
}) => {
  const history = useHistory();
  const location = useLocation();
  const {
    isOpen,
    onOpen,
    onClose,
  } = useDisclosure();

  const name = menuItem?.name;
  const selected = location.pathname.includes(id);
  const isBranch = restaurant?.type === 'branch';

  const _handleClick = (e) => {
    history.push(`/menu/${menuId}/${id}`);
  };

  const _handleEditClick = (e) => {
    e.stopPropagation();
    onOpen();
  };

  return (
    <Draggable draggableId={id} index={index}>
      {(provided) => (
        <ListItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          style={provided.draggableProps.style}
          height={height}
          display="flex"
          alignItems="center"
          pl={1}
          m={0}
          borderRadius="lg"
          onClick={_handleClick}
        >
          {!isBranch && (
            <div {...provided.dragHandleProps}>
              <MoreVertical size={18} />
            </div>
          )}
          <Flex
            flex={1}
            height="40px"
            ml={2}
            pr={2}
            alignItems="center"
            borderRadius="6px"
            bg={selected && 'red.100'}
          >
            <Text
              mx={4}
              flex={1}
              noOfLines={1}
              fontWeight="medium"
              color={selected && 'red.700'}
            >
              {name}
            </Text>
            {!isBranch && (
              <Edit2 size={14} onClick={_handleEditClick} />
            )}
          </Flex>
          {!isBranch && (
            <EditCategoryModal
              id={id}
              isOpen={isOpen}
              onClose={onClose}
            />
          )}
        </ListItem>
      )}
    </Draggable>
  );
};

const height = '48px';

const mapStateToProps = (state, { id }) => ({
  menuItem: getMenuItem(state, { id, normalize: true }),
  restaurant: getRestaurant(state),
});

const propsAreEqual = (prevProps, nextProps) => prevProps.id === nextProps.id
  && prevProps.index === nextProps.index
  && prevProps.menuId === nextProps.menuId
  && prevProps.menuItem?.name === nextProps.menuItem?.name
  && prevProps.restaurant?.type === nextProps.restaurant?.type;

/* Export
============================================================================= */
export default connect(mapStateToProps)(React.memo(MenuListItem, propsAreEqual));
