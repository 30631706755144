import React from 'react';
import { connect } from 'react-redux';
import { User } from 'react-feather';
import {
  Box,
  Text,
  HStack,
  Avatar,
} from '@chakra-ui/react';

import { getOrder } from '../../../entities/redux/selectors';

/* =============================================================================
<OrderCardRider />
============================================================================= */
const OrderCardRider = ({ order }) => {
  if (!order?.rider) {
    return null;
  }

  const username = order?.rider?.profile
    ? `${order.rider.profile.firstName} ${order.rider.profile.lastName}` : '';
  const avatar = order?.rider?.profile?.avatar;

  return (
    <Box>
      <Text fontSize="sm">Delivery Guy</Text>
      <HStack>
        <Avatar src={avatar} width="15px" height="15px" />
        <Text fontSize="sm">{username}</Text>
      </HStack>
    </Box>
  );
};

const mapStateToProps = (state, { id }) => ({
  order: getOrder(state, { id }),
});

const propsAreEqual = (prevProps, nextProps) => (
  prevProps.order?.rider?.profile?.firstName === nextProps.order?.rider?.profile?.firstName
    && prevProps.order?.rider?.profile?.lastName === nextProps.order?.rider?.profile?.lastName
    && prevProps.order?.rider?.profile?.avatar === nextProps.order?.rider?.profile?.avatar
);

/* Export
============================================================================= */
export default connect(mapStateToProps)(React.memo(OrderCardRider, propsAreEqual));
