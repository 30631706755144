import React from 'react';
import { Menu } from 'react-feather';
import {
  Button,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react';

import SideNavOpen from '../SideNav/SideNavOpen';

/* =============================================================================
<HeaderSideNavButton />
============================================================================= */
const HeaderSideNavButton = ({ orderCount }) => {
  const [isSmallerThan766] = useMediaQuery('(max-width: 766px)');
  const { isOpen, onOpen, onClose } = useDisclosure();

  if (isSmallerThan766 && !window.ReactNativeWebView) {
    return (
      <Button
        display="flex"
        variant="unstyled"
        onClick={onOpen}
      >
        <Menu />
        <SideNavOpen orderCount={orderCount} onClose={onClose} isOpen={isOpen} />
      </Button>
    );
  }
  return null;
};

/* Export
============================================================================= */
export default HeaderSideNavButton;
