import React from 'react';
import {
  Tab,
  Box,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
} from '@chakra-ui/react';

import OpeningSchedule from '../../common/OpeningSchedule/index';
import RestaurantInformation from '../../common/RestaurantInformation';

/* =============================================================================
<RestaurantSettings />
============================================================================= */
const RestaurantSettings = () => (
  <Box mt={6}>
    <Tabs size="sm" colorScheme="brand" isLazy>
      <TabList border="none">
        <Tab fontWeight="medium">Restaurant Details</Tab>
        <Tab fontWeight="medium">Opening Times</Tab>
      </TabList>
      <TabPanels>
        <TabPanel px={0} py={5}>
          <RestaurantInformation />
        </TabPanel>
        <TabPanel px={0} py={5}>
          <OpeningSchedule />
        </TabPanel>
      </TabPanels>
    </Tabs>
  </Box>
);

/* Export
============================================================================= */
export default RestaurantSettings;
