import * as constants from './constants';

const INITIAL_STATE = {
  menus: [],
  choiceGroups: [],
  categories: [],
  discounts: [],
  error: null,
  loading: false,
};

export default function reducer(state = INITIAL_STATE, action) {
  const { type, payload, error } = action;

  switch (type) {
    // MENUS_GET
    case constants.MENUS_GET.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.MENUS_GET.FAIL:
      return {
        ...state,
        error,
      };
    case constants.MENUS_GET.SUCCESS:
      return {
        ...state,
        error: null,
        menus: payload,
      };
    case constants.MENUS_GET.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // MENU_GET
    case constants.MENU_GET.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.MENU_GET.FAIL:
      return {
        ...state,
        error,
      };
    case constants.MENU_GET.SUCCESS:
      return {
        ...state,
        error: null,
      };
    case constants.MENU_GET.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // MENU_CREATE
    case constants.MENU_CREATE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.MENU_CREATE.FAIL:
      return {
        ...state,
        error,
      };
    case constants.MENU_CREATE.SUCCESS:
      return {
        ...state,
        error: null,
        menus: [...state.menus, payload],
      };
    case constants.MENU_CREATE.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // MENU_UPDATE
    case constants.MENU_UPDATE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.MENU_UPDATE.FAIL:
      return {
        ...state,
        error,
      };
    case constants.MENU_UPDATE.SUCCESS:
      return {
        ...state,
        error: null,
      };
    case constants.MENU_UPDATE.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // MENU_DELETE
    case constants.MENU_DELETE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.MENU_DELETE.FAIL:
      return {
        ...state,
        error,
      };
    case constants.MENU_DELETE.SUCCESS:
      return {
        ...state,
        error: null,
        menus: state.menus.filter((item) => item !== payload),
      };
    case constants.MENU_DELETE.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // MENU_ITEM
    case constants.MENU_ITEM_GET.REQUEST:
    case constants.MENU_ITEM_CREATE.REQUEST:
    case constants.MENU_ITEM_UPDATE.REQUEST:
    case constants.MENU_ITEM_DELETE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.MENU_ITEM_GET.FAIL:
    case constants.MENU_ITEM_CREATE.FAIL:
    case constants.MENU_ITEM_UPDATE.FAIL:
    case constants.MENU_ITEM_DELETE.FAIL:
      return {
        ...state,
        error,
      };
    case constants.MENU_ITEM_GET.SUCCESS:
    case constants.MENU_ITEM_CREATE.SUCCESS:
    case constants.MENU_ITEM_UPDATE.SUCCESS:
    case constants.MENU_ITEM_DELETE.SUCCESS:
      return {
        ...state,
        error: null,
      };
    case constants.MENU_ITEM_GET.COMPLETE:
    case constants.MENU_ITEM_CREATE.COMPLETE:
    case constants.MENU_ITEM_UPDATE.COMPLETE:
    case constants.MENU_ITEM_DELETE.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // DISH
    case constants.DISH_CREATE.REQUEST:
    case constants.DISH_UPDATE.REQUEST:
    case constants.DISH_DELETE.REQUEST:
    case constants.DISH_AVAILABILITY_CHANGE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.DISH_CREATE.FAIL:
    case constants.DISH_UPDATE.FAIL:
    case constants.DISH_DELETE.FAIL:
    case constants.DISH_AVAILABILITY_CHANGE.FAIL:
      return {
        ...state,
        error,
      };
    case constants.DISH_CREATE.SUCCESS:
    case constants.DISH_UPDATE.SUCCESS:
    case constants.DISH_DELETE.SUCCESS:
    case constants.DISH_AVAILABILITY_CHANGE.SUCCESS:
      return {
        ...state,
        error: null,
      };
    case constants.DISH_CREATE.COMPLETE:
    case constants.DISH_UPDATE.COMPLETE:
    case constants.DISH_DELETE.COMPLETE:
    case constants.DISH_AVAILABILITY_CHANGE.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // CHOICE_GROUPS_GET
    case constants.CHOICE_GROUPS_GET.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.CHOICE_GROUPS_GET.FAIL:
      return {
        ...state,
        error,
      };
    case constants.CHOICE_GROUPS_GET.SUCCESS:
      return {
        ...state,
        error: null,
        choiceGroups: payload,
      };
    case constants.CHOICE_GROUPS_GET.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // CHOICE_GROUP_CREATE
    case constants.CHOICE_GROUP_CREATE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.CHOICE_GROUP_CREATE.FAIL:
      return {
        ...state,
        error,
      };
    case constants.CHOICE_GROUP_CREATE.SUCCESS:
      return {
        ...state,
        error: null,
        choiceGroups: [...state.choiceGroups, payload],
      };
    case constants.CHOICE_GROUP_CREATE.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // CHOICE_GROUP_UPDATE
    case constants.CHOICE_GROUP_UPDATE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.CHOICE_GROUP_UPDATE.FAIL:
      return {
        ...state,
        error,
      };
    case constants.CHOICE_GROUP_UPDATE.SUCCESS:
      return {
        ...state,
        error: null,
      };
    case constants.CHOICE_GROUP_UPDATE.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // CHOICE_GROUP_DELETE
    case constants.CHOICE_GROUP_DELETE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.CHOICE_GROUP_DELETE.FAIL:
      return {
        ...state,
        error,
      };
    case constants.CHOICE_GROUP_DELETE.SUCCESS:
      return {
        ...state,
        error: null,
        choiceGroups: state.choiceGroups.filter((item) => item !== payload),
      };
    case constants.CHOICE_GROUP_DELETE.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // DISCOUNTS_GET
    case constants.DISCOUNTS_GET.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.DISCOUNTS_GET.FAIL:
      return {
        ...state,
        error,
      };
    case constants.DISCOUNTS_GET.SUCCESS:
      return {
        ...state,
        error: null,
        discounts: payload,
      };
    case constants.DISCOUNTS_GET.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // DISCOUNT_DISHES_UPDATE
    case constants.DISCOUNT_DISHES_UPDATE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.DISCOUNT_DISHES_UPDATE.FAIL:
      return {
        ...state,
        error,
      };
    case constants.DISCOUNT_DISHES_UPDATE.SUCCESS:
      return {
        ...state,
        error: null,
      };
    case constants.DISCOUNT_DISHES_UPDATE.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // DISCOUNT_DISH_DELETE
    case constants.DISCOUNT_DISH_DELETE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.DISCOUNT_DISH_DELETE.FAIL:
      return {
        ...state,
        error,
      };
    case constants.DISCOUNT_DISH_DELETE.SUCCESS:
      return {
        ...state,
        error: null,
      };
    case constants.DISCOUNT_DISH_DELETE.COMPLETE:
      return {
        ...state,
        loading: false,
      };

      // CATEGORIES_GET
    case constants.CATEGORIES_GET.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.CATEGORIES_GET.FAIL:
      return {
        ...state,
        error,
      };
    case constants.CATEGORIES_GET.SUCCESS:
      return {
        ...state,
        error: null,
        categories: payload,
      };
    case constants.CATEGORIES_GET.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // POS_MENUS_GET
    case constants.POS_MENUS_GET.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.POS_MENUS_GET.FAIL:
      return {
        ...state,
        error,
      };
    case constants.POS_MENUS_GET.SUCCESS:
      return {
        ...state,
        error: null,
        menus: payload,
      };
    case constants.POS_MENUS_GET.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
