import React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { Redirect } from 'react-router';

import { getUser } from '../auth/redux/selectors';
import { fromAdminPortal } from '../util/functions';

const UnAuthenticatedRoute = ({ user, children, ...rest }) => (
  <Route
    {...rest}
    render={({ location }) =>
      !user ? children : <Redirect to={fromAdminPortal(user) ? "/pos" : "/live_order"} />
    }
  />
);

const mapStateToProps = state => ({
  user: getUser(state),
});

export default connect(mapStateToProps, null)(UnAuthenticatedRoute);
