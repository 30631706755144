import React from 'react';
import {
  Tr,
  Th,
  Td,
  Box,
  Text,
  Table,
  Thead,
  Tbody,
  Select,
  HStack,
  Button,
} from '@chakra-ui/react';
import { Minus, Plus } from 'react-feather';

/* =============================================================================
<MenusAddDishInfoTable />
============================================================================= */
const MenusAddDishInfoTable = ({
  qty,
  name,
  price,
  onPlus,
  onMinus,
  variant,
  variants,
  onVariantChange,
}) => (
  <Box flex={1}>
    <Table variant="simple" colorScheme="gray">
      <Thead>
        <Tr>
          {COLUMNS.map((title) => (
            <Th
              key={title}
              bg="gray.50"
              color="#667085"
              fontSize="13px"
              borderTop="1px solid #E4E7EC"
            >
              {title}
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        <Tr>
          <Td>{name}</Td>
          <Td>
            {variants?.length ? (
              <Select value={variant} placeholder="Select" onChange={onVariantChange}>
                {variants?.map((item) => (
                  <option
                    key={item.price}
                    value={item.name}
                  >
                    {item.name}
                  </option>
                ))}
              </Select>
            ) : 'none'}
          </Td>
          <Td>
            <HStack>
              <Button
                p={0}
                size="xs"
                colorScheme="green"
                onClick={onPlus}
              >
                <Plus size="18px" />
              </Button>
              <Text>{qty}</Text>
              <Button
                p={0}
                size="xs"
                onClick={onMinus}
              >
                <Minus size="18px" />
              </Button>
            </HStack>
          </Td>
          <Td>{price}</Td>
        </Tr>
      </Tbody>
    </Table>
  </Box>
);

const COLUMNS = [
  'Food',
  'Variation',
  'Quantity',
  'Price ',
];

/* Export
============================================================================= */
export default MenusAddDishInfoTable;
