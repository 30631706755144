import React from 'react';
import {
  Input,
  HStack,
  FormLabel,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/react';
import { FastField } from 'formik';

/* =============================================================================
<EmployeeName />
============================================================================= */
const EmployeeName = () => (
  <HStack spacing={5} alignItems="flex-start">
    <FastField name="firstName">
      {({ field, form }) => (
        <FormControl
          flex={1}
          isInvalid={form.errors.firstName && form.touched.firstName}
        >
          <FormLabel fontWeight="normal">First Name</FormLabel>
          <Input id="firstName" placeholder="Ex : Sakeef Ameer" {...field} />
          <FormErrorMessage>{form.errors.firstName}</FormErrorMessage>
        </FormControl>
      )}
    </FastField>
    <FastField name="lastName">
      {({ field, form }) => (
        <FormControl
          flex={1}
          isInvalid={form.errors.lastName && form.touched.lastName}
        >
          <FormLabel fontWeight="normal">Last Name</FormLabel>
          <Input id="lastName" placeholder="Ex : Sakeef Ameer" {...field} />
          <FormErrorMessage>{form.errors.lastName}</FormErrorMessage>
        </FormControl>
      )}
    </FastField>
  </HStack>
);

/* Export
============================================================================= */
export default EmployeeName;
