import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useFormikContext } from 'formik';

import { getEmployee } from '../../../../entities/redux/selectors';

/* =============================================================================
<EmployeeEditModalInitialize />
============================================================================= */
const EmployeeEditModalInitialize = ({ employee }) => {
  const { resetForm } = useFormikContext();
  const firstName = employee?.profile.firstName;
  const lastName = employee?.profile.lastName;
  const avatar = employee?.profile.avatar;
  const phone = employee?.phone?.slice(3);
  const role = employee?.role.id;
  const email = employee?.email;
  const salary = employee?.salary;

  // Set default values
  useEffect(() => {
    resetForm({
      values: {
        firstName,
        lastName,
        avatar,
        phone,
        role,
        email,
        salary,
      },
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    firstName,
    lastName,
    avatar,
    role,
    email,
    salary,
  ]);

  return null;
};

const mapStateToProps = (state, { id }) => ({
  employee: getEmployee(state, { id }),
});

/* Export
============================================================================= */
export default connect(mapStateToProps)(EmployeeEditModalInitialize);
