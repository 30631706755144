import React from 'react';
import { Th } from '@chakra-ui/react';

/* =============================================================================
<NeighborhoodsTableHeadItem />
============================================================================= */
const NeighborhoodsTableHeadItem = ({ title, onClick }) => (
  <Th
    bg="gray.50"
    color="#667085"
    fontSize="13px"
    borderTop="1px solid #E4E7EC"
    cursor="pointer"
    onClick={onClick}
  >
    {title}
  </Th>
);

/* Export
============================================================================= */
export default NeighborhoodsTableHeadItem;
