import React from 'react';
import { FastField } from 'formik';
import {
  Input,
  VStack,
  Textarea,
  FormLabel,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
} from '@chakra-ui/react';

/* =============================================================================
<RestaurantFormAbout />
============================================================================= */
const RestaurantFormAbout = ({ disabled }) => (
  <>
    <FastField name="about">
      {({ field, form }) => (
        <FormControl
          mb={8}
          display="flex"
          isInvalid={form.errors.about && form.touched.about}
        >
          <FormLabel flex={1.5}>About</FormLabel>
          <VStack alignItems="flex-start" flex={2}>
            <Textarea id="about" {...field} disabled={disabled} />
            <FormErrorMessage>
              <FormErrorIcon />
              {form.errors.about}
            </FormErrorMessage>
          </VStack>
        </FormControl>
      )}
    </FastField>
    <FastField name="website">
      {({ field, form }) => (
        <FormControl
          mb={8}
          display="flex"
          isInvalid={form.errors.website && form.touched.website}
        >
          <FormLabel flex={1.5}>Website</FormLabel>
          <VStack alignItems="flex-start" flex={2}>
            <Input id="website" {...field} disabled={disabled} />
            <FormErrorMessage>
              <FormErrorIcon />
              {form.errors.website}
            </FormErrorMessage>
          </VStack>
        </FormControl>
      )}
    </FastField>
  </>

);

/* Export
============================================================================= */
export default RestaurantFormAbout;
