import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Image,
  Modal,
  Button,
  Divider,
  ModalBody,
  ModalHeader,
  ModalFooter,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
} from '@chakra-ui/react';

import MenusAddDishInfoTable from './MenusAddDishInfoTable';
import MenusAddExtraChoiceTable from './MenusAddExtraChoiceTable';

import { getDish as selectDish } from '../../../../entities/redux/selectors';
import { getDish as getDishActions } from '../../../redux/actions';
import { calcDishTotal } from '../../../../util/functions';

/* =============================================================================
<MenusAddDishModal />
============================================================================= */
const MenusAddDishModal = ({
  dish,
  isOpen,
  onClose,
  onAddToCart,
  getDish,
}) => {
  const [qty, setQty] = useState(1);
  const [variant, setVariant] = useState('');
  const [options, setOptions] = useState({});

  const id = dish?.id;
  const name = dish?.name;
  const variants = dish?.variants;
  const photo = dish?.photos && dish?.photos[0];
  const choiceGroups = dish?.choiceGroups;

  const { total: price } = calcDishTotal(dish, {
    variant: variants?.find((item) => item.name === variant),
  });

  // Get dish and reset state
  useEffect(() => () => {
    if (id) {
      getDish(id);
    }
    setQty(1);
    setVariant(null);
    setOptions({});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // Select first variant at start
  useEffect(() => {
    if (variants?.length) {
      setVariant(variants[0].name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variants]);

  const _handleVariantChange = (e) => {
    setVariant(e.target.value);
  };

  const _handlePlusClick = () => {
    setQty((prevState) => prevState + 1);
  };

  const _handleMinusClick = () => {
    if (qty > 1) {
      setQty((prevState) => prevState - 1);
    }
  };

  const _handleChoiceAdd = (group) => (value) => {
    setOptions((prevState) => ({
      ...prevState,
      [group]: value,
    }));
  };

  const _handleChoiceDelete = (group) => (value) => {
    const newGroup = options[group]?.filter((item) => item !== value);
    setOptions((prevState) => ({
      ...prevState,
      [group]: newGroup,
    }));
  };

  const _handleSubmit = () => {
    const total = calcDishTotal(dish, {
      extras: options,
      variant: variants?.find((item) => item.name === variant),
      quantity: qty,
    });

    onAddToCart({
      name,
      variant,
      price: total.price,
      discount: total.discount,
      qty,
      prepared: false,
      dish: id,
      options: total.extras,
    });
    onClose();
  };

  return (
    <Modal size="3xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent h="85vh">
        <ModalHeader fontSize="lg">Add Items</ModalHeader>
        <ModalCloseButton />
        <ModalBody overflowY="scroll">
          <Divider mb={5} />
          {/* <Image width="100%" height="400px" src={photo} alt={name} /> */}
          <MenusAddDishInfoTable
            qty={qty}
            name={name}
            price={price}
            variant={variant}
            variants={variants}
            onPlus={_handlePlusClick}
            onMinus={_handleMinusClick}
            onVariantChange={_handleVariantChange}
          />
          {choiceGroups?.map((group) => (
            <MenusAddExtraChoiceTable
              key={group.id}
              name={group.name}
              min={group.min}
              max={group.max}
              data={group.choices}
              value={options[group.id]}
              multiple={group.max > 1}
              required={group.min > 0}
              onSelect={_handleChoiceAdd(group.id)}
              onDelete={_handleChoiceDelete(group.id)}
            />
          ))}
        </ModalBody>
        <ModalFooter>
          <Button mr={2} flex={1} colorScheme="gray" onClick={onClose}>Cancel</Button>
          <Button ml={2} flex={1} onClick={_handleSubmit}>Add to Cart</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const mapStateToProps = (state, { id }) => ({
  dish: selectDish(state, { id }),
});

const mapDispatchToProps = {
  getDish: getDishActions,
};

const propsAreEqual = (prevProps, nextProps) => prevProps.isOpen === nextProps.isOpen
  && prevProps.dish?.id === nextProps.dish?.id
  && prevProps.dish?.name === nextProps.dish?.name
  && prevProps.dish?.price === nextProps.dish?.price
  && prevProps.dish?.discount?.type === nextProps.dish?.discount?.type
  && prevProps.dish?.discount?.value === nextProps.dish?.discount?.value
  && prevProps.dish?.photos?.toString() === nextProps.dish?.photos?.toString()
  && JSON.stringify(prevProps.dish?.variants) === JSON.stringify(nextProps.dish?.variants);

/* Export
============================================================================= */
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(MenusAddDishModal, propsAreEqual));
