import React from 'react';
import {
  FastField,
  FieldArray,
  useFormikContext,
  getIn,
} from 'formik';
import {
  Text,
  HStack,
  VStack,
  Button,
  Switch,
  Input,
  InputGroup,
  FormControl,
  InputLeftAddon,
  FormErrorMessage,
  FormErrorIcon,
} from '@chakra-ui/react';
import { Trash } from 'react-feather';

/* =============================================================================
<ReservationFormEvents />
============================================================================= */
const ReservationFormEvents = () => {
  const { values } = useFormikContext();

  const { setFieldValue } = useFormikContext();

  const _handleEventToggle = (index) => () => {
    setFieldValue(`events.${index}.active`, !values.events[index].active, false);
  };

  return (
    <HStack pr={[0, 0, 0, '20%']} alignItems="flex-start" minH="44px" mb={5}>
      <Text flex={1.4} fontSize="sm" fontWeight="semibold">Events</Text>
      <FieldArray name="events">
        {(arrayHelper) => (
          <VStack alignItems="flex-start" flex={3}>
            {values.events
              && values.events.map((event, index) => (
                <HStack w="100%" key={index} alignItems="flex-start">
                  <HStack w="130px" mt={2}>
                    <Switch
                      size="lg"
                      colorScheme="brand"
                      isChecked={event.active}
                      onChange={_handleEventToggle(index)}
                    />
                    <Text>{event.active ? 'Active' : 'Inactive'}</Text>
                  </HStack>
                  <HStack align="flex-start">
                    <FastField name={`events.${index}.name`}>
                      {({ field, form }) => (
                        <FormControl
                          w="278px"
                          isInvalid={getIn(form.errors, `events.${index}.name`) && getIn(form.touched, `events.${index}.name`)}
                        >
                          <Input {...field} placeholder="Event Name" />
                          <FormErrorMessage>
                            <FormErrorIcon />
                            {getIn(form.errors, `events.${index}.name`)}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </FastField>
                    <FastField name={`events.${index}.fee`}>
                      {({ field, form }) => (
                        <FormControl
                          w="130px"
                          isInvalid={getIn(form.errors, `events.${index}.fee`) && getIn(form.touched, `events.${index}.fee`)}
                        >
                          <InputGroup>
                            <InputLeftAddon>Rs</InputLeftAddon>
                            <Input type="number" {...field} />
                          </InputGroup>
                          <FormErrorMessage>
                            <FormErrorIcon />
                            {getIn(form.errors, `events.${index}.fee`)}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </FastField>
                  </HStack>
                  <Button
                    variant="unstlyed"
                    leftIcon={<Trash size="14px" />}
                    onClick={() => arrayHelper.remove(index)}
                  >
                    Delete
                  </Button>
                </HStack>
              ))}
            <Button
              color="red.700"
              variant="unstlyed"
              onClick={() => arrayHelper.push({
                active: true,
                name: '',
                fee: '',
              })}
            >
              + Add Event
            </Button>
          </VStack>
        )}
      </FieldArray>
    </HStack>
  );
};

/* Export
============================================================================= */
export default ReservationFormEvents;
