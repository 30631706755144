import React from 'react';
import { connect } from 'react-redux';
import { FastField } from 'formik';
import {
  Input,
  HStack,
  Select,
  FormLabel,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/react';

import { getRestaurant } from '../../../../../restaurant/redux/selectors';

/* =============================================================================
<ReservationEditModalSeatsAndEvent />
============================================================================= */
const ReservationEditModalSeatsAndEvent = ({ restaurant }) => {
  const events = restaurant?.booking?.events;

  return (
    <HStack mt={5} spacing={5} alignItems="flex-start">
      <FastField name="seats">
        {({ field, form }) => (
          <FormControl
            flex={1}
            isInvalid={form.errors.seats && form.touched.seats}
          >
            <FormLabel>Seats</FormLabel>
            <Input id="seats" {...field} />
            <FormErrorMessage>{form.errors.seats}</FormErrorMessage>
          </FormControl>
        )}
      </FastField>
      <FastField name="event">
        {({ field, form }) => (
          <FormControl
            flex={1}
            mt={5}
            isInvalid={form.errors.event && form.touched.event}
          >
            <FormLabel>Events</FormLabel>
            <Select {...field} placeholder="Select">
              {events?.map((event) => (
                <option key={event._id} value={event.name}>
                  {event.name}
                </option>
              ))}
            </Select>
            <FormErrorMessage>{form.errors.event}</FormErrorMessage>
          </FormControl>
        )}
      </FastField>
    </HStack>
  );
};

const mapStateToProps = (state) => ({
  restaurant: getRestaurant(state),
});

/* Export
============================================================================= */
export default connect(mapStateToProps)(ReservationEditModalSeatsAndEvent);
