import { useEffect, useState } from 'react';

const usePagination = (data = [], itemsPerPage = 5) => {
  const [page, setPage] = useState(1);
  const [items, setItems] = useState([]);

  useEffect(() => {
    const begin = (page - 1) * itemsPerPage;
    const end = begin + itemsPerPage;
    setItems(data.slice(begin, end));
  }, [data, page, itemsPerPage]);

  const maxPage = Math.ceil(data.length / itemsPerPage) || 1;
  const canNext = (page < maxPage);
  const canPrev = page > 1;

  const next = () => {
    setPage((prevState) => Math.min(prevState + 1, maxPage + 1));
  };

  const prev = () => {
    setPage((prevState) => Math.max(prevState - 1, 1));
  };

  return {
    next,
    prev,
    page,
    items,
    maxPage,
    canNext,
    canPrev,
  };
};

export default usePagination;
