import React from 'react';
import {
  HStack,
  Divider,
} from '@chakra-ui/react';

import Card from '../../../common/Card';
import OrderCardId from './OrderCardId';
import OrderCardUser from './OrderCardUser';
import OrderCardNotes from './OrderCardNotes';
import OrderCardRider from './OrderCardRider';
import OrderCardDishes from './OrderCardDishes';
import OrderCardAddress from './OrderCardAddress';
import OrderCardPartner from './OrderCardPartner';
import OrderCardCompletedInfo from './OrderCardCompletedInfo';

/* =============================================================================
<OrderCard />
============================================================================= */
const OrderCard = ({
  id,
  footer,
  onDetailsClick,
}) => (
  <Card p={5} mb={4}>
    <OrderCardPartner id={id} />
    <OrderCardId id={id} />
    <OrderCardUser id={id} onDetailsClick={onDetailsClick} />
    <OrderCardAddress id={id} />
    <OrderCardDishes id={id} />
    <OrderCardNotes id={id} />
    <Divider my={3} />
    <OrderCardRider id={id} />
    <OrderCardCompletedInfo id={id} />
    <HStack justify="flex-end">
      {footer}
    </HStack>
  </Card>
);

/* Export
============================================================================= */
export default OrderCard;
