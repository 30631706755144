import { normalize } from 'normalizr';

import request from '../../util/request';
import * as constants from './constants';
import { addEntities } from '../../entities/redux/actions';
import {
  user as userSchema,
  dish as dishSchema,
  order as orderSchema,
  booking as bookingSchema,
} from '../../entities/api/schema';

/**
 * DISH_GET
 */
export const getDish = (id, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.DISH_GET.REQUEST });

    const payload = await request({
      url: `/dishes/${id}`,
      method: 'GET',
    });
    const { entities } = normalize(payload, [dishSchema]);

    dispatch(addEntities(entities));
    dispatch({ type: constants.DISH_GET.SUCCESS });

    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.DISH_GET.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.DISH_GET.COMPLETE });
  }
};

/**
 * RIDERS_GET
 */
export const getRiders = (cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.RIDERS_GET.REQUEST });

    const payload = await request({
      url: '/users/riders',
      method: 'GET',
    });

    const { entities, result } = normalize(payload.data, [userSchema]);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.RIDERS_GET.SUCCESS,
      payload: result,
    });

    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.RIDERS_GET.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.RIDERS_GET.COMPLETE });
  }
};

/**
 * WAITERS_GET
 */
export const getWaiters = (cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.WAITERS_GET.REQUEST });

    const payload = await request({
      url: '/users/waiters',
      method: 'GET',
    });

    const { entities, result } = normalize(payload.data, [userSchema]);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.WAITERS_GET.SUCCESS,
      payload: result,
    });

    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.WAITERS_GET.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.WAITERS_GET.COMPLETE });
  }
};

/**
 * ORDER_ADD
 */
export const addOrder = (payload) => ({
  type: constants.ORDER_ADD,
  payload,
});

/**
 * ORDER_REMOVE
 */
export const removeOrder = (payload) => ({
  type: constants.ORDER_REMOVE,
  payload,
});

/**
 * ORDERS_GET
 */
export const getOrders = (cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.ORDERS_GET.REQUEST });

    const payload = await request({
      url: `/pos/orders?t=${Date.now()}`,
      method: 'GET',
    });
    const { entities, result } = normalize(payload.data, [orderSchema]);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.ORDERS_GET.SUCCESS,
      payload: result,
    });

    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.ORDERS_GET.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.ORDERS_GET.COMPLETE });
  }
};

/**
 * ORDER_PLACE
 */
export const placeOrder = (order) => {
  const payload = {
    uid: order.uid,
    tag: order.tag,
    type: order.type,
    status: 'accepted',
    table: order.table && { id: order.table },
    waiter: order.waiter && { id: order.waiter },
    restaurant: { id: order.restaurant },
    address: {
      text: order.address,
    },
    items: order.items,
    payment: {
      vat: order.vat,
      amount: order.offline.total,
      discount: order.offline.discount,
      method: order.paymentMethod,
      deliveryFee: order.deliveryFee,
      posDiscount: order.posDiscount,
      posDiscountPerc: order.posDiscountPerc,
    },
    meta: {
      name: order.name,
      phone: order.phone,
    },
    createdAt: order.createdAt,
    acceptedAt: order.createdAt,
  };
  return {
    type: constants.ORDER_PLACE.REQUEST,
    payload,
    meta: {
      offline: {
        effect: {
          url: '/pos/orders',
          method: 'POST',
          data: order,
        },
        commit: {
          type: constants.ORDER_PLACE.COMMIT,
          meta: payload,
        },
        rollback: {
          type: constants.ORDER_PLACE.ROLLBACK,
          meta: payload,
        },
      },
    },
  };
};

/**
 * ORDER_UPDATE
 */
export const updateOrder = (order) => {
  const payload = {
    uid: order.uid,
    tag: order.tag,
    type: order.type,
    table: order.table && { id: order.table },
    waiter: order.waiter && { id: order.waiter },
    restaurant: { id: order.restaurant },
    address: {
      text: order.address,
    },
    items: order.items,
    payment: {
      vat: order.vat,
      amount: order.offline.total,
      discount: order.offline.discount,
      method: order.paymentMethod,
      deliveryFee: order.deliveryFee,
      posDiscount: order.posDiscount,
      posDiscountPerc: order.posDiscountPerc,
    },
    meta: {
      name: order.name,
      phone: order.phone,
    },
  };
  return {
    type: constants.ORDER_UPDATE.REQUEST,
    payload,
    meta: {
      offline: {
        effect: {
          url: `/pos/orders/${order.uid}`,
          method: 'PUT',
          data: order,
        },
        commit: {
          type: constants.ORDER_UPDATE.COMMIT,
          meta: payload,
        },
        rollback: {
          type: constants.ORDER_UPDATE.ROLLBACK,
          meta: payload,
        },
      },
    },
  };
};

/**
 * ORDER_ITEMS_UPDATE
 */
export const updateOrderItems = (order) => {
  const payload = {
    uid: order.uid,
    items: order.items,
  };
  return {
    type: constants.ORDER_ITEMS_UPDATE.REQUEST,
    payload,
    meta: {
      offline: {
        effect: {
          url: `/pos/orders/${order.uid}/items`,
          method: 'PUT',
          data: order,
        },
        commit: {
          type: constants.ORDER_ITEMS_UPDATE.COMMIT,
          meta: payload,
        },
        rollback: {
          type: constants.ORDER_ITEMS_UPDATE.ROLLBACK,
          meta: payload,
        },
      },
    },
  };
};

/**
 * ORDER_READY
 */
export const readyOrder = (id) => {
  const payload = {
    uid: id,
    status: 'prepared',
    preparedAt: new Date(),
  };
  return {
    type: constants.ORDER_READY.REQUEST,
    payload,
    meta: {
      offline: {
        effect: {
          url: `/pos/orders/${id}/prepare?t=${Date.now()}`,
          method: 'PUT',
        },
        commit: {
          type: constants.ORDER_READY.COMMIT,
          meta: payload,
        },
        rollback: {
          type: constants.ORDER_READY.ROLLBACK,
          meta: payload,
        },
      },
    },
  };
};

/**
 * ORDER_DISPATCH
 */
export const dispatchOrder = (order) => {
  const payload = {
    uid: order.uid,
    rider: { id: order.rider },
  };
  return {
    type: constants.ORDER_DISPATCH.REQUEST,
    payload,
    meta: {
      offline: {
        effect: {
          url: `/pos/orders/${order.uid}/dispatch?t=${Date.now()}`,
          method: 'PUT',
          data: { rider: order.rider },
        },
        commit: {
          type: constants.ORDER_DISPATCH.COMMIT,
          meta: payload,
        },
        rollback: {
          type: constants.ORDER_DISPATCH.ROLLBACK,
          meta: payload,
        },
      },
    },
  };
};

/**
 * ORDER_COMPLETE
 */
export const completeOrder = (id) => {
  const payload = {
    uid: id,
    status: 'completed',
    completedAt: new Date(),
  };
  return {
    type: constants.ORDER_COMPLETE.REQUEST,
    payload,
    meta: {
      offline: {
        effect: {
          url: `/pos/orders/${id}/complete?t=${Date.now()}`,
          method: 'PUT',
        },
        commit: {
          type: constants.ORDER_COMPLETE.COMMIT,
          meta: payload,
        },
        rollback: {
          type: constants.ORDER_COMPLETE.ROLLBACK,
          meta: payload,
        },
      },
    },
  };
};

/**
 * ORDER_COMPLETE
 */
export const deleteOrder = (id) => {
  const payload = {
    uid: id,
  };
  return {
    type: constants.ORDER_DELETE.REQUEST,
    payload,
    meta: {
      offline: {
        effect: {
          url: `/pos/orders/${id}`,
          method: 'DELETE',
        },
        commit: {
          type: constants.ORDER_DELETE.COMMIT,
          meta: payload,
        },
        rollback: {
          type: constants.ORDER_DELETE.ROLLBACK,
          meta: payload,
        },
      },
    },
  };
};

/**
 * BOOKINGS_GET
 */
export const getBookings = (cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.BOOKINGS_GET.REQUEST });

    const payload = await request({
      url: `/pos/bookings?t=${Date.now()}`,
      method: 'GET',
    });
    const { entities, result } = normalize(payload.data, [bookingSchema]);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.BOOKINGS_GET.SUCCESS,
      payload: result,
    });

    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.BOOKINGS_GET.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.BOOKINGS_GET.COMPLETE });
  }
};

/**
 * BOOKING_CREATE
 */
export const createBooking = (booking) => {
  const payload = {
    uid: booking.uid,
    status: 'accepted',
    date: booking.date,
    time: booking.time,
    seats: booking.seats,
    event: booking.event,
    payment: {
      amount: booking.amount,
    },
    meta: {
      name: booking.name,
      phone: booking.phone,
    },
    createdAt: booking.createdAt,
    acceptedAt: booking.createdAt,
  };
  return {
    type: constants.BOOKING_CREATE.REQUEST,
    payload,
    meta: {
      offline: {
        effect: {
          url: '/pos/bookings',
          method: 'POST',
          data: booking,
        },
        commit: {
          type: constants.BOOKING_CREATE.COMMIT,
          meta: payload,
        },
        rollback: {
          type: constants.BOOKING_CREATE.ROLLBACK,
          meta: payload,
        },
      },
    },
  };
};

/**
 * BOOKING_UPDATE
 */
export const updateBooking = (booking) => {
  const payload = {
    uid: booking.uid,
    date: booking.date,
    time: booking.time,
    seats: booking.seats,
    event: booking.event,
    meta: {
      name: booking.name,
      phone: booking.phone,
    },
  };
  return {
    type: constants.BOOKING_UPDATE.REQUEST,
    payload,
    meta: {
      offline: {
        effect: {
          url: `/pos/bookings/${booking.uid}`,
          method: 'PUT',
          data: booking,
        },
        commit: {
          type: constants.BOOKING_UPDATE.COMMIT,
          meta: payload,
        },
        rollback: {
          type: constants.BOOKING_UPDATE.ROLLBACK,
          meta: payload,
        },
      },
    },
  };
};

/**
 * BOOKING_CANCEL
 */
export const cancelBooking = (id) => {
  const payload = {
    uid: id,
    status: 'canceled',
    canceledAt: new Date(),
  };
  return {
    type: constants.BOOKING_CANCEL.REQUEST,
    payload,
    meta: {
      offline: {
        effect: {
          url: `/pos/bookings/${id}/cancel?t=${Date.now()}`,
          method: 'PUT',
        },
        commit: {
          type: constants.BOOKING_CANCEL.COMMIT,
          meta: payload,
        },
        rollback: {
          type: constants.BOOKING_CANCEL.ROLLBACK,
          meta: payload,
        },
      },
    },
  };
};

/**
 * BOOKING_COMPLETE
 */
export const completeBooking = (id) => {
  const payload = {
    uid: id,
    status: 'completed',
    completedAt: new Date(),
  };
  return {
    type: constants.BOOKING_COMPLETE.REQUEST,
    payload,
    meta: {
      offline: {
        effect: {
          url: `/pos/bookings/${id}/complete?t=${Date.now()}`,
          method: 'PUT',
        },
        commit: {
          type: constants.BOOKING_COMPLETE.COMMIT,
          meta: payload,
        },
        rollback: {
          type: constants.BOOKING_COMPLETE.ROLLBACK,
          meta: payload,
        },
      },
    },
  };
};
