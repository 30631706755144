import React from 'react';
import { Form, Formik } from 'formik';

import RestaurantFormCapacity from './ReservationFormCapacity';
import RestaurantFormDuration from './ReservationFormDuration';
import ReservationFormHours from './ReservationFormHours';
import ReservationFormFee from './ReservationFormFee';
import ReservationFormSeats from './ReservationFormSeats';
import ReservationFormInterval from './ReservationFormInterval';
import ReservationFormHeader from './ReservationFormHeader';
import ReservationFormInitialize from './ReservationFormInitialize';
import ReservationFormEvents from './ReservationFormEvents';

/* =============================================================================
<ReservationForm />
============================================================================= */
const ReservationForm = ({ initialValues, validationSchema, onSubmit }) => (
  <Formik
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={onSubmit}
  >
    {({ isSubmitting, errors }) => (
      <Form>
        <ReservationFormHeader isSubmitting={isSubmitting} />
        <RestaurantFormCapacity />
        <RestaurantFormDuration />
        <ReservationFormFee />
        <ReservationFormInterval />
        <ReservationFormSeats />
        <ReservationFormHours />
        <ReservationFormEvents />
        <ReservationFormInitialize />
      </Form>
    )}
  </Formik>
);

/* Export
============================================================================= */
export default ReservationForm;
