import React, { useEffect } from 'react';
import * as Yup from 'yup';
import moment from 'moment';
import { connect } from 'react-redux';
import { useToast } from '@chakra-ui/react';

import Card from '../../common/Card';
import ReservationForm from '../components/ReservationForm/index';

import {
  getRestaurant as getRestaurantAction,
  updateRestaurantBooking as updateRestaurantBookingAction,
} from '../../restaurant/redux/actions';

/* =============================================================================
<ReservationSettingScreen />
============================================================================= */
const ReservationSettingScreen = ({ getRestaurant, updateRestaurantBooking }) => {
  const toast = useToast();

  // Get restaurant
  useEffect(() => {
    getRestaurant();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _handleSubmit = async (values) => {
    const hours = values.hours.map((hour) => {
      if (hour.discount) {
        return {
          active: hour.active,
          discount: hour.discount,
          start: (moment(hour.start, 'hh:mm').hours() * 60) + moment(hour.start, 'hh:mm').minutes(),
        };
      }
      return {
        active: hour.active,
        start: (moment(hour.start, 'hh:mm').hours() * 60) + moment(hour.start, 'hh:mm').minutes(),
      };
    });

    const payload = {
      ...values,
      hours,
    };
    await updateRestaurantBooking(payload, (err) => {
      if (err) {
        toast({
          title: err.message,
          isClosable: true,
          status: 'error',
          duration: 3000,
        });
      } else {
        toast({
          title: 'Changes Saved',
          isClosable: true,
          status: 'success',
          duration: 3000,
        });
      }
    });
  };

  return (
    <Card m={5} flex={1} direction="column">
      <ReservationForm
        initialValues={initialValues}
        validationSchema={ReservationSettingsSchema}
        onSubmit={_handleSubmit}
      />
    </Card>
  );
};

const initialValues = {
  capacity: '',
  duration: '',
  fee: '',
  seats: {
    min: '',
    max: '',
  },
  interval: {
    min: '',
    max: '',
  },
  hours: [
    {
      active: false,
      start: '',
    },
    {
      active: false,
      discount: 10,
      start: '',
    },
  ],
  events: [
    {
      active: false,
      name: '',
      fee: '',
    },
  ],
};

const ReservationSettingsSchema = Yup.object().shape({
  capacity: Yup.number()
    .required('Must not be empty!'),
  duration: Yup.number()
    .required('Must not be empty'),
  fee: Yup.number()
    .required('Must not be empty'),
  seats: Yup.object().shape({
    min: Yup.number()
      .required('Must not be empty'),
    max: Yup.number()
      .required('Must not be empty'),
  }),
  interval: Yup.object().shape({
    min: Yup.number()
      .required('Must not be empty'),
    max: Yup.number()
      .required('Must not be empty'),
  }),
  hours: Yup.array().of(Yup.object().shape({
    start: Yup.string().required('Must not be empty!'),
    active: Yup.boolean().required('Must not be empty!'),
    discount: Yup.number().optional(),
  })),
  events: Yup.array().of(Yup.object().shape({
    name: Yup.string().required('Must not be empty!'),
    fee: Yup.number().required('Must not be empty!'),
    active: Yup.boolean().required('Must not be empty!'),
  })),
});

const mapDispatchToProps = {
  getRestaurant: getRestaurantAction,
  updateRestaurantBooking: updateRestaurantBookingAction,
};

/* Export
============================================================================= */
export default connect(null, mapDispatchToProps)(ReservationSettingScreen);
