import React from 'react';
import { Box, HStack } from '@chakra-ui/react';

import RestaurantInfoRatings from './RestaurantInfoRatings';
import RestaurantInfoDetails from './RestaurantInfoDetails';
import RestaurantInfoLocation from './RestaurantInfoLocation';
import RestaurantInfoBranches from './RestaurantInfoBranches';
import RestaurantInfoOwnerInformation from './RestaurantInfoOwnerInformation';

/* =============================================================================
<RestaurantInfo />
============================================================================= */
const RestaurantInfo = () => (
  <Box mt={8}>
    <HStack align="stretch">
      <RestaurantInfoDetails />
      <RestaurantInfoRatings />
    </HStack>
    <HStack mt={5} align="stretch">
      <RestaurantInfoLocation />
      <RestaurantInfoBranches />
      <RestaurantInfoOwnerInformation />
    </HStack>
  </Box>
);

/* Export
============================================================================= */
export default RestaurantInfo;
