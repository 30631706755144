import { actionGenerator } from '../../util/reduxHelpers';

export const STATUS_SET = 'BOOKING/STATUS_SET';
export const BOOKINGS_GET = actionGenerator(
  'BOOKING/BOOKINGS_GET',
);
export const BOOKINGS_REFRESH = actionGenerator(
  'BOOKING/BOOKINGS_REFRESH',
);
export const BOOKINGS_BY_MONTH_GET = actionGenerator(
  'BOOKING/BOOKINGS_BY_MONTH_GET',
);
export const BOOKING_UPDATE = actionGenerator(
  'BOOKING/BOOKING_UPDATE',
);
