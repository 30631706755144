import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Trash } from 'react-feather';
import {
  Box,
  Text,
  HStack,
  Image,
  ListItem,
  useToast,
  Spinner,
} from '@chakra-ui/react';

import { getDish } from '../../../../entities/redux/selectors';
import { deleteDiscountDish as deleteDiscountDishAction } from '../../../redux/actions';

/* =============================================================================
<DiscountDish />
============================================================================= */
const DiscountDish = ({
  dish,
  discountId,
  deleteDiscountDish,
}) => {
  const toast = useToast();
  const dishId = dish?.id;
  const name = dish?.name || '';
  const about = dish?.about || '';
  const photo = dish?.photos && dish.photos[0];
  const price = dish?.variants?.length ? dish.variants[0]?.price : dish?.price;
  const [loading, setLoading] = useState(false);

  const _handleDeleteClick = async () => {
    setLoading(true);
    await deleteDiscountDish(discountId, dishId, (err) => {
      if (err) {
        toast({
          title: 'Error',
          description: err.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: 'Dish removed',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
    });
    setLoading(false);
  };

  return (
    <ListItem
      my={4}
      bg="white"
      display="flex"
      height={height}
      overflow="hidden"
      borderRadius="lg"
      alignItems="center"
      border="1px solid #E4E7EC"
      justifyContent="space-between"
    >
      <HStack flex={1} px={6}>
        <Box flex={1} pl={5}>
          <Text fontWeight="semibold">{name}</Text>
          <Text my="4px" fontSize="sm">{about}</Text>
          <Text fontWeight="semibold">
            {price}
            {' '}
            Rs
          </Text>
        </Box>
        {loading ? (
          <Spinner />
        ) : (
          <Trash size={14} onClick={_handleDeleteClick} />
        )}
      </HStack>
      <Image w="auto" h="100%" src={photo} />
    </ListItem>
  );
};

const height = '146px';

const mapStateToProps = (state, { id }) => ({
  dish: getDish(state, { id }),
});

const mapDispatchToProps = {
  deleteDiscountDish: deleteDiscountDishAction,
};

const propsAreEqual = (prevProps, nextProps) => (
  prevProps.dish?.name === nextProps.dish?.name
  && prevProps.dish?.about === nextProps.dish?.about
  && prevProps.dish?.price === nextProps.dish?.price
  && prevProps.dish?.photos?.toString() === nextProps.dish?.photos?.toString()
  && (prevProps.dish?.variants && prevProps.dish?.variants[0]?.price)
    === (nextProps.dish?.variants && nextProps.dish?.variants[0]?.price)
);

/* Export
============================================================================= */
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(DiscountDish, propsAreEqual));
