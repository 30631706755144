import moment from 'moment';

/**
 * Convert degrees to radians
 */
export const degreesToRadians = (degrees) => (degrees * Math.PI) / 180;

/**
 * Reorder list by start and end index
 */
export const reorder = (list, startIndex, endIndex) => {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Calculate diff between 2 dates
 */
export const calculateTimeDiff = (start, end) => {
  let str = '';
  const m1 = moment(start);
  const m2 = moment(end);
  const duration = moment.duration(m2.diff(m1));
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  if (hours > 0) {
    str += `${hours} ${hours > 1 ? 'hours' : 'hour'} `;
  }
  if (minutes > 0) {
    str += `${minutes} ${minutes > 1 ? 'minutes' : 'minute'} `;
  }
  if (seconds > 0) {
    str += `${seconds} ${seconds > 1 ? 'seconds' : 'second'}`;
  }

  return str;
};

/**
 * Calculate dish extras and extras total
 */
export const calcDishExtras = (dish, options) => {
  let total = 0;
  const extras = [];

  if (dish && options) {
    Object.keys(options).forEach((option) => {
      const choiceGroup = dish?.choiceGroups?.find((cg) => cg.id === option);
      const extra = {
        name: choiceGroup?.name,
        price: 0,
        values: [],
      };
      options[option].forEach((choice) => {
        const _choice = choiceGroup?.choices?.find((c) => c.id === choice);
        extra.values.push(_choice?.name);
        extra.price += (_choice?.price || 0);
        total += (_choice?.price || 0);
      });
      extras.push(extra);
    });
  }

  return {
    total,
    extras,
  };
};

/**
 * Calculate dish discount
 */
export const calcDishDiscount = (dish, variant) => {
  let discount = 0;
  const today = new Date();
  const start = new Date(dish?.discount?.startAt);
  const end = new Date(dish?.discount?.endAt);

  if (start < today && end > today) {
    if (dish?.discount?.type === 'percentage') {
      const price = variant?.price || (dish?.variants && dish?.variants[0]?.price) || dish?.price;
      discount = (price * dish.discount.value) / 100;
    }

    if (dish?.discount?.type === 'amount') {
      discount = dish.discount.value;
    }
  }

  return (discount || 0);
};

/**
 * Calculate dish total, subtotal and discount
 */
export const calcDishTotal = (dish, {
  extras,
  variant,
  quantity,
} = {}) => {
  const { total: extrasTotal, extras: _extras } = calcDishExtras(dish, extras);
  const price = (
    variant?.price || (dish?.variants && dish?.variants[0]?.price) || dish?.price
  ) || 0;
  const subtotal = (price + extrasTotal) * (quantity || 1);
  const discount = calcDishDiscount(dish, variant) * (quantity || 1);
  const total = subtotal - discount;

  return {
    price,
    total,
    subtotal,
    discount,
    extras: _extras,
  };
};


export const fromAdminPortal = (user) => {
  try {
      return user.usingCode && window.self !== window.top;
  } catch (e) {
      return true;
  }
}
