import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useFormikContext } from 'formik';

import moment from 'moment';
import { getBooking } from '../../../../../entities/redux/selectors';

/* =============================================================================
<ReservationEditModalInitialize />
============================================================================= */
const ReservationEditModalInitialize = ({ booking }) => {
  const { resetForm } = useFormikContext();

  const name = booking?.meta?.name || `${booking?.customer?.profile?.firstName} ${booking?.customer?.profile?.lastName}`;
  const phone = booking?.meta?.phone || booking?.customer?.phone;
  const seats = booking?.seats;
  const date = moment(booking?.date).format('YYYY-MM-DD');
  const time = booking?.time;
  const status = booking?.status;
  const event = booking?.event;
  const note = booking?.note;

  // Set default values
  useEffect(() => {
    resetForm({
      values: {
        name,
        phone,
        seats,
        date,
        time,
        status,
        event,
        note,
      },
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    name,
    phone,
    seats,
    date,
    time,
    status,
    event,
    note,
  ]);

  return null;
};

const mapStateToProps = (state, { id }) => ({
  booking: getBooking(state, { id }),
});

/* Export
============================================================================= */
export default connect(mapStateToProps)(ReservationEditModalInitialize);
