import React from 'react';
import {
  Text,
  Input,
  Radio,
  Stack,
  HStack,
  Divider,
  FormLabel,
  InputGroup,
  RadioGroup,
  FormControl,
  InputLeftAddon,
} from '@chakra-ui/react';

/* =============================================================================
<OrderSetupPaymentMethod />
============================================================================= */
const OrderSetupPaymentMethod = ({
  value,
  onChange,
}) => (
  <FormControl mt={4}>
    <FormLabel fontSize="sm" fontWeight="normal">Payment Method</FormLabel>
    <RadioGroup
      colorScheme="brand"
      value={value}
      onChange={onChange}
    >
      <Stack direction="row" spacing={8}>
        <Radio value="cod">Cash Payment</Radio>
        <Radio value="card">Credit / Debit</Radio>
      </Stack>
    </RadioGroup>
  </FormControl>
);

/* Export
============================================================================= */
export default OrderSetupPaymentMethod;
