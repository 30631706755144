import React from 'react';
import { FastField } from 'formik';
import { connect } from 'react-redux';
import {
  VStack,
  Box,
  FormErrorIcon,
  FormLabel,
  FormControl,
  FormErrorMessage,
  Select,
} from '@chakra-ui/react';
import { getMenuItems } from '../../../entities/redux/selectors';

/* =============================================================================
<DishMenuCategory />
============================================================================= */
const DishMenuCategory = ({ menuItems }) => {
  const items = Object.values(menuItems);
  return (
    <FastField name="menuItem">
      {({ field, form }) => (
        <FormControl
          mb={8}
          display="flex"
          isInvalid={form.errors.menuItem && form.touched.about}
        >
          <Box flex={2.2}>
            <FormLabel mb={0}>Menu Category</FormLabel>
          </Box>
          <VStack alignItems="flex-start" flex={3}>
            <Select id="menuItem" {...field}>
              {items.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </Select>
            <FormErrorMessage>
              <FormErrorIcon />
              {form.errors.menuItem}
            </FormErrorMessage>
          </VStack>
        </FormControl>
      )}
    </FastField>
  );
};

const mapStateToProps = (state) => ({
  menuItems: getMenuItems(state),
});

/* Export
============================================================================= */
export default connect(mapStateToProps, null)(DishMenuCategory);
