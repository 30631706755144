import React from 'react';
import {
  Drawer,
  HStack,
  DrawerBody,
  DrawerHeader,
  DrawerContent,
  DrawerOverlay,
} from '@chakra-ui/react';

import { ReactComponent as AppNameIcon } from '../../assets/icons/app-name-icon.svg';
import { ReactComponent as AppLogoIcon } from '../../assets/icons/app-logo-icon.svg';
import SideNavList from './SideNavList';

const SideNavOpen = ({ orderCount, onClose, isOpen }) => (
  <Drawer placement="left" onClose={onClose} isOpen={isOpen}>
    <DrawerOverlay />
    <DrawerContent>
      <DrawerHeader border="none" borderBottomWidth="1px">
        <HStack>
          <AppLogoIcon />
          <AppNameIcon />
        </HStack>
      </DrawerHeader>
      <DrawerBody px={4}>
        <SideNavList isOpen={isOpen} orderCount={orderCount} />
      </DrawerBody>
    </DrawerContent>
  </Drawer>
);

export default SideNavOpen;
