import React from 'react';
import { FastField } from 'formik';
import {
  Input,
  VStack,
  FormLabel,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
} from '@chakra-ui/react';

/* =============================================================================
<RestaurantFormDeliveryTime />
============================================================================= */
const RestaurantFormDeliveryTime = ({ disabled }) => (
  <FastField name="deliveryTime">
    {({ field, form }) => (
      <FormControl
        mb={8}
        display="flex"
        isInvalid={form.errors.deliveryTime && form.touched.deliveryTime}
      >
        <FormLabel flex={1.5}>Delivery Time in Minutes</FormLabel>
        <VStack alignItems="flex-start" flex={2}>
          <Input id="deliveryTime" {...field} disabled={disabled} />
          <FormErrorMessage>
            <FormErrorIcon />
            {form.errors.deliveryTime}
          </FormErrorMessage>
        </VStack>
      </FormControl>
    )}
  </FastField>
);

/* Export
============================================================================= */
export default RestaurantFormDeliveryTime;
