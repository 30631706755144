import React, { useState, useEffect } from 'react';
import { List, ListItem } from '@chakra-ui/react';
import { connect } from 'react-redux';

import DiscountHeader from './DiscountHeader';
import DiscountDish from './DiscountDish';

import { getDiscount } from '../../../../entities/redux/selectors';

/* =============================================================================
<Discount />
============================================================================= */
const Discount = ({ id, discount }) => {
  const dishes = discount?.dishes;

  return (
    <ListItem>
      <DiscountHeader id={id} />
      <List>
        {dishes?.map((item) => (
          <DiscountDish
            id={item}
            key={item}
            discountId={id}
          />
        ))}
      </List>
    </ListItem>
  );
};

const mapStateToProps = (state, { id }) => ({
  discount: getDiscount(state, { id, normalize: true }),
});

const propsAreEqual = (prevProps, nextProps) => (
  prevProps.discount?.dishes?.toString() === nextProps.discount?.dishes?.toString()
);

/* Export
============================================================================= */
export default connect(mapStateToProps)(React.memo(Discount, propsAreEqual));
