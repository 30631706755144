import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Text,
  Tabs,
  Tab,
  HStack,
  TabList,
} from '@chakra-ui/react';

import { getType } from '../../redux/selectors';
import { setType as setTypeAction } from '../../redux/actions';

/* =============================================================================
<OrderHistoryTypeSelect />
============================================================================= */
const OrderHistoryTypeSelect = ({ type, setType }) => {
  const [tabIndex, setTabIndex] = useState(0);

  // Set current active tab
  useEffect(() => {
    switch (type) {
      case 'all':
        setTabIndex(0);
        break;
      case 'delivery':
        setTabIndex(1);
        break;
      case 'take_away':
        setTabIndex(2);
        break;
      default:
        break;
    }
  }, [type]);

  const _handleTabChange = (value) => {
    setTabIndex(value);
    switch (value) {
      case 0:
        setType('all');
        break;
      case 1:
        setType('delivery');
        break;
      case 2:
        setType('take_away');
        break;
      default:
        break;
    }
  };

  return (
    <HStack justify="space-between" px={6}>
      <Tabs ml="-30px" mb={3} colorScheme="brand" index={tabIndex} onChange={_handleTabChange}>
        <TabList border="none">
          <Tab border="none">
            <Text>All</Text>
          </Tab>
          <Tab border="none">
            <Text>Delivery / Gift</Text>
          </Tab>
          <Tab border="none">
            <Text>Takeaway</Text>
          </Tab>
        </TabList>
      </Tabs>
    </HStack>
  );
};

const mapStateToProps = (state) => ({
  type: getType(state),
});

const mapDispatchToProps = {
  setType: setTypeAction,
};

const propsAreEqual = (prevProps, nextProps) => prevProps.type === nextProps.type;

/* Export
============================================================================= */
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(OrderHistoryTypeSelect, propsAreEqual));
