import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Form, Formik } from 'formik';
import { Trash } from 'react-feather';
import {
  VStack,
  Modal,
  Button,
  HStack,
  Divider,
  ModalBody,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  useToast,
  Box,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

import DishName from '../DishName';
import DishPhoto from '../DishPhoto';
// import AddNewInventoryItems from '../../AddNewInventoryItems';
import DishDescription from '../DishDescription';
import DishChoiceGroups from '../DishChoiceGroups';
import DishPricesAndVariants from '../DishPricesAndVariants/index';
import EditDishModalInitialize from './EditDishModalInitialize';
import { uploadDishPhoto } from '../../../../util/upload';

import {
  updateDish as updateDishAction,
  deleteDish as deleteDishAction,
  getMenuItem,
} from '../../../redux/actions';
import { DishSchema } from '../schema';
import DishMenuCategory from '../DishMenuCategory';

/* =============================================================================
<EditDishModal />
============================================================================= */
const EditDishModal = ({
  id,
  isOpen,
  onClose,
  updateDish,
  deleteDish,
}) => {
  const toast = useToast();
  const params = useParams();
  const dispatch = useDispatch();
  const [deleting, setDeleting] = useState(false);
  const oldMenuItemId = params?.menuItem;

  const _handleDeleteClick = async () => {
    setDeleting(true);
    await deleteDish(id, (err) => {
      if (!err) {
        toast({
          title: 'Menu item deleted',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        onClose();
      } else {
        toast({
          title: err.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    });
    setDeleting(false);
  };

  // Note: There's a different submit handler to create the dish
  const _handleSubmit = async (values) => {
    const firstVariantPrice = values.variants?.[0]?.price;
    const payload = {
      ...values,
      price: firstVariantPrice || values.price || null,
      id,
    };

    if (values.photo) {
      const photoURL = await uploadDishPhoto(values.photo);
      payload.photo = photoURL;
    }

    await updateDish(payload, (err) => {
      if (!err) {
        toast({
          title: 'Changes Saved',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        onClose();

        // Refetch menu categories
        if (oldMenuItemId !== values.menuItem) {
          dispatch(getMenuItem(values.menuItem));
          dispatch(getMenuItem(oldMenuItemId));
        }
      } else {
        toast({
          title: err.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    });
  };

  return (
    <Modal size="3xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="lg">Edit Item</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Divider mb={5} />
          <Formik
            initialValues={INITIAL_VALUES}
            validationSchema={DishSchema}
            onSubmit={_handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <DishPhoto />
                <DishName />
                <DishDescription />
                <DishMenuCategory />
                <DishPricesAndVariants />
                <DishChoiceGroups />
                {/* <AddNewInventoryItems /> */}
                <VStack>
                  <Button
                    colorScheme="gray"
                    rightIcon={<Trash size="20px" />}
                    isLoading={deleting}
                    onClick={_handleDeleteClick}
                  >
                    Delete
                  </Button>
                </VStack>
                <Divider my={5} />
                <HStack mb={2} spacing={4}>
                  <Button
                    flex={1}
                    colorScheme="gray"
                    variant="outline"
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" flex={1} isLoading={isSubmitting}>
                    Update item
                  </Button>
                </HStack>
                <EditDishModalInitialize id={id} />
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const INITIAL_VALUES = {
  name: '',
  about: '',
  photo: undefined,
  menuItem: '',
  category: '',
  price: '',
  variants: [],
  choiceGroups: [],
  stock: [],
};

const mapDispatchToProps = {
  updateDish: updateDishAction,
  deleteDish: deleteDishAction,
};

/* Export
============================================================================= */
export default connect(null, mapDispatchToProps)(EditDishModal);
