import React from 'react';
import { connect } from 'react-redux';
import {
  Text,
  HStack,
  Select,
  Divider,
  FormLabel,
  FormControl,
} from '@chakra-ui/react';

import {
  getAreasWithDetails,
  getTablesWithDetails,
  getWaitersWithDetails,
} from '../../redux/selectors';

/* =============================================================================
<OrderSetupDineInInfo />
============================================================================= */
const OrderSetupDineInInfo = ({
  area,
  table,
  waiter,
  areas,
  tables,
  waiters,
  onAreaChange,
  onTableChange,
  onWaiterChange,
}) => {
  const areaOptions = areas?.map((ao) => ({
    label: ao?.name,
    value: ao?.id,
  }));
  const tableOptions = tables?.filter((t) => t.area === area).map((to) => ({
    label: to?.name,
    value: to?.id,
  }));
  const waiterOptions = waiters?.map((wo) => ({
    label: wo?.profile?.firstName + ' ' + wo?.profile?.lastName, // eslint-disable-line
    value: wo?.id,
  }));

  return (
    <>
      <Text fontSize="lg" fontWeight="medium">Dine In Information</Text>
      <Text fontSize="sm">Update dine in details here.</Text>
      <Divider my={5} />
      <HStack spacing={4}>
        <FormControl>
          <FormLabel fontSize="sm" fontWeight="normal">Area:</FormLabel>
          <Select
            value={area}
            placeholder="Select"
            textTransform="capitalize"
            onChange={(event) => onAreaChange(event.target.value)}
          >
            {areaOptions.map((option) => (
              <option key={option.value} value={option.value}>{option.label}</option>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel fontSize="sm" fontWeight="normal">Table:</FormLabel>
          <Select
            value={table}
            placeholder="Select"
            textTransform="capitalize"
            onChange={(event) => onTableChange(event.target.value)}
          >
            {tableOptions.map((option) => (
              <option key={option.value} value={option.value}>{option.label}</option>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel fontSize="sm" fontWeight="normal">Waiter:</FormLabel>
          <Select
            value={waiter}
            placeholder="Select"
            textTransform="capitalize"
            onChange={(event) => onWaiterChange(event.target.value)}
          >
            {waiterOptions.map((option) => (
              <option key={option.value} value={option.value}>{option.label}</option>
            ))}
          </Select>
        </FormControl>
      </HStack>
    </>
  );
};

const mapStateToProps = (state) => ({
  areas: getAreasWithDetails(state),
  tables: getTablesWithDetails(state),
  waiters: getWaitersWithDetails(state),
});

/* Export
============================================================================= */
export default connect(mapStateToProps)(OrderSetupDineInInfo);
