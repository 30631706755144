import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useFormikContext } from 'formik';

import { getChoiceGroup } from '../../../../../entities/redux/selectors';

/* =============================================================================
<EditChoiceModalInitialize />
============================================================================= */
const EditChoiceModalInitialize = ({ choiceGroup }) => {
  const { resetForm } = useFormikContext();

  const id = choiceGroup?.id;
  const name = choiceGroup?.name;
  const min = choiceGroup?.min;
  const max = choiceGroup?.max;
  const about = choiceGroup?.about;
  const choices = choiceGroup?.choices;
  const choicesStr = choiceGroup?.choices?.map((choice) => choice.id + choice.name + choice.price).join(',');

  // Set default values
  useEffect(() => {
    resetForm({
      values: {
        id,
        name,
        min,
        max,
        about,
        choices,
      },
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    id,
    name,
    min,
    max,
    about,
    choicesStr,
  ]);

  return null;
};

const mapStateToProps = (state, { id }) => ({
  choiceGroup: getChoiceGroup(state, { id }),
});

/* Export
============================================================================= */
export default connect(mapStateToProps)(EditChoiceModalInitialize);
