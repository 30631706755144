import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Tr,
  Td,
  Button,
  HStack,
  Switch,
  useToast,
  useDisclosure,
  Text,
  Tag,
  TagLabel,
  TagLeftIcon,
  Box,
} from '@chakra-ui/react';
import { Edit } from 'react-feather';

import EditTableModal from '../ManagementModals/EditTableModal';

import { deleteTable as deleteTableAction } from '../../redux/actions';
import { getTable } from '../../../entities/redux/selectors';

/* =============================================================================
<TableManagementTableItem />
============================================================================= */
const TableManagementTableItem = ({ id, table, deleteTable }) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);

  const name = table?.name;
  const capacity = table?.capacity;
  const area = table?.area.name;
  const status = table?.status;

  const _handleDeleteClick = async () => {
    setLoading(true);
    await deleteTable(id, (err) => {
      if (err) {
        toast({
          title: 'Error occured.',
          description: err?.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    });
    setLoading(false);
  };

  return (
    <Tr>
      <Td>{name}</Td>
      <Td>{capacity}</Td>
      <Td>{area}</Td>
      <Td>
        {status === 'empty' && (
          <Tag colorScheme="gray">
            <Box w="6px" h="6px" bgColor="#999" borderRadius="50%" />
            <TagLabel ml={2}>Empty</TagLabel>
          </Tag>
        )}
        {status === 'occupied' && (
        <Tag colorScheme="red">
          <Box w="6px" h="6px" bgColor="red.700" borderRadius="50%" />
          <TagLabel ml={2}>Occupied</TagLabel>
        </Tag>
        )}
      </Td>
      <Td>
        <HStack>
          <Button leftIcon={<Edit size="18px" />} onClick={onOpen}>
            Edit
          </Button>
          <Button
            variant="outline"
            colorScheme="gray"
            isLoading={loading}
            onClick={_handleDeleteClick}
          >
            Delete
          </Button>
        </HStack>
      </Td>
      <EditTableModal id={id} isOpen={isOpen} onClose={onClose} />
    </Tr>
  );
};

const mapStateToProps = (state, { id }) => ({
  table: getTable(state, { id }),
});

const mapDispatchToProps = {
  deleteTable: deleteTableAction,
};

// eslint-disable-next-line max-len
const propsAreEqual = (prevProps, nextProps) => prevProps.table?.name === nextProps.table?.name
&& prevProps.table?.capacity === nextProps.table?.capacity
&& prevProps.table?.status === nextProps.table?.status
&& prevProps.table?.area === nextProps.table?.area
&& prevProps.table?.restaurant.toString() === nextProps.table?.restaurant.toString();

/* Export
============================================================================= */
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(TableManagementTableItem, propsAreEqual));
