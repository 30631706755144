import { normalize } from 'normalizr';

import request from '../../util/request';
import * as constants from './constants';
import { addEntities } from '../../entities/redux/actions';
import { inventory as inventorySchema } from '../../entities/api/schema';
/**
 *.INVENTORIES_GET
 */
export const getInventories = (nextCursor, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.INVENTORIES_GET.REQUEST });

    const payload = await request({
      url: '/inventories',
      method: 'GET',
      params: {
        next_cursor: nextCursor,
      },
    });
    const { entities, result } = normalize(payload.data, [inventorySchema]);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.INVENTORIES_GET.SUCCESS,
      payload: {
        data: result,
        nextCursor: payload.next_cursor,
      },
    });
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.INVENTORIES_GET.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.INVENTORIES_GET.COMPLETE });
  }
};

/**
 * INVENTORIES_REFRESH
 */
export const refreshInventories = (cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.INVENTORIES_REFRESH.REQUEST });

    const payload = await request({
      url: '/inventories',
      method: 'GET',
    });
    const { entities, result } = normalize(payload.data, [inventorySchema]);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.INVENTORIES_REFRESH.SUCCESS,
      payload: {
        data: result,
        nextCursor: payload.next_cursor,
      },
    });
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.INVENTORIES_REFRESH.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.INVENTORIES_REFRESH.COMPLETE });
  }
};

/**
 * INVENTORY_GET
 */
export const getInventory = (id, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.INVENTORY_GET.REQUEST });

    const payload = await request({
      url: `/inventories/${id}`,
      method: 'GET',
    });
    const { entities, result } = normalize(payload, inventorySchema);

    dispatch(addEntities(entities));
    dispatch({ type: constants.INVENTORY_GET.SUCCESS });
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.INVENTORY_GET.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.INVENTORY_GET.COMPLETE });
  }
};

/**
 * INVENTORY_CREATE
 */
export const createInventory = (data, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.INVENTORY_CREATE.REQUEST });

    const payload = await request({
      url: '/inventories',
      method: 'POST',
      data,
    });
    const { entities, result } = normalize(payload, inventorySchema);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.INVENTORY_CREATE.SUCCESS,
      payload: result,
    });
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.INVENTORY_CREATE.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.INVENTORY_CREATE.COMPLETE });
  }
};

/**
 * INVENTORY_UPDATE
 */
export const updateInventory = (inventory, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.INVENTORY_UPDATE.REQUEST });

    const payload = await request({
      url: `/inventories/${inventory.id}`,
      method: 'POST',
      data: inventory,
    });
    const { entities } = normalize(payload, inventorySchema);

    dispatch(addEntities(entities));
    dispatch({ type: constants.INVENTORY_UPDATE.SUCCESS });

    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({ type: constants.INVENTORY_UPDATE.FAIL, error });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.INVENTORY_UPDATE.COMPLETE });
  }
};

/**
 * INVENTORY_DELETE
 */
export const deleteInventory = (id, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.INVENTORY_DELETE.REQUEST });

    await request({
      url: `/inventories/${id}`,
      method: 'DELETE',
    });

    dispatch({
      type: constants.INVENTORY_DELETE.SUCCESS,
      payload: id,
    });
    if (typeof cb === 'function') {
      cb(null);
    }
  } catch (error) {
    dispatch({
      type: constants.INVENTORY_DELETE.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.INVENTORY_DELETE.COMPLETE });
  }
};
