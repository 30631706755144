import React from 'react';
import { FastField, useFormikContext, getIn } from 'formik';
import { Trash } from 'react-feather';
import {
  Text,
  Input,
  Button,
  HStack,
  Switch,
  InputGroup,
  FormControl,
  FormErrorIcon,
  InputRightAddon,
  FormErrorMessage,
} from '@chakra-ui/react';

/* =============================================================================
<ReservationFormHoursSelectTime />
============================================================================= */
const ReservationFormHoursSelectTime = ({
  hour,
  index,
  onDeleteHourClick,
}) => {
  const { setFieldValue } = useFormikContext();

  const _handleHourToggle = () => {
    setFieldValue(`hours.${index}.active`, !hour.active, false);
  };

  return (
    <HStack w="100%" alignItems="flex-start">
      <HStack w="130px" mt={2}>
        <Switch
          size="lg"
          colorScheme="brand"
          isChecked={hour.active}
          onChange={_handleHourToggle}
        />
        <Text>{hour.active ? 'Active' : 'Inactive'}</Text>
      </HStack>
      <HStack align="flex-start">
        <FastField name={`hours.${index}.start`}>
          {({ field, form }) => (
            <FormControl
              w="150px"
              isInvalid={getIn(form.errors, `hours.${index}.start`) && getIn(form.touched, `hours.${index}.start`)}
            >
              <Input type="time" {...field} />
              <FormErrorMessage>
                <FormErrorIcon />
                {getIn(form.errors, `hours.${index}.start`)}
              </FormErrorMessage>
            </FormControl>
          )}
        </FastField>
        {hour.discount !== undefined && (
        <FastField name={`hours.${index}.discount`}>
          {({ field, form }) => (
            <FormControl
              w="100px"
              isInvalid={getIn(form.errors, `hours.${index}.discount`) && getIn(form.touched, `hours.${index}.discount`)}
            >
              <InputGroup>
                <Input {...field} />
                <InputRightAddon>%</InputRightAddon>
              </InputGroup>
              <FormErrorMessage>
                <FormErrorIcon />
                {getIn(form.errors, `hours.${index}.discount`)}
              </FormErrorMessage>
            </FormControl>
          )}
        </FastField>
        )}
      </HStack>
      <Button
        variant="unstlyed"
        leftIcon={<Trash size="14px" />}
        onClick={onDeleteHourClick}
      >
        Delete
      </Button>
    </HStack>
  );
};

/* Export
============================================================================= */
export default ReservationFormHoursSelectTime;
