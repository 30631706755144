import React from 'react';
import { connect } from 'react-redux';
import { Check, Trash, Edit, ArrowRight } from 'react-feather';
import { Button, HStack, useDisclosure } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';

import OrderDispatchModal from '../../../../common/OrderDispatchModal';

import { getOrder } from '../../../../entities/redux/selectors';
import {
  completeOrder as completeOrderAction,
  dispatchOrder as dispatchOrderAction,
  deleteOrder as deleteOrderAction,
} from '../../../redux/actions';
import OrderDeleteModal from '../../../../common/OrderDeleteModal';

/* =============================================================================
<OngoingOrderCardFooter />
============================================================================= */
const OngoingOrderCardFooter = ({
  id,
  order,
  deleteOrder,
  dispatchOrder,
  completeOrder,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isDeleteModalOpen,
    onOpen: openDeleteModal,
    onClose: closeDeleteModal,
  } = useDisclosure();

  const type = order?.type;
  const deliveryGuy = order?.rider;

  const _handleDeleteClick = () => {
    openDeleteModal();
  };

  const _handleDispatchSubmit = rider => {
    dispatchOrder({ ...order, rider });
  };

  const _handleMarkAsCompletedClick = async () => {
    await completeOrder(order?.uid);
  };

  return (
    <HStack mb={5} justify="space-between">
      {type === 'delivery' && !deliveryGuy ? (
        <>
          <Button rightIcon={<ArrowRight size="20px" />} onClick={onOpen}>
            Dispatch
          </Button>
          <OrderDispatchModal
            id={id}
            isOpen={isOpen}
            onClose={onClose}
            onSubmit={_handleDispatchSubmit}
          />
        </>
      ) : (
        <Button
          leftIcon={<Check size="20px" />}
          onClick={_handleMarkAsCompletedClick}
        >
          Complete
        </Button>
      )}
      {type !== 'delivery' && !deliveryGuy ? (
        <HStack spacing={3}>
          <Button
            p={0}
            variant="outline"
            colorScheme="gray"
            onClick={_handleDeleteClick}
          >
            <Trash size="20px" />
          </Button>
          <Button
            p={0}
            as={NavLink}
            to={`/pos/order/${id}`}
            variant="outline"
            colorScheme="gray"
          >
            <Edit size="20px" />
          </Button>
        </HStack>
      ) : type === 'delivery' && !deliveryGuy ? (
        <HStack spacing={3}>
          <Button
            p={0}
            variant="outline"
            colorScheme="gray"
            onClick={_handleDeleteClick}
          >
            <Trash size="20px" />
          </Button>
          <Button
            p={0}
            as={NavLink}
            to={`/pos/order/${id}`}
            variant="outline"
            colorScheme="gray"
          >
            <Edit size="20px" />
          </Button>
        </HStack>
      ) : null}
      <OrderDeleteModal
        isOpen={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onSubmit={() => deleteOrder(order?.uid)}
      />
    </HStack>
  );
};

const mapStateToProps = (state, { id }) => ({
  order: getOrder(state, { id }),
});

const mapDispatchToProps = {
  completeOrder: completeOrderAction,
  deleteOrder: deleteOrderAction,
  dispatchOrder: dispatchOrderAction,
};

/* Export
============================================================================= */
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OngoingOrderCardFooter);
