/**
 * Redux async action creator
 */
export const actionGenerator = (actionName) => ({
  REQUEST: `${actionName}_REQUEST`,
  SUCCESS: `${actionName}_SUCCESS`,
  FAIL: `${actionName}_FAIL`,
  COMPLETE: `${actionName}_COMPLETE`,
});

/**
* Redux offline action creator
*/
export const createOfflineAction = (actionName) => ({
  REQUEST: `${actionName}_REQUEST`,
  COMMIT: `${actionName}_COMMIT`,
  ROLLBACK: `${actionName}_ROLLBACK`,
});
