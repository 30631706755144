import React from 'react';
import { connect } from 'react-redux';
import { Box, HStack, Text } from '@chakra-ui/react';
import { Mail, MapPin, Phone } from 'react-feather';

import { getRestaurant } from '../../redux/selectors';

/* =============================================================================
<RestaurantInfoDetails />
============================================================================= */
const RestaurantInfoDetails = ({ restaurant }) => {
  const isBranch = restaurant?.type === 'branch';
  const isChain = restaurant?.type === 'chain';
  const email = isBranch ? restaurant?.chain?.email : restaurant?.email;
  const phone = restaurant?.phone?.delivery1;
  const address = restaurant?.address?.text;

  return (
    <Box flex={2} p={4} borderRadius="8px" border="1px solid #D0D5DD">
      <Text fontWeight="medium">Restaurant Info</Text>
      {!isChain && (
        <HStack my={5}>
          <MapPin size="16px" />
          <Text>
            Address :
            {' '}
            {address}
          </Text>
        </HStack>
      )}
      <HStack my={5}>
        <Mail size="16px" />
        <Text>
          Email :
          {' '}
          {email}
        </Text>
      </HStack>
      <HStack mt={5}>
        <Phone size="16px" />
        <Text>
          Phone :
          {' '}
          {phone}
        </Text>
      </HStack>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  restaurant: getRestaurant(state),
});

/* Export
============================================================================= */
export default connect(mapStateToProps)(RestaurantInfoDetails);
