import React from 'react';
import {
  Text,
  HStack,
} from '@chakra-ui/react';

/* =============================================================================
<OrderSetupTotal />
============================================================================= */
const OrderSetupTotal = ({
  vat,
  total,
  subtotal,
  discount,
  posDiscount
}) => (
  <>
    <HStack mb={2} spacing={4} justify="space-between">
      <Text fontWeight="semibold">Subtotal</Text>
      <Text fontWeight="semibold">{`Rs: ${Math.round(subtotal)}`}</Text>
    </HStack>
    {Boolean(discount) && (
      <HStack mb={2} spacing={4} justify="space-between">
        <Text fontWeight="semibold">Discount</Text>
        <Text fontWeight="semibold">{`Rs: ${Math.round(discount)}`}</Text>
      </HStack>
    )}
    <HStack mb={2} spacing={4} justify="space-between">
      <Text fontWeight="semibold">GST</Text>
      <Text fontWeight="semibold">{`Rs: ${Math.round(vat)}`}</Text>
    </HStack>
    {Boolean(posDiscount) && (
      <HStack mb={2} spacing={4} justify="space-between">
        <Text fontWeight="semibold">Pos Discount</Text>
        <Text fontWeight="semibold">{`Rs: ${Math.round(posDiscount)}`}</Text>
      </HStack>
    )}
    <HStack mb={5} spacing={4} justify="space-between">
      <Text fontSize="lg" fontWeight="semibold">TOTAL</Text>
      <Text fontSize="lg" fontWeight="semibold">{`Rs: ${Math.round(total)}`}</Text>
    </HStack>
  </>
);

/* Export
============================================================================= */
export default OrderSetupTotal;
