import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { FastField, Form, Formik } from 'formik';
import { useParams } from 'react-router-dom';
import {
  Box,
  Text,
  Modal,
  Input,
  Button,
  Select,
  HStack,
  VStack,
  Divider,
  Textarea,
  FormLabel,
  ModalBody,
  FormControl,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  FormErrorIcon,
  ModalCloseButton,
  FormErrorMessage,
  useToast,
} from '@chakra-ui/react';

import { getCategory } from '../../../../entities/redux/selectors';
import { getCategories as selectCategories } from '../../../redux/selectors';
import {
  createMenuItem as createMenuItemAction,
  getCategories as getCategoriesAction,
} from '../../../redux/actions';

/* =============================================================================
<CreateCategoryModal />
============================================================================= */
const CreateCategoryModal = ({
  isOpen,
  categories,
  onClose,
  createMenuItem,
  getCategories,
}) => {
  const toast = useToast();
  const params = useParams();
  const menuId = params?.menu;

  // Get categories
  useEffect(() => {
    getCategories();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _handleSubmit = async (values) => {
    const payload = {
      ...values,
      menu: menuId,
    };
    await createMenuItem(payload, (err) => {
      if (!err) {
        toast({
          title: 'Category created',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        onClose();
      } else {
        toast({
          title: err.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    });
  };

  return (
    <Modal size="3xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="lg">Create Category</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Divider mb={5} />
          <Formik
            initialValues={INITIAL_VALUES}
            validationSchema={CreateCategorySchema}
            onSubmit={_handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <FastField name="name">
                  {({ field, form }) => (
                    <FormControl
                      mb={8}
                      display="flex"
                      isInvalid={form.errors.name && form.touched.name}
                    >
                      <FormLabel flex={2.1}>Category Name</FormLabel>
                      <VStack alignItems="flex-start" flex={3}>
                        <Input id="name" {...field} />
                        <FormErrorMessage>
                          <FormErrorIcon />
                          {form.errors.name}
                        </FormErrorMessage>
                      </VStack>
                    </FormControl>
                  )}
                </FastField>
                <FastField name="about">
                  {({ field, form }) => (
                    <FormControl
                      mb={8}
                      display="flex"
                      isInvalid={form.errors.about && form.touched.about}
                    >
                      <Box flex={2.2}>
                        <FormLabel mb={0}>Category Description</FormLabel>
                        <Text fontSize="sm">Write a short introduction,</Text>
                      </Box>
                      <VStack alignItems="flex-start" flex={3}>
                        <Textarea id="about" {...field} />
                        <FormErrorMessage>
                          <FormErrorIcon />
                          {form.errors.about}
                        </FormErrorMessage>
                      </VStack>
                    </FormControl>
                  )}
                </FastField>
                <FastField name="category">
                  {({ field, form }) => (
                    <FormControl
                      mb={8}
                      display="flex"
                      isInvalid={form.errors.category && form.touched.category}
                    >
                      <FormLabel flex={2.2} mb={0}>Select Category</FormLabel>
                      <VStack alignItems="flex-start" flex={3}>
                        <Select placeholder="Select" name="category" {...field}>
                          {categories.map((category) => (
                            <option key={category.id} value={category.id}>{category.name}</option>
                          ))}
                        </Select>
                        <FormErrorMessage>
                          <FormErrorIcon />
                          {form.errors.category}
                        </FormErrorMessage>
                      </VStack>
                    </FormControl>
                  )}
                </FastField>
                <HStack mb={2} spacing={4}>
                  <Button
                    flex={1}
                    colorScheme="gray"
                    variant="outline"
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    flex={1}
                    isLoading={isSubmitting}
                  >
                    Create Category
                  </Button>
                </HStack>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const INITIAL_VALUES = {
  name: '',
  about: '',
  category: '',
};

const CreateCategorySchema = Yup.object().shape({
  name: Yup.string()
    .required('Must not be empty!'),
  about: Yup.string()
    .optional('Must not be empty!'),
  category: Yup.string()
    .required('Must not be empty!'),
});

const mapStateToProps = (state) => ({
  categories: selectCategories(state).map((id) => getCategory(state, { id, normalize: true })),
});

const mapDispatchToProps = {
  createMenuItem: createMenuItemAction,
  getCategories: getCategoriesAction,
};

const propsAreEqual = (prevProps, nextProps) => prevProps.isOpen === nextProps.isOpen
  && prevProps.categories.length === nextProps.categories.length;

/* Export
============================================================================= */
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(CreateCategoryModal, propsAreEqual));
