import React from 'react';
import {
  Text,
  HStack,
  Select,
} from '@chakra-ui/react';

/* =============================================================================
<OrderSetupTypeSelect />
============================================================================= */
const OrderSetupTypeSelect = ({ value, onChange }) => (
  <HStack spacing={4}>
    <Text flex={1}>Order type:</Text>
    <Select
      flex={1}
      value={value}
      onChange={(event) => onChange(event.target.value)}
    >
      {ORDER_TYPES.map((orderType) => (
        <option key={orderType.value} value={orderType.value}>{orderType.label}</option>
      ))}
    </Select>
  </HStack>
);

const ORDER_TYPES = [
  {
    label: 'Take Away',
    value: 'take_away',
  },
  {
    label: 'Dine In',
    value: 'dine_in',
  },
  {
    label: 'Delivery',
    value: 'delivery',
  },
];

/* Export
============================================================================= */
export default OrderSetupTypeSelect;
