import React from 'react';
import { useLocation } from 'react-router';
import { Menu } from 'react-feather';
import { Box, Button } from '@chakra-ui/react';

import SideNavList from './SideNavList';

/* =============================================================================
<SideNavClosed />
============================================================================= */
const SideNavClosed = ({ orderCount, onOpen }) => {
  const location = useLocation();

  return (
    <Box
      pos="fixed"
      top={0}
      bottom={0}
      left={0}
      zIndex={10}
      border="1px solid #E4E7EC"
      bgColor="white"
      overflowY="hidden"
    >
      <Button
        m="1rem"
        display="flex"
        variant="unstyled"
        onClick={onOpen}
      >
        <Menu />
      </Button>
      <SideNavList isOpen={false} orderCount={orderCount} />
    </Box>
  );
};

const isActive = (link, location) => location.pathname.startsWith(link);

/* Export
============================================================================= */
export default SideNavClosed;
