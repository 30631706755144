import React from 'react';
import { Bookmark } from 'react-feather';
import { Flex, Link, Text } from '@chakra-ui/react';
import { NavLink, useLocation } from 'react-router-dom';

/* =============================================================================
<MenuListItemFeatured />
============================================================================= */
const MenuListItemFeatured = ({ menuId }) => {
  const location = useLocation();
  const selected = location.pathname.includes(`${menuId}/featured`);

  return (
    <Link
      ml={1}
      as={NavLink}
      to={`/menu/${menuId}/featured`}
      display="flex"
      alignItems="center"
    >
      <Bookmark size={18} />
      <Flex
        flex={1}
        height="40px"
        ml={2}
        alignItems="center"
        borderRadius="6px"
        bg={selected && 'red.100'}
      >
        <Text
          mx={4}
          noOfLines={1}
          fontWeight="medium"
          color={selected && 'red.700'}
        >
          Featured Items
        </Text>
      </Flex>
    </Link>
  );
};

/* Export
============================================================================= */
export default MenuListItemFeatured;
