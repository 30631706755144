import { normalize } from 'normalizr';

import request from '../../util/request';
import * as constants from './constants';
import { addEntities } from '../../entities/redux/actions';
import {
  getDish as selectDish,
  getDiscount as selectDiscount,
} from '../../entities/redux/selectors';
import {
  menu as menuSchema,
  dish as dishSchema,
  category as categorySchema,
  discount as discountSchema,
  menuItem as menuItemSchema,
  choiceGroup as choiceGroupSchema,
} from '../../entities/api/schema';

/**
 * MENUS_GET
 */
export const getMenus = (cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.MENUS_GET.REQUEST });

    const payload = await request({
      url: '/menus',
      method: 'GET',
    });
    const { entities, result } = normalize(payload.data, [menuSchema]);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.MENUS_GET.SUCCESS,
      payload: result,
    });

    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({ type: constants.MENUS_GET.FAIL, error });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.MENUS_GET.COMPLETE });
  }
};

/**
 * MENU_GET
 */
export const getMenu = (id, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.MENU_GET.REQUEST });

    const payload = await request({
      url: `/menus/${id}`,
      method: 'GET',
    });
    const { entities } = normalize(payload, menuSchema);

    dispatch(addEntities(entities));
    dispatch({ type: constants.MENU_GET.SUCCESS });

    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({ type: constants.MENU_GET.FAIL, error });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.MENU_GET.COMPLETE });
  }
};

/**
 * MENU_CREATE
 */
export const createMenu = (menu, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.MENU_CREATE.REQUEST });

    const payload = await request({
      url: '/menus',
      method: 'POST',
      data: menu,
    });
    const { entities, result } = normalize(payload, menuSchema);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.MENU_CREATE.SUCCESS,
      payload: result,
    });

    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({ type: constants.MENU_CREATE.FAIL, error });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.MENU_CREATE.COMPLETE });
  }
};

/**
 * MENU_UPDATE
 */
export const updateMenu = (menu, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.MENU_UPDATE.REQUEST });

    const payload = await request({
      url: `/menus/${menu.id}`,
      method: 'PUT',
      data: menu,
    });
    const { entities } = normalize(payload, menuSchema);

    dispatch(addEntities(entities));
    dispatch({ type: constants.MENU_UPDATE.SUCCESS });

    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({ type: constants.MENU_UPDATE.FAIL, error });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.MENU_UPDATE.COMPLETE });
  }
};

/**
 * MENU_DELETE
 */
export const deleteMenu = (id, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.MENU_DELETE.REQUEST });

    await request({
      url: `/menus/${id}`,
      method: 'DELETE',
    });

    dispatch({
      type: constants.MENU_DELETE.SUCCESS,
      payload: id,
    });

    if (typeof cb === 'function') {
      cb(null, id);
    }
  } catch (error) {
    dispatch({ type: constants.MENU_DELETE.FAIL, error });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.MENU_DELETE.COMPLETE });
  }
};

/**
 * MENU_ITEM_GET
 */
export const getMenuItem = (id, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.MENU_ITEM_GET.REQUEST });

    const payload = await request({
      url: `/menu_items/${id}`,
      method: 'GET',
    });
    const { entities } = normalize(payload, menuItemSchema);

    dispatch(addEntities(entities));
    dispatch({ type: constants.MENU_ITEM_GET.SUCCESS });

    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({ type: constants.MENU_ITEM_GET.FAIL, error });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.MENU_ITEM_GET.COMPLETE });
  }
};

/**
 * MENU_ITEM_CREATE
 */
export const createMenuItem = (menuItem, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.MENU_ITEM_CREATE.REQUEST });

    const payload = await request({
      url: '/menu_items',
      method: 'POST',
      data: menuItem,
    });
    const { entities } = normalize(payload, menuSchema);

    dispatch(addEntities(entities));
    dispatch({ type: constants.MENU_ITEM_CREATE.SUCCESS });

    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({ type: constants.MENU_ITEM_CREATE.FAIL, error });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.MENU_ITEM_CREATE.COMPLETE });
  }
};

/**
 * MENU_ITEM_UPDATE
 */
export const updateMenuItem = (menuItem, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.MENU_ITEM_UPDATE.REQUEST });

    const payload = await request({
      url: `/menu_items/${menuItem.id}`,
      method: 'PUT',
      data: menuItem,
    });
    const { entities } = normalize(payload, menuItemSchema);

    dispatch(addEntities(entities));
    dispatch({ type: constants.MENU_ITEM_UPDATE.SUCCESS });

    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({ type: constants.MENU_ITEM_UPDATE.FAIL, error });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.MENU_ITEM_UPDATE.COMPLETE });
  }
};

/**
 * MENU_ITEM_DELETE
 */
export const deleteMenuItem = (id, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.MENU_ITEM_DELETE.REQUEST });

    const payload = await request({
      url: `/menu_items/${id}`,
      method: 'DELETE',
    });
    const { entities } = normalize(payload, menuSchema);

    dispatch(addEntities(entities));
    dispatch({ type: constants.MENU_ITEM_DELETE.SUCCESS });

    if (typeof cb === 'function') {
      cb(null, id);
    }
  } catch (error) {
    dispatch({ type: constants.MENU_ITEM_DELETE.FAIL, error });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.MENU_ITEM_DELETE.COMPLETE });
  }
};

/**
 * DISH_CREATE
 */
export const createDish = (dish, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.DISH_CREATE.REQUEST });

    const payload = await request({
      url: '/dishes',
      method: 'POST',
      data: dish,
    });
    const { entities } = normalize(payload, menuItemSchema);

    dispatch(addEntities(entities));
    dispatch({ type: constants.DISH_CREATE.SUCCESS });

    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({ type: constants.DISH_CREATE.FAIL, error });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.DISH_CREATE.COMPLETE });
  }
};

/**
 * DISH_UPDATE
 */
export const updateDish = (dish, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.DISH_UPDATE.REQUEST });

    const payload = await request({
      url: `/dishes/${dish.id}`,
      method: 'PUT',
      data: dish,
    });
    const { entities } = normalize(payload, dishSchema);

    dispatch(addEntities(entities));
    dispatch({ type: constants.DISH_UPDATE.SUCCESS });

    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({ type: constants.DISH_UPDATE.FAIL, error });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.DISH_UPDATE.COMPLETE });
  }
};

/**
 * DISH_DELETE
 */
export const deleteDish = (id, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.DISH_DELETE.REQUEST });

    const payload = await request({
      url: `/dishes/${id}`,
      method: 'DELETE',
    });
    const { entities } = normalize(payload, menuItemSchema);

    dispatch(addEntities(entities));
    dispatch({ type: constants.DISH_DELETE.SUCCESS });

    if (typeof cb === 'function') {
      cb(null, id);
    }
  } catch (error) {
    dispatch({ type: constants.DISH_DELETE.FAIL, error });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.DISH_DELETE.COMPLETE });
  }
};

/**
 * DISH_DELETE
 */
export const updateDishAvailability = (dish, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.DISH_AVAILABILITY_CHANGE.REQUEST });

    const payload = await request({
      url: `/dishes/${dish?.id}/status`,
      method: 'PUT',
      data: {
        available: dish?.available,
      },
    });
    const { entities } = normalize(payload, dishSchema);

    dispatch(addEntities(entities));
    dispatch({ type: constants.DISH_AVAILABILITY_CHANGE.SUCCESS });

    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({ type: constants.DISH_AVAILABILITY_CHANGE.FAIL, error });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.DISH_AVAILABILITY_CHANGE.COMPLETE });
  }
};

/**
 * CHOICE_GROUPS_GET
 */
export const getChoiceGroups = (cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.CHOICE_GROUPS_GET.REQUEST });

    const payload = await request({
      url: '/choice_groups',
      method: 'GET',
    });
    const { entities, result } = normalize(payload.data, [choiceGroupSchema]);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.CHOICE_GROUPS_GET.SUCCESS,
      payload: result,
    });

    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({ type: constants.CHOICE_GROUPS_GET.FAIL, error });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.CHOICE_GROUPS_GET.COMPLETE });
  }
};

/**
 * CHOICE_GROUP_CREATE
 */
export const createChoiceGroup = (menu, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.CHOICE_GROUP_CREATE.REQUEST });

    const payload = await request({
      url: '/choice_groups',
      method: 'POST',
      data: menu,
    });
    const { entities, result } = normalize(payload, choiceGroupSchema);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.CHOICE_GROUP_CREATE.SUCCESS,
      payload: result,
    });

    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({ type: constants.CHOICE_GROUP_CREATE.FAIL, error });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.CHOICE_GROUP_CREATE.COMPLETE });
  }
};

/**
 * CHOICE_GROUP_UPDATE
 */
export const updateChoiceGroup = (menu, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.CHOICE_GROUP_UPDATE.REQUEST });

    const payload = await request({
      url: `/choice_groups/${menu.id}`,
      method: 'PUT',
      data: menu,
    });
    const { entities } = normalize(payload, choiceGroupSchema);

    dispatch(addEntities(entities));
    dispatch({ type: constants.CHOICE_GROUP_UPDATE.SUCCESS });

    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({ type: constants.CHOICE_GROUP_UPDATE.FAIL, error });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.CHOICE_GROUP_UPDATE.COMPLETE });
  }
};

/**
 * CHOICE_GROUP_DELETE
 */
export const deleteChoiceGroup = (id, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.CHOICE_GROUP_DELETE.REQUEST });

    await request({
      url: `/choice_groups/${id}`,
      method: 'DELETE',
    });

    dispatch({
      type: constants.CHOICE_GROUP_DELETE.SUCCESS,
      payload: id,
    });

    if (typeof cb === 'function') {
      cb(null, id);
    }
  } catch (error) {
    dispatch({ type: constants.CHOICE_GROUP_DELETE.FAIL, error });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.CHOICE_GROUP_DELETE.COMPLETE });
  }
};

/**
 * DISCOUNTS_GET
 */
export const getDiscounts = (cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.DISCOUNTS_GET.REQUEST });

    const payload = await request({
      url: '/discounts',
      method: 'GET',
    });
    const { entities, result } = normalize(payload.data, [discountSchema]);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.DISCOUNTS_GET.SUCCESS,
      payload: result,
    });

    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({ type: constants.DISCOUNTS_GET.FAIL, error });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.DISCOUNTS_GET.COMPLETE });
  }
};

/**
 * DISCOUNT_DISHES_UPDATE
 */
export const updateDiscountDishes = (dishes, id, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.DISCOUNT_DISHES_UPDATE.REQUEST });

    const payload = await request({
      url: `/discounts/${id}`,
      method: 'PUT',
      data: { dishes },
    });

    const { entities } = normalize(payload, discountSchema);

    dispatch(addEntities(entities));
    dispatch({ type: constants.DISCOUNT_DISHES_UPDATE.SUCCESS });

    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({ type: constants.DISCOUNT_DISHES_UPDATE.FAIL, error });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.DISCOUNT_DISHES_UPDATE.COMPLETE });
  }
};

/**
 * DISCOUNT_DISH_DELETE
 */
export const deleteDiscountDish = (discountId, dishId, cb) => async (dispatch, getState) => {
  try {
    dispatch({ type: constants.DISCOUNT_DISH_DELETE.REQUEST });

    await request({
      url: `/discounts/${discountId}/dishes/${dishId}`,
      method: 'DELETE',
    });

    const discount = selectDiscount(getState(), { id: discountId });

    // Update discount entity
    const { entities: discountEntities } = normalize({
      id: discountId,
      dishes: discount?.dishes?.filter((dish) => dish?.id !== dishId),
    }, discountSchema);
    dispatch(addEntities(discountEntities));

    // Update dish entity
    const { entities: dishEntities } = normalize({
      id: dishId,
      discount: null,
    }, dishSchema);
    dispatch(addEntities(dishEntities));

    dispatch({ type: constants.DISCOUNT_DISH_DELETE.SUCCESS });

    if (typeof cb === 'function') {
      cb(null, dishId);
    }
  } catch (error) {
    dispatch({ type: constants.DISCOUNT_DISH_DELETE.FAIL, error });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.DISCOUNT_DISH_DELETE.COMPLETE });
  }
};

/**
 * CATEGORIES_GET
 */
export const getCategories = (cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.CATEGORIES_GET.REQUEST });

    const payload = await request({
      url: '/categories',
      method: 'GET',
    });
    const { entities, result } = normalize(payload.data, [categorySchema]);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.CATEGORIES_GET.SUCCESS,
      payload: result,
    });

    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({ type: constants.CATEGORIES_GET.FAIL, error });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.CATEGORIES_GET.COMPLETE });
  }
};

/**
 * POS_MENUS_GET
 */
export const getPosMenus = (cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.POS_MENUS_GET.REQUEST });

    const payload = await request({
      url: '/pos/menus',
      method: 'GET',
    });
    const { entities, result } = normalize(payload.data, [menuSchema]);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.POS_MENUS_GET.SUCCESS,
      payload: result,
    });

    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({ type: constants.POS_MENUS_GET.FAIL, error });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.POS_MENUS_GET.COMPLETE });
  }
};
