import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Box, HStack, Text } from '@chakra-ui/react';

import OrderReceiptInfoTag from './OrderReceiptInfoTag';

import { getOrder } from '../../entities/redux/selectors';

/* =============================================================================
<OrderReceiptOrderDetails />
============================================================================= */
const OrderReceiptOrderDetails = ({ order }) => {
  const tag = order?.tag;
  const type = order?.type;
  const table = order?.table?.name;
  const createdAt = order?.createdAt && moment(order.createdAt).format('MMMM Do, h:mm a');
  const deliveryAddress = order?.address?.text;

  return (
    <>
      {type === 'delivery' && (
        <Box mb={2} border="1px solid #000">
          <OrderReceiptInfoTag title="Delivery Address" m={0} />
          <Box px={2} pb={5}>
            <Text>{deliveryAddress}</Text>
          </Box>
        </Box>
      )}
      {type === 'dine_in' && <OrderReceiptInfoTag title="Table" value={table} />}
      {type === 'take_away' && <OrderReceiptInfoTag title="Pickup" />}
      <Box>
        <Text fontWeight="bold" fontSize="xl">
          {`Order #${tag}`}
        </Text>
        <HStack my={2} justify="space-between">
          <Text>Date:</Text>
          <Text>{createdAt}</Text>
        </HStack>
      </Box>
    </>
  );
};

const mapStateToProps = (state, { id }) => ({
  order: getOrder(state, { id }),
});

const propsAreEqual = (prevProps, nextProps) => (
  prevProps.order?.tag === nextProps.order?.tag
  && prevProps.order?.type === nextProps.order?.type
  && prevProps.order?.createdAt === nextProps.order?.createdAt
  && prevProps.order?.table?.name === nextProps.order?.table?.name
  && prevProps.order?.address?.text === nextProps.order?.address?.text
);

/* Export
============================================================================= */
export default connect(mapStateToProps)(React.memo(OrderReceiptOrderDetails, propsAreEqual));
