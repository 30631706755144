import React from 'react';
import { Search, Plus } from 'react-feather';
import {
  Box,
  HStack,
  Text,
  Input,
  Button,
  Divider,
  InputGroup,
  InputLeftElement,
  useDisclosure,
} from '@chakra-ui/react';

import AddInventoryModal from './InventoryModals/AddInventoryModal';

/* =============================================================================
<InventoryHeader />
============================================================================= */
const InventoryHeader = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box mb={6}>
      <AddInventoryModal isOpen={isOpen} onClose={onClose} />
      <HStack justify="space-between">
        <Box>
          <Text fontSize="lg" fontWeight="medium">Inventory Managements</Text>
          <Text>Set Inventory</Text>
        </Box>
        <Button leftIcon={<Plus size="16px" />} onClick={onOpen}>Add new inventory</Button>
      </HStack>
      <Divider mt={6} mb={10} />
      <HStack justify="space-between" px={6}>
        <Text fontSize="lg" fontWeight="medium">Inventory</Text>
        <InputGroup maxW="320px">
          <InputLeftElement><Search size="15px" /></InputLeftElement>
          <Input placeholder="Search" />
        </InputGroup>
      </HStack>
    </Box>
  );
};

/* Export
============================================================================= */
export default InventoryHeader;
