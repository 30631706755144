import moment from 'moment';
import { createSelector } from 'reselect';

import { getRestaurant } from '../../restaurant/redux/selectors';

/**
 * Get error
 */
export const getError = (state) => state.Booking.error;

/**
  * Get loading
  */
export const getLoading = (state) => state.Booking.loading;

/**
  * Get status
  */
export const getStatus = (state) => state.Booking.status;

/**
 * Get by status
 */
export const getByStatus = (state) => state.Booking.byStatus;

/**
 * Get by month
 */
export const getByMonth = (state) => state.Booking.byMonth;

/**
 * Get booking entities
 */
const getBookingEntities = (state) => state.Entities.bookings;

/**
 * Get month
 */
const getMonth = (state, { month }) => month;

/**
  * Get booking by status
  */
export const getBookingByStatus = createSelector(
  [getByStatus, getStatus],
  (byStatus, status) => (
    (byStatus[status] && byStatus[status].data) || []
  ),
);

/**
  * Get nextCursor by status
  */
export const getNextCursorByStatus = createSelector(
  [getByStatus, getStatus],
  (byStatus, status) => (
    (byStatus[status] && byStatus[status].nextCursor) || ''
  ),
);

/**
 * Get booking by status
 */
export const getBookingByMonth = createSelector(
  [getByMonth, getMonth],
  (byMonth, month) => (
    byMonth[month] || []
  ),
);

/**
 * Get calendar events
 */
export const getCalendarEvents = createSelector(
  [getBookingEntities, getBookingByMonth, getRestaurant],
  (byId, allIds, restaurant) => {
    const events = [];
    const capacity = +restaurant?.booking?.capacity || 0;

    allIds.forEach((id) => {
      const booking = byId[id];
      const date = moment(booking?.date).startOf('day').minutes(+booking?.time).toDate();
      const event = events.find((ev) => ev.start.toString() === date.toString());

      if (event) {
        event.resource.free -= +booking?.seats || 0;
        event.resource.reserved += +booking?.seats || 0;
        event.resource.bookings.push(id);
        event.title = `${moment(date).format('hh:mm A')} ${event.resource.free} seat free / ${event.resource.reserved} reserved`;
      } else {
        const free = capacity - +booking?.seats;
        const reserved = +booking?.seats || 0;

        events.push({
          id: events?.length,
          title: `${moment(date).format('hh:mm A')} ${free} seat free / ${reserved} reserved`,
          start: date,
          end: date,
          resource: {
            free,
            reserved,
            bookings: [id],
          },
        });
      }
    });

    return events;
  },
);
