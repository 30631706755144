import React from 'react';
import { connect } from 'react-redux';
import { Flex, useMediaQuery } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

import Header from './Header/index';
import SideNav from './SideNav/index';

import { getUser } from '../auth/redux/selectors';
import getSetupCompleted from '../setup/redux/selector';
import { fromAdminPortal } from '../util/functions';

/* =============================================================================
<Layout />
============================================================================= */
const Layout = ({ children, user }) => {
  const location = useLocation();
  const path = location.pathname.split('/').slice(1)[0];
  const [isLargerThan766] = useMediaQuery('(min-width: 766px)');

  return (
    <Flex
      flex={1}
      height="100%"
      pos="relative"
    >
      {user && path !== 'pos' && !fromAdminPortal(user) && isLargerThan766 ? <SideNav /> : null}
      {user && !fromAdminPortal(user) ? <Header /> : null}
      <Flex
        flex={1}
        bgColor={!isLargerThan766 && '#fff'}
        mt={user && !fromAdminPortal(user) ? [16, 16, 20] : null}
        ml={user && path !== 'pos' && !fromAdminPortal(user) && isLargerThan766 ? '4.5rem' : null}
        flexFlow="column nowrap"
        w="full"
      >
        {children}
      </Flex>
    </Flex>
  );
};

const mapStateToProps = (state) => ({
  user: getUser(state),
  setUpCompleted: !!getSetupCompleted(state),
});

/* Export
============================================================================= */
export default connect(mapStateToProps, null)(Layout);
