import React from 'react';
import * as Yup from 'yup';
import {
  Text,
  HStack,
  Button,
  VStack,
  Divider,
} from '@chakra-ui/react';
import { Trash } from 'react-feather';

/* =============================================================================
<MenuFooter />
============================================================================= */
const MenuFooter = ({
  isSubmitting,
  isDeleting,
  onDelete,
  onCancel,
}) => (
  <>
    <VStack mt={5}>
      {typeof onDelete === 'function' && (
        <Button
          colorScheme="gray"
          isLoading={isDeleting}
          onClick={onDelete}
          rightIcon={<Trash size="20px" />}
        >
          Delete
        </Button>
      )}
    </VStack>
    <Divider my={5} />
    <HStack mb={2} spacing={4}>
      <Button
        flex={1}
        colorScheme="gray"
        variant="outline"
        onClick={onCancel}
      >
        Cancel
      </Button>
      <Button
        type="submit"
        flex={1}
        isLoading={isSubmitting}
      >
        Save Changes
      </Button>
    </HStack>
  </>
);

/* Export
============================================================================= */
export default MenuFooter;
