import React from 'react';
import * as Yup from 'yup';
import {
  Modal,
  Input,
  Divider,
  ModalOverlay,
  ModalContent,
  HStack,
  ModalHeader,
  ModalBody,
  FormLabel,
  FormControl,
  ModalCloseButton,
  FormErrorMessage,
  Button,
} from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';

/* =============================================================================
<InventoryRestockModal />
============================================================================= */
const InventoryRestockModal = ({ isOpen, onClose }) => {
  const _handleSubmit = () => {
    onClose();
  };

  return (
    <Modal size="2xl" isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="lg">Edit Inventory</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Divider mb={5} />
          <Formik
            initialValues={{ amount: undefined }}
            validationSchema={editInventorySchema}
            onSubmit={_handleSubmit}
          >
            {() => (
              <Form>
                <Field name="amount">
                  {({ field, form }) => (
                    <FormControl flex={1} isInvalid={form.errors.amount && form.touched.amount}>
                      <FormLabel>Amount</FormLabel>
                      <Input id="name" placeholder="Ex :2" {...field} />
                      <FormErrorMessage>{form.errors.amount}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Divider my={5} />
                <HStack mb={5}>
                  <Button type="submit" flex={1}>Save</Button>
                </HStack>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const editInventorySchema = Yup.object().shape({
  amount: Yup.string()
    .required('Must not be empty'),
});

/* Export
============================================================================= */
export default InventoryRestockModal;
