import React from 'react';
import { connect } from 'react-redux';
import {
  Box,
  Text,
  Menu,
  HStack,
  Button,
  Divider,
  MenuList,
  MenuItem,
  MenuButton,
  useDisclosure,
} from '@chakra-ui/react';
import { ChevronDown } from 'react-feather';

import CreateDishModal from './DishModals/CreateDishModal';
import EditMenuModal from './MenuModals/MenuModals/EditMenuModal/index';
import CreateCategoryModal from './MenuModals/CreateCategoryModal/index';
import AddFeaturedItemModal from './AddFeaturedItemModal';

import { getRestaurant } from '../../restaurant/redux/selectors';

/* =============================================================================
<MenuItemDetailsHeader />
============================================================================= */
const MenuItemDetailsHeader = ({
  title, menuId, menuItemId, restaurant,
}) => {
  const isBranch = restaurant?.type === 'branch';
  const {
    isOpen: isEditModalOpen,
    onOpen: onEditModalOpen,
    onClose: onEditModalClose,
  } = useDisclosure();
  const {
    isOpen: isAddItemModalOpen,
    onOpen: onAddItemModalOpen,
    onClose: onAddItemModalClose,
  } = useDisclosure();
  const {
    isOpen: isCreateCategoryModalOpen,
    onOpen: onCreateCategoryModalOpen,
    onClose: onCreateCategoryModalClose,
  } = useDisclosure();
  const {
    isOpen: isAddFeaturedItemModalOpen,
    onOpen: onAddFeaturedItemModalOpen,
    onClose: onAddFeaturedItemModalClose,
  } = useDisclosure();

  return (
    <>
      <HStack flex={1} justify="space-between">
        <HStack>
          <Text fontSize="lg" fontWeight="semibold">{title}</Text>
          {!isBranch && (
            <Button variant="link" fontSize="sm" onClick={onEditModalOpen}>Edit Menu</Button>
          )}
        </HStack>
        {!isBranch && (
          <Menu>
            <MenuButton as={Button} variant="light" rightIcon={<ChevronDown />}>
              Actions
            </MenuButton>
            <MenuList zIndex={2}>
              <MenuItem onClick={onCreateCategoryModalOpen}>Add New Category</MenuItem>
              {Boolean(menuItemId) && (
                <MenuItem onClick={onAddItemModalOpen}>Add New Item</MenuItem>
              )}
              <MenuItem onClick={onAddFeaturedItemModalOpen}>Add Featured Items</MenuItem>
            </MenuList>
          </Menu>
        )}
      </HStack>
      <Divider my={4} />
      {!isBranch && (
        <>
          <EditMenuModal
            menuId={menuId}
            isOpen={isEditModalOpen}
            onClose={onEditModalClose}
          />
          <CreateCategoryModal
            menuId={menuId}
            isOpen={isCreateCategoryModalOpen}
            onClose={onCreateCategoryModalClose}
          />
          {Boolean(menuItemId) && (
          <CreateDishModal
            menuId={menuId}
            menuItemId={menuItemId}
            isOpen={isAddItemModalOpen}
            onClose={onAddItemModalClose}
          />
          )}
          <AddFeaturedItemModal
            menuId={menuId}
            isOpen={isAddFeaturedItemModalOpen}
            onClose={onAddFeaturedItemModalClose}
          />
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  restaurant: getRestaurant(state),
});

const propsAreEqual = (prevProps, nextProps) => prevProps.title !== nextProps.title
    && prevProps.menuId !== nextProps.menuId
    && prevProps.menuItemId !== nextProps.menuItemId
    && prevProps.restaurant?.type !== nextProps.restaurant?.type;

/* Export
============================================================================= */
export default connect(mapStateToProps)(React.memo(MenuItemDetailsHeader, propsAreEqual));
