import React from 'react';
import { connect } from 'react-redux';
import { Edit } from 'react-feather';
import {
  Tr,
  Td,
  Text,
  Button,
  useDisclosure,
} from '@chakra-ui/react';

import EditChoiceModal from '../MenuModals/ChoicesModals/EditChoiceModal';

import { getChoiceGroup } from '../../../entities/redux/selectors';
import { getRestaurant } from '../../../restaurant/redux/selectors';

/* =============================================================================
<ExtraChoicesTableItem />
============================================================================= */
const ExtraChoicesTableItem = ({ restaurant, choiceGroup }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isBranch = restaurant?.type === 'branch';

  const name = choiceGroup?.name;
  const min = choiceGroup?.min;
  const totalChoices = choiceGroup?.choices?.length || 0;
  const availableChoices = choiceGroup?.choices?.filter((choice) => choice.available) || [];
  const unavailableChoices = choiceGroup?.choices?.filter((choice) => !choice.available) || [];

  return (
    <Tr>
      <EditChoiceModal
        id={choiceGroup?.id}
        isOpen={isOpen}
        onClose={onClose}
      />
      <Td maxW="200px">
        <Text fontSize="sm" fontWeight="semibold">{name}</Text>
        <Text fontSize="sm">
          {min > 0 && `Required ( Select ${min} ),
         ${totalChoices} Choices`}
        </Text>
      </Td>
      <Td maxW="200px">
        <Text fontSize="sm" fontWeight="semibold">
          {availableChoices.map((choice) => choice.name).join(', ')}
        </Text>
        <Text fontSize="sm">
          {`${availableChoices.length} Choices Available`}
        </Text>
      </Td>
      <Td maxW="200px">
        <Text fontSize="sm" fontWeight="semibold">
          {unavailableChoices.map((choice) => choice.name).join(', ')}
        </Text>
        <Text fontSize="sm">
          {`${unavailableChoices.length} Choices Unavailable`}
        </Text>
      </Td>
      {!isBranch && (
        <Td maxW="200px">
          <Button
            onClick={onOpen}
            variant="light"
            leftIcon={<Edit size="18px" color="#A32821" />}
          >
            Edit
          </Button>
        </Td>
      )}
    </Tr>
  );
};

const mapStateToProps = (state, { id }) => ({
  choiceGroup: getChoiceGroup(state, { id }),
  restaurant: getRestaurant(state),
});

/* Export
============================================================================= */
export default connect(mapStateToProps)(ExtraChoicesTableItem);
