import { createSelector } from 'reselect';

/**
 * Get error
 */
export const getError = (state) => state.Neighborhoods.error;

/**
 * Get loading
 */
export const getLoading = (state) => state.Neighborhoods.loading;

/**
 * Get query
 */
export const getQuery = (state) => state.Neighborhoods.query;

/**
 * Get neighborhoods
 */
export const getNeighborhoods = (state) => state.Neighborhoods.neighborhoods;

/**
 * Get neighborhoods entity
 */
export const getNeighborhoodsEntity = (state) => state.Entities.neighborhoods;

/**
 * Get visible neighborhoods
 */
export const getVisibleNeighborhoods = createSelector(
  [getNeighborhoods, getNeighborhoodsEntity, getQuery],
  (ids, byId, query) => ids?.filter((id) => {
    const neighborhood = byId[id];
    const regExp = new RegExp(query, 'i');

    if (!query) {
      return true;
    }

    if (neighborhood?.name?.search(regExp) > -1) {
      return true;
    }

    if (neighborhood?.area?.search(regExp) > -1) {
      return true;
    }

    return false;
  }),
);
