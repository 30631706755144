import * as constants from './constants';

export const INITIAL_STATE = {
  type: 'all',
  status: 'completed',
  byTypeAndStatus: {},
  error: null,
  loading: false,
};

export default function reducer(state = INITIAL_STATE, action) {
  const { type, payload, error } = action;
  switch (type) {
    // TYPE_SET
    case constants.TYPE_SET:
      return {
        ...state,
        type: payload,
      };

    // STATUS_SET
    case constants.STATUS_SET:
      return {
        ...state,
        status: payload,
      };

      // ORDERS_GET
    case constants.ORDERS_GET.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.ORDERS_GET.FAIL:
      return {
        ...state,
        error,
      };
    case constants.ORDERS_GET.SUCCESS:
      return {
        ...state,
        error: null,
        byTypeAndStatus: {
          ...state.byTypeAndStatus,
          [`${state.type}/${state.status}`]: {
            data: [...new Set([...state.byTypeAndStatus[`${state.type}/${state.status}`].data, ...payload.data])],
            nextCursor: payload.nextCursor,
          },
        },
      };
    case constants.ORDERS_GET.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // ORDERS_REFRESH
    case constants.ORDERS_REFRESH.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.ORDERS_REFRESH.FAIL:
      return {
        ...state,
        error,
      };
    case constants.ORDERS_REFRESH.SUCCESS:
      return {
        ...state,
        error: null,
        byTypeAndStatus: {
          ...state.byTypeAndStatus,
          [`${state.type}/${state.status}`]: {
            data: payload.data,
            nextCursor: payload.nextCursor,
          },
        },
      };
    case constants.ORDERS_REFRESH.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
