import React from 'react';
import { Box, Divider, Text } from '@chakra-ui/react';

import OwnerFormName from './OwnerFormName';
// import OwnerFormEmail from './OwnerFormEmail';
import OwnerFormPhone from './OwnerFormPhone';

/* =============================================================================
<OwnerForm />
============================================================================= */
const OwnerForm = () => (
  <Box pr={[0, 0, 0, 36]}>
    <Divider my={5} />
    <Text fontSize="xl">Owner Information</Text>
    <Text fontSize="sm">Update your information details here.</Text>
    <Divider my={5} />
    <OwnerFormName />
    {/* <OwnerFormEmail /> */}
    <OwnerFormPhone />
  </Box>
);

/* Export
============================================================================= */
export default OwnerForm;
