import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Tab, Spinner } from '@chakra-ui/react';

import { getMenuItem as selectMenuItem } from '../../../../entities/redux/selectors';
import { getMenuItem as getMenuItemAction } from '../../../../menu/redux/actions';

/* =============================================================================
<MenusItemTabItem />
============================================================================= */
const MenusItemTabItem = ({ menuItem, getMenuItem }) => {
  // Get menu Item
  useEffect(() => {
    getMenuItem(menuItem.id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuItem.id]);

  return (
    <Tab fontWeight="medium">
      {menuItem.name ? menuItem.name : (
        <Spinner />
      )}
    </Tab>
  );
};

const mapStateToProps = (state, { id }) => ({
  menuItem: selectMenuItem(state, { id, normalize: true }),
});

const mapDispatchToProps = {
  getMenuItem: getMenuItemAction,
};

// eslint-disable-next-line max-len
const propsAreEqual = (prevProps, nextProps) => prevProps.menuItem?.id === nextProps.menuItem?.id
 && prevProps.menuItem?.name === nextProps.menuItem?.name;

/* Export
============================================================================= */
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(MenusItemTabItem, propsAreEqual));
