import React from 'react';
import { connect } from 'react-redux';
import { Text } from '@chakra-ui/react';

import { getError } from '../../redux/selectors';

/* =============================================================================
<OrderSetupError />
============================================================================= */
const OrderSetupError = ({ error }) => {
  if (!error) {
    return null;
  }

  return (
    <Text mt={3} align="center" color="red">{error?.message}</Text>
  );
};

const mapStateToProps = (state) => ({
  error: getError(state),
});

/* Export
============================================================================= */
export default connect(mapStateToProps)(OrderSetupError);
