import { actionGenerator } from '../../util/reduxHelpers';

export const TYPE_SET = 'ORDERS/TYPE_SET';
export const ORDERS_GET = actionGenerator(
  'ORDERS/ORDERS_GET',
);
export const ORDER_GET = actionGenerator(
  'ORDERS/ORDER_GET',
);
export const ORDER_UPDATE = actionGenerator(
  'ORDERS/ORDER_UPDATE',
);
export const BYKEA_ORDER_UPDATE = actionGenerator(
  'ORDERS/BYKEA_ORDER_UPDATE',
);
export const ORDER_ADD = 'ORDERS/ORDER_ADD';

export const RIDERS_GET = actionGenerator(
  'ORDERS/RIDERS_GET',
);

export const DAYS_FILTER_SET = 'ORDERS/DAYS_FILTER_SET';
export const QUERY_SET = 'ORDERS/QUERY_SET';
