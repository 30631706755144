import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Tr,
  Td,
  Switch,
  Button,
  useToast,
  useDisclosure,
} from '@chakra-ui/react';
import { Edit } from 'react-feather';

import EmployeeEditModal from '../EmployeeModals/EmployeeEditModal/index';

import { getEmployee } from '../../../entities/redux/selectors';
import { updateEmployee as updateEmployeeAction } from '../../redux/actions';

/* =============================================================================
<EmployeesTableItem />
============================================================================= */
const EmployeesTableItem = ({ id, employee, updateEmployee }) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [disabled, setDisabled] = useState(false);

  const name = employee ? `${employee.profile.firstName} ${employee.profile.lastName}` : '';
  const email = employee ? employee.email : '';
  const phone = employee ? employee.phone : '';
  const role = employee ? employee.role?.title : '';
  const salary = employee ? employee.salary : '';

  // Set disabled
  useEffect(() => {
    setDisabled(!!employee?.disabled);
  }, [employee?.disabled]);

  const _handleActiveChange = async () => {
    setDisabled((prevState) => !prevState);
    updateEmployee({
      id,
      disabled: !disabled,
    }, (err) => {
      if (err) {
        setDisabled((prevState) => !prevState);
        toast({
          title: 'Error occured.',
          description: err?.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    });
  };

  return (
    <Tr>
      <Td textTransform="capitalize">{name}</Td>
      <Td>{email}</Td>
      <Td textAlign="start">{phone}</Td>
      <Td textAlign="start" textTransform="capitalize">{role}</Td>
      <Td textAlign="start">
        Rs.
        {salary}
      </Td>
      <Td>
        <Switch isChecked={!disabled} colorScheme="brand" onChange={_handleActiveChange} />
      </Td>
      <Td>
        <Button
          onClick={onOpen}
          leftIcon={<Edit size="18px" color="white" />}
        >
          Edit
        </Button>
      </Td>
      <EmployeeEditModal id={employee?.id} isOpen={isOpen} onClose={onClose} />
    </Tr>
  );
};

const mapStateToProps = (state, { id }) => ({
  employee: getEmployee(state, { id }),
});

const mapDispatchToProps = {
  updateEmployee: updateEmployeeAction,
};

// eslint-disable-next-line max-len
const propsAreEqual = (prevProps, nextProps) => prevProps.employee?.email === nextProps.employee?.email
  && prevProps.employee?.phone === nextProps.employee?.phone
  && prevProps.employee?.salary === nextProps.employee?.salary
  && prevProps.employee?.disabled === nextProps.employee?.disabled
  && prevProps.employee?.profile?.firstName === nextProps.employee?.profile?.firstName
  && prevProps.employee?.profile?.lastName === nextProps.employee?.profile?.lastName
  && prevProps.employee?.role?.title === nextProps.employee?.role?.title;

/* Export
============================================================================= */
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(EmployeesTableItem, propsAreEqual));
