import React from 'react';
import {
  Box,
  HStack,
  Button,
  Divider,
} from '@chakra-ui/react';

/* =============================================================================
<OrderSetupAddReservationFooter />
============================================================================= */
const OrderSetupAddReservationFooter = ({ onClose }) => (
  <Box flex={1}>
    <Divider my={5} />
    <HStack spacing={5}>
      <Button
        flex={1}
        colorScheme="gray"
        onClick={onClose}
      >
        Cancel

      </Button>
      <Button flex={1} type="submit">
        Add Reservation
      </Button>
    </HStack>
  </Box>
);

/* Export
============================================================================= */
export default OrderSetupAddReservationFooter;
