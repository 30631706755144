import React, { useState } from 'react';
import {
  Box,
  Text,
  Alert,
  Input,
  HStack,
  FormLabel,
  AlertIcon,
  FormControl,
  Button,
} from '@chakra-ui/react';
import {
  Check, Trash, Plus,
} from 'react-feather';
import { FastField, FieldArray, useFormikContext } from 'formik';

/* =============================================================================
<MenuSchedule />
============================================================================= */
const MenuSchedule = () => {
  const { values, setFieldValue } = useFormikContext();
  const [selectedIndex, setSelectedIndex] = useState(null);

  const { hours } = values;

  const _handleDayClick = (hour, index) => () => {
    setSelectedIndex(index);
    setFieldValue(`hours.${index}.active`, !hour.active);
  };

  return (
    <Box>
      <Text mb="10px" fontWeight="semibold">Menu Availability</Text>
      <Alert alignItems="flex-start" fontSize="sm" colorScheme="blue.50" status="info">
        <AlertIcon color="blue.400" />
        Select when this menu will be available to customers.
        Only one menu can be available at any given time.
        If you have two or more menus on the same day,
        make sure there is no gap in time between menus.
      </Alert>
      <HStack my={5}>
        {hours.map((hour, index) => (
          <React.Fragment key={index}>
            {hour.active ? (
              <Button
                flex={1}
                leftIcon={<Check />}
                colorScheme="brand"
                borderRadius="20px"
                onClick={_handleDayClick(hour, index)}
              >
                {DAYS[index]}
              </Button>
            ) : (
              <Button
                flex={1}
                colorScheme="gray"
                borderRadius="20px"
                onClick={_handleDayClick(hour, index)}
              >
                {DAYS[index]}
              </Button>
            )}
          </React.Fragment>
        ))}
      </HStack>
      <FieldArray name={`hours.${selectedIndex}.hours`}>
        {(arrayHelper) => (
          <Box>
            {typeof selectedIndex === 'number' && hours[selectedIndex].active && (
              <>
                <HStack justify="flex-end">
                  <Text>{DAYS[selectedIndex]}</Text>
                  <Button
                    size="sm"
                    variant="link"
                    onClick={() => arrayHelper.push({ start: '00:00', end: '23:59' })}
                    leftIcon={<Plus size="18px" />}
                  >
                    Add Timing
                  </Button>
                </HStack>
                <Box>
                  {hours[selectedIndex].hours.map((hour, index) => (
                    <HStack key={index} mb={5} spacing={8} alignItems="flex-end">
                      <FastField name={`hours.${selectedIndex}.hours.${index}.start`}>
                        {({ field }) => (
                          <FormControl>
                            <FormLabel>Start Time</FormLabel>
                            <Input type="time" {...field} />
                          </FormControl>
                        )}
                      </FastField>
                      <Text fontSize="18px">To</Text>
                      <FastField name={`hours.${selectedIndex}.hours.${index}.end`}>
                        {({ field }) => (
                          <FormControl>
                            <FormLabel>End Time</FormLabel>
                            <Input type="time" {...field} />
                          </FormControl>
                        )}
                      </FastField>
                      <Button onClick={() => arrayHelper.remove(index)} colorScheme="gray">
                        <Trash size="40px" />
                      </Button>
                    </HStack>
                  ))}
                </Box>
              </>
            )}
          </Box>
        )}
      </FieldArray>
    </Box>
  );
};

const DAYS = [
  'Sun',
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat',
];

/* Export
============================================================================= */
export default MenuSchedule;
