import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Card from '../../common/Card';
import ReservationsHeader from '../components/Reservations/ReservationsHeader/index';
import ReservationsTable from '../components/Reservations/ReservationsTable/index';
import ReservationsFooter from '../components/Reservations/ReservationsFooter';
import usePagination from '../../hooks/use-pagination';

import { getStatus, getBookingByStatus, getNextCursorByStatus } from '../redux/selectors';
import {
  getBookings as getBookingsAction,
  refreshBookings as refreshBookingsAction,
} from '../redux/actions';

/* =============================================================================
<ReservationsScreen />
============================================================================= */
const ReservationsScreen = ({
  status,
  bookings,
  getBookings,
  refreshBookings,
  nextCursor,
}) => {
  const {
    next,
    prev,
    page,
    maxPage,
    items,
    canNext,
    canPrev,
  } = usePagination(bookings);

  // Get bookings
  useEffect(() => {
    refreshBookings(status);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const _handleNext = () => {
    if (canNext) {
      next();
      return;
    }

    if (nextCursor) {
      getBookings(status, nextCursor, (err) => {
        if (!err) {
          next(bookings);
        }
      });
    }
  };

  const _handlePrev = () => {
    if (canPrev) {
      prev();
    }
  };

  return (
    <Card m={5} flex={1} direction="column">
      <ReservationsHeader />
      <ReservationsTable data={items} />
      <ReservationsFooter
        currentPage={page}
        maxPage={maxPage}
        canNext={canNext}
        canPrev={canPrev}
        onNext={_handleNext}
        onPrev={_handlePrev}
      />
    </Card>
  );
};

const mapStateToProps = (state) => ({
  status: getStatus(state),
  bookings: getBookingByStatus(state),
  nextCursor: getNextCursorByStatus(state),
});

const mapDispatchToProps = {
  getBookings: getBookingsAction,
  refreshBookings: refreshBookingsAction,
};

const propsAreEqual = (prevProps, nextProps) => prevProps.status === nextProps.status
  && prevProps.nextCursor === nextProps.nextCursor
  && prevProps.bookings?.toString() === nextProps.bookings?.toString();

/* Export
============================================================================= */
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(ReservationsScreen, propsAreEqual));
