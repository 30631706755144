import moment from 'moment';
import * as constants from './constants';

export const INITIAL_STATE = {
  status: 'pending',
  byStatus: {},
  byMonth: {},
  error: null,
  loading: false,
};

export default function reducer(state = INITIAL_STATE, action) {
  const { type, payload, error } = action;
  switch (type) {
    // STATUS_SET
    case constants.STATUS_SET:
      return {
        ...state,
        status: payload,
      };

    // BOOKINGS_GET
    case constants.BOOKINGS_GET.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.BOOKINGS_GET.FAIL:
      return {
        ...state,
        error,
      };
    case constants.BOOKINGS_GET.SUCCESS:
      return {
        ...state,
        error: null,
        byStatus: {
          ...state.byStatus,
          [payload.status]: {
            data: [...new Set([...state.byStatus[state.status].data, ...payload.data])],
            nextCursor: payload.nextCursor,
          },
        },
      };
    case constants.BOOKINGS_GET.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // BOOKINGS_REFRESH
    case constants.BOOKINGS_REFRESH.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.BOOKINGS_REFRESH.FAIL:
      return {
        ...state,
        error,
      };
    case constants.BOOKINGS_REFRESH.SUCCESS:
      return {
        ...state,
        error: null,
        byStatus: {
          ...state.byStatus,
          [payload.status]: {
            data: payload.data,
            nextCursor: payload.nextCursor,
          },
        },
      };
    case constants.BOOKINGS_REFRESH.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // BOOKINGS_BY_MONTH_GET
    case constants.BOOKINGS_BY_MONTH_GET.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.BOOKINGS_BY_MONTH_GET.FAIL:
      return {
        ...state,
        error,
      };
    case constants.BOOKINGS_BY_MONTH_GET.SUCCESS:
      return {
        ...state,
        error: null,
        byMonth: {
          ...state.byMonth,
          [payload.month]: payload.data,
        },
      };
    case constants.BOOKINGS_BY_MONTH_GET.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // BOOKING_UPDATE
    case constants.BOOKING_UPDATE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.BOOKING_UPDATE.FAIL:
      return {
        ...state,
        error,
      };
    case constants.BOOKING_UPDATE.SUCCESS:
      return {
        ...state,
        error: null,
      };
    case constants.BOOKING_UPDATE.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
