import React from 'react';
import { Divider, Flex } from '@chakra-ui/react';

import OrderReceiptHeader from './OrderReceiptHeader';
import OrderReceiptFooter from './OrderReceiptFooter';
import OrderReceiptItemsList from './OrderReceiptItemsList';
import OrderReceiptOrderDetails from './OrderReceiptOrderDetails';
import OrderReceiptPaymentDetails from './OrderReceiptPaymentDetails';
import OrderReceiptCustomerDetails from './OrderReceiptCustomerDetails';

/* =============================================================================
<OrderReceipt />
============================================================================= */
const OrderReceipt = ({ id }) => (
  <Flex
    p={5}
    w="97%"
    bgColor="#fff"
    flexDir="column"
  >
    <OrderReceiptHeader id={id} />
    <OrderReceiptOrderDetails id={id} />
    <OrderReceiptCustomerDetails id={id} />
    <Divider mb={4} />
    <OrderReceiptItemsList id={id} />
    <OrderReceiptPaymentDetails id={id} />
    <OrderReceiptFooter id={id} />
  </Flex>
);

/* Export
============================================================================= */
export default OrderReceipt;
