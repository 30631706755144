import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { Center, Text, useToast } from '@chakra-ui/react';

import Card from '../../common/Card';
import NeighborhoodsTable from '../components/NeighborhoodsTable';
import NeighborhoodsHeader from '../components/NeighborhoodsHeader';

import { getRestaurant } from '../../restaurant/redux/selectors';
import { getVisibleNeighborhoods } from '../redux/selectors';
import {
  getNeighborhoods as getNeighborhoodsAction,
  updateNeighborhoods as updateNeighborhoodsAction,
} from '../redux/actions';
import NeighborhoodViewModal from '../components/NeighborhoodViewModal';
import MyNeighborhoodsViewModal from '../components/MyNeighborhoodsViewModal';

/* =============================================================================
<NeighborhoodsScreen />
============================================================================= */
const NeighborhoodsScreen = ({
  restaurant,
  neighborhoods,
  getNeighborhoods,
  updateNeighborhoods,
}) => {
  const toast = useToast();
  const [sortBySelected, setSortBySelected] = useState(false);
  const [viewNeighborhood, setViewNeighborhood] = useState(null);
  const [viewMyNeighborhoods, setViewMyNeighborhoods] = useState(false);
  const [selectedNeighborhoods, setSelectedNeighborhoods] = useState([]);

  // Get neighborhoods
  useEffect(() => {
    getNeighborhoods();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Set selected neighborhoods
  useEffect(() => {
    if (restaurant?.neighborhoods?.length) {
      setSelectedNeighborhoods(
        restaurant.neighborhoods.map(neighborhood => ({
          id: neighborhood.id,
          fee:
            restaurant.deliveryFees && restaurant.deliveryFees[neighborhood.id],
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restaurant?.neighborhoods]);

  const _handleSelectAll = () => {
    setSelectedNeighborhoods(
      neighborhoods?.map(neighborhood => ({
        id: neighborhood,
        fee: '',
      }))
    );
  };

  const _handleUnselectAll = () => {
    setSelectedNeighborhoods([]);
  };

  const _handleItemSelect = id => {
    setSelectedNeighborhoods(prevState => [...prevState, { id, fee: '' }]);
  };

  const _handleItemUnselect = id => {
    setSelectedNeighborhoods(prevState =>
      prevState.filter(neighborhood => neighborhood.id !== id)
    );
  };

  const _handleItemFeeChange = (id, fee) => {
    setSelectedNeighborhoods(prevState =>
      prevState.map(neighborhood => {
        if (neighborhood.id === id) {
          return {
            ...neighborhood,
            fee,
          };
        }
        return neighborhood;
      })
    );
  };

  const _handleItemViewClick = id => {
    setViewNeighborhood(id);
  };

  const _handleViewModalClose = () => {
    setViewNeighborhood(null);
  };

  const _handleSaveClick = useCallback(() => {
    const itemWithoutFee = selectedNeighborhoods.find(item => !item.fee);

    if (itemWithoutFee) {
      toast({
        title: 'Delivery fee is required!',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    updateNeighborhoods(selectedNeighborhoods, err => {
      if (!err) {
        toast({
          title: 'Changes Saved',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: err.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNeighborhoods]);

  const sortedBySelected = neighborhoods
    .slice()
    .sort((id1, id2) =>
      selectedNeighborhoods.find(n => n.id === id1)
        ? -1
        : selectedNeighborhoods.find(n => n.id === id2)
        ? 1
        : 0
    );

  if (restaurant?.type !== 'chain') {
    return (
      <Card m={5} flex={1} direction="column" overflowY="auto">
        <NeighborhoodsHeader
          onClickViewMyAreas={() => setViewMyNeighborhoods(true)}
          onSaveClick={_handleSaveClick}
        />
        <NeighborhoodsTable
          data={sortBySelected ? sortedBySelected : neighborhoods}
          onClickColumn={() => setSortBySelected(prevState => !prevState)}
          selectedData={selectedNeighborhoods}
          onSelectAll={_handleSelectAll}
          onUnselectAll={_handleUnselectAll}
          onItemSelect={_handleItemSelect}
          onItemUnselect={_handleItemUnselect}
          onItemFeeChange={_handleItemFeeChange}
          onItemViewClick={_handleItemViewClick}
        />
        <NeighborhoodViewModal
          id={viewNeighborhood}
          onClose={_handleViewModalClose}
        />
        <MyNeighborhoodsViewModal
          isOpen={viewMyNeighborhoods}
          ids={selectedNeighborhoods.map(n => n.id)}
          onClose={() => setViewMyNeighborhoods(false)}
        />
      </Card>
    );
  }

  return (
    <Center height="calc(100vh - 72px)" px={6}>
      <Text fontSize="2xl" fontWeight="bold" color="gray.500">
        You need to login to individual branch to manage delivery areas
      </Text>
    </Center>
  );
};

const mapStateToProps = state => ({
  restaurant: getRestaurant(state),
  neighborhoods: getVisibleNeighborhoods(state),
});

const mapDispatchToProps = {
  getNeighborhoods: getNeighborhoodsAction,
  updateNeighborhoods: updateNeighborhoodsAction,
};

const propsAreEqual = (prevProps, nextProps) =>
  prevProps.restaurant?.type === nextProps.restaurant?.type &&
  prevProps.neighborhoods?.toString() === nextProps.neighborhoods?.toString() &&
  prevProps.restaurant?.neighborhoods?.toString() ===
    nextProps.restaurant?.neighborhoods?.toString();

/* Export
============================================================================= */
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(NeighborhoodsScreen, propsAreEqual));
