import React from 'react';
import { connect } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { Maximize, XCircle } from 'react-feather';
import {
  Flex,
  Button,
  HStack,
  List,
  ListItem,
} from '@chakra-ui/react';

import Card from '../../common/Card';
import POS_LINKS from '../../static/pos_links';

import {
  getBookingsCount,
  getOngoingOrdersCount,
  getCompletedOrdersCount,
} from '../redux/selectors';
import { getUser } from '../../auth/redux/selectors';
import { fromAdminPortal } from '../../util/functions';

/* =============================================================================
<PosNavHeader />
============================================================================= */
const PosNavHeader = ({
  user,
  fullScreen,
  bookingCount,
  ongoingOrderCount,
  completedOrderCount,
}) => {
  const location = useLocation();
  const orderCount = {
    '/pos/ongoing_order': ongoingOrderCount,
    '/pos/today_order': completedOrderCount,
    '/pos/reservation': bookingCount,
  };

  const _renderOrderCountBadge = (count) => (
    <Flex
      width="20px"
      height="20px"
      align="center"
      justify="center"
      ml={2}
      mb={2}
      bgColor="red"
      color="white"
      fontSize="10px"
      fontWeight="bold"
      borderRadius="50%"
    >
      {count}
    </Flex>
  );

  if (fromAdminPortal(user)) {
    return null
  }

  return (
    <Card
      my={fullScreen.active ? 0 : 5}
      mx={fullScreen.active ? 0 : 9}
      py={5}
      flex={1}
      display="flex"
      justifyContent="space-between"
      overflowX="auto"
    >
      <List display="flex">
        {POS_LINKS.map((link) => (
          <ListItem key={link.to}>
            {isActive(link.to, location) ? (
              <Button
                mr={12}
                as={NavLink}
                to={link.to}
                display="flex"
                color="red.700"
                variant="unstyled"
                alignItems="center"
                leftIcon={link.ActiveIcon}
              >
                {link.title}
                {orderCount[link.to] > 0 && _renderOrderCountBadge(orderCount[link.to])}
              </Button>
            ) : (
              <Button
                mr={12}
                as={NavLink}
                to={link.to}
                color="gray"
                display="flex"
                variant="unstyled"
                alignItems="center"
                leftIcon={link.UnActiveIcon}
              >
                {link.title}
                {orderCount[link.to] > 0 && _renderOrderCountBadge(orderCount[link.to])}
              </Button>
            )}
          </ListItem>
        ))}
      </List>
      <HStack>
        {fullScreen.active ? (
          <Button
            variant="outline"
            colorScheme="gray"
            borderRadius="8px"
            onClick={fullScreen.exit}
          >
            <Maximize size="15px" />
          </Button>
        ) : (
          <Button
            variant="outline"
            colorScheme="gray"
            borderRadius="8px"
            onClick={fullScreen.enter}
          >
            <Maximize size="15px" />
          </Button>
        )}
       <Button
          as={NavLink}
          to="/dashboard"
          variant="light"
          leftIcon={<XCircle size="20px" />}
        >
          Close POS
        </Button>
      </HStack>
    </Card>
  );
};

const isActive = (link, location) => location.pathname.startsWith(link);

const mapStateToProps = (state) => ({
  user: getUser(state),
  bookingCount: getBookingsCount(state),
  ongoingOrderCount: getOngoingOrdersCount(state, { type: 'all' }),
  completedOrderCount: getCompletedOrdersCount(state),
});

const propsAreEqual = (prevProps, nextProps) => (
  prevProps.bookingCount === nextProps.bookingCount
  && prevProps.ongoingOrderCount === nextProps.ongoingOrderCount
  && prevProps.completedOrderCount === nextProps.completedOrderCount
  && prevProps.fullScreen?.active === nextProps.fullScreen?.active
);

/* Export
============================================================================= */
export default connect(mapStateToProps)(React.memo(PosNavHeader, propsAreEqual));
