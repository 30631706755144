import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Box, useMediaQuery } from '@chakra-ui/react';

import OrdersHeader from '../components/OrdersHeader';
import OrdersMobileView from '../components/OrdersMobileView';
import OrdersDesktopView from '../components/OrdersDesktopView';

import { getOrders as getOrdersAction } from '../redux/actions';
import { getRestaurant as getRestaurantAction } from '../../restaurant/redux/actions';

/* =============================================================================
<OrdersScreen />
============================================================================= */
const OrdersScreen = ({ getOrders, getRestaurant }) => {
  const [isSmallerThan766] = useMediaQuery('(max-width: 766px)');

  // Get orders
  useEffect(() => {
    getOrders();
    getRestaurant();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Listen for browser tab focus event
  useEffect(() => {
    _handleOnTabFocus();
    window.addEventListener('focus', _handleOnTabFocus);
    return () => {
      window.removeEventListener('focus', _handleOnTabFocus);
    };
  }, []);

  const _handleOnTabFocus = async () => {
    try {
      const reg = await navigator?.serviceWorker?.ready;
      if (reg) {
        const notifications = await reg.getNotifications();
        notifications.forEach((notification) => {
          // Close new order notifications
          if (notification?.data?.type === 'order_new') {
            notification.close();
          }
        });
      }
    } catch (e) {
      // TODO
    }
  };

  return (
    <Box m={[3, 3, 5]} flex={1}>
      <OrdersHeader />
      {isSmallerThan766 ? (
        <OrdersMobileView />
      ) : (
        <OrdersDesktopView />
      )}
    </Box>
  );
};

const mapDispatchToProps = {
  getOrders: getOrdersAction,
  getRestaurant: getRestaurantAction,
};

/* Export
============================================================================= */
export default connect(null, mapDispatchToProps)(OrdersScreen);
