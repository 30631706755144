import React from 'react';
import {
  HStack,
  Button,
  Divider,
} from '@chakra-ui/react';

/* =============================================================================
<AddFeaturedItemModalFooter />
============================================================================= */
const AddFeaturedItemModalFooter = ({
  isSubmitting,
  onCancel,
  onSubmit,
}) => (
  <>
    <Divider my={5} />
    <HStack mb={2} spacing={4}>
      <Button
        flex={1}
        colorScheme="gray"
        variant="outline"
        onClick={onCancel}
      >
        Cancel
      </Button>
      <Button
        flex={1}
        isLoading={isSubmitting}
        onClick={onSubmit}
      >
        Save Changes
      </Button>
    </HStack>
  </>
);

/* Export
============================================================================= */
export default AddFeaturedItemModalFooter;
