import React from 'react';
import { connect } from 'react-redux';
import { Wrapper } from '@googlemaps/react-wrapper';
import { MapPin } from 'react-feather';
import { Box, Text, HStack } from '@chakra-ui/react';

import MapView from '../../../common/MapView';
import MapMarker from '../../../common/MapMarker';
import { GOOGLE_API_KEY } from '../../../config/secrets';

import { getRestaurant } from '../../redux/selectors';

/* =============================================================================
<RestaurantInfoLocation />
============================================================================= */
const RestaurantInfoLocation = ({ restaurant }) => {
  if (restaurant?.type === 'chain') {
    return null;
  }

  const address = restaurant?.address?.text;
  const location = restaurant?.location?.length === 2
    && { lat: restaurant.location[1], lng: restaurant.location[0] };

  return (
    <Box flex={1.5} p={4} borderRadius="8px" border="1px solid #D0D5DD">
      <Text fontWeight="medium">Restaurant Location</Text>
      <Box my={2} height="230px" borderRadius="md" overflow="hidden">
        {location && (
        <Wrapper apiKey={GOOGLE_API_KEY} render={render}>
          <MapView
            zoom={15}
            center={location}
          >
            <MapMarker position={location} />
          </MapView>
        </Wrapper>
        )}
      </Box>
      <HStack>
        <MapPin size="18px" />
        <Text>{address}</Text>
      </HStack>
    </Box>
  );
};

const render = (status) => <div>{status}</div>;

const mapStateToProps = (state) => ({
  restaurant: getRestaurant(state),
});

/* Export
============================================================================= */
export default connect(mapStateToProps)(RestaurantInfoLocation);
