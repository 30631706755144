import React from 'react';
import {
  Text,
  Input,
  HStack,
} from '@chakra-ui/react';

/* =============================================================================
<OrderSetupDeliveryFeeSelect />
============================================================================= */
const OrderSetupDeliveryFeeSelect = ({
  value,
  onChange,
}) => (
  <HStack spacing={4}>
    <Text flex={1}>Delivery Fee:</Text>
    <Input
      flex={1}
      type="number"
      value={value}
      placeholder="Select"
      onChange={(event) => onChange(event.target.value)}
    />
  </HStack>
);

/* Export
============================================================================= */
export default OrderSetupDeliveryFeeSelect;
