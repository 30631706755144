import React, { useEffect, useState } from 'react';
import DropZone from 'react-dropzone';
import {
  VStack,
  Flex,
  Text,
  Button,
  Input,
  Image,
  useToast,
} from '@chakra-ui/react';

import { ReactComponent as UploadIcon } from '../assets/icons/edit-upload-icon.svg';

/* =============================================================================
<FileDrop />
============================================================================= */
const FileDrop = ({ size, value, onChange }) => {
  const toast = useToast();
  const [photo, setPhoto] = useState(null);

  // Read file
  useEffect(() => {
    if (value instanceof File) {
      const reader = new FileReader();

      reader.onabort = () => toast({
        title: 'file read was aboard.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      reader.onerror = () => toast({
        title: 'file read has failed.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      reader.onload = () => {
        const binaryStr = reader.result;
        setPhoto(binaryStr);
      };
      reader.readAsDataURL(value);
    } else {
      setPhoto(value);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  if (photo) {
    return (
      <VStack>
        <Image src={photo} w={size} h={size} borderRadius="8px" />
        <Button my="12px" variant="outline" colorScheme="gray" onClick={() => setPhoto(null)}>
          Delete
        </Button>
      </VStack>
    );
  }

  return (
    <DropZone onDrop={(files) => onChange(files[0])}>
      {({ getRootProps, getInputProps }) => (
        <Flex
          p={4}
          w="300px"
          flexDir="column"
          alignItems="center"
          justifyContent="center"
          borderRadius="8px"
          border="2px dashed #d1d1d1"
          {...getRootProps()}
        >
          <UploadIcon />
          <Input {...getInputProps()} />
          <Text align="center">
            <Text color="red.700" fontWeight="bold">Click to upload</Text>
            or drag and drop
            SVG, PNG, JPG or GIF (max. 800x400px)
          </Text>
        </Flex>
      )}
    </DropZone>
  );
};

/* Export
============================================================================= */
export default FileDrop;
