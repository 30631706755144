import React from 'react';
import { connect } from 'react-redux';
import {
  Td,
  Text,
  HStack,
} from '@chakra-ui/react';
import { Clock } from 'react-feather';

import { calculateTimeDiff } from '../../../../../util/functions';

import { getBooking } from '../../../../../entities/redux/selectors';

/* =============================================================================
<ReservationsTableItemStatus />
============================================================================= */
const ReservationsTableItemStatus = ({ booking }) => {
  const status = booking?.status;

  const completed = status === 'completed' && 'green';
  const pending = status === 'pending' || status === 'accepted' ? 'orange' : '';
  const canceled = status === 'canceled' || status === 'declined' ? 'red' : '';

  return (
    <Td>
      <HStack>
        <Clock
          size="16px"
          color={pending || completed || canceled}
        />
        <Text
          fontSize="sm"
          color={pending || completed || canceled}
        >
          {status}
        </Text>
      </HStack>
    </Td>
  );
};

const mapStateToProps = (state, { id }) => ({
  booking: getBooking(state, { id }),
});

// eslint-disable-next-line max-len
const propsAreEqual = (prevProps, nextProps) => prevProps.booking?.status === nextProps.booking?.status;

/* Export
============================================================================= */
export default connect(
  mapStateToProps,
)(React.memo(ReservationsTableItemStatus, propsAreEqual));
