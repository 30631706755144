import React from 'react';
import {
  FormControl,
  Button,
  VStack,
  Input,
  InputLeftAddon,
  FormErrorMessage,
  InputGroup,
  HStack,
  Text,
  FormErrorIcon,
} from '@chakra-ui/react';
import { FastField, FieldArray, getIn } from 'formik';
import { Plus, Trash } from 'react-feather';

/* =============================================================================
<MultipleVariants />
============================================================================= */
const MultipleVariants = ({ variants }) => (
  <FieldArray name="variants">
    {(arrayHelper) => (
      <HStack my={8} alignItems="flex-start">
        <Text fontWeight="semibold" fontSize="sm" flex={2.1}>Item Price</Text>
        <VStack flex={3}>
          {variants && variants.map((variant, index) => (
            <HStack alignItems="flex-start">
              <FastField name={`variants.${index}.name`}>
                {({ field, form }) => (
                  <FormControl flex={1} isInvalid={getIn(form.errors, `variants.${index}.name`) && getIn(form.touched, `variants.${index}.name`)}>
                    <Input {...field} placeholder="Name" />
                    <FormErrorMessage>
                      <FormErrorIcon />
                      {getIn(form.errors, `variants.${index}.name`)}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </FastField>
              <FastField name={`variants.${index}.price`}>
                {({ field, form }) => (
                  <FormControl
                    my={8}
                    flex={1}
                    display="flex"
                    justifyContent="space-between"
                    isInvalid={getIn(form.errors, `variants.${index}.price`) && getIn(form.touched, `variants.${index}.price`)}
                  >
                    <VStack alignItems="flex-start" flex={2}>
                      <InputGroup>
                        <InputLeftAddon>Rs</InputLeftAddon>
                        <Input {...field} />
                      </InputGroup>
                      <FormErrorMessage>
                        <FormErrorIcon />
                        {getIn(form.errors, `variants.${index}.price`)}
                      </FormErrorMessage>
                    </VStack>
                  </FormControl>
                )}
              </FastField>
              {index > 0 ? (
                <Button
                  flex={1}
                  size="sm"
                  pt={4}
                  mx={10}
                  variant="link"
                  colorScheme="black"
                  onClick={() => arrayHelper.remove(index)}
                  leftIcon={<Trash size="18px" />}
                >
                  Delete
                </Button>
              ) : (
                <Button
                  flex={1}
                  size="sm"
                  pt={4}
                  mx={10}
                  variant="link"
                  leftIcon={<Plus size="18px" />}
                  onClick={() => arrayHelper.push({ name: '', price: '' })}
                >
                  Add Variation
                </Button>
              )}
            </HStack>
          ))}
        </VStack>
      </HStack>
    )}
  </FieldArray>
);

const SizeOptions = [
  'Small',
  'Medium',
  'Large',
  'Extra Large',
];

/* Export
============================================================================= */
export default MultipleVariants;
