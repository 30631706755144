import * as constants from './constants';

export const INITIAL_STATE = {
  inventories: [],
  nextCursor: '',
  error: null,
  loading: false,
};

export default function reducer(state = INITIAL_STATE, action) {
  const { type, payload, error } = action;

  switch (type) {
    // INVENTORIES_GET
    case constants.INVENTORIES_GET.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.INVENTORIES_GET.FAIL:
      return {
        ...state,
        error,
      };
    case constants.INVENTORIES_GET.SUCCESS:
      return {
        ...state,
        error: null,
        inventories: [...new Set([...state.inventories, ...payload.data])],
        nextCursor: payload.nextCursor,
      };
    case constants.INVENTORIES_GET.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // INVENTORIES_REFRESH
    case constants.INVENTORIES_REFRESH.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.INVENTORIES_REFRESH.FAIL:
      return {
        ...state,
        error,
      };
    case constants.INVENTORIES_REFRESH.SUCCESS:
      return {
        ...state,
        error: null,
        inventories: payload.data,
        nextCursor: payload.nextCursor,
      };
    case constants.INVENTORIES_REFRESH.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // INVENTORY_GET
    case constants.INVENTORY_GET.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.INVENTORY_GET.FAIL:
      return {
        ...state,
        error,
      };
    case constants.INVENTORY_GET.SUCCESS:
      return {
        ...state,
        error: null,
      };
    case constants.INVENTORY_GET.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    // INVENTORY_CREATE
    case constants.INVENTORY_CREATE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.INVENTORY_CREATE.FAIL:
      return {
        ...state,
        error,
      };
    case constants.INVENTORY_CREATE.SUCCESS:
      return {
        ...state,
        error: null,
        inventories: [...state.inventories, payload],
      };
    case constants.INVENTORY_CREATE.COMPLETE:
      return {
        ...state,
        loading: false,
      };

      // INVENTORY_UPDATE
    case constants.INVENTORY_UPDATE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.INVENTORY_UPDATE.FAIL:
      return {
        ...state,
        error,
      };
    case constants.INVENTORY_UPDATE.SUCCESS:
      return {
        ...state,
        error: null,
      };
    case constants.INVENTORY_UPDATE.COMPLETE:
      return {
        ...state,
        loading: false,
      };

      // INVENTORY_DELETE
    case constants.INVENTORY_DELETE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.INVENTORY_DELETE.FAIL:
      return {
        ...state,
        error,
      };
    case constants.INVENTORY_DELETE.SUCCESS:
      return {
        ...state,
        error: null,
        inventories: state.inventories.filter((item) => item !== payload),
      };
    case constants.INVENTORY_DELETE.COMPLETE:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
