import React from 'react';
import { FormControl, FormLabel, Textarea } from '@chakra-ui/react';

const OrderSetupComment = ({comment, onChange}) => (
  <FormControl mt={4}>
    <FormLabel fontSize="sm" fontWeight="normal">
      Additional Comments
    </FormLabel>
    <Textarea value={comment} onChange={(e) => onChange(e.target.value)} rows={2}/>
  </FormControl>
);

export default OrderSetupComment;
