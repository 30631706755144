import { actionGenerator } from '../../util/reduxHelpers';

export const NEIGHBORHOODS_GET = actionGenerator(
  'NEIGHBORHOODS/NEIGHBORHOODS_GET',
);

export const NEIGHBORHOODS_UPDATE = actionGenerator(
  'NEIGHBORHOODS/NEIGHBORHOODS_UPDATE',
);

export const QUERY_SET = 'NEIGHBORHOODS/QUERY_SET';
