import React, { useCallback, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Check, ArrowRight, Printer } from 'react-feather';
import {
  Box,
  Button,
  useToast,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react';

import OrderReceipt from '../../../../common/OrderReceipt';
import OrderCancelModal from '../../../../common/OrderCancelModal';
import OrderDispatchModal from '../../../../common/OrderDispatchModal';
import usePrint from '../../../../util/usePrint';

import { getOrder } from '../../../../entities/redux/selectors';
import { updateOrder as updateOrderAction } from '../../../redux/actions';

/* =============================================================================
<OrdersAcceptedActionsDefault />
============================================================================= */
const OrdersAcceptedActionsDefault = ({ id, order, updateOrder }) => {
  const toast = useToast();
  const orderReceiptRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [isLargerThen766] = useMediaQuery('(min-width: 766px)');
  const {
    isOpen: dispatchModalIsOpen,
    onOpen: onDispatchModalOpen,
    onClose: onDispatchModalClose,
  } = useDisclosure();
  const {
    isOpen: cancelModalIsOpen,
    onOpen: onCancelModalOpen,
    onClose: onCancelModalClose,
  } = useDisclosure();

  // eslint-disable-next-line max-len
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const reactToPrintContent = useCallback(() => orderReceiptRef.current, [orderReceiptRef.current]);

  const _handlePrintClick = usePrint({contentEl: reactToPrintContent, order});

  const _handleCancelConfirm = async (declineReason) => {
    await updateOrder({
      id: order.id,
      action: 'decline',
      declineReason,
    }, (err) => {
      if (err) {
        toast({
          title: err.message,
        });
      }
    });
    onCancelModalClose();
  };

  const _handleDispatchClick = async (rider) => {
    await updateOrder({
      id: order.id,
      rider,
      action: 'dispatch',
    }, (err) => {
      if (err) {
        toast({
          title: err.message,
        });
      }
    });
    onDispatchModalClose();
  };

  const _handleMarkAsPreparedClick = async () => {
    setLoading(true);
    await updateOrder({
      id: order.id,
      action: 'ready',
    }, (err) => {
      if (err) {
        toast({
          title: err.message,
        });
      }
    });
    setLoading(false);
  };

  const _handleMarkAsCompletedClick = async () => {
    setLoading(true);
    await updateOrder({
      id: order.id,
      action: 'complete',
    }, (err) => {
      if (err) {
        toast({
          title: err.message,
        });
      }
    });
    setLoading(false);
  };

  return (
    <>
      <Box display="none">
        <Box ref={orderReceiptRef}>
          <OrderReceipt id={id} />
        </Box>
      </Box>
      <Button
        px={4}
        variant="outline"
        colorScheme="gray"
        onClick={onCancelModalOpen}
      >
        Cancel
      </Button>
      <Button
        variant="outline"
        colorScheme="gray"
        onClick={_handlePrintClick}
      >
        <Printer size="20px" />
      </Button>
      {order?.type === 'delivery' ? (
        <>
          <Button
            ml={2}
            w={['100%', '100%', 'auto']}
            rightIcon={isLargerThen766 && <ArrowRight size="20px" />}
            onClick={onDispatchModalOpen}
          >
            Dispatch
          </Button>
          <OrderDispatchModal
            isOpen={dispatchModalIsOpen}
            onClose={onDispatchModalClose}
            onSubmit={_handleDispatchClick}
          />
        </>
      ) : (
        <>
          {order?.status === 'accepted' && (
            <Button
              ml={2}
              w={['100%', '100%', 'auto']}
              isLoading={loading}
              leftIcon={isLargerThen766 && <Check size="20px" />}
              onClick={_handleMarkAsPreparedClick}
            >
              Ready
            </Button>
          )}
          {order?.status === 'prepared' && (
            <Button
              w={['100%', '100%', 'auto']}
              isLoading={loading}
              leftIcon={isLargerThen766 && <Check size="20px" />}
              onClick={_handleMarkAsCompletedClick}
            >
              Complete
            </Button>
          )}
        </>
      )}
      <OrderCancelModal
        isOpen={cancelModalIsOpen}
        onClose={onCancelModalClose}
        onSubmit={_handleCancelConfirm}
      />
    </>
  );
};

const mapStateToProps = (state, { id }) => ({
  order: getOrder(state, { id }),
});

const mapDispatchToProps = {
  updateOrder: updateOrderAction,
};

const propsAreEqual = (prevProps, nextProps) => prevProps.order?.id === nextProps.order?.id
  && prevProps.order?.type === nextProps.order?.type
  && prevProps.order?.status === nextProps.order?.status;

/* Export
============================================================================= */
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(OrdersAcceptedActionsDefault, propsAreEqual));
