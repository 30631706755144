import { createSelector } from 'reselect';

/**
 * Get error
 */
export const getError = (state) => state.Management.error;

/**
   * Get loading
   */
export const getLoading = (state) => state.Management.loading;

/**
   * Get tab
   */
export const getTab = (state) => state.Management.tab;

/**
   * Get tables
   */
export const getTables = (state) => state.Management.tables;

/**
   * Get area Entity
   */
export const getAreaEntity = (state) => state.Entities.areas;

/**
   * Get areas
   */
export const getAreas = (state) => state.Management.areas;

/**
 * Get areas by Id
 */
export const getAreasById = createSelector(
  [getAreas, getAreaEntity],
  (allIds, byId) => allIds.map((id) => byId[id]),
);

/**
 * Get tables by Id
 */
export const getTablesById = createSelector(
  [getTables, getAreaEntity],
  (allIds, byId) => allIds.map((id) => byId[id]),
);
