import { actionGenerator } from '../../util/reduxHelpers';

export const EMPLOYEES_GET = actionGenerator(
  'EMPLOYEES/EMPLOYEES_GET',
);
export const EMPLOYEES_REFRESH = actionGenerator(
  'EMPLOYEES/EMPLOYEES_REFRESH',
);
export const EMPLOYEE_GET = actionGenerator(
  'EMPLOYEES/EMPLOYEE_GET',
);
export const EMPLOYEE_CREATE = actionGenerator(
  'EMPLOYEES/EMPLOYEE_CREATE',
);
export const EMPLOYEE_UPDATE = actionGenerator(
  'EMPLOYEES/EMPLOYEE_UPDATE',
);
export const ROLES_GET = actionGenerator(
  'EMPLOYEES/ROLES_GET',
);
