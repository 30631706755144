import { normalize } from 'normalizr';
import * as constants from './constants';

import request from '../../util/request';
import { addEntities } from '../../entities/redux/actions';
import { area as areaSchema, table as tableSchema } from '../../entities/api/schema';

/**
 * TAB_SET
 */
export const setTab = (payload) => ({
  type: constants.TAB_SET,
  payload,
});

/**
 *AREAS_GET
 */
export const getAreas = (cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.AREAS_GET.REQUEST });

    const payload = await request({
      url: '/areas',
      method: 'GET',
    });
    const { entities, result } = normalize(payload.data, [areaSchema]);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.AREAS_GET.SUCCESS,
      payload: result,
    });
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.AREAS_GET.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.AREAS_GET.COMPLETE });
  }
};

/**
 *.AREA_CREATE
 */
export const createArea = (area, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.AREA_CREATE.REQUEST });

    const payload = await request({
      url: '/areas',
      method: 'POST',
      data: area,
    });
    const { entities, result } = normalize(payload, areaSchema);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.AREA_CREATE.SUCCESS,
      payload: result,
    });
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.AREA_CREATE.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.AREA_CREATE.COMPLETE });
  }
};

/**
 *.AREA_UPDATE
 */
export const updateArea = (area, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.AREA_UPDATE.REQUEST });
    const payload = await request({
      url: `/areas/${area.id}`,
      method: 'PUT',
      data: area,
    });
    const { entities } = normalize(payload, areaSchema);

    dispatch(addEntities(entities));
    dispatch({ type: constants.AREA_UPDATE.SUCCESS });
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.AREA_UPDATE.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.AREA_UPDATE.COMPLETE });
  }
};

/**
 *.AREA_DELETE
 */
export const deleteArea = (id, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.AREA_DELETE.REQUEST });

    await request({
      url: `/areas/${id}`,
      method: 'DELETE',
    });

    dispatch({
      type: constants.AREA_DELETE.SUCCESS,
      payload: id,
    });

    if (typeof cb === 'function') {
      cb(null);
    }
  } catch (error) {
    dispatch({
      type: constants.AREA_DELETE.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.AREA_DELETE.COMPLETE });
  }
};

/**
 *TABLES_GET
 */
export const getTables = (cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.TABLES_GET.REQUEST });

    const payload = await request({
      url: '/tables',
      method: 'GET',
    });
    const { entities, result } = normalize(payload.data, [tableSchema]);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.TABLES_GET.SUCCESS,
      payload: result,
    });
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.TABLES_GET.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.TABLES_GET.COMPLETE });
  }
};

/**
 *.TABLE_CREATE
 */
export const createTable = (table, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.TABLE_CREATE.REQUEST });

    const payload = await request({
      url: '/tables',
      method: 'POST',
      data: table,
    });

    const { entities, result } = normalize(payload, tableSchema);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.TABLE_CREATE.SUCCESS,
      payload: result,
    });
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.TABLE_CREATE.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.TABLE_CREATE.COMPLETE });
  }
};

/**
 *.TABLE_UPDATE
 */
export const updateTable = (table, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.TABLE_UPDATE.REQUEST });
    const payload = await request({
      url: `/tables/${table.id}`,
      method: 'PUT',
      data: table,
    });
    const { entities } = normalize(payload, tableSchema);

    dispatch(addEntities(entities));
    dispatch({ type: constants.TABLE_UPDATE.SUCCESS });
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.TABLE_UPDATE.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.TABLE_UPDATE.COMPLETE });
  }
};

/**
 *.TABLE_DELETE
 */
export const deleteTable = (id, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.TABLE_DELETE.REQUEST });

    await request({
      url: `/tables/${id}`,
      method: 'DELETE',
    });

    dispatch({
      type: constants.TABLE_DELETE.SUCCESS,
      payload: id,
    });

    if (typeof cb === 'function') {
      cb(null);
    }
  } catch (error) {
    dispatch({
      type: constants.TABLE_DELETE.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.TABLE_DELETE.COMPLETE });
  }
};
