import React, { useState } from 'react';
import { ChevronDown, RotateCcw } from 'react-feather';
import { connect } from 'react-redux';
import {
  Menu,
  Button,
  HStack,
  MenuList,
  MenuButton,
  Text,
  IconButton,
  useMediaQuery,
} from '@chakra-ui/react';

import HeaderMenuUser from './HeaderMenuUser';
import HeaderMenuLinks from './HeaderMenuLinks';
import HeaderMenuRestaurantOperations from './HeaderMenuRestaurantOperations';
import { getRestaurant } from '../../../restaurant/redux/selectors';

/* =============================================================================
<HeaderMenu />
============================================================================= */
const HeaderMenu = ({ restaurant }) => {
  const [isSmallerThan766] = useMediaQuery('(max-width: 766px)');
  const [loading, setLoading] = useState(false);

  const onRefresh = () => {
    setLoading(true);
    window.location.reload();
  };

  return (
    <HStack>
      {isSmallerThan766 && window.ReactNativeWebView && (
        <IconButton
          variant="ghost"
          icon={<RotateCcw size={20} />}
          isLoading={loading}
          isDisabled={loading}
          onClick={onRefresh}
          _hover={{ color: 'white', bgColor: 'brand.50' }}
        />
      )}
      <HeaderMenuUser />
      <Menu isLazy>
        <MenuButton
          as={Button}
          variant="unstyled"
          rightIcon={<ChevronDown />}
        />
        <MenuList mt={3}>
          {isSmallerThan766 && (
            <Text
              px="20px"
              fontSize="xs"
              fontWeight="bold"
              color="gray.600"
              mb={2}
            >
              {restaurant?.name || restaurant?.chain?.name}
              {' '}
              {restaurant?.address?.branch && restaurant?.address?.branch}
            </Text>
          )}
          <HeaderMenuRestaurantOperations />
          <HeaderMenuLinks />
        </MenuList>
      </Menu>
    </HStack>
  );
};

const mapStateToProps = (state) => ({
  restaurant: getRestaurant(state),
});

/* Export
============================================================================= */
export default connect(mapStateToProps)(HeaderMenu);
