import React from 'react';
import { FieldArray, useFormikContext } from 'formik';
import {
  Box,
  Text,
  HStack,
  Switch,
  VStack,
} from '@chakra-ui/react';

import OpeningScheduleDaySelectTime from './OpeningScheduleDaySelectTime';

/* =============================================================================
<OpeningScheduleDay />
============================================================================= */
const OpeningScheduleDay = ({ day, index }) => {
  const { setFieldValue } = useFormikContext();

  const _handleDayToggle = () => {
    setFieldValue(`hours.${index}.closed`, !day.closed, false);
  };

  return (
    <HStack alignItems="flex-start" minH="44px" mb={5}>
      <Text flex={1} fontSize="sm">{DAYS[index]}</Text>
      <HStack flex={0.6}>
        <Switch
          size="lg"
          colorScheme="brand"
          isChecked={!day.closed}
          onChange={_handleDayToggle}
        />
        <Text>{day.closed ? 'Close' : 'Open'}</Text>
      </HStack>
      {!day.closed ? (
        <FieldArray name={`hours.${index}.hours`}>
          {(arrayHelper) => (
            <VStack flex={4}>
              {day.hours.map((hour, i) => (
                <OpeningScheduleDaySelectTime
                  key={i}
                  hour={hour}
                  index={i}
                  dayIndex={index}
                  onDeleteHourClick={() => arrayHelper.pop()}
                  onAddHourClick={() => arrayHelper.push({ open: '00:00', close: '11:59' })}
                />
              ))}
            </VStack>
          )}
        </FieldArray>
      ) : <Box flex={4} />}
    </HStack>
  );
};

const DAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

/* Export
============================================================================= */
export default OpeningScheduleDay;
