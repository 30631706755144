import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Search } from 'react-feather';
import {
  HStack,
  Input,
  Tabs,
  Tab,
  TabList,
  InputGroup,
  InputLeftElement,
  Button,
  useDisclosure,
} from '@chakra-ui/react';

import { getStatus } from '../../redux/selectors';
import { setStatus as setStatusAction } from '../../redux/actions';
import OrderHistoryDownloadModal from '../OrderHistoryDownloadModal';

/* =============================================================================
<OrderHistoryStatusSelect />
============================================================================= */
const OrderHistoryStatusSelect = ({ status, setStatus }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const { isOpen, onClose, onOpen } = useDisclosure();
  // Set current active tab
  useEffect(() => {
    switch (status) {
      case 'completed':
        setTabIndex(0);
        break;
      case 'canceled':
        setTabIndex(1);
        break;

      default:
        break;
    }
  }, [status]);

  const _handleTabChange = value => {
    setTabIndex(value);
    switch (value) {
      case 0:
        setStatus('completed');
        break;
      case 1:
        setStatus('canceled');
        break;

      default:
        break;
    }
  };
  return (
    <>
      <HStack justify="space-between" px={6}>
        <Tabs
          ml="-30px"
          colorScheme="brand"
          index={tabIndex}
          onChange={_handleTabChange}
        >
          <TabList border="none">
            <Tab>Completed</Tab>
            <Tab>Canceled</Tab>
          </TabList>
        </Tabs>
        <HStack>
          <Button px={8} onClick={onOpen}>
            Download
          </Button>
          <InputGroup maxW="320px">
            <InputLeftElement>
              <Search size="15px" />
            </InputLeftElement>
            <Input placeholder="Search" />
          </InputGroup>
        </HStack>
      </HStack>
      <OrderHistoryDownloadModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};

const mapStateToProps = state => ({
  status: getStatus(state),
});

const mapDispatchToProps = {
  setStatus: setStatusAction,
};

const propsAreEqual = (prevProps, nextProps) =>
  prevProps.type === nextProps.type;

/* Export
============================================================================= */
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(OrderHistoryStatusSelect, propsAreEqual));
