import React from 'react';
import { connect } from 'react-redux';
import { Wrapper } from '@googlemaps/react-wrapper';
import {
  Box,
  Modal,
  ModalBody,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
} from '@chakra-ui/react';

import MapView from '../../common/MapView';
import { GOOGLE_API_KEY } from '../../config/secrets';

import { getNeighborhood } from '../../entities/redux/selectors';

/* =============================================================================
<NeighborhoodViewModal />
============================================================================= */
const NeighborhoodViewModal = ({ id, neighborhood, onClose }) => {
  const isOpen = Boolean(id);
  const name = neighborhood?.name;
  const town = neighborhood?.area;
  const geometry = neighborhood?.geometry;
  const title = `${name}, ${town}`;
  const polygon = {
    path:
      geometry?.coordinates &&
      geometry.coordinates[0]?.map(coordinate => ({
        lat: coordinate[1],
        lng: coordinate[0],
      })),
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box height="318px" mb={6}>
            <Wrapper apiKey={GOOGLE_API_KEY}>
              <MapView polygons={[polygon]} />
            </Wrapper>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const mapStateToProps = (state, { id }) => ({
  neighborhood: getNeighborhood(state, { id }),
});

const propsAreEqual = (prevProps, nextProps) =>
  prevProps.id === nextProps.id &&
  prevProps.neighborhood?.name === nextProps.neighborhood?.name &&
  prevProps.neighborhood?.town === nextProps.neighborhood?.town &&
  JSON.stringify(prevProps.neighborhood?.geometry) ===
    JSON.stringify(nextProps.neighborhood?.geometry);

/* Export
============================================================================= */
export default connect(mapStateToProps)(
  React.memo(NeighborhoodViewModal, propsAreEqual)
);
