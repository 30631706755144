import React from 'react';
import { connect } from 'react-redux';

import Card from '../../common/Card';
import TodayOrderHeader from '../components/TodayOrder/TodayOrderHeader';
import TodayOrderFooter from '../components/TodayOrder/TodayOrderFooter';
import TodayOrderTable from '../components/TodayOrder/TodayOrderTable/index';
import usePagination from '../../hooks/use-pagination';

import { getCompletedOrders } from '../redux/selectors';

/* =============================================================================
<TodayOrderScreen />
============================================================================= */
const TodayOrderScreen = ({ orders }) => {
  const {
    next,
    prev,
    page,
    maxPage,
    items,
    canNext,
    canPrev,
  } = usePagination(orders);

  const _handleNext = () => {
    if (canNext) {
      next();
    }
  };

  const _handlePrev = () => {
    if (canPrev) {
      prev();
    }
  };

  return (
    <Card>
      {/* <TodayOrderHeader /> */}
      <TodayOrderTable data={items} />
      <TodayOrderFooter
        currentPage={page}
        maxPage={maxPage}
        canNext={canNext}
        canPrev={canPrev}
        onNext={_handleNext}
        onPrev={_handlePrev}
      />
    </Card>
  );
};

const mapStateToProps = (state) => ({
  orders: getCompletedOrders(state),
});

/* Export
============================================================================= */
export default connect(mapStateToProps)(TodayOrderScreen);
