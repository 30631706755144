import React from 'react';
import { Route, Switch } from 'react-router';
import {
  Box,
  Flex,
  HStack,
} from '@chakra-ui/react';

import Card from '../../common/Card';
import MenuList from '../components/MenuList/index';
import MenuHeader from '../components/MenuHeader';
import ExtraChoices from '../components/ExtraChoices';
import Discounts from '../components/Discounts';
import MenuItemDetails from '../components/MenuItemDetails';
import MenuFeaturedItems from '../components/MenuFeaturedItems';

/* =============================================================================
<MenuScreen />
============================================================================= */
const MenuScreen = () => (
  <Flex m={5} flex={1} direction="column">
    <MenuHeader />
    <Card mt={4} flex={1} p={4} as={HStack} alignItems="flex-start">
      <MenuList />
      <Switch>
        <Route path="/menu" exact>
          <Box flex={4} />
        </Route>
        <Route path="/menu/extra_choices" exact>
          <ExtraChoices />
        </Route>
        <Route path="/menu/discounts" exact>
          <Discounts />
        </Route>
        <Route path="/menu/:menu/featured" exact>
          <MenuFeaturedItems />
        </Route>
        <Route path="/menu/:menu/:menuItem" exact>
          <MenuItemDetails />
        </Route>
      </Switch>
    </Card>
  </Flex>
);

/* Export
============================================================================= */
export default MenuScreen;
