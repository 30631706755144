import React from 'react';
import { FastField } from 'formik';
import {
  Box,
  Input,
  VStack,
  FormLabel,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
} from '@chakra-ui/react';

/* =============================================================================
<ReservationFormFee />
============================================================================= */
const ReservationFormFee = () => (
  <Box pr={[0, 0, 0, '40%']}>
    <FastField name="fee">
      {({ field, form }) => (
        <FormControl
          mb={8}
          display="flex"
          isInvalid={form.errors.fee && form.touched.fee}
        >
          <FormLabel flex={1.5}>Fee</FormLabel>
          <VStack alignItems="flex-start" flex={2}>
            <Input type="number" id="fee" {...field} />
            <FormErrorMessage>
              <FormErrorIcon />
              {form.errors.fee}
            </FormErrorMessage>
          </VStack>
        </FormControl>
      )}
    </FastField>
  </Box>
);

/* Export
============================================================================= */
export default ReservationFormFee;
