import React from 'react';
import { Box, Text, Divider } from '@chakra-ui/react';

import OrderHistoryTypeSelect from './OrderHistoryTypeSelect';
import OrderHistoryStatusSelect from './OrderHistoryStatusSelect';

/* =============================================================================
<OrderHistoryHeader />
============================================================================= */
const OrderHistoryHeader = () => (
  <Box mb={5}>
    <Text fontSize="lg" fontWeight="medium">Order History</Text>
    <Text fontSize="sm">View past orders</Text>
    <Divider my={5} />
    <OrderHistoryTypeSelect />
    <OrderHistoryStatusSelect />
  </Box>
);

/* Export
============================================================================= */
export default OrderHistoryHeader;
