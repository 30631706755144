import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import ViewportList from "react-viewport-list";
import {
  VStack,
  useDisclosure,
  useMediaQuery,
  Flex,
} from '@chakra-ui/react';

import OrderCard from '../OrderCard/index';
import OrdersTabHeader from '../OrdersTabHeader';
import OrderDetailsModal from '../../../common/OrderDetailsModal/index';
import OrdersAcceptedActions from './OrderAcceptedActions';

import { getOrdersByStatusAndType } from '../../redux/selectors';

/* =============================================================================
<OrdersAccepted />
============================================================================= */
const OrdersAccepted = ({ orders }) => {
  const list = useRef();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isLargerThan766] = useMediaQuery('(min-width: 766px)');

  const _handleDetailsModalOpen = (id) => {
    setSelectedOrder(id);
    onOpen();
  };

  const _handleDetailsModalClose = () => {
    setSelectedOrder(null);
    onClose();
  };

  return (
    <VStack flex={1}>
      {isLargerThan766 && (
        <OrdersTabHeader title={`Accepted (${orders.length})`} bg="orange.400" />
      )}
      <Flex direction="column" h="56vh" w="100%" overflowY="scroll" ref={list}>
        <ViewportList
          viewportRef={list}
          items={orders}
          itemMinSize={100}
        >
          {(order) => (
            <OrderCard
              id={order}
              key={order}
              footer={<OrdersAcceptedActions id={order} />}
              onDetailsClick={() => _handleDetailsModalOpen(order)}
            />
          )}
        </ViewportList>
      </Flex>
      <OrderDetailsModal
        id={selectedOrder}
        isOpen={isOpen}
        footer={<OrdersAcceptedActions id={selectedOrder} />}
        onClose={_handleDetailsModalClose}
      />
    </VStack>
  );
};

const mapStateToProps = (state) => ({
  orders: getOrdersByStatusAndType(state, { status: 'accepted' }),
});

// eslint-disable-next-line max-len
const propsAreEqual = (prevProps, nextProps) => prevProps.orders.toString() === nextProps.orders.toString();

/* Export
============================================================================= */
export default connect(mapStateToProps)(React.memo(OrdersAccepted, propsAreEqual));
