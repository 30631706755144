import React, { useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import {
  Box,
  Tag,
  Text,
  HStack,
  Button,
} from '@chakra-ui/react';
import { FileText } from 'react-feather';
import { useReactToPrint } from 'react-to-print';

import OrderReceipt from '../../../../common/OrderReceipt';

import { getOrder } from '../../../../entities/redux/selectors';

/* =============================================================================
<OngoingOrderCardId />
============================================================================= */
const OngoingOrderCardId = ({ id, order }) => {
  const orderTag = order?.tag;
  const orderReceiptRef = useRef(null);
  const orderType = order?.type === 'delivery' ? 'Delivery'
    : order?.type === 'take_away' ? 'Take Away'
      : order?.type === 'dine_in' ? 'Dine In' : null;

  // eslint-disable-next-line max-len
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const reactToPrintContent = useCallback(() => orderReceiptRef.current, [orderReceiptRef.current]);

  const _handlePrintClick = useReactToPrint({
    content: reactToPrintContent,
  });

  return (
    <HStack align="start" justify="space-between">
      <Box>
        <Tag variant="subtle" colorScheme="red" mb={2}>{orderType}</Tag>
        <Text fontSize="sm" color="brand.700" mb={2}>{`#${orderTag}`}</Text>
      </Box>
      <Button variant="outline" colorScheme="gray" onClick={_handlePrintClick}>
        <FileText />
      </Button>
      <Box display="none">
        <Box ref={orderReceiptRef}>
          <OrderReceipt id={id} />
        </Box>
      </Box>
    </HStack>
  );
};

const mapStateToProps = (state, { id }) => ({
  order: getOrder(state, { id }),
});

const propsAreEqual = (prevProps, nextProps) => (
  prevProps.order?.tag === nextProps.order?.tag
  && prevProps.order?.type === nextProps.order?.type
);

/* Export
============================================================================= */
export default connect(mapStateToProps)(React.memo(OngoingOrderCardId, propsAreEqual));
