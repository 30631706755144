import React, { useState } from 'react';
import { Box, HStack, Text } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import OrderSetup from '../components/OrderSetup/index';
import Menus from '../components/Menus/index';

import { getOrder } from '../../entities/redux/selectors';
import Card from '../../common/Card';

/* =============================================================================
<EditOrderScreen />
============================================================================= */
const EditOrderScreen = () => {
  const params = useParams();
  const id = params?.id;
  const order = useSelector((state) => getOrder(state, { id }));

  const [items, setItems] = useState(order?.items);

  const _handleItemSelect = (item) => {
    setItems((prevState) => [...prevState, item]);
  };

  return (
    <HStack align="start" p={0} spacing={4}>
      <Menus onItemSelect={_handleItemSelect} />
      <OrderSetup id={id} items={items} setItems={setItems} />
    </HStack>
  );
};

/* Export
============================================================================= */
export default EditOrderScreen;
