import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Tabs,
  TabList,
} from '@chakra-ui/react';

import MenusTabItem from './MenusTabItem';

import { getMenus } from '../../../../menu/redux/selectors';

/* =============================================================================
<MenusTab />
============================================================================= */
const MenusTab = ({ menus, value, onChange }) => {
  const [tabIndex, setTabIndex] = useState(0);

  // Set default menu at start
  useEffect(() => {
    if (!value && menus?.length) {
      onChange(menus[0]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menus?.length]);

  // Select tab
  useEffect(() => {
    if (value) {
      for (let i = 0; i < menus?.length; i += 1) {
        if (menus[i] === value) {
          setTabIndex(i);
          break;
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const _handleChange = (index) => {
    onChange(menus[index]);
  };

  return (
    <Tabs index={tabIndex} px={6} isLazy colorScheme="brand" onChange={_handleChange}>
      <TabList border="none">
        {menus?.map((menu) => (
          <MenusTabItem key={menu} id={menu} />
        ))}
      </TabList>
    </Tabs>
  );
};

const mapStateToProps = (state) => ({
  menus: getMenus(state),
});

const propsAreEqual = (prevProps, nextProps) => prevProps.value === nextProps.value
  && prevProps.menus?.toString() === nextProps.menus?.toString();

/* Export
============================================================================= */
export default connect(mapStateToProps)(React.memo(MenusTab, propsAreEqual));
