import React from 'react';
import {
  Text,
  Input,
  HStack,
  FormLabel,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
} from '@chakra-ui/react';
import { FastField } from 'formik';

/* =============================================================================
<ChoiceRange />
============================================================================= */
const ChoiceRange = () => (
  <HStack spacing={4} alignItems="flex-start">
    <FastField name="min">
      {({ field, form }) => (
        <FormControl mb={8} isInvalid={form.errors.min && form.touched.min}>
          <FormLabel>Minimum Number of Choices</FormLabel>
          <Input
            id="min"
            {...field}
          />
          <Text mt={2} fontSize="sm">Enter 0 if this group is optional for your customers</Text>
          <FormErrorMessage>
            <FormErrorIcon />
            {form.errors.min}
          </FormErrorMessage>
        </FormControl>
      )}
    </FastField>
    <FastField name="max">
      {({ field, form }) => (
        <FormControl mb={8} isInvalid={form.errors.max && form.touched.max}>
          <FormLabel>Maximum Number of Choices</FormLabel>
          <Input
            id="max"
            {...field}
          />
          <Text mt={2} fontSize="sm">Enter 1 if your customers can only choose</Text>
          <FormErrorMessage>
            <FormErrorIcon />
            {form.errors.max}
          </FormErrorMessage>
        </FormControl>
      )}
    </FastField>
  </HStack>
);

/* Export
============================================================================= */
export default ChoiceRange;
