import React from 'react';
import {
  Text,
  HStack,
} from '@chakra-ui/react';
import { useFormikContext } from 'formik';

import FileSelect from '../../../common/FileSelect';

/* =============================================================================
<EmployeePhotoUpload />
============================================================================= */
const EmployeePhotoUpload = () => {
  const { values, setFieldValue } = useFormikContext();

  const _handlePhotoChange = async (value) => {
    setFieldValue('avatar', value);
  };

  return (
    <HStack my={5} alignItems="flex-start">
      <Text flex={1}>Employee Photo</Text>
      <FileSelect size="160px" value={values.avatar} onChange={_handlePhotoChange} />
    </HStack>
  );
};

/* Export
============================================================================= */
export default EmployeePhotoUpload;
