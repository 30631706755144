import { createSelector } from 'reselect';

import { getAreas, getTables } from '../../management/redux/selectors';

/**
 * Get error
 */
export const getError = (state) => state.Pos.error;

/**
 * Get loading
 */
export const getLoading = (state) => state.Pos.loading;

/**
 * Get riders
 */
export const getRiders = (state) => state.Pos.riders;

/**
 * Get waiters
 */
export const getWaiters = (state) => state.Pos.waiters;

/**
 * Get orders
 */
export const getOrders = (state) => state.Pos.orders;

/**
 * Get bookings
 */
export const getBookings = (state) => state.Pos.bookings;

/**
 * Get bookings count
 */
export const getBookingsCount = createSelector(
  [getBookings],
  (ids) => ids.length,
);

/**
 * Get orders entity
 */
const getOrdersEntity = (state) => state.Entities.orders || {};

/**
 * Get users entity
 */
const getUsersEntity = (state) => state.Entities.users || {};

/**
 * Get areas entity
 */
const getAreasEntity = (state) => state.Entities.areas || {};

/**
 * Get tables entity
 */
const getTablesEntity = (state) => state.Entities.tables || {};

/**
 * Get  type
 */
export const getType = (state, { type }) => type;

/**
 * Get on going orders
 */
export const getOngoingOrders = createSelector(
  [getOrders, getOrdersEntity, getType],
  (allIds, byId, type) => {
    if (type === 'all') {
      return allIds
        .filter((id) => (byId[id]?.status === 'accepted' || byId[id]?.status === 'prepared'));
    }
    return allIds
      .filter((id) => (byId[id]?.status === 'accepted' || byId[id]?.status === 'prepared') && byId[id]?.type === type);
  },
);

/**
 * Get completed orders
 */
export const getCompletedOrders = createSelector(
  [getOrders, getOrdersEntity],
  (allIds, byId) => allIds
    .filter((id) => byId[id]?.status === 'completed'),
);

/**
 * Get on going orders count
 */
export const getOngoingOrdersCount = createSelector(
  [getOngoingOrders],
  (allIds) => allIds?.length || 0,
);

/**
 * Get completed orders count
 */
export const getCompletedOrdersCount = createSelector(
  [getCompletedOrders],
  (allIds) => allIds?.length || 0,
);

/**
 * Get riders with details
 */
export const getRidersWithDetails = createSelector(
  [getRiders, getUsersEntity],
  (allIds, byId) => allIds.map((id) => byId[id]),
);

/**
 * Get waiters with details
 */
export const getWaitersWithDetails = createSelector(
  [getWaiters, getUsersEntity],
  (allIds, byId) => allIds.map((id) => byId[id]),
);

/**
 * Get areas
 */
export const getAreasWithDetails = createSelector(
  [getAreas, getAreasEntity],
  (allIds, byId) => allIds.map((id) => byId[id]),
);

/**
 * Get tables
 */
export const getTablesWithDetails = createSelector(
  [getTables, getTablesEntity],
  (allIds, byId) => allIds.map((id) => byId[id]),
);
