import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  Button,
  HStack,
  Select,
  Divider,
  ModalBody,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
} from '@chakra-ui/react';

import { getRidersById } from '../orders/redux/selectors';
import {
  getRiders as getRidersAction,
} from '../orders/redux/actions';

/* =============================================================================
<OrderDispatchModal />
============================================================================= */
const OrderDispatchModal = ({
  riders,
  isOpen,
  onClose,
  onSubmit,
  getRiders,
}) => {
  const [rider, setRider] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getRiders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _handleRiderSelect = (e) => {
    setRider(e.target.value);
  };

  const _handleDispatchClick = async () => {
    setLoading(true);
    await onSubmit(rider);
    setLoading(false);
  };

  return (
    <Modal size="2xl" isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent mx={2}>
        <ModalHeader fontSize="lg">Dispatch</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Divider mb={5} />
          <Select placeholder="Select Rider" onChange={_handleRiderSelect}>
            {riders.map((item) => (
              <option key={item.id} value={item.id}>
                {`${item.profile?.firstName} ${item.profile?.lastName}`}
              </option>
            ))}
          </Select>
          <Divider my={5} />
          <HStack alignItems={['flex-end', 'flex-end', 'flex-start']}>
            <Button
              mr={4}
              flex={1}
              color="gray"
              variant="outline"
              colorScheme="gray"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              flex={1}
              onClick={_handleDispatchClick}
              isLoading={loading}
            >
              Set Dispatch
            </Button>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  riders: getRidersById(state),
});

const mapDispatchToProps = {
  getRiders: getRidersAction,
};

/* Export
============================================================================= */
export default connect(mapStateToProps, mapDispatchToProps)(OrderDispatchModal);
