import moment from 'moment';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useFormikContext } from 'formik';

import { getRestaurant } from '../../restaurant/redux/selectors';

/* =============================================================================
<OpeningScheduleInitialize />
============================================================================= */
const OpeningScheduleInitialize = ({ restaurant }) => {
  const { setFieldValue } = useFormikContext();
  const hours = restaurant?.hours;

  useEffect(() => {
    if (hours?.length) {
      setFieldValue('hours', hours.map((hour) => ({
        closed: hour.closed,
        hours: hour.hours.map((h) => ({
          open: moment().startOf('day').minutes(h.open).format('HH:mm'),
          close: moment().startOf('day').minutes(h.close).format('HH:mm'),
        })),
      })));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hours]);

  return null;
};

const mapStateToProps = (state) => ({
  restaurant: getRestaurant(state),
});

/* Export
============================================================================= */
export default connect(mapStateToProps)(OpeningScheduleInitialize);
