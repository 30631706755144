import React from 'react';
import { connect } from 'react-redux';
import {
  Box,
  Text,
  Button,
  HStack,
  Divider,
  useDisclosure,
} from '@chakra-ui/react';
import { Plus } from 'react-feather';

import CreateChoiceModal from '../MenuModals/ChoicesModals/CreateChoiceModal';

import { getRestaurant } from '../../../restaurant/redux/selectors';

/* =============================================================================
<ExtraChoicesHeader />
============================================================================= */
const ExtraChoicesHeader = ({ restaurant }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isBranch = restaurant?.type === 'branch';

  return (
    <>
      <HStack flex={1} justify="space-between">
        <Box>
          <Text fontSize="20px" fontWeight="semibold">Extra Choices</Text>
          <Text fontSize="sm">
            This is where you’ll find the groups of
            choices your customers can add to their items
          </Text>
        </Box>
        {!isBranch && (
          <>
            <Button onClick={onOpen} leftIcon={<Plus size="20px" />}>
              Create Choice Groups
            </Button>
            <CreateChoiceModal isOpen={isOpen} onClose={onClose} />
          </>
        )}
      </HStack>
      <Divider my={4} />
    </>
  );
};

const mapStateToProps = (state) => ({
  restaurant: getRestaurant(state),
});

// eslint-disable-next-line max-len
const propsAreEqual = (prevProps, nextProps) => prevProps.restaurant?.type === nextProps.restaurant?.type;

/* Export
============================================================================= */
export default connect(mapStateToProps)(React.memo(ExtraChoicesHeader, propsAreEqual));
