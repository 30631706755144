import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { normalize } from 'normalizr';
import debounce from 'lodash/debounce';
import {
  Modal,
  Input,
  Divider,
  Spinner,
  useToast,
  FormLabel,
  ModalBody,
  InputGroup,
  FormControl,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  InputRightElement,
} from '@chakra-ui/react';

import EditDiscountModalItem from './EditDiscountModalItem';
import EditDiscountModalFooter from './EditDiscountModalFooter';
import { dish as dishSchema } from '../../../../entities/api/schema';
import request from '../../../../util/request';

import { updateDiscountDishes as updateDiscountDishesAction } from '../../../redux/actions';
import { addEntities as addEntitiesAction } from '../../../../entities/redux/actions';

/* =============================================================================
<EditDiscountModal />
============================================================================= */
const EditDiscountModal = ({
  isOpen,
  onClose,
  discountId,
  addEntities,
  updateDiscountDishes,
}) => {
  const toast = useToast();
  const [query, setQuery] = useState('');
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState();
  const [searching, setSearching] = useState(false);
  const [selected, setSelected] = useState([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const _searchDishes = useCallback(
    debounce((text) => {
      (async () => {
        setSearching(true);
        try {
          const payload = await request({
            url: '/dishes/find',
            method: 'GET',
            params: {
              q: text,
            },
          });
          const { entities, result } = normalize(payload.data, [dishSchema]);

          addEntities(entities);
          setItems(result);
        } catch (e) {
          // TODO
        }
        setSearching(false);
      })();
    }, 400),
    [],
  );

  const _handleChange = (event) => {
    setQuery(event.target.value);
    _searchDishes(event.target.value);
  };

  const _handleSelect = (e, id) => {
    setSelected((prevState) => [...prevState, id]);
  };

  const _handleUnselect = (e, id) => {
    setSelected((prevState) => prevState.filter((item) => item !== id));
  };

  const _handleSubmit = async () => {
    if (!selected?.length) {
      return;
    }

    setLoading(true);

    await updateDiscountDishes(selected, discountId, (err) => {
      if (err) {
        toast({
          title: 'Error',
          description: err.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } else {
        onClose();
        toast({
          title: 'Changes Saved',
          status: 'success',
          duration: 3000,
          isClosable: true,

        });
      }
    });
    setLoading(false);
  };

  return (
    <Modal size="3xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="lg">Edit Discount Dishes</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Divider mb={5} />
          <FormControl mb={8}>
            <FormLabel flex={2.1}>Search Food</FormLabel>
            <InputGroup>
              <Input
                value={query}
                name="searchFood"
                onChange={_handleChange}
              />
              {searching && (
                <InputRightElement>
                  <Spinner size="sm" />
                </InputRightElement>
              )}
            </InputGroup>
          </FormControl>
          {items.map((item) => (
            <EditDiscountModalItem
              id={item}
              key={item}
              selected={selected.includes(item)}
              onSelect={_handleSelect}
              onUnselect={_handleUnselect}
            />
          ))}
          <EditDiscountModalFooter
            isSubmitting={loading}
            onCancel={onClose}
            onSubmit={_handleSubmit}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const mapDispatchToProps = {
  addEntities: addEntitiesAction,
  updateDiscountDishes: updateDiscountDishesAction,
};

/* Export
============================================================================= */
export default connect(null, mapDispatchToProps)(EditDiscountModal);
