import React from 'react';
import { getIn, FastField } from 'formik';
import {
  Input,
  VStack,
  FormLabel,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
} from '@chakra-ui/react';

/* =============================================================================
<OwnerFormName />
============================================================================= */
const OwnerFormName = () => (
  <>
    <FastField name="owner.firstName">
      {({ field, form }) => (
        <FormControl
          mb={8}
          display="flex"
          justifyContent="space-between"
          isInvalid={getIn(form.errors, 'owner.firstName') && getIn(form.touched, 'owner.firstName')}
        >
          <FormLabel flex={1.5}>Owner First Name</FormLabel>
          <VStack alignItems="flex-start" flex={2}>
            <Input
              {...field}
              id="owner.firstName"
            />
            <FormErrorMessage>
              <FormErrorIcon />
              {getIn(form.errors, 'owner.firstName')}
            </FormErrorMessage>
          </VStack>
        </FormControl>
      )}
    </FastField>
    <FastField name="owner.lastName">
      {({ field, form }) => (
        <FormControl
          mb={8}
          display="flex"
          justifyContent="space-between"
          isInvalid={getIn(form.errors, 'owner.lastName') && getIn(form.touched, 'owner.lastName')}
        >
          <FormLabel flex={1.5}>Owner Last Name</FormLabel>
          <VStack alignItems="flex-start" flex={2}>
            <Input
              {...field}
              id="owner.lastName"
            />
            <FormErrorMessage>
              <FormErrorIcon />
              {getIn(form.errors, 'owner.lastName')}
            </FormErrorMessage>
          </VStack>
        </FormControl>
      )}
    </FastField>
  </>
);

/* Export
============================================================================= */
export default OwnerFormName;
