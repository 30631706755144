import { normalize } from 'normalizr';

import request from '../../util/request';
import * as constants from './constants';
import { addEntities } from '../../entities/redux/actions';
import { user as userSchema, role as roleSchema } from '../../entities/api/schema';

/**
 * EMPLOYEES_GET
 */
export const getEmployees = (nextCursor, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.EMPLOYEES_GET.REQUEST });

    const payload = await request({
      url: '/users',
      method: 'GET',
      params: {
        next_cursor: nextCursor,
      },
    });
    const { entities, result } = normalize(payload.data, [userSchema]);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.EMPLOYEES_GET.SUCCESS,
      payload: {
        data: result,
        nextCursor: payload.next_cursor,
      },
    });
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.EMPLOYEES_GET.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.EMPLOYEES_GET.COMPLETE });
  }
};

/**
 * EMPLOYEES_REFRESH
 */
export const refreshEmployees = (cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.EMPLOYEES_REFRESH.REQUEST });

    const payload = await request({
      url: '/users',
      method: 'GET',
    });
    const { entities, result } = normalize(payload.data, [userSchema]);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.EMPLOYEES_REFRESH.SUCCESS,
      payload: {
        data: result,
        nextCursor: payload.next_cursor,
      },
    });
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.EMPLOYEES_REFRESH.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.EMPLOYEES_REFRESH.COMPLETE });
  }
};

/**
 * EMPLOYEE_GET
 */
export const getEmployee = (id, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.EMPLOYEE_GET.REQUEST });

    const payload = await request({
      url: `/users/${id}`,
      method: 'GET',
    });
    const { entities, result } = normalize(payload, userSchema);

    dispatch(addEntities(entities));
    dispatch({ type: constants.EMPLOYEE_GET.SUCCESS });
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.EMPLOYEE_GET.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.EMPLOYEE_GET.COMPLETE });
  }
};

/**
 * EMPLOYEE_CREATE
 */
export const createEmployee = (data, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.EMPLOYEE_CREATE.REQUEST });

    const payload = await request({
      url: '/users',
      method: 'POST',
      data,
    });
    const { entities, result } = normalize(payload, userSchema);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.EMPLOYEE_CREATE.SUCCESS,
      payload: result,
    });
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.EMPLOYEE_CREATE.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.EMPLOYEE_CREATE.COMPLETE });
  }
};

/**
 * EMPLOYEE_UPDATE
 */
export const updateEmployee = (employee, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.EMPLOYEE_UPDATE.REQUEST });

    const payload = await request({
      url: `/users/${employee.id}`,
      method: 'PUT',
      data: employee,
    });
    const { entities } = normalize(payload, userSchema);

    dispatch(addEntities(entities));
    dispatch({ type: constants.EMPLOYEE_UPDATE.SUCCESS });
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.EMPLOYEE_UPDATE.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.EMPLOYEE_UPDATE.COMPLETE });
  }
};

/**
 * ROLES_GET
 */
export const getRoles = (cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.ROLES_GET.REQUEST });

    const payload = await request({
      url: '/users/roles',
      method: 'GET',
    });
    const { entities, result } = normalize(payload.data, [roleSchema]);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.ROLES_GET.SUCCESS,
      payload: result,
    });
    if (typeof cb === 'function') {
      cb(null);
    }
  } catch (error) {
    dispatch({
      type: constants.ROLES_GET.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.ROLES_GET.COMPLETE });
  }
};
