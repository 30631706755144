import React from 'react';
import { FastField } from 'formik';
import {
  Box,
  Input,
  Select,
  VStack,
  FormLabel,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
} from '@chakra-ui/react';

/* =============================================================================
<RestaurantFormDuration />
============================================================================= */
const RestaurantFormDuration = () => (
  <Box pr={[0, 0, 0, '40%']}>
    <FastField name="duration">
      {({ field, form }) => (
        <FormControl
          mb={8}
          display="flex"
          isInvalid={form.errors.duration && form.touched.duration}
        >
          <FormLabel flex={1.5}>Total duration (in minutes)</FormLabel>
          <VStack alignItems="flex-start" flex={2}>
            <Input id="duration" {...field} />
            <FormErrorMessage>
              <FormErrorIcon />
              {form.errors.duration}
            </FormErrorMessage>
          </VStack>
        </FormControl>
      )}
    </FastField>
  </Box>
);

const DURATION_MOCK = [
  '30 Minutes',
  '60 Minutes',
  '90 Minutes',
  '120 Minutes',
];

/* Export
============================================================================= */
export default RestaurantFormDuration;
