import React from 'react';
import {
  FormControl,
  FormLabel,
  Button,
  VStack,
  Input,
  InputLeftAddon,
  FormErrorMessage,
  InputGroup,
  FormErrorIcon,
} from '@chakra-ui/react';
import { Field } from 'formik';
import { Plus } from 'react-feather';

/* =============================================================================
<SingleVariant />
============================================================================= */
const SingleVariant = ({ onAddVariant }) => (
  <Field name="price">
    {({ field, form }) => (
      <FormControl
        my={8}
        display="flex"
        justifyContent="space-between"
        isInvalid={form.errors.price && form.touched.price}
      >
        <FormLabel flex={1.5}>Item Price</FormLabel>
        <VStack alignItems="flex-start" flex={2}>
          <InputGroup>
            <InputLeftAddon>Rs</InputLeftAddon>
            <Input {...field} id="price" />
            <Button
              size="sm"
              mx={10}
              variant="link"
              onClick={onAddVariant}
              leftIcon={<Plus size="18px" />}
            >
              Add Variation
            </Button>
          </InputGroup>
          <FormErrorMessage>
            <FormErrorIcon />
            {form.errors.price}
          </FormErrorMessage>
        </VStack>
      </FormControl>
    )}
  </Field>
);

/* Export
============================================================================= */
export default SingleVariant;
