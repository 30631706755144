import React from 'react';
import {
  Box,
  Input,
  Alert,
  Textarea,
  FormLabel,
  AlertIcon,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
} from '@chakra-ui/react';
import { FastField } from 'formik';

/* =============================================================================
<MenuInformation />
============================================================================= */
const MenuInformation = () => (
  <Box>
    <Alert colorScheme="blue.50" fontSize="sm" status="info" alignItems="flex-start">
      <AlertIcon color="blue.400" />
      Customers will be able to order from a menu between the hours specified.
      Each menu contains items that are grouped by categories.
    </Alert>
    <FastField name="name">
      {({ field, form }) => (
        <FormControl mt={5} isInvalid={form.errors.name && form.touched.name}>
          <FormLabel>Menu Title</FormLabel>
          <Input
            id="name"
            {...field}
            placeholder="Enter title"
          />
          <FormErrorMessage>
            <FormErrorIcon />
            {form.errors.name}
          </FormErrorMessage>
        </FormControl>
      )}
    </FastField>
    <FastField name="about">
      {({ field, form }) => (
        <FormControl my={5} isInvalid={form.errors.about && form.touched.about}>
          <FormLabel>Description</FormLabel>
          <Textarea
            size="lg"
            id="about"
            {...field}
            placeholder="Enter description"
          />
          <FormErrorMessage>
            <FormErrorIcon />
            {form.errors.about}
          </FormErrorMessage>
        </FormControl>
      )}
    </FastField>
  </Box>
);

/* Export
============================================================================= */
export default MenuInformation;
