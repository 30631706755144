import React from 'react';
import { Box, HStack, Text } from '@chakra-ui/react';

/* =============================================================================
<OrderDetailsDishItem />
============================================================================= */
const OrderDetailsDishItem = ({ item }) => {
  const qty = item?.qty || 1;
  const name = item?.name || '';
  const price = item?.price || 0;
  const options = item?.options || [];
  const variant = item?.variant;
  const subtotal = price * qty;

  return (
    <>
      <HStack justify="space-between">
        <Text flex={1} fontWeight="semibold">
          {qty}
          X
        </Text>
        <Text
          flex={6}
          textAlign="left"
          color="brand.700"
          fontWeight="semibold"
        >
          {name}
          {variant ? ` (${variant})` : ''}
        </Text>
        <Text flex={1.7} fontWeight="semibold">
          Rs.
          {price}
        </Text>
        <Text flex={1.6} fontWeight="semibold">
          Rs.
          {subtotal}
        </Text>
      </HStack>
      {variant && (
        <HStack>
          <Box flex={1} />
          <Text flex={9} fontSize="xs" fontWeight="semibold">
            Variant:
            {' '}
            {variant}
          </Text>
        </HStack>
      )}
      {options.map((option, index) => (
        <HStack key={index} justify="space-between">
          <Box flex={1} />
          <Text flex={6} fontSize="xs" fontWeight="semibold">
            {option.name}
            :
            {' '}
            {option?.values?.map((value) => value)?.join(', ')}
          </Text>
          <Box flex={1.7} />
          <Text flex={1.6} fontWeight="semibold">
            +Rs
            {' '}
            {(option.price || 0) * qty}
          </Text>
        </HStack>
      ))}
    </>
  );
};

/* Export
============================================================================= */
export default OrderDetailsDishItem;
