import React from 'react';
import { MessageSquare } from 'react-feather';
import { Box, HStack, Text } from '@chakra-ui/react';

import OrderReceiptItemsListItemExtraChoices from './OrderReceiptItemsListItemExtraChoices';

/* =============================================================================
<OrderReceiptItemsListItem />
============================================================================= */
const OrderReceiptItemsListItem = ({ item }) => (
  <Box my={3}>
    <HStack justify="space-between">
      <Text flex={1} fontWeight="semibold">
        {item?.qty}
        X
      </Text>
      <Text
        flex={10}
        textAlign="left"
        fontWeight="semibold"
      >
        {item?.name}
        {' '}
        {item?.variant && `(${item.variant})`}
      </Text>
      <Text fontWeight="semibold">
        Rs.
        {item?.price}
      </Text>
    </HStack>
    {item?.options?.map((option, i) => <OrderReceiptItemsListItemExtraChoices key={i} option={option} />)}
    {item?.note && (
    <HStack spacing={5} my={3}>
      <MessageSquare fill="#000" />
      <Text fontSize="xl" fontWeight="600">
        !
        {' '}
        {item?.note}
      </Text>
    </HStack>
    )}
  </Box>
);

/* Export
============================================================================= */
export default OrderReceiptItemsListItem;
