import React, { useEffect } from 'react';
import {
  Box, HStack, Text, useToast, VStack,
} from '@chakra-ui/react';
import { ref, getDatabase } from 'firebase/database';
import { useObjectVal } from 'react-firebase-hooks/database';
import { RefreshCw } from 'react-feather';
import { app } from '../config/firebase';

const database = getDatabase(app);

const AppVersion = React.memo(() => {
  const toast = useToast();

  const [currentVersion, loading, error] = useObjectVal(
    ref(
      database,
      process.env.REACT_APP_ENVIRONMENT === 'production'
        ? 'appVersionProd'
        : 'appVersionDev',
    ),
  );

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT === 'local') {
      return;
    }

    if (
      !error
      && !loading
      && currentVersion !== process.env.REACT_APP_GIT_SHA
    ) {
      if (!toast.isActive('app-version')) {
        toast({
          id: 'app-version',
          duration: null,
          isClosable: false,
          position: 'bottom-right',
          render({ id }) {
            return (
              <HStack
                id={id}
                p={4}
                spacing={3}
                color="white"
                boxShadow="lg"
                cursor="pointer"
                userSelect="none"
                borderRadius="lg"
                bgColor="blue.600"
                alignItems="flex-start"
                onClick={() => window.location.reload()}
                _hover={{ bgColor: 'blue.500' }}
                _active={{ bgColor: 'blue.700' }}
              >
                <Box>
                  <RefreshCw width="20px" />
                </Box>
                <VStack spacing={0} alignItems="flex-start">
                  <Text fontSize="lg" fontWeight="bold">New Version Available</Text>
                  <Text>Please refresh now</Text>
                </VStack>
              </HStack>
            );
          },
        });
      }
    }
  }, [currentVersion, loading, toast, error]);

  if (!loading && currentVersion === process.env.REACT_APP_GIT_SHA) {
    if (toast.isActive('app-version')) {
      toast.close('app-version');
    }
  }

  return null;
});

export default AppVersion;
