import { normalize } from 'normalizr';

import request from '../../util/request';
import * as constants from './constants';
import { addEntities } from '../../entities/redux/actions';
import { order as orderSchema, user as userSchema } from '../../entities/api/schema';
import { trackForUser } from '../../util/analytics';
import { getRestaurant } from '../../restaurant/redux/selectors';

const ANALYTIC_EVENTS = {
  accept: 'Order Accepted',
  decline: 'Order Cancelled',
  complete: 'Order Completed',
  dispatch: 'Order Dispatched',
  ready: 'Order Prepared',
};

/**
 * TYPE_SET
 */
export const setType = (payload) => ({
  type: constants.TYPE_SET,
  payload,
});

/**
 * DAYS_FILTER_SET
 */
export const setDaysFilter = (payload) => ({
  type: constants.DAYS_FILTER_SET,
  payload,
});

/**
 * QUERY_SET
 */
export const setQuery = (payload) => ({
  type: constants.QUERY_SET,
  payload,
});

/**
 * ORDERS_GET
 */
export const getOrders = (days, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.ORDERS_GET.REQUEST });

    const payload = await request({
      url: '/orders/feed',
      method: 'GET',
      params: { t: Date.now(), days },
    });
    const { entities, result } = normalize(payload.data, [orderSchema]);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.ORDERS_GET.SUCCESS,
      payload: result,
    });
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.ORDERS_GET.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.ORDERS_GET.COMPLETE });
  }
};

/**
 * ORDER_GET
 */
export const getOrder = (id, cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.ORDER_GET.REQUEST });

    const payload = await request({
      url: `/orders/${id}`,
      method: 'GET',
    });
    const { entities } = normalize(payload, orderSchema);

    dispatch(addEntities(entities));
    dispatch({ type: constants.ORDER_GET.SUCCESS });
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.ORDER_GET.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.ORDER_GET.COMPLETE });
  }
};

/**
 * ORDER_UPDATE
 */
export const updateOrder = (order, cb) => async (dispatch, getState) => {
  try {
    dispatch({ type: constants.ORDER_UPDATE.REQUEST });

    const payload = await request({
      url: `/orders/${order.id}`,
      method: 'PUT',
      data: order,
    });
    const event = ANALYTIC_EVENTS[order.action];

    if (event) {
      const restaurant = getRestaurant(getState());
      const restaurantName = restaurant?.name || restaurant?.chain?.name;
      const restaurantBranch = restaurant?.address?.branch;
      trackForUser(payload.customer.id, event, {
        restaurantId: restaurant?.id,
        restaurantChainId: restaurant?.chain?.id,
        restaurantName,
        restaurantBranch,
        orderType: payload.type,
        status: payload.status,
        dishes: payload.items.map((item) => item.name),
        amount: payload.payment.amount,
        method: payload.payment.method,
        deliveryFee: payload.payment.deliveryFee,
        discount: payload.payment.discount,
        couponDiscount: payload.payment.couponDiscount,
        qrCodeDiscount: payload.payment.qrCodeDiscount,
        tax: payload.payment.vat,
        partner: payload.partner,
        walletAmount: payload.payment.wallet,
        walletEnabled: !!payload.payment.wallet,
        ...(payload.callOrder && {callOrder: true}),
        ...(order.action === 'decline' && { declineReason: payload.declineReason }),
      });
    }

    const { entities } = normalize(payload, orderSchema);

    dispatch(addEntities(entities));
    dispatch({ type: constants.ORDER_UPDATE.SUCCESS });
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.ORDER_UPDATE.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.ORDER_UPDATE.COMPLETE });
  }
};

/**
 * BYKEA_ORDER_UPDATE
 */
export const updateBykeaOrder = (order, cb) => async (dispatch, getState) => {
  try {
    dispatch({ type: constants.BYKEA_ORDER_UPDATE.REQUEST });

    const payload = await request({
      url: `/orders/${order.id}/bykea`,
      method: 'PUT',
      data: order,
    });
    const event = ANALYTIC_EVENTS[order.action];

    if (event) {
      const restaurant = getRestaurant(getState());
      const restaurantName = restaurant?.name || restaurant?.chain?.name;
      const restaurantBranch = restaurant?.address?.branch;
      trackForUser(payload.customer.id, event, {
        restaurantId: restaurant?.id,
        restaurantChainId: restaurant?.chain?.id,
        restaurantName,
        restaurantBranch,
        orderType: payload.type,
        status: payload.status,
        dishes: payload.items.map((item) => item.name),
        amount: payload.payment.amount,
        method: payload.payment.method,
        deliveryFee: payload.payment.deliveryFee,
        discount: payload.payment.discount,
        couponDiscount: payload.payment.couponDiscount,
        qrCodeDiscount: payload.payment.qrCodeDiscount,
        tax: payload.payment.vat,
        partner: payload.partner,
        walletAmount: payload.payment.wallet,
        walletEnabled: !!payload.payment.wallet,
        ...(payload.callOrder && {callOrder: true}),
        ...(order.action === 'decline' && { declineReason: payload.declineReason }),
      });
    }

    const { entities } = normalize(payload, orderSchema);

    dispatch(addEntities(entities));
    dispatch({ type: constants.BYKEA_ORDER_UPDATE.SUCCESS });
    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.BYKEA_ORDER_UPDATE.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.BYKEA_ORDER_UPDATE.COMPLETE });
  }
};

/**
 * ORDER_ADD
 */
export const addOrder = (payload) => ({
  type: constants.ORDER_ADD,
  payload,
});

/**
 * RIDERS_GET
 */
export const getRiders = (cb) => async (dispatch) => {
  try {
    dispatch({ type: constants.RIDERS_GET.REQUEST });

    const payload = await request({
      url: '/users/riders',
      method: 'GET',
    });

    const { entities, result } = normalize(payload.data, [userSchema]);

    dispatch(addEntities(entities));
    dispatch({
      type: constants.RIDERS_GET.SUCCESS,
      payload: result,
    });

    if (typeof cb === 'function') {
      cb(null, payload);
    }
  } catch (error) {
    dispatch({
      type: constants.RIDERS_GET.FAIL,
      error,
    });
    if (typeof cb === 'function') {
      cb(error);
    }
  } finally {
    dispatch({ type: constants.RIDERS_GET.COMPLETE });
  }
};
