import React from 'react';
import { connect } from 'react-redux';
import { Text, HStack, MenuItem } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { LogOut, Settings } from 'react-feather';

import { logout as logoutAction } from '../../../auth/redux/actions';

/* =============================================================================
<HeaderMenu />
============================================================================= */
const HeaderMenu = ({ logout }) => {
  const _handleLogout = () => {
    logout();
  };

  return (
    <>
      {!window.ReactNativeWebView && (
        <MenuItem as={NavLink} to="/restaurant?index=1" px={5} py={3}>
          <HStack>
            <Settings />
            <Text fontWeight="semibold">Settings</Text>
          </HStack>
        </MenuItem>
      )}
      <MenuItem px={5} py={3} onClick={_handleLogout}>
        <HStack>
          <LogOut />
          <Text fontWeight="semibold">Logout</Text>
        </HStack>
      </MenuItem>
    </>
  );
};

const mapDispatchToProps = {
  logout: logoutAction,
};

/* Export
============================================================================= */
export default connect(null, mapDispatchToProps)(HeaderMenu);
