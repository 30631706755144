import React from 'react';
import { HStack, Text } from '@chakra-ui/react';

/* =============================================================================
<OrderReceiptInfoTag />
============================================================================= */
const OrderReceiptInfoTag = ({ title, value, ...props }) => (
  <HStack mb={2} p={2} justify="space-between" {...props}>
    <Text fontWeight="600" fontSize="xl">{title}</Text>
    <Text fontWeight="600" fontSize="xl" textTransform="capitalize">{value}</Text>
  </HStack>
);

/* Export
============================================================================= */
export default OrderReceiptInfoTag;
