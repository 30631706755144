import { HStack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import React, { useState } from 'react';

import Card from '../../common/Card';
import OngoingOrderHeader from '../components/OngoingOrder/OngoingOrderHeader';
import OngoingOrderCard from '../components/OngoingOrder/OngoingOrderCard/index';

import { getOngoingOrders } from '../redux/selectors';

/* =============================================================================
<OngoingOrderScreen />
============================================================================= */
const OngoingOrderScreen = () => {
  const [type, setType] = useState('all');

  const orders = useSelector((state) => getOngoingOrders(state, { type }));

  return (
    <Card h="100%">
      <OngoingOrderHeader type={type} setType={setType} />
      <HStack alignItems="flex-start" spacing={0} flexWrap="wrap">
        {orders?.map(renderOrderCard)}
      </HStack>
    </Card>
  );
};

const renderOrderCard = (order) => <OngoingOrderCard id={order} key={order} />;

/* Export
============================================================================= */
export default OngoingOrderScreen;
