import React from 'react';
import { connect } from 'react-redux';
import { Avatar, Center, Text } from '@chakra-ui/react';

import OrderReceiptInfoTag from './OrderReceiptInfoTag';

import { getOrder } from '../../entities/redux/selectors';

/* =============================================================================
<OrderReceipt />
============================================================================= */
const OrderReceipt = ({ order }) => {
  const orderAmount = order?.payment?.amount || 0;
  const orderWalletAmount = order?.payment?.wallet || 0;
  const orderPaymentMethod = order?.payment?.method === 'card' || orderWalletAmount === orderAmount
    ? 'Online'
    : 'Cash';
  const restaurantLogo = (
    order?.restaurant?.chain?.photos && order?.restaurant?.chain?.photos[0])
    || (order?.restaurant?.photos && order?.restaurant?.photos[0]);

  return (
    <>
      <Center mb={2}>
        {/* <Text fontWeight="bold">{restaurantName}</Text> */}
        <Avatar size="xl" src={restaurantLogo} />
      </Center>
      <OrderReceiptInfoTag title="Payment" value={order?.pos ? order?.payment?.method : orderPaymentMethod} />
    </>
  );
};

const mapStateToProps = (state, { id }) => ({
  order: getOrder(state, { id }),
});

const propsAreEqual = (prevProps, nextProps) => (
  prevProps.order?.payment?.method === nextProps.order?.payment?.method
  && prevProps.order?.payment?.amount === nextProps.order?.payment?.amount
  && prevProps.order?.payment?.wallet === nextProps.order?.payment?.wallet
  && prevProps.order?.restaurant?.photos?.toString()
    === nextProps.order?.restaurant?.photos?.toString()
  && prevProps.order?.restaurant?.chain?.photos?.toString()
    === nextProps.order?.restaurant?.chain?.photos?.toString()
);

/* Export
============================================================================= */
export default connect(mapStateToProps)(React.memo(OrderReceipt, propsAreEqual));
