import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { nanoid } from 'nanoid';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  useToast,
} from '@chakra-ui/react';

import OrderSetupAddReservationNameAndPhone from './OrderSetupAddReservationNameAndPhone';
import OrderSetupAddReservationDineInInfo from './OrderSetupAddReservationDineInInfo';
import OrderSetupAddReservationDateAndTime from './OrderSetupAddReservationDateAndTime';
import OrderSetupAddReservationNote from './OrderSetupAddReservationNote';
import OrderSetupAddReservationFooter from './OrderSetupAddReservationFooter';

import { getRestaurant as selectRestaurant } from '../../../../restaurant/redux/selectors';
import { createBooking as createBookingAction } from '../../../redux/actions';
import { getRestaurant as getRestaurantAction } from '../../../../restaurant/redux/actions';

/* =============================================================================
<OrderSetupAddReservationModal />
============================================================================= */
const OrderSetupAddReservationModal = ({
  isOpen,
  restaurant,
  onClose,
  createBooking,
  getRestaurant,
}) => {
  const toast = useToast();
  const events = restaurant?.booking?.events;
  const bookingFee = restaurant?.booking?.fee;

  // Get restaurant
  useEffect(() => {
    if (isOpen) {
      getRestaurant();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const _handleSubmit = (values) => {
    createBooking({
      ...values,
      uid: nanoid(),
      phone: `+92${values.phone}`,
      amount: values?.event
        ? events?.find((event) => event.name === values.event)?.fee
        : bookingFee,
      createdAt: new Date(),
    });
    onClose();
  };

  return (
    <Modal size="3xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <Formik
          initialValues={initialValues}
          validationSchema={AddReservationSchema}
          onSubmit={_handleSubmit}
        >
          {() => (
            <Form>
              <ModalHeader fontSize="lg">Add Reservation</ModalHeader>
              <ModalCloseButton />
              <ModalBody overflowY="scroll">
                <OrderSetupAddReservationNameAndPhone />
                <OrderSetupAddReservationDineInInfo />
                <OrderSetupAddReservationDateAndTime />
                <OrderSetupAddReservationNote />
              </ModalBody>
              <ModalFooter>
                <OrderSetupAddReservationFooter onClose={onClose} />
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

const initialValues = {
  name: '',
  phone: '',
  seats: '',
  event: '',
  table: '',
  date: '',
  time: '',
  note: '',
};

const AddReservationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Must not be empty!'),
  phone: Yup.number()
    .required('Must not be empty!'),
  seats: Yup.number()
    .required('Must not be empty!'),
  event: Yup.string()
    .optional(),
  date: Yup.string()
    .required('Must not be empty!'),
  time: Yup.string()
    .required('Must not be empty!'),
  note: Yup.string()
    .optional(),
});

const mapStateToProps = (state) => ({
  restaurant: selectRestaurant(state),
});

const mapDispatchToProps = {
  createBooking: createBookingAction,
  getRestaurant: getRestaurantAction,
};

const propsAreEqual = (prevProps, nextProps) => (
  prevProps.isOpen === nextProps.isOpen
  && prevProps.restaurant?.booking?.fee === nextProps.restaurant?.booking?.fee
  && JSON.stringify(prevProps.restaurant?.booking?.events)
  === JSON.stringify(nextProps.restaurant?.booking?.events)
);

/* Export
============================================================================= */
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(OrderSetupAddReservationModal, propsAreEqual));
