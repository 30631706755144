import React from 'react';
import { useHistory, useLocation } from 'react-router';
import {
  Box,
  Text,
  Button,
  HStack,
  Divider,
} from '@chakra-ui/react';

/* =============================================================================
<OpeningScheduleHeader />
============================================================================= */
const OpeningScheduleHeader = ({ isSubmitting }) => {
  const history = useHistory();
  const location = useLocation();
  const paths = location.pathname.split('/').slice(1);

  const _handleSkip = () => {
    history.push('/setup/done');
  };

  return (
    <>
      <HStack justify="space-between">
        <Box>
          <Text fontSize="lg" fontWeight="semibold">Opening Times</Text>
          <Text fontSize="sm">
            Manage your operating schedule and
            update your special opening time
          </Text>
        </Box>
        <HStack>
          {paths[0] === 'setup' && (
            <Button
              colorScheme="gray"
              variant="outline"
              onClick={_handleSkip}
            >
              will do later
            </Button>
          )}
          <Button
            type="submit"
            isLoading={isSubmitting}
          >
            {paths === 'setup' ? 'Continue' : 'Save Changes'}
          </Button>
        </HStack>
      </HStack>
      <Divider my={5} />
    </>
  );
};

/* Export
============================================================================= */
export default OpeningScheduleHeader;
