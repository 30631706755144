import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useFormikContext } from 'formik';

import { getDish, getMenuItems } from '../../../../entities/redux/selectors';

/* =============================================================================
<EditDishModalInitialize />
============================================================================= */
const EditDishModalInitialize = ({ id, dish, menuItems }) => {
  const { resetForm } = useFormikContext();

  const name = dish?.name;
  const about = dish?.about;
  const photo = dish?.photos && dish?.photos[0];
  const price = dish?.price;
  const variants = dish?.variants;
  const variantsStr = dish?.variants?.map((variant) => variant.name + variant.price).join(',');
  const choiceGroups = dish?.choiceGroups;
  const choiceGroupsStr = dish?.choiceGroups?.map((choiceGroup) => choiceGroup).join(',');
  const menuItem = Object.values(menuItems).find((item) => item.dishes?.includes(id)).id;

  // Set default values
  useEffect(() => {
    resetForm({
      values: {
        name,
        about,
        price,
        photoUrl: photo,
        variants,
        choiceGroups,
        menuItem,
      },
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    name,
    about,
    price,
    photo,
    variantsStr,
    choiceGroupsStr,
    menuItem,
  ]);

  return null;
};

const mapStateToProps = (state, { id }) => ({
  dish: getDish(state, { id, normalize: true }),
  menuItems: getMenuItems(state),
});

/* Export
============================================================================= */
export default connect(mapStateToProps)(EditDishModalInitialize);
