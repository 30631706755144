import { actionGenerator, createOfflineAction } from '../../util/reduxHelpers';

export const DISH_GET = actionGenerator('POS/DISH_GET');
export const RIDERS_GET = actionGenerator('POS/RIDERS_GET');
export const WAITERS_GET = actionGenerator('POS/WAITERS_GET');

export const ORDER_ADD = 'ORDERS/ORDER_ADD';
export const ORDER_REMOVE = 'ORDERS/ORDER_REMOVE';
export const ORDERS_GET = actionGenerator('POS/ORDERS_GET');
export const ORDER_PLACE = createOfflineAction('POS/ORDER_PLACE');
export const ORDER_UPDATE = createOfflineAction('POS/ORDER_UPDATE');
export const ORDER_ITEMS_UPDATE = createOfflineAction('POS/ORDER_ITEMS_UPDATE');
export const ORDER_READY = createOfflineAction('POS/ORDER_READY');
export const ORDER_DISPATCH = createOfflineAction('POS/ORDER_DISPATCH');
export const ORDER_COMPLETE = createOfflineAction('POS/ORDER_COMPLETE');
export const ORDER_DELETE = createOfflineAction('POS/ORDER_DELETE');

export const BOOKINGS_GET = actionGenerator('POS/BOOKINGS_GET');
export const BOOKINGS_REFRESH = actionGenerator('POS/BOOKINGS_REFRESH');
export const BOOKING_CREATE = createOfflineAction('POS/BOOKING_CREATE');
export const BOOKING_UPDATE = createOfflineAction('POS/BOOKING_UPDATE');
export const BOOKING_CANCEL = createOfflineAction('POS/BOOKING_CANCEL');
export const BOOKING_COMPLETE = createOfflineAction('POS/BOOKING_COMPLETE');
