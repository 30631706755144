import React from 'react';
import {
  Tr,
  Box,
  Table,
  Thead,
  Tbody,
} from '@chakra-ui/react';

import TodayOrderTableItem from './TodayOrderTableItem';
import TodayOrderTableHeadItem from './TodayOrderTableHeadItem';

/* =============================================================================
<TodayOrderTable />
============================================================================= */
const TodayOrderTable = ({ data }) => (
  <Box flex={1}>
    <Table variant="simple" colorScheme="gray">
      <Thead>
        <Tr>
          {COLUMNS.map(renderColumn)}
        </Tr>
      </Thead>
      <Tbody>
        {data.map(renderItem)}
      </Tbody>
    </Table>
  </Box>
);

const renderColumn = (item, i) => <TodayOrderTableHeadItem key={`${i}`} title={item} />;
const renderItem = (item) => <TodayOrderTableItem key={item} id={item} />;

const COLUMNS = [
  'Name',
  'Type',
  'CREATED AT',
  'Price',
  'ACTION',
];

/* Export
============================================================================= */
export default TodayOrderTable;
