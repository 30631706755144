import React from 'react';
import { connect } from 'react-redux';
import { useToast } from '@chakra-ui/toast';
import { Form, Formik } from 'formik';

import moment from 'moment';
import OpeningScheduleDay from './OpeningScheduleDay/index';
import OpeningScheduleHeader from './OpeningScheduleHeader';
import OpeningScheduleInitialize from './OpeningScheduleInitialize';

import { updateRestaurantHours as updateRestaurantHoursAction } from '../../restaurant/redux/actions';

/* =============================================================================
<OpeningScheduleScreen />
============================================================================= */
const OpeningScheduleScreen = ({ updateRestaurantHours }) => {
  const toast = useToast();

  const _handleSubmit = async (values) => {
    const hours = values.hours.map((hour) => ({
      closed: hour.closed,
      hours: hour.hours.map((h) => ({
        open: (moment(h.open, 'hh:mm').hours() * 60) + moment(h.open, 'hh:mm').minutes(),
        close: (moment(h.close, 'hh:mm').hours() * 60) + moment(h.close, 'hh:mm').minutes(),
      })),
    }));

    await updateRestaurantHours(hours, (err) => {
      if (!err) {
        toast({
          title: 'Changes Saved',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: err.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    });
  };

  return (
    <Formik initialValues={INITIAL_VALUES} onSubmit={_handleSubmit}>
      {({ values, isSubmitting }) => (
        <Form>
          <OpeningScheduleHeader isSubmitting={isSubmitting} />
          {values.hours.map((day, index) => (
            <OpeningScheduleDay
              key={index}
              day={day}
              index={index}
            />
          ))}
          <OpeningScheduleInitialize />
        </Form>
      )}
    </Formik>
  );
};

const INITIAL_VALUES = {
  hours: [
    {
      closed: false,
      hours: [
        { open: '00:00', close: '23:59' },
      ],
    },
    {
      closed: false,
      hours: [
        { open: '00:00', close: '23:59' },
      ],
    },
    {
      closed: false,
      hours: [
        { open: '00:00', close: '23:59' },
      ],
    },
    {
      closed: true,
      hours: [
        { open: '00:00', close: '23:59' },
      ],
    },
    {
      closed: false,
      hours: [
        { open: '00:00', close: '23:59' },
      ],
    },
    {
      closed: true,
      hours: [
        { open: '00:00', close: '23:59' },
      ],
    },
    {
      closed: false,
      hours: [
        { open: '00:00', close: '23:59' },
      ],
    },
  ],
};

const mapDispatchToProps = {
  updateRestaurantHours: updateRestaurantHoursAction,
};

/* Export
============================================================================= */
export default connect(null, mapDispatchToProps)(OpeningScheduleScreen);
