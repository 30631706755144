import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Tr,
  Td,
  Circle,
  Button,
  useDisclosure,
  HStack,
  useToast,
} from '@chakra-ui/react';
import { Archive, Edit } from 'react-feather';

import InventoryEditModal from '../InventoryModals/InventoryEditModal/index';
import InventoryRestockModal from '../InventoryModals/InventoryRestockModal';

import { getInventory } from '../../../entities/redux/selectors';
import { deleteInventory as deleteInventoryAction } from '../../redux/actions';

/* =============================================================================
<InventoryTableItem />
============================================================================= */
const InventoryTableItem = ({ restaurant, inventory, deleteInventory }) => {
  const {
    isOpen: isRestockModalOpen,
    onOpen: onRestockModalOpen,
    onClose: onRestockModalClose,
  } = useDisclosure();
  const {
    isOpen: isEditModalOpen,
    onOpen: onEditModalOpen,
    onClose: onEditModalClose,
  } = useDisclosure();

  const toast = useToast();
  const isBranch = restaurant?.type === 'branch';

  const id = inventory ? inventory.id : '';
  const name = inventory ? inventory.name : '';
  const unit = inventory ? inventory.unit : '';
  const price = inventory ? inventory.price : '';
  const amount = inventory ? inventory.amount : '';
  const createdAt = inventory?.createdAt
    ? moment().minutes(inventory.createdAt).format('LL') : '';

  const _handleDeleteInventory = () => {
    deleteInventory(id, (err) => {
      if (!err) {
        toast({
          title: 'Inventory deleted',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: err.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    });
  };

  return (
    <Tr>
      <Td>
        <Circle
          size="40px"
          color="#027A48"
          fontWeight="medium"
          backgroundColor="#ECFDF3"
        >
          {`#${id}`}
        </Circle>
      </Td>
      <Td>{name}</Td>
      <Td>{unit}</Td>
      <Td textAlign="start">{price}</Td>
      <Td textAlign="start">{amount}</Td>
      <Td>{createdAt}</Td>
      {isBranch && (
        <Td>
          <Button onClick={onRestockModalOpen} variant="light" leftIcon={<Archive size="18px" />}>
            Restock
          </Button>
          <InventoryRestockModal isOpen={isRestockModalOpen} onClose={onRestockModalClose} />
        </Td>
      )}
      {!isBranch && (
        <Td>
          <HStack>
            <Button
              variant="outline"
              colorScheme="gray"
              onClick={_handleDeleteInventory}
            >
              Delete
            </Button>
            <Button
              onClick={onEditModalOpen}
              leftIcon={<Edit size="18px" color="#A32821" />}
            >
              Edit
            </Button>
          </HStack>
          <InventoryEditModal
            id={id}
            isOpen={isEditModalOpen}
            onClose={onEditModalClose}
          />
        </Td>
      )}
    </Tr>
  );
};

const mapStateToProps = (state, { id }) => ({
  inventory: getInventory(state, { id }),
});

const mapDispatchToProps = {
  deleteInventory: deleteInventoryAction,
};

// eslint-disable-next-line max-len
const propsAreEqual = (prevProps, nextProps) => prevProps.inventory?.name === nextProps.inventory?.name
  && prevProps.inventory?.price === nextProps.inventory?.price
  && prevProps.inventory?.amount === nextProps.inventory?.amount
  && prevProps.inventory?.unit === nextProps.inventory?.unit;

/* Export
============================================================================= */
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(InventoryTableItem, propsAreEqual));
