import React from 'react';
import { X as XIcon, Bell as BellIcon } from 'react-feather';
import {
  Box,
  Text,
  Flex,
  Button,
} from '@chakra-ui/react';

/* =============================================================================
<NotificationToast />
============================================================================= */
const NotificationToast = ({
  title,
  body,
  onClose,
  onClick,
}) => {
  const _handleClick = () => {
    onClose();
    onClick();
  };

  const _handleCloseClick = (event) => {
    event.stopPropagation();
    onClose();
  };

  return (
    <Flex
      justifyContent="flex-start"
      color="white"
      bgColor="brand.800"
      borderRadius="lg"
      onClick={_handleClick}
    >
      <Box pt={5} pl={4}>
        <BellIcon color="#fff" />
      </Box>
      <Box flex={1} p={4}>
        <Text fontWeight="semibold">{title}</Text>
        <Text>{body}</Text>
      </Box>
      <Button pt={3} variant="unstyled" onClick={_handleCloseClick}>
        <XIcon size={16} color="#fff" />
      </Button>
    </Flex>
  );
};

/* Export
============================================================================= */
export default NotificationToast;
