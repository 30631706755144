import React from 'react';
import { Plus, Trash } from 'react-feather';
import {
  Text,
  Box,
  Input,
  Select,
  HStack,
  Divider,
  Button,
  FormLabel,
  InputGroup,
  FormControl,
  FormErrorIcon,
  InputLeftAddon,
  FormErrorMessage,
} from '@chakra-ui/react';
import {
  FastField,
  FieldArray,
  useFormikContext,
  getIn,
} from 'formik';

/* =============================================================================
<ChoiceAddChoices />
============================================================================= */
const ChoiceAddChoices = () => {
  const { values, errors } = useFormikContext();
  const error = errors.choices;

  return (
    <Box>
      <Divider my={5} />
      <Text fontSize="sm" fontWeight="semibold">Choices</Text>
      <Text fontSize="sm">
        Add choices for your customer to pick from, for example, French Fries,
        or Salad. You can also add existing menu items as a choice.
      </Text>
      {Boolean(typeof error === 'string') && (
        <Text color="red" fontSize="xs">
          {error}
        </Text>
      )}
      <FieldArray name="choices">
        {(arrayHelper) => (
          <Box>
            {values.choices && values.choices.map((choice, index) => (
              <HStack mt={5} spacing={5} alignItems="flex-end">
                <FastField name={`choices.${index}.available`}>
                  {({ field, form }) => (
                    <FormControl isInvalid={getIn(form.errors, `choices.${index}.available`) && getIn(form.touched, `choices.${index}.available`)}>
                      <FormLabel>Status</FormLabel>
                      <Select {...field}>
                        <option value>Available</option>
                        <option value={false}>Unavailable</option>
                      </Select>
                      <FormErrorMessage>
                        <FormErrorIcon />
                        {getIn(form.errors, `choices.${index}.available`)}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </FastField>
                <FastField name={`choices.${index}.name`}>
                  {({ field, form }) => (
                    <FormControl isInvalid={getIn(form.errors, `choices.${index}.name`) && getIn(form.touched, `choices.${index}.name`)}>
                      <FormLabel>Choice Name</FormLabel>
                      <Input {...field} />
                      <FormErrorMessage>
                        <FormErrorIcon />
                        {getIn(form.errors, `choices.${index}.name`)}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </FastField>
                <FastField name={`choices.${index}.price`}>
                  {({ field, form }) => (
                    <FormControl isInvalid={getIn(form.errors, `choices.${index}.price`) && getIn(form.touched, `choices.${index}.price`)}>
                      <FormLabel>Price</FormLabel>
                      <InputGroup>
                        <InputLeftAddon>Rs</InputLeftAddon>
                        <Input {...field} />
                      </InputGroup>
                      <FormErrorMessage>
                        <FormErrorIcon />
                        {getIn(form.errors, `choices.${index}.price`)}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </FastField>
                <Button
                  variant="outline"
                  colorScheme="gray"
                  onClick={() => arrayHelper.remove(index)}
                >
                  <Trash size="45px" color="#84818A" />
                </Button>
              </HStack>
            ))}
            <Button
              flex={1}
              size="sm"
              pt={4}
              mx={2}
              variant="link"
              leftIcon={<Plus size="18px" />}
              onClick={() => arrayHelper.push({ name: '', price: '', available: true })}
            >
              Add Choice
            </Button>
          </Box>
        )}
      </FieldArray>
      <Divider my={5} />
    </Box>
  );
};

/* Export
============================================================================= */
export default ChoiceAddChoices;
