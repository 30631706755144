import React from 'react';
import { connect } from 'react-redux';
import { CheckSquare, Square, Check } from 'react-feather';
import {
  Box,
  Text,
  Image,
  HStack,
  ListItem,
} from '@chakra-ui/react';

import { getDish, getMenu } from '../../../entities/redux/selectors';

/* =============================================================================
<AddFeaturedItemModalItem />
============================================================================= */
const AddFeaturedItemModalItem = ({
  id,
  menu,
  dish,
  selected,
  onSelect,
  onUnselect,
}) => {
  const name = dish?.name || '';
  const about = dish?.about || '';
  const photo = dish?.photos && dish.photos[0];
  const price = dish?.variants?.length ? dish.variants[0]?.price : dish?.price;
  const disabled = menu?.featured?.includes(id);

  const _handleSelect = async (e) => {
    onSelect(e, id);
  };

  const _handleUnselect = async (e) => {
    onUnselect(e, id);
  };

  return (
    <ListItem
      mb={4}
      bg="white"
      display="flex"
      height={height}
      overflow="hidden"
      borderRadius="lg"
      alignItems="center"
      border="1px solid #E4E7EC"
      justifyContent="space-between"
    >
      <HStack flex={1} px={6}>
        {disabled ? (
          <Check size={20} />
        ) : selected ? (
          <CheckSquare size={20} onClick={_handleUnselect} />
        ) : (
          <Square size={20} onClick={_handleSelect} />
        )}
        <Box flex={1} pl={5}>
          <Text fontWeight="semibold">{name}</Text>
          <Text my="4px" fontSize="sm">{about}</Text>
          <Text fontWeight="semibold">
            {price}
            {' '}
            Rs
          </Text>
        </Box>
      </HStack>
      <Image w="auto" h="100%" src={photo} />
    </ListItem>
  );
};

const height = '146px';

const mapStateToProps = (state, { id, menuId }) => ({
  dish: getDish(state, { id, normalize: true }),
  menu: getMenu(state, { id: menuId, normalize: true }),
});

const propsAreEqual = (prevProps, nextProps) => prevProps.id === nextProps.id
  && prevProps.selected === nextProps.selected
  && prevProps.dish?.name === nextProps.dish?.name
  && prevProps.dish?.about === nextProps.dish?.about
  && prevProps.dish?.price === nextProps.dish?.price
  && prevProps.dish?.photos?.toString() === nextProps.dish?.photos?.toString()
  && prevProps.menu?.featured?.toString() === nextProps.menu?.featured?.toString()
  // eslint-disable-next-line max-len
  && (prevProps.dish?.variants && prevProps.dish?.variants[0]?.price) === (nextProps.dish?.variants && nextProps.dish?.variants[0]?.price);

/* Export
============================================================================= */
export default connect(mapStateToProps)(React.memo(AddFeaturedItemModalItem, propsAreEqual));
