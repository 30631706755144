import React from 'react';
import {
  Text,
  Input,
  FormLabel,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
} from '@chakra-ui/react';
import { FastField } from 'formik';

/* =============================================================================
<ChoiceName />
============================================================================= */
const ChoiceName = () => (
  <FastField name="name">
    {({ field, form }) => (
      <FormControl mb={8} isInvalid={form.errors.name && form.touched.name}>
        <FormLabel>Name</FormLabel>
        <Input
          {...field}
          placeholder="Enter Name"
        />
        <FormErrorMessage>
          <FormErrorIcon />
          {form.errors.name}
        </FormErrorMessage>
      </FormControl>
    )}
  </FastField>
);

/* Export
============================================================================= */
export default ChoiceName;
